<template>
    <div class="profilePhoto">
        <h2 class="title">Update your Photo</h2>
        <p>Allowed file types: JPG or PNG. File dimensions should be over 600px X 600px </p>
        <div id="drop-zone">
            <label class="hideable file-upload">Upload a file by dragging and dropping a file here.
                <input class="hideable" type="file" id="pic" accept="image/jpeg,image/png" ref="file" @change="storeFile(null)">
            </label>
            <div class="progress hideable">
                <div id="progress-bar" class="progress-bar progress-bar-striped" role="progressbar" style="" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <a @click="sendImage" class="btn btn-primary disabled crop">Crop and Save</a>
            </div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {
    data() {
        return {
            cropInfo: null,
            imageUuid: null
        }
    },
    mounted() {
        this.initDropZone();
    },
    methods: {
        ...mapActions(['updateAvatarUrl']),
        updateAndReset(response) {
            this.updateAvatarUrl(response.data.profileImage);
            this.$store.commit("user/updateAvatar", response.data.profileImage);
            this.$router.push({ name: 'profile' });
            $('#picture').remove();
            $('.hideable').show();
            $('#progress-bar').attr('aria-valuenow', 0).css('width', 0);
            $('a.crop').addClass('disabled');
            $('html, body').animate({
                scrollTop: 0
            }, 400);
            this.flashMessage({ m: "Profile Photo updated", d: 1000 });
            if (this.isMobile()) {
                this.$router.push({ name: 'account' });
            }
        },
        sendImage() {
            let loader = this.$loading.show({ container: $('#drop-zone')[0] });
            axios.post('/api/add_profile_image', {
                    "imageUuid": this.imageUuid,
                    "w": this.cropInfo.width,
                    "h": this.cropInfo.height,
                    "x": this.cropInfo.x,
                    "y": this.cropInfo.y
                })
                .then((response) => {
                    $('#picture').rcrop('destroy');
                    if (response.data.status == 1) {
                        this.updateAndReset(response);
                    } else {
                        this.flashMessage({ m: response.data.error, e: 1, d: 3500 });
                        setTimeout(function() {
                            window.location.reload();
                        }, 3000);
                    }
                    loader.hide();
                })
        },
        initRCrop() {
            var self = this;

            $('#picture').rcrop({
                minSize: [100, 100],
                preserveAspectRatio: true,
            });
            $('#picture').on('rcrop-ready', function() {
                self.cropInfo = $('#picture').rcrop('getValues');
                var circle = $('<div class="cropCircle"></div>');
                $('.rcrop-croparea').append(circle);
            });
            $('#picture').on('rcrop-changed', function() {
                self.cropInfo = $('#picture').rcrop('getValues');
            });
            $('a.crop.disabled').removeClass('disabled');
        },
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        initDropZone() {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.preventDefaults, false)
                document.body.addEventListener(eventName, this.preventDefaults, false)
            });
            ['dragenter', 'dragover'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.highlight, false)
            });
            ['dragleave', 'drop'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.unhighlight, false)
            });

            // Handle dropped files
            document.getElementById("drop-zone").addEventListener('drop', this.handleDrop, false)
        },
        highlight(e) {
            document.getElementById("drop-zone").classList.add('highlight')
        },
        unhighlight(e) {
            document.getElementById("drop-zone").classList.remove('active')
        },
        handleDrop(e) {
            var dt = e.dataTransfer
            var file = dt.files[0];
            this.storeFile(file);
        },
        async store(file) {
            // axios.defaults.headers.common["Authorization"] = `Bearer ${window.token}`
            const response = await axios.post('/vapor_signed_url', {
                'bucket': '',
                'content_type': file.type,
                'expires': ''
            });

            var instance = axios.create();
            instance.defaults.headers.common = {};
            const s3Response = await instance.put(response.data.url, file, {
                headers: response.data.headers,
                onUploadProgress: (progressEvent) => {
                    var width = ((progressEvent.loaded / progressEvent.total) * 100);
                    if (width > 98) {
                        $('.hideable').hide();
                        $('#drop-zone').addClass('noBorder');
                    }
                    $('#progress-bar').attr('aria-valuenow', width).css('width', width + '%');
                }
            });
            return response.data;
        },
        storeFile(file) {
            if (null == file) {
                file = this.$refs.file.files[0];
            }
            this.store(file).then(response => {
                var url = response.url.split('?');
                $('.hideable').hide();
                $('#drop-zone').addClass('noBorder');
                this.imageUuid = response.uuid;

                var img = $('<img id="picture" src="' + url[0] + '">');
                $('#drop-zone').append(img);
                var self = this;
                $('#picture').on('load', function() {
                    self.initRCrop();
                });
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.profilePhoto {
    p {
        padding-left: 15px;
    }
}

div.cropCircle {
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: radial-gradient(transparent 70%, rgba(256, 256, 256, .5) 70%);
}

.progress {
    background-color: transparent;
}

a.crop.disabled {
    display: none !important;
}

</style>
