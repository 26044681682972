<template>
    <nav class="navbar navbar-custom fixed-top navbar-expand-lg navbar-light" role="navigation">
        <!-- Hide this on the home page -->
        <div class="searchFromNav">
            <search></search>
        </div>
        <div class="container">
            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/" class="navbar-brand"><img src="https://d1812kujpqokfk.cloudfront.net/assets/logo.svg"></router-link>
            <a v-if="isMobile()" data-toggle="collapse" class="mobileSearch" @click="showSearch"><img src="https://d1812kujpqokfk.cloudfront.net/assets/search1.svg"></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="my-1 mx-2 close">X</span>
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                	
                    <li class=" nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true">
                            About
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        	<!-- <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/p/about`" class="nav-link a">About Us</router-link> -->
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/how_it_works" class="nav-link a">How it works</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/blog`" class="nav-link a">Blog</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/p/faq`" class="nav-link a">FAQ</router-link>
                            
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/cause/new" class="nav-link">{{ $t("nav.start") }}</router-link>
                    </li>
                     <li class="nav-item">
                        <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/causes" class="nav-link">Causes</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/gifts?orderby=Recent" class="nav-link">{{ $t("nav.shop") }}</router-link>
                    </li>
                    
                    <li v-if="!isMobile()" class="nav-item searchLink"><a @click="showSearch" class="nav-link">Search</a>
                    </li>
                    <li class=" nav-item dropdown user" v-if="!isMobile()">
                        <a v-if="currentUser" class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true">
                            <img class="userIdenticon" :src="currentUser.avatar_url ? currentUser.avatar_url : getCloudFrontUrl() + 'identicons/' + currentUser.identicon"><span v-if="messageCount" class="mc">{{messageCount}}</span>{{currentUser.name}}
                        </a>
                        <a v-if="!currentUser" href="/login" class="nav-link">{{ $t("nav.sign in") }}</a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/conversations`" class="nav-link a">{{ $t("nav.messages") }}<span v-if="messageCount" class="mc mob">{{messageCount}}</span></router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/liked_causes`" class="nav-link a">{{ $t("nav.liked causes") }}</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/liked_items`" class="nav-link a">{{ $t("nav.liked items") }}</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/causes`" class="nav-link a">{{ $t("nav.my causes") }}</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/gifts`" class="nav-link a">{{ $t("nav.my items") }}</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/purchases`" class="nav-link a">{{ $t("nav.purchases") }}</router-link>
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/profile`" class="nav-link a account">{{ $t("nav.account") }}</router-link>
                            <a class="nav-link a" data-toggle="collapse" data-target=".navbar-collapse.show" @click="logout()" onclick="">{{ $t("nav.sign out") }}</a>
                        </div>
                    </li>
                    <template v-if="isMobile()">
                        <li class=" nav-item" v-if="currentUser">
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account/conversations`" class="nav-link">{{ $t("nav.messages") }}<span v-if="messageCount" class="mc mob">{{messageCount}}</span></router-link>
                        </li>
                        <li class=" nav-item" v-if="currentUser">
                            <router-link data-toggle="collapse" data-target=".navbar-collapse.show" :to="`/account`" class="nav-link">{{ $t("nav.account") }}</router-link>
                        </li>
                        <li class=" nav-item" v-if="currentUser">
                            <a class="nav-link" @click="logout()" onclick="">{{ $t("nav.sign out") }}</a>
                        </li>
                        <li class=" nav-item" v-if="!currentUser">
                            <a href="/login" class="nav-link">{{ $t("nav.sign in") }}</a>
                        </li>
                    </template>
                    <!-- <li v-if="!currentUser && $route.path != '/register'" class="nav-item">
                        <router-link data-toggle="collapse" data-target=".navbar-collapse.show" to="/register" class="nav-link register">Sign Up</router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import Search from '../search/Searchbox';
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('user');
export default {
    computed: {
        ...mapGetters(['currentUser']),
        ...mapState({ messageCount: state => state.channel.unread })
    },
    components: {
        Search
    },
    mounted() {
        this.init()
    },
    methods: {
        ...mapActions(['logout']),
        init() {
            this.setHeaderColor();
            var self = this;
            $('.navbar').on("click", 'li:not(.searchLink),.navbar-brand,.navbar-toggler span', function() {
                self.hideSearch();
            });
            $('#navbarNavDropdown').on('show.bs.collapse', function() {
                $('.mobileSearch').hide();
            });
            $('#navbarNavDropdown').on('hidden.bs.collapse', function() {
                $('.mobileSearch').show();
            });
        },
        setHeaderColor() {
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll >= 10) {
                    $("nav").addClass("scrolled");
                }
                if (scroll == 0) {
                    $("nav").removeClass("scrolled");
                }
            });
        },
        showSearch() {
            // See Searchbox.vue for how this is removed when user clicks search choice
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
            var backdrop = '<div class="modal-backdrop fade show"></div>';
            $('body').append(backdrop);
            $('.searchFromNav').show();
            $('#searchInput').focus();
            $('.modal-backdrop').on("click", function() {
                $('.searchFromNav').hide();
                $('#searchInput').val('');
                $(this).remove();
            })

        },
        hideSearch() {
            $('.modal-backdrop').remove();
            $('#searchInput').val('');
            $('.searchFromNav').hide();
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

body.homeOnly {
    nav.navbar-custom #navbarNavDropdown .nav-item {
        @include media-breakpoint-up(lg) {
            width: 33%;
        }
    }
}

.searchFromNav {
    width: 300px;
    margin-left: -150px;
    position: fixed;
    top: 150px;
    left: 50%;
    z-index: 2000;
    display: none;
}

nav.navbar-custom {
    background-color: #fff;
    z-index: 1100;

    a span.mc {
        display: none;

        background-color: $red;
        color: #fff !important;
        // padding:3px;
        border-radius: 50%;
        position: relative;
        left: -10px;
        text-align: center;
        top: -9px;
        // right: -3px;
        font-size: 11px;
        line-height: 12px;
        width: 12px;
        height: 12px;

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    .mobileSearch {
        // color: #51506F;
        // font-size: 16px;
        // opacity: 0.6;
        // font-weight: normal;
        position: absolute;
        right: 20%;
        padding: 0;
        margin: 0;
        margin-left: -25px;
        opacity: 0.6;

        @media only screen and (min-width: 350px) {
            right: 17%;
        }

        @include media-breakpoint-up(md) {
            right: 13%;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }

    // height: 60px;
    .navbar-toggler {
        border: none;
        outline: none;
        box-shadow: none;
    }

    .navbar-toggler-icon {
        // modifying the stroke color from the bootstrap icon
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 95, 88)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h16M4 24h24'/%3E%3C/svg%3E");
    }

    .navbar-toggler[aria-expanded="true"]>.close {
        color: $red;
        display: inline;
        opacity: 1;
    }

    .navbar-toggler[aria-expanded="false"]>.close {
        display: none;
    }

    .navbar-toggler[aria-expanded="true"]>.navbar-toggler-icon {
        display: none;
    }

    &.scrolled {
        // background: $white;
        box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    }

    .navbar-brand {
        padding-top: 0;
        padding-left: 3px;

        img {
            height: 19px;
        }

        @include media-breakpoint-up(sm) {
            padding-left: 18px;
        }

        // @include media-breakpoint-up(md) {
        // 	padding-left: 18px;
        // }
        @include media-breakpoint-up(lg) {
            padding-left: 3px;
            margin-top: -5px;
        }
    }

    #navbarNavDropdown {
        width: 100%;
        // background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/jbpanda.png'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/bulb.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/cc.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/moneypot.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/red.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/stars.svg'), url('https://d1812kujpqokfk.cloudfront.net/assets/home/icons/npug.png');
        // background-attachment: fixed;
        // background-position: 82% 15%, 7% 41%, 97% 35%, 1% 9%, 86% 67%, 36% 56%, 50% 30%;
        // background-repeat: no-repeat;
        // background-size: 4%, 4%, 4%, 4%, 2%, 4%, 5%;

        // @include media-breakpoint-up(sm) {
        //     background-position: 82% 15%, 7% 41%, 97% 35%, 1% 9%, 86% 67%, 36% 56%, 50% 20%;
        //     background-size: 3%, 3%, 3%, 3%, 1%, 3%, 4%;
        // }

        // @include media-breakpoint-down(md) {
        //     padding-bottom: 50px;
        // }

        .navbar-nav {
            width: 100%;
        }

        .dropdown-menu {
            background-color: #fff;
            z-index: 3000;
            border: none;
            border-radius: 12px;
            box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.17);
            padding: 10px 15px;

            .nav-link.a {
                color: $bluetext;
                line-height: 30px;
                opacity: 1;
                cursor: pointer;
                font-weight: 500;
                &:hover {
                	background-color: $blue;
                	border-radius: 8px;
                }
            }

            .nav-link.account {
                margin-top: 10px;
                border-top: 1px solid lighten(#51506F, 50%);

            }
        }

        .nav-item {
            // width: 90%;
            text-align: center;

            @include media-breakpoint-down(md) {
                padding-bottom: 30px;
            }

            @include media-breakpoint-up(lg) {
                width: 25%;

                &.searchLink {

                    img {
                        height: 100%;
                        padding: 8%;
                        cursor: pointer;
                    }
                }
            }

            a.nav-link {
                color: adjust-hue($bluetext, 20);
                font-family: $cat;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                position: relative;

                .mob {
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    left: -1px;
                }

                @include media-breakpoint-up(lg) {
                    // color: $grey;
                    font-size: 17px;
                    opacity: 0.6;
                    padding-left:0;
                    padding-right:0;
                }

                &.login {
                    color: $buttonblue;
                }

                // &.register {
                //     color: $red;
                //     font-size: 14px;
                //     font-weight: bold !important;
                //     font-family: $cat;
                //     text-align: center;
                //     // width: 120px;
                //     // height: 40px;
                //     padding: 10px;
                //     border-radius: 20px;
                //     background-color: $white;
                //     opacity: 1;
                //     // box-shadow: 6px 4px 14px 0 rgba(72,152,254,0.12);
                // }
            }
        }

        span {
            color: $grey;
        }
    }
}

</style>
