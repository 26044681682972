import Checkout from '../../components/checkout/Checkout'
import Donatecheckout from '../../components/donations/Checkout'
import Newcause from '../../components/causes/Newcause'
import Editcause from '../../components/causes/Editcause'
import Editcausephoto from '../../components/causes/EditCausePhoto'
import CausePublishShare from '../../components/causes/CausePublishShare'
import Addgift from '../../components/gifts/Add'
import Giftlocation from '../../components/gifts/Giftlocation'
import Editgift from '../../components/gifts/Edit'
import Shipping from '../../components/checkout/Shipping'
import Eula from '../../components/auth/Eula'

export default [{
        path: '/checkout/:uuid/:shipping?',
        name: 'checkout',
        props: true,
        component: Checkout,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/shipping-quote/:uuid',
        name: 'shippingquote',
        props: true,
        component: Shipping,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/donate/:uuid/:amount',
        name: 'donatecheckout',
        component: Donatecheckout,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/cause/new',
        name: 'newcause',
        component: Newcause,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/cause/edit/:uuid', // TODO start using uuids instead of ids
        name: 'editcause',
        component: Editcause,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/cause/publish/:uuid/:v?',
        name: 'causepublishshare',
        component: CausePublishShare,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/cause/edit_photo/:uuid',
        name: 'editcausephoto',
        component: Editcausephoto,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/gift/add/:causeUuid/:causeName',
        name: 'addgift',
        component: Addgift,
        props:true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/gift/confirm_address/:uuid/:v',
        name: 'giftlocation',
        component: Giftlocation,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/gift/edit/:uuid',
        name: 'editgift',
        props: true,
        component: Editgift,
        meta: {
            requiresAuth: true
        },
    },
    // {
    //     path: '/eula',
    //     name: 'eula',
    //     props: false,
    //     component: Eula,
    //     meta: {
    //         requiresAuth: true
    //     },
    // },
]
