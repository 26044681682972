<script>
    export default {
        name: 'Titletag',
        props: ['title','description','image'],
        watch: {
            title: {
                immediate: true,
                handler() {
                    // TODO SET A DEFAULT VALUES
                    if(document.title) {
                    	document.title = this.title;
                    }
                    
                    if(document.querySelector('meta[property="og:title"]')) {
                    	document.querySelector('meta[property="og:title"]').setAttribute("content", this.title);	
                    }
                    
                    if(this.description) {
                    	var sDescription = this.description.substring(0,500)+'...';
                    }else{
                    	var sDescription = 'Raise money fast for a cause with this unique & easy fundraising alternative for charities and individuals. It is like GoFundMe meets eBay!';
                    }
                    if(document.querySelector('meta[name="description"]')) {
                    	document.querySelector('meta[name="description"]').setAttribute("content", sDescription);
                    }
                    if(document.querySelector('meta[property="og:description"]')) {
                    	document.querySelector('meta[property="og:description"]').setAttribute("content", sDescription);	
                    }
        	 	 	
                    if(this.image && document.querySelector('meta[property="og:image"]')) {
                    	document.querySelector('meta[property="og:image"]').setAttribute("content", this.image);	
                    }
                    if(document.querySelector('meta[property="og:url"]')) {
                    	document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);	
                    }
                    
                }
            }
        },
        render () {
        },
    }
</script>
