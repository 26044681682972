import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import user from './modules/user'
import cause from './modules/cause'
import gift from './modules/gift'
import account from './modules/account'
import checkout from './modules/checkout'
import search from './modules/search'
import channel from './modules/channel'
import slots from './modules/slots'
import wp from './modules/wp'


Vue.use(Vuex);
export const store = new Vuex.Store({
	modules:{
		user, cause, gift, account, checkout, search, channel, slots, wp
	}
});
setTimeout(() => {
	if(store.getters['user/currentUser'] && store.getters['user/currentUser'].name){
		store.dispatch('user/refresh');
	}
	setInterval(() => {
		if(store.getters['user/currentUser'] && store.getters['user/currentUser'].name){
			store.dispatch('user/refresh');
		} 
	}, 2000000);
},10);



