<template>
    <div class="ct container logins">
        <titletag title="Login to FunditOn"></titletag>
        <div class="login row justify-content-center">
            <div class="card">
                <div class="card-head">{{$t('login.welcome')}}</div>
                <div class="card-body">
                    <!-- <div class="form-group row">
                        <i class="fab fa-facebook-f"></i>
                        <a class="f" :href="facebookUrl">{{$t('login.Continue-f')}}</a>
                    </div> -->
                    <div class="form-group row">
                        <a class="g" :href="googleUrl">{{$t('login.Continue-g')}}</a>
                    </div>
                    <div class="form-group row or">
                        <span class="d">...............</span><span class="or">{{$t('login.or')}}</span><span class="d">...............</span>
                    </div>
                    <div class="form-group row e" v-if="authError">
                        <p class="error">
                            {{ authError }}
                        </p>
                    </div>
                    <form @submit.prevent="authenticate">
                        <div class="form-group row">
                            <i class="fas fa-envelope"></i>
                            <input type="email" v-model="form.email" class="form-control f" :placeholder="$t('login.email')" autocomplete="username">
                        </div>
                        <div class="form-group row">
                            <i class="fas fa-lock"></i>
                            <input type="password" v-model="form.password" class="form-control l" :placeholder="$t('login.password')" autocomplete="current-password">
                            <router-link :to="{path: '/reset-password'}" class="forgot-link">{{ $t("nav.forgot") }}</router-link>
                        </div>
                        <div class="form-group row">
                            <input class="submit" type="submit" :value="$t('login.login')">
                        </div>
                    </form>
                    <!-- <router-link :to="{path: '/register', query: {redirect: this.$route.query.redirect}}" class="nav-link" style="display: inline;">{{ $t("nav.register") }}</router-link> -->
                </div>
            </div>
            <div class="row mem">
                <p>Not registered yet? <router-link to="/register"> Register</router-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { login } from '../../helpers/auth';
export default {
    name: "login",
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            loginMessage: null,
            error: null,
            redirect: null
        };
    },
    methods: {
        authenticate() {
            if (this.validEmail(this.form.email)) {
                let loader = this.$loading.show();
                this.$store.dispatch('user/login'); // just sets to logging in being active
                login(this.$data.form) // submits login
                    .then((res) => {
                        this.$store.commit("user/loginSuccess", res);
                        setTimeout(() => {
                        	this.$store.dispatch('channel/setChannels');
                        },500);
                        
                        loader.hide();
                        this.$router.push(this.$route.query.redirect || '/')
                    })
                    .catch((error) => {
                        loader.hide();
                        this.$store.commit("user/loginFailed", { error });
                    });
            }
        },
        showMessage() {
            var r = this.$route.query.redirect;
            if (r) {
                if (r.indexOf('cause/new') == 1) {
                    this.loginMessage = 'To start a cause please login or register';
                }
                if (r.indexOf('account/conv') == 1) {
                    this.loginMessage = 'To buy or send messages please login';
                }
                if (r.indexOf('gift/add') == 1) {
                    this.loginMessage = 'To add an item please login';
                }
                if (r.indexOf('donate') == 1) {
                    this.loginMessage = 'To donate please login. Donations to qualified non-profits are tax deductible.';
                }
                if (r.indexOf('account/noti') == 1) {
                    this.loginMessage = 'To update email preferences please login.';
                }
                if (this.loginMessage) {
                    this.flashMessage({ m: this.loginMessage, d: 1200 });
                }

            }
        }
    },
    mounted() {
    	this.showMessage();
    	axios.get('/sanctum/csrf-cookie').then(response => {});
    	
    },
    watch: {
    	$route(to, from) {
    		this.showMessage();
    		// axios.get('/sanctum/csrf-cookie').then(response => {});
    	}
    },
    computed: {
        authError() {
            return this.$store.getters['user/authError'];
        },
        // facebookUrl() {
        //     var path = this.$route.query.redirect || '/';
        //     return '/facebook_redirect?url=' + path;
        // },
        googleUrl() {
            var path = this.$route.query.redirect || '/';
            return '/google_redirect?url=' + path;
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.logins {
    max-width: 500px;
    margin-top: 50px;
}

.login {
    background-color: rgba(213, 231, 255, 0.5);
    height: auto;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.18);

    .card {
        background-color: transparent;
        border: none;
        text-align: center;
        padding: 5px;
        width: 100%;
        max-width: 360px;

        .card-head {
            // width:550px;
            color: $bluetext;
            font-family: $cat;
            font-size: 38px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 49px;
            text-align: center;
            padding: 20px 0 10px 0;

            &.reg {
                // margin-left: -20px;
            }
        }

        .card-body {
            background-color: transparent;
            border: none;
            // width: 360px;
            text-align: center;

            form {

                .form-group {
                    margin-bottom: 1px;

                    input {
                        border-radius: 0;
                        line-height: normal;

                        &.f {
                            border-radius: 10px 10px 0 0;
                        }

                        &.l {
                            border-radius: 0 0 10px 10px;
                        }

                        &.submit {
                            background-color: $buttonblue;
                            color: #FFFFFF;
                            font-family: $cat;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 21px;
                            text-align: center;
                            padding-left: 0px;
                            margin-top: 20px;
                            border-radius: 25px;
                        }
                    }

                }

            }

            p {

                color: #52506F;
                font-family: $open-sans;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 26px;
                text-align: left;

                &.termsAgree {
                    font-size: 12px;
                    color: $grey;
                    padding-top: 10px;
                    padding-left:5px;
                    margin-bottom: -11px;
                    line-height: 30px;
                    


                    a {
                        width: auto;
                        height: auto;
                        color: $buttonblue;
                        padding-left: 0;
                        background-color: $blue;
                        font-size: 12px;
                        font-family: inherit;
                        line-height: 30px;
                        font-family: $open-sans;
                        vertical-align: top;
                    }
                }
            }
        }

        .form-group {
            position: relative;
            background-color: transparent;
            margin-bottom: 12px;
            text-align: center;

            &.e {
                margin-bottom: 3px;
            }

            &.ta {
                padding-bottom: 0
            }

            .error {
                text-align: center;
                color: $red;
                width: 100%;
                margin-bottom: 0;
            }

            &.or {

                span.d {
                    width: 45%;
                    overflow: hidden;
                    white-space: nowrap;
                    color: $lightgrey;
                    letter-spacing: 6px;
                    margin-top: -2px;
                }

                span.or {
                    width: 10%;
                    color: $lightgrey;
                    opacity: 0.45;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }

            i {
                position: absolute;
                color: $icongrey;
                top: 20px;
                left: 25px;

                &.fa-facebook-f {
                    background-color: #3B5999;
                    width: 20px;
                    height: 20px;
                    border-radius: 9px;
                    top: 15px;
                    padding-top: 4px;
                    color: $white;

                    &:before {
                        font-size: 16px;
                    }
                }
            }

            input,
            a {
                width: 100%;
                height: 50px;
                border-radius: 30px;
                padding-left: 54px;
                border: none;
                font-family: $cat;
                font-size: 16px;
                background-color: $white;
                color: $bluetext;
                outline: none;

                &.g {
                    background-image: url(https://d1812kujpqokfk.cloudfront.net/assets/google-g.png);
                    background-size: 5%;
                    background-position: 25px 16px;
                    background-repeat: no-repeat;
                    padding-left: 54px;
                    text-align: left;
                    color: $bluetext !important;
                    line-height: 50px;
                    font-family: $cat;
                }

                &.f {
                    padding-left: 54px;
                    text-align: left;
                    color: $bluetext !important;
                    line-height: 50px;
                }

                &::placeholder {
                    opacity: 0.45;
                    color: $grey;
                    font-family: $cat;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }

            a {
                vertical-align: middle;
                text-align: center;
            }

            .forgot-link {
                position: absolute;
                bottom: 18px;
                right: 32px;
                background: transparent;
                height: 22px;
                width: 60px;
                padding-left: 0;
                opacity: 0.45;
                color: $lightgrey !important;
                font-family: $cat;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                text-align: right;
                text-transform: uppercase;

            }
        }
    }

    .mem {
        p {
            color: $grey;
            font-family: $cat;

            a {
                color: $buttonblue;
                font-weight: 600;
            }
        }

    }

}

.altLogin {
    text-align: center;
    padding-top: 5px;

    img {
        max-width: 220px;
        height: auto;
    }
}

</style>
