<template>
    <div class="homePageHeader">
        <div class="contain">
            <div class="tl">
                <div>
                    <h1>Donations-in-kind made easy</h1>
                    <p>Take the hassle out out of processing generous items that are not directly useful to your organization.</p>
                </div>
                <div>
                    <h1>Engage and expand your fundraising community</h1>
                    <p>Your community can convert their goods into funds for your non-profit by simply selling them while you get the proceeds.</p>
                </div>
                <div>
                    <h1>Donate and shop for good</h1>
                    <p>FunditOn is a peer to peer marketplace where goods and services are bought and sold to raise funds for causes your community cares about. Shop all causes or the one you wish to support.</p>
                </div>
                <!-- <html-fragment :html="homeData.textslots['home-subtitle'].content"></html-fragment> -->
            </div>
            <div class="tr">
                <router-link class="secondaryButton rev" to="/cause/new">Start a Cause</router-link>
                <router-link class="secondaryButton rev" to="/gifts">Shop to Support</router-link>
            </div>
        </div>
        <div class="cheader">
            <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/hands.jpeg">
        </div>
    </div>
</template>
<script>
import Search from '../search/Searchbox';
export default {
    props: ["homeData"],
    components: {
        Search
    },
    methods: {
        rotateText() {
            var d = $('.tl>div');
            var i = 1; // start on second as first is already showing
            $(d).hide();
            $(d).first().show();
            setInterval(() => {
                $('.tl>div').hide();
                if (i == 3) { i = 0; }
                var d = $('.tl>div')[i];
                $(d).fadeIn(700);
                i++;
            }, 10000);
        },
        fixheader() {
            var navp = parseInt($('.navbar-custom').css('padding-left'), 10);
            var nca = parseInt($('.navbar-custom .container>a').css('padding-left'), 10);
            var nm = parseInt($('.navbar-custom .container').css('margin-left'), 10);
            var np = parseInt($('.navbar-custom .container').css('padding-left'), 10);
            var lp = $('.homePageHeader .contain').css('padding-left');
            var pl = np + nm + navp + nca + 'px';
            if (pl) {
                $('.homePageHeader .contain').css("padding-left", pl);
                $('.homePageHeader .cheader').css("padding-right", pl);
                if(this.isMobile()) {
                $('.homePageHeader .cheader').css("padding-left", pl);
            }
            }
        }
    },
    mounted() {
        this.fixheader();
        this.rotateText();
        // $(window).on('resize', this.fixheader);
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.homePageHeader {
    display: none;
    flex-direction: column;
    padding-top: 60px;
    position: relative;
    width: 100%;
    height: auto;
    background-color: #fff;
    width: 100%;


    overflow: hidden;

    .howLink {
        padding-top: 10px;

        a {
            color: $bluetext;
            font-family: $cat;
            text-decoration: none;
            display: flex;

            >div {
                width: 25px;
                height: 25px;
                background-color: $red;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-left: 10px;

                >div {
                    width: 0;
                    height: 0;
                    margin-left: 3px;
                    border-top: 7px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 7px solid #fff;
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }
            }
        }
    }

    .cheader {
        width: 100%;
        padding: 25px;

        img {
            width: 100%;
            opacity: 0.75;
        }
    }

    >.contain {
        width: 100%;
        // padding: 20px;
        display: flex;
        align-items: left;
        flex-direction: column;

        .tr {
            @media (max-width: 337px) {
                a.secondaryButton {
                    font-size: 14px;
                }
            }
        }

        .tl {
            p {
                font-family: $open-sans;
                color: $textcolor;
                font-size: 18px;
            }

        }

        @include media-breakpoint-up(md) {
            // flex-direction: row;
            padding-bottom: 50px;

            .tl {
                // width: 65%;
            }

            .tr {
                display: flex;
                flex-direction: column;
                // width: 35%;
                padding-right: 5%;
                padding-top: 0;
                display: flex;

            }
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 32px;

            .tl {
                // width: 70%;
            }

            .tr {
                // width: 30%;
                // margin-bottom: -60px;
                padding-right: 10%;

                >.secondaryButton {
                    width: 210px;
                    margin: 15px 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .tr {
                padding-right: 15%;

            }
        }
    }

    .tl {
        width: 100%;
        padding-right: 10px;
    }

    .tr {
        display: flex;
        flex-direction: row;
        width: 100%;
        /* padding-right: 10%; */
        column-gap: 10%;
        justify-content: center;
        padding-right: 15px;
        padding-top: 25px;

        >.secondaryButton {
            width: 170px;
            margin: 12px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;

            @include media-breakpoint-up(sm) {
                font-size: 18px;
                line-height: 33px;
            }
        }
    }

    h1 {
        // font-size: 24px;
        color: $bluetext;
        text-align: left;
        line-height: 34px;
        font-weight: 500;
        padding: 1rem 0;
        padding-bottom: 0;
        margin-bottom: 5px;
        background: transparent;
        max-width: 600px;

        span {
            font-size: 10px;
        }



        // @include media-breakpoint-up(lg) {
        //     font-size: 65px;
        //     line-height: 45px;
        //     // padding-bottom: 20px;
        // }
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        h1 {
            line-height: 32px;
            padding-bottom: 10px;
        }

    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 31px;
            line-height: 40px;
        }

        // padding-bottom: 20px;
    }

    // @include media-breakpoint-up(lg) {
    //     h1 {
    //         font-size: 55px;
    //         line-height: 35px;
    //     }

    //     // padding-bottom: 20px;
    // }

    h2.subtitle {
        font-family: $open-sans;
        color: $bluetext;
        font-size: 15px;
        font-weight: 400;
        // padding: 0 1rem;
        line-height: 21px;
        max-width: 650px;

        @include media-breakpoint-up(sm) {
            font-size: 17px;
            line-height: 25px;
            padding-top: 7px;
            padding-bottom: 20px;
        }
    }



    .searchContain {
        padding: 0 15px;

        .searchBox {
            z-index: 1070;
        }
    }

}

</style>
