<template>
    <div class=" giftGallery">
        <div v-if="shippable" class="ship">
            <img src="https://d1812kujpqokfk.cloudfront.net/assets/shipping.svg" alt="Seller is willing to ship">
            <div class="shippable">SELLER IS WILLING TO SHIP</div>
        </div>
        <div v-if="primary" class="mainOne" ref="mainphoto">
            <img id="mainPhoto" :src="getCloudFrontUrl() + primary.path_full">
        </div>
        <div v-if="primary" class="thumbs">
            <ul>
                <li :url="getCloudFrontUrl() + photo.path_thumb" v-for="photo in photos" v-bind:style="{ backgroundImage: 'url(' + getCloudFrontUrl() + photo.path_thumb + ')' }" @click="setActiveImage">
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['photos', 'primary', 'shippable'],
    data() {
        return {
            activeUrl: null,
            xDown: null,
            yDown: null
        }
    },
    mounted() {
        this.activeUiid = this.primary ?  this.primary.uuid : null;
        this.activeUrl = this.primary ? this.getCloudFrontUrl() + this.primary.path_full : null;
        this.init();
    },
    beforeDestroy: function() {
        document.removeEventListener('touchstart', this.handleTouchStart);
        document.removeEventListener('touchmove', this.handleTouchMove);
    },
    methods: {
        init() {
            if (window.matchMedia("(max-width: 768px)").matches) {
                $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
                this.swipeDetect();
            } else {
                $('[data-toggle="tooltip"]').tooltip({ "delay": 400 });
            }
            var interval = setInterval(() => {

                if (document.getElementById("mainPhoto") && document.getElementById("mainPhoto").complete) {
                    clearInterval(interval);
                    this.placeShippingIcon();
                }
                if (!document.getElementById("mainPhoto")) {
                    clearInterval(interval);
                }
            }, 20);
            this.placeShippingIcon();
        },
        placeShippingIcon() {
            if (this.shippable) {
                var container = $('.mainOne').width();
                var photo = $('#mainPhoto').width();
                var diff = 5;
                if(photo < container) {
                	diff = ((container - photo) / 2) + 5;
                }
                $('.ship').css('left', diff + 'px');
            }

        },
        swipeDetect() {
            document.addEventListener('touchstart', this.handleTouchStart, false);
            document.addEventListener('touchmove', this.handleTouchMove, false);

        },
        handleTouchStart(evt) {
            const firstTouch = this.getTouches(evt)[0];
            this.xDown = firstTouch.clientX;
            this.yDown = firstTouch.clientY;
        },
        getTouches(evt) {
            return evt.touches || // browser API
                evt.originalEvent.touches; // jQuery
        },
        handleTouchMove(evt) {
            if (!this.xDown || !this.yDown) {
                return;
            }
            if ($(evt.target).attr('class') != 'mainOne') {
                return;
            }
            if ($('.thumbs li').length < 2) {
                return;
            }

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) { /*most significant*/
                var mainPhoto = $('#mainPhoto');
                var src = mainPhoto.attr('src');
                src = src.replace('full', 'thumbs');

                var currentThumb = $('.thumbs li[url="' + src + '"]');

                if (xDiff > 0) {
                    /* left swipe */
                    currentThumb.next().click();
                } else {
                    /* right swipe */

                    currentThumb.prev().click();
                }
            }
            /* reset values */
            this.xDown = null;
            this.yDown = null;
        },
        setActiveImage(e) {
            let loader = this.$loading.show({
                container: this.$refs.mainphoto
            });
            var el = e.target;
            var url = $(el).attr('url');
            url = url.replace('thumbs', 'full');
            $('#mainPhoto').attr('src', url);
            var interval = setInterval(() => {
                if (document.getElementById("mainPhoto").complete) {
                    loader.hide();
                    clearInterval(interval);
                    this.placeShippingIcon();
                }
            }, 20);
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.giftGallery {
    overflow-x: hidden;
    width: 100%;
    position: relative;

    // padding-left:0;
    // padding-right:0;


    .ship {
        top: 20px;

        @include media-breakpoint-up(md) {
            top: 30px;
        }
    }

    .mainOne {
        position: relative;
        height: 239px;
        width: auto;
        text-align: center;
        border-radius: 10px;
        padding-top: 10px;
        -ms-scroll-chaining: none;

        @include media-breakpoint-up(sm) {
            height: 255px;
        }

        @include media-breakpoint-up(md) {
            padding-top: 20px;
            height: 440px;
        }

        img {
            height: 100%;
            max-width: auto;
            user-select: none;
            pointer-events: none;
            border-radius: 10px;
        }
    }

    .thumbs {
        ul {
            padding-top: 15px;
            white-space: nowrap;
            overflow-x: scroll;
            padding-left: 0;

            li {
                display: inline-block;
                width: 138px;
                height: 70px;
                background-size: cover;
                border-radius: 8px;
                cursor: pointer;
                margin-right: 14px;
            }
        }
    }
}

</style>
