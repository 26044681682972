<template>
    <div class="causeActions">
        <div v-if="!isMobile" class="shareItem" @click="shareNow"><i class="fa fa-share-square-o" aria-hidden="true"></i></div>
        <div v-if="!isMobile" class="likeIt float-left" @click="likeThis($event,'cause',cause.id)"><i v-bind:class="liked ? 'fas' : 'far'" class=" fa-heart"></i></div>
        <button class="support mShare float-left" @click="shareNow">Share</button>
        <button class="support float-right" data-toggle="modal" data-target="#supportChoices">Donate to support</button>
    </div>
</template>
<script>
import { getLocalUser } from "../../helpers/auth";
export default {
    props: ['cause'],
    data() {
        return {
            liked: false
        }
    },
    mounted() {
        var user = getLocalUser();
        if (null != user) {
            for (var i = 0; i < user.likes.length; i++)
                if (this.cause.id == user.likes[i].cause_id) {
                    this.liked = true;
                }
        }
    },
    methods: {
    	shareNow()
    	{
    		var content = $('.itemShare','#causePage');
    		if(content.length) {
				$('#shareModal .modal-body').empty().append(content);
			}else{
				content = $('.itemShare');
				$('#shareModal .modal-body').empty().append(content);
			}
			$('#shareModal').modal('show');
			$('#shareModal .itemShare').show();
    	},
        likeThis(e,t,id) {
        	this.liked = this.likeIt(e,t,id,this.liked);
        }
    }
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.causeActions {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1090;
    background: $white;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
	 @include media-breakpoint-up(sm) {
	 	display: none;
	 }
    .shareItem,
    .likeIt {
        width: 18%;
        color: $lightgrey;
    }

    i.fas {
        color: $red;
    }

    i.far {
        color: $lightgrey;
    }

    i.fa-comments-o {
        color: $lightgrey;
    }

    button {
        border-radius: 30px;
        width: 46%;
        height: 50px;
        margin-left: 2%;
        margin-right: 2%;
        outline: none;


        &.support {
            background-color: $red;
            color: $white;
            border:none;
            font-family: $cat;
            &.mShare {
            	border: 1px solid #51506F;
			    border-radius: 25px;
			    background-color: #fff;
			    color: $grey;
		
            }
            &:hover {
	        	background-color: #fff;
	        	border: 1px solid $red;
	        	color: $red;
	        }
        }

        &.browse {
            border: 1px solid $actiongrey;
            background-color: $white;
        }
    }
}

@keyframes heart {
    from {
        color: $lightgrey;
    }

    to {
        color: $red;
        
    }
}

</style>
