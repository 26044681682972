<template>
    <div class="col payment_card ct forms">
        <form :action="`#`" method="post" id="payment-form">
            <div class="row">
                <h3 v-show="!success">Payment Information</h3>
            </div>
             <div v-show="savedCards.length && !success && !error" class="saved_cards row">
                <div v-for="savedCard in savedCards" class="radiocheck">
                    <label>Use saved card: <img :src="'https://d1812kujpqokfk.cloudfront.net/assets/ccards/' + savedCard.brand + '.png'">...{{savedCard.last4}}
                        <input v-model="chosenCard" type="radio" class="" :value="savedCard.payment_method">
                        <span></span>
                    </label>
                </div>
                <div class="or">Or use a new card below</div>
            </div>
            <div v-show="!success && !error" class="row">
                <div id="card-element"></div>
                <div id="card-errors" role="alert"></div>
            </div>
            
            <div v-show="!success && !error" class="row">
                <div class="radiocheck">
                    <label>Save card for later use
                        <input v-model="save_card" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.cc-tooltip')">i</div>
                </div>
            </div>
           <div v-show="!success && !error" class="row">
                <div class="radiocheck">
                    <label>Buy anonymously
                        <input v-model="anonymous" name="anon" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.anon')">i</div>
                </div>
            </div>
            <div v-show="!success && !error" class="row">
            	<div id="terms-error" role="alert" v-if="!agree && agree != null"></div>
                <div class="radiocheck">
                    <label dusk="agree-donate">Agree to FunditOn <a class="terms" href="/terms" target="_blank">terms of service</a>
                        <input v-model="agree" name="terms" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.agreedonate')">i</div>
                </div>
            </div>
            <div class="row pay" v-show="!success && !error">
                <button class="btn btn-primary btn-block buy">Pay with card</button>
            </div>
            <div class="row apple" v-show="!success && !error">
                <div id="payment-request-button"></div>
            </div>
            <div class="row">
                <div class="messaging" v-show="success">
                	<h3>Your payment succeeded!</h3>
                    <p>Email confirmation sent<br>Order ID: {{order.uuid}}</p>
                    <p class="thanks">Thank you for supporting<br>{{order.cause.name}}</p>
                    <div class="onward form-group">
                    	<router-link :to="`/c/${order.cause.url_str}`" class="btn-primary">Show cause</router-link>
                    	<router-link :to="`/causes`" class="btn-primary simple">Browse causes</router-link>
                    </div>
                    <share :item="order.cause" :key="order.cause.uuid" :url="'/c/' + order.cause.url_str"></share>
                </div>
                <div class="messaging error" v-show="error">
                    <p>Your payment did not go through</p>
                    <p>{{error_message}}</p>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import Share from '../causes/Share'
const { mapActions } = createNamespacedHelpers('account');
export default {
    name: 'Card',
    props: {
        'order': null,
        'savedCards': null

    },
    components: {
    	Share
    },
    watch: {
        order(value) {
            this.initWhenReady();
        }
    },
    mounted() {
        this.initWhenReady();
        
    },
    data() {
        return {
            success: false,
            error: false,
            error_message: null,
            save_card: false,
            anonymous: false,
            payment_method: null,
            payment_intent_id: null,
            cardFilled: false,
            chosenCard: null,
            agree: false
        }
    },
    methods: {
    	...mapActions(['setAccountDetails']),
        initWhenReady() {
            var interval = setInterval(() => {
                if (this.order) {
                    clearInterval(interval);
                    this.initiateStripe();
                    this.initPaymentButtons();
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
                    } else {
                        $('[data-toggle="tooltip"]').tooltip({ "delay": 200 });
                    }
                }
            }, 20);
        },
        setStripeStyles() {
            var style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
            return style;
        },
        make_payment() {
            let loader = this.$loading.show();
            return new Promise((res, rej) => {
                axios.post('/api/make_donation', { 'order_id': this.order.id, 'payment_method_id': this.payment_method, 'save_card': this.save_card, 'payment_intent_id': this.payment_intent_id, 'anonymous': this.anonymous, 'agree': this.agree })
                    .then(response => {
                        res(response);
                        loader.hide();
                    }, error => {
                        rej(error);
                        loader.hide();
                    });
            });
        },
        initPaymentButtons() {
            var self = this;
            var amount = parseFloat(this.order.order_total * 100);
            var displayItems = [{'label': 'Donation to ' + this.order.cause.name, 'amount': amount}];
            
            var stripe = Stripe(this.stripePublicKey());
            var paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'FunditOn',
                    amount: amount
                },
                displayItems: displayItems
            });
            var elements = stripe.elements();
            var prButton = elements.create('paymentRequestButton', {
                paymentRequest: paymentRequest,
            });
            // Check the availability of the Payment Request API first.
            paymentRequest.canMakePayment().then(function(result) {
                if (result) {
                    prButton.mount('#payment-request-button');
                } else {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
            });
            prButton.on("click", function(event) {
            	if(!self.agree) {
            		document.getElementById('terms-error').textContent = "Please agree to terms";
            		event.preventDefault();
            		return;
            	}
            	// document.getElementById('terms-error').textContent = "";
            });
            paymentRequest.on(
                'paymentmethod',
                function(ev) {
                    $('button.buy').prop('disabled', true);
                    // First, confirm the PaymentIntent without handling potential
                    // next actions to see if there are any payment errors.
                    self.save_card = false;
                    self.payment_method = ev.paymentMethod.id;
                    self.ev = ev;
                    self.make_payment().then(self.handleMakePaymentResponse);
                });
        },
        handleMakePaymentResponse(response) {
            if (response.data.error) {
                this.error = true;
                this.error_message = response.data.error;
                if (this.ev) {
                    this.ev.complete('fail');
                }
                $('#payment-request-button').parent('.row').hide();
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
                var stripe = Stripe(this.stripePublicKey());
                stripe.handleCardAction(
                    response.data.payment_intent_client_secret
                ).then(this.handleRequiredAction);
            } else {
                this.success = true;
                if (this.ev) {
                    this.ev.complete('success');   
                }
                $('#payment-request-button').parent('.row').hide();
                $('.cryptoPay').hide(); // should be in a better way but a quick fix :)
                this.$store.commit('account/setChanged', { "a": "account" });
            }
        },
        handleStripeCreatePaymentResponse(result) {
            if (result.error) {
                this.success = false;
                $('button.buy').prop('disabled', false);
                document.getElementById('card-errors').textContent = result.error.message;
            } else {
            	document.getElementById('card-errors').textContent = "";
                this.payment_method = result.paymentMethod.id;
                this.make_payment().then(this.handleMakePaymentResponse);
            }
        },
        handleRequiredAction(response) {
        	if(response.error) {
        		 this.success = false;
        		 $('button.buy').prop('disabled', false);
                document.getElementById('card-errors').textContent = response.error.message;
        	}
        	if(response.paymentIntent) {
	            this.payment_intent_id = response.paymentIntent.id;
	            this.payment_method = null;
	            this.make_payment().then(this.handleMakePaymentResponse);
        	}
        },
        initiateStripe() {
            var self = this;
            var stripe = Stripe(this.stripePublicKey());
            var elements = stripe.elements();
            var card = elements.create('card', { style: this.setStripeStyles() });
            card.mount('#card-element');
            card.addEventListener('change', function(event) {
                self.chosenCard = null;
                $('.saved_cards input').prop("checked", false);
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
                if (event.complete) {
                    //card element is good to go
                    self.cardFilled = true;
                } else {
                    self.cardFilled = false;
                }
            });

            $('.saved_cards input').on("click", function() {
    			card.clear()	
    			setTimeout(() => {
    				$('.saved_cards input').each(function(i,v) {
        				$(v).prop("checked", false);
        			});
        			$(this).prop("checked", true);
        			self.chosenCard = $(this).val();
        			self.save_card = 0;
        		},30);

            });
            // Handle form submission.

            var form = document.getElementById('payment-form');
            form.addEventListener('submit', function(event) {
            	event.preventDefault();
            	if(!self.cardFilled && !self.chosenCard) {
            		document.getElementById('card-errors').textContent = "Please fill out your card details";
            		return;
            	}
            	if(!self.agree) {
            		document.getElementById('terms-error').textContent = "Please agree to terms";
            		return;
            	}
            	// document.getElementById('terms-error').textContent = "";
                event.preventDefault();
                $('button.buy').prop('disabled', true);
                if (self.chosenCard) {
                    self.save_card = false;
                    self.payment_method = self.chosenCard;
                    self.make_payment().then(self.handleMakePaymentResponse);
                } else {
	                    stripe.createPaymentMethod({
	                        type: 'card',
	                        card: card,
	                        // billing_details: {
	                        //     // Include any additional collected billing details.
	                        //     email: 'Jenny Rosen',
	                        // },
	                    }).then(self.handleStripeCreatePaymentResponse);
	                
                }
            });
        }
    }
}
</script>
