export default {
    namespaced: true,
    state: {
        posts: [],
        pages: [],
        postIndex: []
    },
    mutations: {
        setPost(state, payload) {
            state.posts[payload.slug] = payload;
        },
        setPage(state, payload) {
            state.pages[payload.slug] = payload;
        },
        setPostIndex(state, payload) {
            state.postIndex = payload;
        }
    },
    actions: {
        refresh(context, payload) {
            var url = "/api/wp-" + payload + "-refresh";
            return new Promise((res, rej) => {
                axios.get(url).then(
                    response => {
                        res(response.data);
                    }, error => {
                        rej(error);
                    });
            });
        },
        setPage(context, payload) {
            return new Promise((res, rej) => {
                axios.get("/api/wp-" + payload.p + "/" + payload.s).then(
                    response => {
                        if (response.data.slug) {
                            payload.p == "page" ? context.commit('setPage', response.data) : context.commit('setPost', response.data);
                            res(response.data);
                        } else {
                            rej(response.data);
                        }
                    }, error => {
                        rej(response.data);
                    });
            })
        },
        setPostIndex(context) {
        	return new Promise((res, rej) => {
                axios.get("/api/wp_post_index").then(
                    response => {
                        if (response.data.status) {
                            context.commit('setPostIndex', response.data.excerpts);
                            res(response.data);
                        } else {
                            rej(response.data);
                        }
                    }, error => {
                        rej(response.data);
                    });
            })
        }
    }
}
