<template>
    <div class="row messageContainer requesters" id="assignBuyer">
        <h4>Put on Hold</h4>
        <p>{{buyer.name}} would like to purchase {{channel.gift.name}}. Whilst on hold no other user can buy this item.</p>
        <p class="warn" v-if="this.channel.gift.buyer_id">Currently you have this on hold for someone else.</p>
        <a class="btn" @click="confirmHold">Hold for buyer</a>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');
export default {
    props: ['channel', 'loggedInId'],
    data() {
        return {
        	
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        buyer() {
            for (var i = 0; i < this.channel.users.length; i++) {
                if (this.channel.users[i].id !== this.channel.gift.user_id) {
                    return this.channel.users[i];
                }
            }
        },
        userHasHold() { // current buyer has already got a hold on this item
                if (this.buyer.id == this.channel.gift.buyer_id) {
                    return true;
                }   
            return false;
        }
    },
    methods: {
    	...mapActions(['assignBuyer']),
        init() {
            if (this.canShow()) {
                $('#assignBuyer').show();
            }
        },
        confirmHold() {
        	if (this.channel.gift.user_id == this.loggedInId) {
        		if(this.channel.gift.buyer_id) {
        			var data = {
        				h: "Confirm changing the hold",
        				q: "Only one person can keep your item on hold at a time. Are you sure you want to re-assign the hold?",
        				y: "Yes",
        				n: "Cancel"
        			};
        			this.confirmDialog(data, this.assignBuyerId);
        		}else{
        			this.assignBuyerId(true);
        		}
            }
        },
        assignBuyerId(bool) {
        	if(bool) {
        		let loader = this.$loading.show();
            	this.assignBuyer({"gift_uuid" : this.channel.gift.uuid, "buyer_id" : this.buyer.id, "channel_id" : this.channel.id})
            		.then(response => {
            			// TODO need to be checking the response here
	            		loader.hide();
	            		this.$emit('reloadMessages');
	            		$('#assignBuyer').hide();		
            		
            	})
        	}
        	
        },
        canShow() {
            var canShow = false;
            if (this.channel.gift.user_id === this.loggedInId && !this.userHasHold) {
                return true;
            }
            return canShow;
        }

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
// styles are in Buyrequest.vue

</style>
