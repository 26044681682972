<template>
    <div id="g-steps">
        <ul>
            <li class="f" @click="goToUrl('f')">
                <div><span v-html="fState"></span></div>
                <div>Add Item</div>
            </li>
            <li class="s" @click="goToUrl('s')">
                <div><span v-html="sState"></span></div>
                <div>Location</div>
            </li>
            <li class="t" @click="goToUrl('t')">
                <div><span v-html="tState"></span></div>
                <div>Publish &amp; Share</div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'steps',
    props: ['uuid', 'active'],
    data() {
        return {}
    },
    watch: {
        active(value) {
            this.setState();
        }
    },
    computed: {
        ...mapState({ gift: state => state.gift.gift }),
        fState() {
            if (this.uuid) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 1;
            }
        },
        sState() {
            if (this.active == 't' || (this.gift && this.gift.user.address && this.gift.user.address.verified)) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 2;
            }
        },
        tState() {
            if (this.gift && this.gift.published) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 3;
            }
        }

    },
    mounted() {
        this.setState();
    },
    methods: {
        goToUrl(el) {
            switch (el) {
                case "f":
                    if (this.uuid) {
                        this.$router.push({ name: 'editgift', params: { uuid: this.uuid } }).catch(err => {});
                    }
                    break;
                case "s":
                    if (this.uuid && this.gift) {
                        this.$router.push({ name: 'giftlocation', params: { uuid: this.uuid, v: "s" } }).catch(err => {});
                    }
                    break;
                case "t":
                    if (this.uuid && this.gift && this.gift.user.addresss && this.gift.user.addresss.verified && this.gift.published) {
                        this.$router.push({ name: 'giftlocation', params: { uuid: this.uuid, v: "fo" } }).catch(err => {});
                    }else{
                    		this.$router.push({ name: 'giftlocation', params: { uuid: this.uuid, v: "t" } }).catch(err => {});
                    }
                    break;
            }
        },
        setState() {
            var s = $('#g-steps');
            $('li ',s).removeClass('active');
            switch (this.active) {
                case "f":
                    $('.f ', s).addClass('active');
                    break;
                case "s":
                    $('.f ,.s ', s).addClass('active');
                    break;
                case "t":
                    $('.f ,.s ,.t ', s).addClass('active');
                    break;
                case "fo":
                    $('.f ,.s ,.t ', s).addClass('active');
                    break;
            }
        }
    }
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
// See Cause/Steps.vue

</style>
