<template>
    <div class="ct container" id="homePage" v-if="homeData">
        <titletag title="Buy and Sell Donated Items to Raise Money for the Charities and Causes you Love" description="Raise money fast for a cause with this unique & easy fundraising alternative for charities and individuals." image="https://d1812kujpqokfk.cloudfront.net/assets/home/hands.jpeg"></titletag>
        
        <div class="popularCauses centered pb-4 container">
            <h3 class="popular">Support an Open Cause</h3>
            <p>Support community causes by donating funds or selling/purchasing marketplace items. Your funds will make a difference in supporting local causes that matter.</p>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                <item v-for="item in homeData.homeCauses" :key="item.id" :item="item"></item>
            </div>
            <router-link to="/causes" class=" rev secondaryButton mt-4">Explore Causes</router-link>
        </div>
        <div class="slider pb-4 container">
            <h3>Three Ways to use FunditOn</h3>
            <slider :text="homeData.textslots"></slider>
        </div>
        <div class="pb-4 container diff" data-aos="fade-up">
            <different :text="homeData.textslots">></different>
        </div>
        <div class="section-1 container" data-aos="fade-up">
            <div class="image"><img src="https://d1812kujpqokfk.cloudfront.net/assets/home/pic_1-min.png"></div>
            <div class="slot-content">
                <html-fragment :html="homeData.textslots['home-section-1'].content"></html-fragment>
                <router-link to="/how_it_works" class="more secondaryButton">Learn more</router-link>
            </div>
        </div>
        <div class="section-2 container" data-aos="fade-up">
            <div class="slot-content">
                <html-fragment :html="homeData.textslots['home-section-2'].content"></html-fragment>
                <router-link to="/how_it_works" class="more secondaryButton">Learn more</router-link>
            </div>
            <div class="image"><img src="https://d1812kujpqokfk.cloudfront.net/assets/home/pic_2-min.png"></div>
        </div>
        <div class="section-3 container" data-aos="fade-left">
            <div class="image"><img src="https://d1812kujpqokfk.cloudfront.net/assets/home/pic_3-min.png"></div>
            <div class="slot-content">
                <html-fragment :html="homeData.textslots['home-section-3'].content"></html-fragment>
                <router-link to="/how_to_sell" class="more secondaryButton">Learn more</router-link>
            </div>
        </div>
        <div class="section-4 container" data-aos="fade-right">
            <div class="slot-content">
                <html-fragment :html="homeData.textslots['home-section-4'].content"></html-fragment>
                <router-link to="/how_to_start_a_cause" class="more secondaryButton">Learn more</router-link>
            </div>
            <div class="image"><img src="https://d1812kujpqokfk.cloudfront.net/assets/home/pic_4-min.png"></div>
        </div>
        <div class="recentItems container">
            <h3>Recently added Items</h3>
            <div class="row">
                <giftitem v-for="item in homeData.lastFourgifts" :key="item.id" :item="item"></giftitem>
            </div>
        </div>
        <div class="getStarted container">
            <div class="startContainer">
                <h2>Ready to get started?</h2>
                <p>Causes receive funds directly. Get your community behind your cause. See <router-link to="/how_it_works">how it works</router-link>
                </p>
                <router-link to="/cause/new" class="btn mt-4">{{ $t("nav.start") }}</router-link>
            </div>
        </div>
       <!--  <div class="itemCats container">
            <h3>Shop by Category</h3>
            <div class="row">
                <div v-for="item in homeData['giftCategories'].slice(0,6)" class="col-sm-4 justify-content-left d-flex align-items-center"> -->
                    <!-- Note svgs should be named by url_str in the database -->
                 <!--    <div>
                        <router-link :to="`/category/g/${item.url_str}`"><img :src="`https://d1812kujpqokfk.cloudfront.net/assets/categories/${item.url_str}.svg`">{{item.name}}</router-link>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import Item from '../causes/Item';
import Giftitem from '../gifts/Item';

import Slider from '../home/Slider';
import Different from '../home/Different';
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    components: {
        Item,
        Giftitem,
        Slider,
        Different
    },
    computed: {
        ...mapState({ homeData: state => state.slots.homeData })
    },
    methods: {
        ...mapActions(['setHomeData']),
        startMovie() {
            document.getElementById('playVid').addEventListener('click', function() {
                document.getElementById('movie').play();
                document.getElementById('playVid').style.display = 'none';
                gtag('event', 'start', {
                    event_category: 'video',
                    event_label: 'home',
                    app_name: 'FunditOn',
                });

            });
        },

    },
    mounted() {
        if (!this.homeData) {
            let loader = this.$loading.show();
            this.setHomeData().then(response => {
                loader.hide();
            });
        }
        this.toggleBodyClass('addClass', 'homeOnly');
    },

    destroyed() {
        this.toggleBodyClass('removeClass', 'homeOnly');
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';


body.homeOnly {
    background-color: #fff;

    .homePageHeader {
        display: flex;
    }

    #app {
        padding: 0;
        width: 100%;
    }

    .mainContain {
        background: transparent;
        // background-color: transparent;
        padding-left: 15px;
        padding-right: 15px;
    }
}

#homePage {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;



    h2 {
        width: 100%;
    }

    h3 {
        color: $bluetext;
        font-family: $cat;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2rem;
        text-align: center;
    }

    .slider {
        background-color: #fff;

        h3 {
            font-size: 30px;
        }
    }

    .diff {
        background-color: #fff;
    }

    .popularCauses {
        background-color: #fff;
        padding-top: 20px;

        a.secondaryButton {
            display: flex;
            position: relative;
            border: 1px solid $buttonblue;
            // border-radius: 25px;
            left: 50%;
            margin-left: -25%;
            width: 50%;
            height: 40px;
            justify-content: center;
            align-items: center;
            font-size: 19px;

            &:hover {
                border: 1px solid rgba(177, 176, 190, 0.4);
            }

        }
    }

    div[class^='section'] {
        background-color: #fff;
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:nth-of-type(odd) {
            flex-direction: column-reverse;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;

            &:nth-of-type(odd) {
                flex-direction: row;
            }

            padding-top: 50px;

            :last-child.slot-content {
                padding-left: 25px;
            }
        }

        .slot-content,
        .image {
            padding-top: 15px;

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }
        }

        .image {
            width: 100%;

            @include media-breakpoint-up(md) {
                flex-basis: 130%;
            }

            text-align: center;

            img {
                width: 90%;
            }
        }

        a.more {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            width: 120px;
            height: 40px;

        }
    }

    @include media-breakpoint-up(md) {
        .section-2 .image {
            width: 120%;
        }

        .section-3 div.image,
        .section-4 div.image {
            flex-basis: 90%;
        }
    }

    .getStarted {
        background-color: #fff;
        padding: 30px 15px;

        .startContainer {
            width: 100%;
            height: 100%;
            background-image: url('https://d1812kujpqokfk.cloudfront.net/assets/home/cta.svg');
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 14px;
            padding: 50px 25px;

            p {
                a {
                    color: #fff;
                }
            }
        }

        h2 {
            color: $white;
        }

        p {
            color: #B8B5E0;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
        }

        a.btn {
            display: block;
            position: relative;
            border-radius: 25px;
            left: 44%;
            margin-left: -22%;
            width: 56%;
            color: $white;
            background-color: $buttonblue;

            @include media-breakpoint-up(sm) {
                max-width: 300px;
                left: 50%;
                margin-left: -150px;
            }
        }
    }

    .recentItems {
        // border-top: 20px solid $blue;
        padding: 15px;
        background-color: #fff;
    }

    .itemCats {
        background-color: #fff;
        padding-top: 15px;

        .row {
            padding: 10px;
        }

        .col-sm-4 {
            margin-bottom: 10px;
            border-radius: 9px;
            height: 60px;
            font-size: 18px;
            line-height: 30px;
            color: $bluetext;
            font-family: $cat;
            padding: 0 15px;

            div {
                display: flex;
                align-items: center;
                padding-left: 10px;
                width: 100%;
                height: 100%;
                border-radius: 9px;

                background-color: rgba(213, 231, 255, 0.5);


            }

            &:nth-of-type(even) {
                div {

                    background-color: rgba(255, 95, 88, 0.1);
                }

            }

            a {
                color: $bluetext;
                font-family: $cat;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 30px;
                margin-left: -10px;
            }

            img {

                padding-left: 15px;
                padding-right: 21px;
            }
        }
    }
}

</style>
