<template>
    <div class="ct container verified logins">
        <titletag title="Email Verified"></titletag>
        <div class="login row justify-content-center ">
            <div class="card">
                <div class="card-head">Email Verified</div>
                <div class="card-body">
                    <p> Your email has been verified.</p>
                    <p>Please <router-link :to="{path: '/login', query: {redirect: this.$route.query.redirect}}">Login</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.verified {
    // margin-top: 60px;
    @include media-breakpoint-up(sm) {
    	margin-top: 100px;
    }
    p {
        font-size: 18px;
        color: $textcolor;
        width:100%;
        text-align: center !important;
        a {
            color: $buttonblue;
            cursor: pointer;
        }
    }
}

</style>
