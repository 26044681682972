<template>
    <div class="row" id="filters">
        <div class="col-12 showFilters"><a class="simple">Show filters</a></div>
        <div class="col-12 form-group">
            <div v-if="cat" class="cat" v-bind:class="{ active: category}">
                <div class="label">
                    <span>Category: </span>
                    <span>{{ category ? category : 'Any' }}</span>
                    <div v-if="category" class="removeFilter">x</div>
                </div>
                <template v-if="type == 'gift'">
                    <div class="popout cats" v-if="giftCategories">
                        <select v-model="category" class="category form-control" id="category">
                            <option value="">Any</option>
                            <option v-for="item in giftCategories" :value="item.name">{{item.name}}</option>
                        </select>
                    </div>
                </template>
                <template v-if="type == 'cause'">
                    <div class="popout cats" v-if="causeCategories">
                        <select v-model="category" class="category form-control" id="category">
                            <option value="">Any</option>
                            <option v-for="item in causeCategories" :value="item.name">{{item.name}}</option>
                        </select>
                    </div>
                </template>
            </div>
            <div v-if="price" class="price" v-bind:class="{ active: parseFloat(prl) !== 5 || parseFloat(prh) !== 1000}">
                <div class="label">
                    <span>Price: </span>
                    <span v-if="parseFloat(prl) == 5 && parseFloat(prh) == 1000">Any</span>
                    <span v-if="parseFloat(prl) !== 5 || parseFloat(prh) !== 1000">${{prl}} - {{prh}}</span>
                    <div v-if="parseFloat(prl) !== 5 || parseFloat(prh) !== 1000" class="removeFilter">x</div>
                </div>
                <div class="popout slider">
                    <input v-model="prl" type="number" min="5" step="5" max="1000" /> -
                    <input v-model="prh" type="number" min="5" step="5" max="1000" />
                    <input v-model="prl" min="5" max="1000" step="5" type="range" />
                    <input class="s" v-model="prh" min="10" max="1000" step="5" type="range" />
                </div>
            </div>
            <div v-if="city" class="city" v-bind:class="{ active: ci}">
                <div class="label">
                    <span>City: </span>
                    <span>{{ ci ? ci : 'Any' }}</span>
                    <div v-if="ci" class="removeFilter">x</div>
                </div>
                <div class="popout cities">
                    <input id="cities" type="text" placeholder="Type city" :value="ci">
                </div>
            </div>
            <div v-if="location" class="location" v-bind:class="{ active: zc}">
                <div class="label">
                    <span>{{ zc ? get_range() : 'Distance:' }}</span>
                    <span>{{ zc ? zc : 'Any' }}</span>
                    <div v-if="zc" class="removeFilter">x</div>
                </div>
                <div class="popout zipprox">
                    <p>Find items within {{get_range()}}</p>
                    <input id="zipprox" type="number" placeholder="zipcode" v-model="zc"><br>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="zr" v-bind:value="`within_15_miles`" id="il0">
                        <label class="form-check-label" for="il0">15</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="zr" v-bind:value="`within_25_miles`" id="il1">
                        <label class="form-check-label" for="il1">25</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="zr" v-bind:value="`within_50_miles`" id="il2">
                        <label class="form-check-label" for="il2">50</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" v-model="zr" v-bind:value="`within_100_miles`" id="il3">
                        <label class="form-check-label" for="il3">100</label>
                    </div>
                    <br>
                    <a class="btn" @click="setFilter">Filter</a>
                    <!-- <input type=radio v-model="zr" v-bind:value="`within_25_miles`" @click="setRadio"><span>25
                        <input type=radio v-model="zr" v-bind:value="`within_50_miles`" @click="setRadio">50
                        <input type=radio v-model="zr" v-bind:value="`within_100_miles`" @click="setRadio">100 -->
                </div>
            </div>
            <div v-if="state" class="state" v-bind:class="{ active: st}">
                <div class="label">
                    <span>State: </span>
                    <span>{{ st ? st : 'Any' }}</span>
                    <div v-if="st" class="removeFilter">x</div>
                </div>
                <div class="popout states">
                    <select v-model="st" class="category form-control" id="states">
                        <option value="">Any</option>
                        <option v-for="item in states()" :value="item.v">{{item.n}}</option>
                    </select>
                </div>
            </div>
            <div v-if="type == 'gift'" class="orderby" v-bind:class="{ active: orderby != 'Recent'}">
                <div class="label">
                    <span>Sort: </span>
                    <span>{{orderby}}</span>
                    <div v-if="orderby !== 'Recent'" class="removeFilter">x</div>
                </div>
                <div class="popout order">
                    <select v-model="orderby" class="category form-control" id="orderBy">
                        <option value="Recent">Recent</option>
                        <option value="Price lowest">Price Lowest</option>
                        <option value="Price highest">Price Highest</option>
                    </select>
                </div>
            </div>
            <div v-if="ship" class="shipping" v-bind:class="{ active: sh}">
                <div class="label" @click="shipToggle">
                    <span class="delivery">Delivery</span>
                    <img v-if="sh" src="https://d1812kujpqokfk.cloudfront.net/assets/shipping.svg">
                    <img v-if="!sh" src="https://d1812kujpqokfk.cloudfront.net/assets/shipping_g.svg">
                    <input id="shippable" type="checkbox" :checked="sh" />
                    <span class="check"></span>
                </div>
            </div>
            <div class="resetAll" @click="clearFilter" v-if="anyActive"><img src="https://d1812kujpqokfk.cloudfront.net/assets/icons/re.png" /></div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState, mapGetters } from 'vuex';
export default {
    name: "filters",
    props: ['type', 'cat', 'price', 'city', 'state', 'ship', 'location'], // decides what we are dealing with and what is shown tot he user
    data() {
        return {
            category: '',
            ci: '', //city
            st: '', //state
            sh: null, //shippable
            prl: 5, //price low 
            prh: 1000, //price high
            zc: '', //zipcode
            zr: 'within_100_miles',
            orderby: 'Recent'
        }
    },
    computed: {
        ...mapState({ giftCategories: state => state.gift.categories }),
        ...mapState({ causeCategories: state => state.cause.categories }),
        anyActive() {
            return this.category || this.ci || this.st || this.sh || this.zc || this.prl > 5 || this.prh < 1000 || this.orderby != 'Recent';
        }
    },
    mounted() {
        this.init();

    },
    watch: {
        $route(to, from) {
            this.init();
        }
    },
    methods: {
        shipToggle() {
            var c = $("#shippable");
            c.prop("checked", !c.prop("checked"));
            this.sh = c.prop("checked");
            this.setFilter();
        },
        toggleFilters() {

            if ($('#filters>div.form-group').is(":visible")) {
                $('#filters>div.form-group').css('display', 'none');
                if (this.createParams().length > 15) {
                    $('.showFilters a').text('Show active filters');
                } else {
                    $('.showFilters a').text('Show filters');
                }

            } else {
                $('#filters>div.form-group').css('display', 'flex');
                $('.showFilters a').text('Hide filters');
            }

        },
        setFromParams() {
            var params = (new URL(document.location)).searchParams;
            if (params.get('category')) {
                this.category = params.get('category');
            }
            if (params.get('price_l')) {
                this.prl = params.get('price_l');
            }
            if (params.get('price_h')) {
                this.prh = params.get('price_h');
            }
            if (params.get('city')) {
                this.ci = params.get('city');
            }
            if (params.get('state')) {
                this.st = params.get('state');
            }
            if (params.get('ship')) {
                this.sh = params.get('ship');
            }
            if (params.get('location')) {
                var zdata = params.get('location').split('at');
                this.zc = zdata[0];
                this.zr = zdata[1];
            }
            if (params.get('orderby')) {
                this.orderby = params.get('orderby');
            }
        },
        init() {
            this.setFromParams();
            var self = this;
            $('.showFilters').on("click", this.toggleFilters);
            if (this.createParams().length > 15) {
                $('.showFilters a').addClass('active').text('Show active filters');
            }
            //categories
            $('#category').on('change', function() {
                self.setFilter();
                $('.cats').hide();
            });
            $('#states').on('change', function() {
                self.setFilter();
                $('.states').hide();
            });
            $('#cities').on('change', function() {
                self.setFilter();
                $('.cities').hide();
            });
            // $('#zipprox').on('blur', function() {
            //     setTimeout(() => {
            //         self.setFilter();
            //         $('.zipprox').hide();
            //     }, 200);

            // });

            $('#orderBy').on('change', function() {
                self.setFilter();
                $('.order').hide();
            });
            // shipping
            $('#shippable').on("change", function() {
                self.setFilter();
            })
            //for price
            $('.slider input').on('change', function() {
                setTimeout(() => {
                    if (parseFloat(self.prh) <= parseFloat(self.prl)) {
                        self.prh = parseFloat(self.prl) + 10;
                    }
                    $('.slider').hide();
                    setTimeout(() => {
                        self.setFilter();
                    }, 20);
                }, 200);
            });
            $('#filters').on("click", '.removeFilter', function(e) {
                e.stopPropagation();
                self.clearFilter(e);
            });
            $('#filters').on("click", '.label', function() {
                $(this).parents().siblings().children('.popout').hide(); //hide any active
                if ($(this).siblings().hasClass('cats')) {
                    if (self.type == 'gift' && !self.giftCategories.length) {
                        self.$store.dispatch('gift/setCategories', null, { root: true });
                    }
                    if (self.type == 'cause' && !self.causeCategories.length) {
                        self.$store.dispatch('cause/setCategories', null, { root: true });
                    }
                }
                $('#cities').val('');
                if ($(this).siblings().hasClass('cities')) {
                    setTimeout(() => {
                        $('#cities').focus();
                    }, 20);
                    self.autocompleteCities();
                }
                $(this).siblings().toggle();
            });

        },
        autocompleteCities() {
            var self = this;
            var term = $('#cities').val();
            var path = this.type + '_cities';
            $("#cities").autocomplete({
                minLength: 3,
                autoFocus: true,
                source: '/api/' + path,
                select: function(event, ui) {
                    self.ci = ui.item.value;
                    $('#cities').blur();
                }
            });
        },
        setRadio() {
            // need to wait for model value to be picked up
            setTimeout(() => {
                this.setFilter();
            }, 10);

        },
        setFilter() {

            if (this.type == 'gift') {
                var url = '/gifts' + this.createParams();

            } else {
                var url = '/causes' + this.createParams();
            }

            var u = window.location.pathname + window.location.search;
            if (u != url) {

                if ($(window).width() < 768) {
                    this.toggleFilters();
                    if (this.createParams().length > 15) {
                        $('.showFilters a').addClass('active').text('Show active filters');
                    } else {
                        $('.showFilters a').removeClass('active');
                    }
                }
                this.$router.push(url);
            }
        },
        clearFilter(e) {
            var p = $(e.target).parents('.active');
            $(e.target).hide();
            p.removeClass('active');
            switch (p.attr('class')) {
                case 'cat':
                    this.category = '';
                    break;
                case 'price':
                    this.prl = 5;
                    this.prh = 1000;
                    break;
                case 'city':
                    this.ci = '';
                    break;
                case 'state':
                    this.st = '';
                    break;
                case 'location':
                    this.zc = '';
                    this.zr = 'within_100_miles';
                    break;
                case 'orderby':
                    this.orderby = 'Recent';
                    break;
                default:
                    this.category = '';
                    this.prl = 5;
                    this.prh = 1000;
                    this.ci = '';
                    this.st = '';
                    this.sh = 0;
                    this.zc = '';
                    this.zr = 'within_100_miles';
                    this.orderby = 'Recent';
            }
            this.setFilter();

        },
        createParams() {
            var p = '';
            if (this.category) {
                p = p + '&category=' + this.category;
            }
            if (this.st) {
                p = p + '&state=' + this.st;
            }
            if (this.ci) {
                p = p + '&city=' + this.ci;
            }
            if (this.sh) {
                p = p + '&ship=' + this.sh;
            }
            if (this.prl != 5) {
                p = p + '&price_l=' + this.prl;
            }
            if (this.prh != 1000) {
                p = p + '&price_h=' + this.prh;
            }
            if (this.zc) {
                p = p + '&location=' + this.zc + 'at' + this.zr;
            }
            p = p + '&orderby=' + this.orderby;

            return '?' + p.substring(1); //removes the first '&'
        },
        get_range() {
            var a = this.zr.replace('within_', '');
            var a = a.replace('_miles', '');
            return a + ' miles of';
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#filters {
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    >div.showFilters {
        display: flex;
        margin: -10px 0 10px 0;

        >a {
            max-width: 170px;
            text-align: center;
            height: 32px;
            text-decoration: none;
            line-height: 10px;

            &.active {
                border-color: lighten($red, 15%);
            }
        }

        @include media-breakpoint-up(md) {
            display: none;

        }
    }

    >div {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        >div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: $grey;
            border-radius: 25px;

            padding: 0 10px;
            height: 40px;
            border: 1px solid rgba(177, 176, 190, 0.4);

            cursor: pointer;
            width: 100%;
            margin: 5px;

            @include media-breakpoint-up(md) {
                width: 170px;
                margin: 0 5px;
            }

            .label {
                width: 100%;
                text-align: center;
            }

            &.cat {
                @include media-breakpoint-up(md) {
                    width: auto;
                }

                min-width: 170px;
            }

            span {
                color: $textcolor;

                &:first-child {
                    color: rgba(18, 12, 83, 0.45);
                }
            }

            &.active {
                border: 1px solid lighten($red, 20%);
            }

            div.removeFilter {
                position: absolute;
                right: 1px;
                top: -12px;
                border-radius: 50%;
                color: #FF5F58;
                padding: 0px 5px;
                z-index: 1000;
                background-color: #fff2f1;
                height: 20px;
                width: 20px;
                line-height: 20px;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .popout {
        display: none;

        background-color: #fff;
        position: absolute;
        border: 1px solid rgba(177, 176, 190, 0.4);
        border-radius: 15px;
        padding: 15px 10px;
        top: 42px;
        left: 0px;
        z-index: 2000;
        color: rgba(18, 12, 83, 0.45);
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 280px;
        }
    }

    // .cities {
    //     width: 100%;

    //     @include media-breakpoint-up(md) {
    //         width: auto;
    //     }

    //     #cities {
    //         height: 40px;
    //         border: 1px solid rgba(177, 176, 190, 0.4);
    //         border-radius: 15px;
    //         outline: none;
    //         width: 100%;
    //     }
    // }

    .slider,
    .location {

        p {
            margin-bottom: 7px;
        }

        >.label {
            line-height: 18px;
        }

        input[type="number"],
        .btn {
            border-radius: 25px;
            width: 100px;
            height: auto;
            border: 1px solid rgba(177, 176, 190, 0.4);
            color: rgba(18, 12, 83, 0.45);
            padding-left: 15px;
        }

        input[type="radio"] {
            height: 25px;
        }

        .btn {
            background-color: $buttonblue;
            color: #fff;
            // min-width: 100px;
        }

        input[type=range] {
            position: relative;
            left: 0;
            bottom: -12px;
            font-size: 18px;
            -webkit-appearance: none;
            width: 100%;
            padding: 0 10px;
            border: none;
            height: 0;

            &.s {
                top: -12px;
            }
        }




        input[type=range]:focus,
        input[type=number]:focus {
            outline: none;
        }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: $greybg;
        }

        input[type=range]:focus::-ms-fill-lower {
            background: $greybg;
        }

        input[type=range]:focus::-ms-fill-upper {
            background: $greybg;
        }

        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-webkit-slider-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $red;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: $red;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -7px;
        }

        input[type=range]::-moz-range-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-moz-range-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $greybg;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: #a1d0ff;
            cursor: pointer;
        }

        input[type=range]::-ms-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }

        input[type=range]::-ms-fill-lower,
        input[type=range]::-ms-fill-upper {
            background: $greybg;
            border-radius: 1px;
            box-shadow: none;
            border: 0;
        }

        input[type=range]::-ms-thumb {
            z-index: 2;
            position: relative;
            box-shadow: 0px 0px 0px #000;
            border: 1px solid $greybg;
            height: 18px;
            width: 18px;
            border-radius: 25px;
            background: #a1d0ff;
            cursor: pointer;

        }


    }

    .location {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .shipping {
        position: relative;
        min-width: 130px;

        @include media-breakpoint-up(md) {
            span.delivery {
                display: none;
            }

            width:70px;
            min-width:70px;

            >.label {
                text-align: left;
            }
        }

        @include media-breakpoint-up(lg) {
            span.delivery {
                display: inline;
            }

            width:170px;

            >.label {
                text-align: center;
            }
        }

        &:hover input~span {
            background-color: #ccc;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked~span {
                background-color: $buttonblue;
            }

            &:checked~span:after {
                display: block;
            }
        }

        .check {
            position: absolute;
            top: 10px;
            right: 10px;
            height: 17px;
            width: 17px;
            line-height: 25px;
            background-color: $icongrey;
            border-radius: 12px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                left: 6px;
                top: 2px;
                width: 5px;
                height: 11px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .resetAll {
        width: 40px;
        border-color: lighten($red, 20%);

    }
}

</style>
