<template>
    <div class="ct container forms" id="locationStep">
        <titletag title="Creating a cause"></titletag>
        <div class="container" v-if="cause">
            <steps :uuid="cause.uuid" :active="activeState"></steps>
            <!-- <h2 v-if="v == 't'" class="title">Step 3: Confirm Your Address</h2> -->
            <h2 v-if="v == 'fo' && !cause.published" class="title">Step 3: Publish</h2>
            <h2 v-if="v == 'fo' && cause && cause.published" class="title">Share</h2>
            <div v-if="v == 'fo' && !cause.published && user" class="publish form-group row">
                <p>You can now preview and publish your cause. A publish button is available on the Preview page</p>
                <router-link :to="`/c/${cause.url_str}`" class="secondaryButton preview">Preview cause before publishing</router-link>
                <a class="btn btn-primary pub" @click="publishCause">Publish</a>
            </div>
            <!--  <div v-if="v == 'fo' && !cause.published && !user" class="publish form-group row">
                <p>To publish we will need your name, email and a password. If you already have an account and your password matches we will use it. If not we will create one.</p>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" id="name" v-model="name" placeholder="Name" class="form-control form-control-lg" required="true">
                        <label class="magic" for="name">Name</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" id="email" v-model="email" placeholder="Email" class="form-control form-control-lg" required="true">
                        <label class="magic" for="email">Email</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="password" id="password" v-model="password" placeholder="Password" class="form-control form-control-lg" required="true">
                        <label class="magic" for="password">Password</label>
                    </div>
                </div>
                <a class="btn btn-primary pub" @click="publishCause">Publish</a>
            </div> -->
        </div>
        <div v-if="v == 'fo' && cause.published" class="publish form-group container">
            <p>"{{ cause.name}}" is published . Share your cause to start getting your community involved.</p>
            <social-sharing :url="domain + '/c/'+ cause.url_str" :description="cause.description" :title="cause.name" inline-template>
                <div class="sharingLinks">
                    <div>
                        <network network="facebook">
                            <i class="fa fa-facebook fb-c"></i>Share your cause on Facebook
                        </network>
                    </div>
                    <div>
                        <network network="twitter">
                            <i class="fa fa-twitter tw-c"></i>Share your cause on Twitter
                        </network>
                    </div>
                    <div>
                        <network network="email">
                            <i class="fa fa-envelope em-c"></i>Share your cause via email
                        </network>
                    </div>
                    <div>
                        <network network="whatsapp">
                            <i class="fa fa-whatsapp wa-c"></i>Share your cause on WhatsApp
                        </network>
                    </div>
                </div>
            </social-sharing>
            <div class="directLink">
                <p>Click below to copy url and share in your own way</p>
                <div class="copied">Copied!</div>
                <div>
                    <input type="text" @click="copyLink()" id="copyUrl" ref="copytext" :value="domain + '/c/'+ cause.url_str">
                </div>
                <router-link class="secondaryButton item" :to="/c/+ cause.url_str">View cause</router-link>
                <p>Add an item to this cause. Pre-populating the cause can help prompt others to donate something similar.</p>
                <router-link :to="`/gift/add/${uuid}/${cause.name}`" class="secondaryButton mb-2">Add an item</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import Steps from './Steps';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
import { getLocalUser } from "../../helpers/auth";

export default {
    props: ['uuid', 'v'], // v is for view
    name: 'causepublishshare',
    components: {
        Steps
    },
    data() {
        return {
            submitted: false,
            errors: null,
            domain: window.location.origin,
            name: null,
            email: null,
            password: null
        }
    },
    computed: {
        ...mapGetters(['getcauseByUuid']),
        cause() {
            return this.getcauseByUuid(this.uuid);
        },
        activeState() {
            return this.v;
        },
        user() {
            return getLocalUser();
        }
    },
    mounted() {
        if (!this.cause || this.cause.uuid != this.uuid || this.refresh) {
            this.setCauseByUuid(this.uuid).then((res) => {});
        }
    },
    methods: {
        ...mapActions(['setCauseByUuid']),
        copyLink() {
            this.$refs.copytext.select();
            document.execCommand("copy");
            $('.copied').css({ "display": "flex" }).delay(1000).fadeOut();
        },
        publishCause() {
            var payload = {
                "uuid": this.uuid,
                "name": this.name,
                "email": this.email,
                "password": this.password,
            }
            let loader = this.$loading.show();
            // wiil need to do this better sometime
            this.$store.dispatch('account/publishCause', payload).then((r) => {
                if (r.success) {
                    this.published = 1;
                    this.setCauseByUuid(this.uuid).then((res) => {

                    });

                } else {
                    this.flashMessage({ m: r.error, e: 1 });
                }
                loader.hide();
            }, error => {
                this.flashMessage({ m: error.data.errors.email[0], e: 1, d: 3500 })
                loader.hide();
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.directLink {
    position: relative;

    .copied {
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 40%;
        top: 16%;
        padding: 10px;
        color: $red;
        z-index: 1000;
        border: 1px solid $blue;
        border-radius: 8px;
        display: none;
        width: 80px !important;
        height: 35px !important;
        background-color: $blue;
    }
}

</style>
