<template>
	<div class="ct container logins">
        <titletag title="Register for FunditOn"></titletag>
        <div class="register login row justify-content-center">
            <div class="card">
                <div class="card-head">Oops!</div>
                <div class="form-group row">
                <p class="error b">
                    It looks like you did not allow us to use your email with Facebook. An email is required for your account. Please try again by clicking below.
                </p>
                        </div>
                <div class="card-body">
                    <div class="form-group row">
                        <i class="fab fa-facebook-f"></i>
                        <a class="f" href="/facebook_no_email">Share email from Facebook</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	name: "socialnoemail",
	props: [],
	data () {
		return {
		}
	},
	computed: {

	},
	mounted() {
	},
	methods: {
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>