<template>
    <div class="giftList row">
        <giftitem v-for="item in gifts" :key="item.id" :item="item"></giftitem>
    </div>
</template>
<script>
import Giftitem from './Item'
export default {
    props: ['gifts'],
    components: {
        Giftitem
    }
}
</script>
