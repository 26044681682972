<template>
    <div class="ct forms container gift" id="giftPage" v-if="gift && gift.url_str == urlStr">
        <pubbox type="item" :item="gift"></pubbox>
        <titletag v-if="gift && gift.primary_photo && gift.primary_photo.path_full" :title="gift.name" :description="`${gift.description}`" :image="getCloudFrontUrl() + gift.primary_photo.path_full"></titletag>
        <div v-if="!available" class="block-heading">
            <div class="empty form-group">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/solditem.svg">
                <p class="nothing">Oops... it seems like this item has been sold. Please take a look at available items to find what you are looking for.</p>
                <router-link class="btn-primary simple" :to="`/gifts`">See All Items</router-link>
            </div>
        </div>
        <div class="row" v-show="ready" v-if="gift && available">
            <div class="col-sm-8">
                <giftgallery :photos="gift.photos" :primary="gift.primary_photo" :shippable="gift.shippable" :key="gift.id"></giftgallery>
                <h1>{{gift.name}}</h1>
                <h4 class="g">${{gift.price}}</h4>
                <div class="sub float-left">
                    <hr class="top">
                    </hr>
                    <span class="loc float-left"><i class="fas fa-map-marker-alt"></i>{{gift.user.address.city}}, {{gift.user.address.state}}</span>
                    <span v-if="gift.category" class="cat float-right">Category: {{gift.category.name}}</span>
                    <hr>
                </div>
                <div class="desc float-left">
                    {{gift.description}}
                </div>
            </div>
            <div class="col-sm-4 mobile">
                <share :item="gift" :key="gift.uuid" :url="'/g/' +urlStr"></share>
                <actions @goToCheckout="goToCheckout" :gift="gift"></actions>
                <supporting :cause="gift.cause"></supporting>
                <report type="gift" :item="gift"></report>
            </div>
            <div class="col-sm-4 desktop">
                <div class="contain">
                    <actions @goToCheckout="goToCheckout" :gift="gift"></actions>
                    <share :item="gift" :key="gift.uuid" :url="'/g/' +urlStr"></share>
                </div>
                <supporting :cause="gift.cause"></supporting>
                <report type="gift" :item="gift"></report>
            </div>
        </div>
        <div class="row" v-if="gifts && gifts.length">
            <div class="col-12">
                <hr>
                <h3 class="sameOwner">More items from this seller</h3>
                <div class="giftList row">
                    <sameuseritem v-for="item in gifts" :key="item.id" :id="item.id" :item="item"></sameuseritem>
                </div>
            </div>
        </div>
        <div v-if="gift" itemtype="https://schema.org/Product" itemscope>
            <meta itemprop="name" :content="gift.name" />
            <link itemprop="image" :href="getCloudFrontUrl() + gift.primary_photo.path_full" />
         
            <meta itemprop="description" :content="gift.description" />
            <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
                <link itemprop="url" :href="getCurrentUrl()" />
                <meta itemprop="availability" content="InStock" />
                <meta itemprop="priceCurrency" content="USD" />
                
                <meta itemprop="price" :content="gift.price" />
            </div>
        </div>
    </div>
</template>
<script>
import Giftgallery from './Giftgallery'
import Actions from './Actions'
import Share from '../causes/Share'
import Supporting from './Supporting'
import Sameuseritem from './Item'
import Pubbox from '../common/PublishBox'
import Report from '../common/Report'
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');

export default {
    components: {
        Giftgallery,
        Actions,
        Share,
        Supporting,
        Sameuseritem,
        Pubbox,
        Report
    },
    props: {
        urlStr: null,
        intent: null

    },
    data() {
        return {
            shipping: false,
            available: true,
        }
    },
    name: 'gift',
    computed: {
        ...mapGetters(['gift']),
        ...mapState({ gifts: state => state.gift.sameUserGifts }),
        ready() {
            //stops flashing old content. see v-show
            if (this.urlStr === this.gift.url_str) {
                return true;
            }
        }
    },
    created() {
        this.initData();
    },
    watch: {
        '$route'(to, from) {
            if (from.params.urlStr !== to.params.urlStr) {
                this.initData();
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        $('#confirmModal').modal('hide');
        next();
    },
    methods: {
        ...mapActions(['setGift']),
        formatDate(date) {
            return moment(new moment(date)).subtract(new Date().getTimezoneOffset(), 'm').fromNow();
        },
        initData() {
            let loader = this.$loading.show();
            this.setGift(this.urlStr).then(response => {
                if (this.intent == 1) {
                    this.goToCheckout();
                }
                if (undefined == this.gift) {
                    this.$router.push('/404');
                    loader.hide();
                }

                this.available = this.gift.status

                loader.hide();
            }, error => {

                console.error("TODO check errors")
            })

        },
        getCurrentUrl() {
        	return window.location.href;
        },
        confirmShipping() {
            if (this.gift.shippable) {
                // Ask user if shipping or picking it up directly
                var data = {
                    h: "Seller is willing to ship",
                    q: "Shipping is primarily for friends and family. Funditon can not guarantee what is shipped to you. This is simply a convenience service we provide. Accepting this do you still want to have this item shipped to you?",
                    y: "Understood, Yes please!",
                    n: "No, I am picking it up"
                };
                //mixin in MyMixins.js always returns a boolean
                this.confirmDialog(data, this.nextStep);
            } else {
                this.$router.push({ name: 'checkout', params: { uuid: this.gift.uuid } });
            }
        },
        nextStep(m) {
            this.shipping = m;
            if (m) {
                this.$router.push({ name: 'shippingquote', params: { uuid: this.gift.uuid } });
            } else {
                this.$router.push({ name: 'checkout', params: { uuid: this.gift.uuid } });
            }
        },
        goToCheckout() {
            if (this.$store.getters['user/currentUser'] && (this.$store.getters['user/currentUser'].id == this.gift.user_id)) {
                this.flashMessage({ e: true, m: "You cannot purchase your own item." });
                return
            } else {
                this.confirmShipping();
            }

        },
        reload() {
            var url = window.location.href;
            url = url.substring(0, url.length - 5);
            window.location = url;
        }

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#giftPage {
    width: 100%;
    float: left;

    div.empty {
        display: flex;
        flex-direction: column;
        padding-top: 80px;

        @include media-breakpoint-up(sm) {
            padding-top: 110px;
        }

        align-items: center;

        img {
            padding-bottom: 40px;
        }

        a {
            max-width: 140px;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
        }

        p.nothing {
            padding: 0 25px;

            @include media-breakpoint-up(sm) {
                padding: 0 25%;
            }

            color: #52506F;
            font-family: $open-sans;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
        }
    }

    .col-sm-8 {
        padding: 0 2%;
    }

    h1,
    h3.sameOwner {
        color: $bluetext;
        padding: 20px 8px 0 10px;
        font-family: $cat;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
    }

    h3.sameOwner {
        padding-bottom: 15px;
    }

    h4 {
        color: $bluetext;
        font-family: $open-sans;
        padding: 0px 8px 0 10px;
        font-size: 18px;
        font-weight: 600;

        &.g {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

    }

    .sub {
        color: $bluetext;
        font-size: 12px;
        width: 100%;
        text-transform: uppercase;
        font-weight: 600;
        padding-left: 10px;

        .loc {

            i {
                color: $red;
                padding-right: 1rem;
            }
        }

        .cat {

            padding-right: 1rem;
        }

        hr {
            color: $grey;
            margin-top: 3rem;

            &.top {
                margin-top: 0;
            }
        }
    }

    .desc {
        width: 100%;
        padding: 10px;
        color: $textcolor;
        white-space: pre-line;
        font-family: $open-sans;
        font-size: 16px;
        line-height: 26px;
    }

    .mobile {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    .desktop {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }

        .contain {
            border-radius: 10px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
            padding: 10px 5px;
            margin-top: 20px;
        }
    }
}

</style>
