<template>
    <div class="ct container accountContainer">
        <titletag title="My Account"></titletag>
        <div class="row">
            <div class="col-sm-3 account" v-if="canShow">
                <div class="person" v-if="profile">
                    <template v-if="profile.avatar_url">
                        <img :src="profile.avatar_url">
                    </template>
                    <template v-else>
                        <img :src="getCloudFrontUrl() + 'identicons/' + profile.identicon">
                    </template>
                    <div v-if="isMobile()" @click="profileLink" class="edit"><i class="fas fa-pencil-alt"></i></div>
                    <div class="user">
                        <h2 class="title">{{profile.name}}</h2>
                        <span> Joined {{dateFromNow(profile.created_at)}}</span>
                    </div>
                </div>
                <div class="menu" v-if="profile">
                    <div class="heading">My Activity</div>
                    <div class="activity-links">
                        <router-link :to="`/account/conversations`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/mssgs.png">{{ $t("nav.messages") }}<span v-if="messageCount" class="mc mob">{{messageCount}}</span></router-link>
                        <router-link :to="`/account/causes`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/causes.png">{{ $t("nav.my causes") }}</router-link>
                        <router-link :to="`/account/gifts`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/items.png">{{ $t("nav.my items") }}</router-link>
                        <router-link :to="`/account/purchases`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/purchases.png">{{ $t("nav.purchases") }}</router-link>
                        <router-link :to="`/account/liked_causes`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/likedcauses.png">{{ $t("nav.liked causes") }}</router-link>
                        <router-link :to="`/account/liked_items`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/likeditems.png">{{ $t("nav.liked items") }}</router-link>
                    </div>
                    <div class="heading">Settings</div>
                    <div class="settings-links">
                        <router-link :to="`/account/profile`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/settings.png">{{ $t("nav.profile") }}</router-link>
                        <router-link :to="`/account/notification_preferences`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/notifications.png">{{ $t("nav.notifications") }}</router-link>
                        <router-link v-if="profile.causes_bought_or_sold_for" :to="`/account/causes_shared_with`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/help.png">My data</router-link>
                        <router-link v-if="profile.connectedaccount" :to="`/account/stripe_account`" class="account-link"><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/stripe.png">{{ $t("nav.stripe") }}</router-link>
                    </div>
                    <a class="account-link" @click="$store.dispatch('user/logout')" onclick=""><img src="https://d1812kujpqokfk.cloudfront.net/assets/account/signout.png">{{ $t("nav.sign out") }}</a>
                </div>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('account');

export default {
    mounted() {
        this.init();
        this.accountChanged = this.changed;
    },
    updated() {
        this.setCanShow();
    },
    computed: {
        ...mapGetters(['profile']),
        ...mapState({ changed: state => state.account.changed }),
        ...mapState({ messageCount: state => state.channel.unread }),
    },
    data() {
        return {
            canShow: false,
            accountChanged: null
        }
    },
    watch: {
        $route(to, from) {
            this.setCanShow();
        },
        accountChanged(b, a) {
            if (b.indexOf('account') != -1) {
                this.setAccountDetails();
            }
        }
    },
    methods: {
        ...mapActions(['setAccountDetails']),
        init() {
            // we will default to user's profile page if they come in on account/ on a larger screen
            if (window.matchMedia("(min-width: 576px)").matches) {
                if (window.location.pathname === '/account') {
                    this.$router.push({ name: 'profile' });
                }
            }
            this.setCanShow();
            if (null == this.profile) {
                let loader = this.$loading.show();
                this.setAccountDetails().then(response => {
                    loader.hide();
                });
            }
        },
        profileLink() {
            this.$router.push({ name: 'profile' }).catch(err => {}); // adding the catch here prevents the same route error
        },
        setCanShow() {
            if (window.matchMedia("(min-width: 576px)").matches) {
                this.canShow = true;
                return;
            }
            if (window.location.pathname === '/account') {
                this.canShow = true;
                return;
            }
            this.canShow = false;
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.accountContainer {
    .account {
        padding: 15px;

        .person {
            display: flex;
            flex-direction: row;
            position: relative;

            img {
                width: 75px;
                height: 75px;
                border-radius: 50%;
            }

            .edit {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 50px;
                left: 50px;
                z-index: 2;
                background-color: $buttonblue;
                border-radius: 50%;
                border: 2px solid #fff;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: #fff;
                }
            }

            .user {
                h2 {
                    padding: 0 15px;
                    margin: 0;
                }

                span {
                    width: 100%;
                    padding: 0 15px;
                    opacity: 0.9;
                    text-transform: uppercase;
                    color: $dategrey;
                    font-family: $open-sans;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
        }

        .menu {
            padding-top: 10px;

            .heading {
                color: $bluetext;
                font-family: $cat;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                padding: 5px 10px;
            }

            .settings-links,
            .activity-links {
                padding: 5px 0;
            }
        }
    }

    .col-sm-9 {
        padding-top: 5px; // note the h2.title has the other 10px
    }

    a.account-link {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 30px;
        width: 100%;
        display: block;
        margin-bottom: 25px;

        img {
            margin-right: 16px;
            margin-left: 9px;
            border-radius: 7px;
        }

        span.mc.mob {
        	display: inline-block;
            background-color: $red;
            color: #fff !important;
            // padding:3px;
            border-radius: 50%;
            position: relative;
            left: -5px;
            text-align: center;
            top: -8px;
            // right: -3px;
            font-size: 11px;
            line-height: 12px;
            width: 12px;
            height: 12px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.back {
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    border: 1px solid rgba(177, 176, 190, 0.4);
    border-radius: 25px;
    color: $bluetext;
    font-family: $cat;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

</style>
