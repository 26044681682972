<template>
    <div class="ct container forms" v-if="gift && gift.uuid == uuid" id="editGift">
        <titletag v-if="gift" :title="'Editing ' + gift.name"></titletag>
        <div class="container">
            <steps :uuid="uuid" active="f"></steps>
            <h2 class="title">Edit {{gift.name}}</h2>
            <p>Upload JPG or PNG images up 12 MB each. Max 10 Photos</p>
            <div id="drop-zone">
                <label id="upload-label" class="file-upload">Drag and drop photos here. Or just click here to choose photos.
                    <input type="file" id="fileElem" multiple accept="image/jpeg,image/png" ref="file" @change="storeFile(null)">
                </label>
                <div class="progress">
                    <div id="progress-bar" class="progress-bar progress-bar-striped bg-success" role="progressbar" style="" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <p class="clickFeaturedText">Click on an image to make it your Featured Image</p>
            <div id="gallery">
                <div v-for="photo in gift.photos" class="uploaded_thumb">
                    <span @click="confirmDelete(photo.uuid)" class="deletePhoto">x</span>
                    <img :uuid="photo.uuid" :src="getCloudFrontUrl() + photo.path_thumb" :class="{'primary' : photo.uuid == gift.primary_photo.uuid}">
                    <span v-if="photo.uuid == gift.primary_photo.uuid" class="primaryImage">Featured Image</span>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <p>To change supported cause start typing a cause name in the field below. Check cause image matches. </p>
            <div class="col-sm-12">
                <input id="causeId" class="form-control" type="text" placeholder="Assosiate with another cause" :value="gift.cause.name">
                <label class="magic" for="causeId">Cause supported</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input v-model="gift.name" type="text" class="form-control" id="giftname" placeholder="Name for your gift" required>
                <label class="magic" for="giftname">Item title</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <select v-model="gift.category_id" class="category form-control form-control-lg" id="category" required>
                    <option value="">Choose a Category</option>
                    <option v-for="category in giftCategories" :value="category.id">{{category.name}}</option>
                </select>
                <label class="magic" for="category">Category</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <span class="dollar">$</span>
                <input v-model="gift.price" type="number" class="form-control focussed" id="price" min="5" max="100000" placeholder="Suggest a price, minimum: 5">
                <label class="magic" for="price">Price</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <textarea v-model="gift.description" class="form-control" id="description" rows="3" placeholder="Add any more details about this item"></textarea>
                <label class="magic" for="description">Description</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div class="radiocheck">
                    <label>Anonymous in the cause activity feed
                        <input v-model="gift.anonymous_in_cause_feed" type="checkbox">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div class="radiocheck">
                    <label>Available for Shipping?
                        <input v-model="gift.shippable" type="checkbox">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <template v-if="gift.shippable">
            <div class="form-group row">
                <div class="col-sm-12">
                    <p>{{$t('gifts.ship-p')}}</p>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="pWidth" class="form-control" type="number" v-model="parcel_width" :placeholder="$t('gifts.parcel-w')">
                    <label class="magic" for="pWidth">Width</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="pHeight" class="form-control" type="number" v-model="parcel_height" :placeholder="$t('gifts.parcel-h')">
                    <label class="magic" for="pHeight">Height</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="pLength" class="form-control" type="number" v-model="parcel_length" :placeholder="$t('gifts.parcel-l')">
                    <label class="magic" for="pLength">Length</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="pWeight" class="form-control" type="text" v-model="parcel_weight" :placeholder="$t('gifts.parcel-weight')">
                    <label class="magic" for="pWeight">Weight</label>
                </div>
            </div>
        </template>
        <div class="form-group row">
            <div class="col-sm-12">
                <a class="btn btn-primary mb-2" @click="updateGift()">Save Changes</a>
            </div>
        </div>
    </div>
</template>
<script>
import Steps from './Steps';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');

export default {
    props: ['uuid'],
    data() {
        return {
            // gift: null,
            image_uuids: [],
            deleted_photos: [],
            numFiles: 0,
            finalTotal: 0,
            uploadProgress: [],
            submitted: false,
            parcel_width: '',
            parcel_height: '',
            parcel_length: '',
            parcel_weight: '',
            causeId: null,
            causePreview: null
        }
    },
    computed: {
        ...mapGetters(['gift', 'categories']),
        giftCategories() {
            return this.categories;
        }
    },
    components: { Steps },
    mounted() {
        this.initData();
    },
    methods: {
        ...mapActions(['setGiftByUuid', 'deleteGiftPhoto', 'setCategories']),
        confirmDelete(uuid) {
            var data = {
                h: "Delete photo",
                q: "Are you sure you want to delete this photo?",
                y: "Yes",
                n: "Cancel",
                uuid: uuid
            }
            this.confirmDialog(data, this.deletePhoto);
        },
        deletePhoto(bool, data) {
            if (bool) {
                let loader = this.$loading.show();
                this.deleteGiftPhoto(data.uuid).then(response => {
                    // this needs to be checked before deleting
                    var img = $('img[uuid="' + data.uuid + '"]');
                    img.parent('.uploaded_thumb').remove();
                    loader.hide();
                });
            }
        },
        setParcelData() {
            if (this.gift.shippable && this.gift.parcel) {
                this.parcel_height = this.gift.parcel.height;
                this.parcel_width = this.gift.parcel.width;
                this.parcel_length = this.gift.parcel.length;
                this.parcel_weight = this.gift.parcel.weight;
            }
        },
        bindDeleteForNewPhotos() {
            $('#gallery').on("click", ".deletePhoto", function() {
                $(this).parents('.uploaded_thumb').remove();
            })
        },
        initNewCause() {
            this.causeId = this.gift.cause_id;
            if (this.gift.cause.primary_photo) {
                this.causePreview = this.getCloudFrontUrl() + this.gift.cause.primary_photo.path_thumb;
            } else {
                this.causePreview = 'https://d1812kujpqokfk.cloudfront.net/assets/cause/causedefault.png';
            }
            this.appendPreview();
            //set up autocomplete on the field
            var self = this;
            $("#causeId").autocomplete({
                minLength: 3,
                source: '/api/cause_search',
                select: function(event, ui) {
                    self.causeId = ui.item.id;
                    if (ui.item.path_thumb) {
                        self.causePreview = self.getCloudFrontUrl() + ui.item.path_thumb;
                    } else {
                        self.causePreview = 'https://d1812kujpqokfk.cloudfront.net/assets/cause/causedefault.png';
                    }

                    self.appendPreview();
                }
            });
        },
        appendPreview() {
            $("#causeId").css('background', 'url(' + this.causePreview + ')').css('background-size', '65px').css('background-repeat', 'no-repeat').css('background-position', 'right 15px center');
        },
        initData() {
            this.bindDeleteForNewPhotos();
            // $('.gift').hide();
            let loader = this.$loading.show();
            this.setGiftByUuid(this.uuid).then(response => {
                // this.gift = this.getGift(this.url_str);
                loader.hide();
                this.setParcelData();
                // $('.gift').show();
                this.initImages();
                this.initDropZone();
                this.initNewCause();
                setTimeout(this.initLabels, 200);
            });
            if (this.categories.length < 1) {
                this.setCategories();
            }

        },
        validate() {
            // add all the things to validate here
            // if shippable check parcel is complete
            this.image_uuids = [];
            var self = this;
            $.each($('img', '#gallery'), function(i, v) {
                self.image_uuids.push($(v).attr('uuid'));
            });
            if (!this.image_uuids.length) {
                this.scrollToError('#fileElem');
                this.flashMessage({ m: 'Your item needs at least one photo', e: 1 });
                return false;
            }
            if (this.image_uuids.indexOf(this.primaryImage) === -1) {
                this.primaryImage = this.image_uuids[0];
            }
            if (!this.gift.name) {
                this.scrollToError('#giftname');
                this.flashMessage({ m: 'Your item needs a name', e: 1 });
                return false;
            }
            if (!this.gift.category_id) {
                this.scrollToError('#category');
                this.flashMessage({ m: 'Your item needs a category', e: 1 });
                return false;
            }
            if (!this.gift.price || this.gift.price < 5) {
                this.scrollToError('#price');
                this.flashMessage({ m: 'Your item needs a price of at least $5', e: 1 });
                return false;
            }
            if (this.gift.price > 999999.99) {
                this.scrollToError('#price');
                this.flashMessage({ m: 'Your item is too expensive. Please stay under $100,000', e: 1 });
                return false;
            }
            if (!this.gift.description) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your item needs a description', e: 1 });
                return false;
            }
            if (this.gift.description.length < 10) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your item description needs a few words :)', e: 1 });
                return false;
            }
            if (this.gift.shippable) {
                // validate the parcel here
                if (!this.parcel_width || this.parcel_width < 0.5) {
                    this.scrollToError('#pWidth');
                    this.flashMessage({ m: this.$t('gifts.wide'), e: 1 });
                    return false;
                }
                if (!this.parcel_height || this.parcel_height < 0.5) {
                    this.scrollToError('#pHeight');
                    this.flashMessage({ m: this.$t('gifts.high'), e: 1 });
                    return false;
                }
                if (!this.parcel_length || this.parcel_length < 0.5) {
                    this.scrollToError('#pLength');
                    this.flashMessage({ m: this.$t('gifts.long'), e: 1 });
                    return false;
                }
                if (!this.parcel_weight || this.parcel_weight < 0.1 || this.parcel_weight > 70) {
                    this.scrollToError('#pWeight');
                    this.flashMessage({ m: this.$t('gifts.weight'), e: 1 });
                    return false;
                }
                if (!this.validParcel(this.parcel_width, this.parcel_height, this.parcel_length)) {
                    this.scrollToError('#pWidth');
                    this.flashMessage({ d: 2500, m: this.$t('gifts.toobig'), e: 1 });
                    return false;
                }
            }
            return true;
        },
        updateGift() {
            if (this.validate()) {
                var payload = {
                    'image_uuids': this.image_uuids,
                    'name': this.gift.name,
                    'deleted_image_uuids': this.deleted_photos, // the diff is needed in case a us
                    'category_id': this.gift.category_id,
                    'primary_photo_uuid': this.primaryImage,
                    'description': this.gift.description,
                    'price': this.gift.price,
                    'uuid': this.gift.uuid,
                    'anonymous_in_cause_feed': this.gift.anonymous_in_cause_feed,
                    'cause_id': this.causeId,
                    'shippable': this.gift.shippable,
                    'width': this.parcel_width,
                    'height': this.parcel_height,
                    'length': this.parcel_length,
                    'weight': this.parcel_weight,
                };
                this.$store.dispatch('gift/updateGift', payload).then(response => {
                    if (response.data.success) {
                        this.$router.push({ name: 'giftlocation', params: { uuid: response.data.gift.uuid, gift: response.data.gift, v: "s" } });
                    } else {
                        this.flashMessage({ m: response.data.error, e: 1 });
                    }
                }, error => {
                    console.error("Got nothing from server. Prompt user to check internet connection and try again")
                })
            }
        },
        initDropZone() {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.preventDefaults, false)
                document.body.addEventListener(eventName, this.preventDefaults, false)
            });
            ['dragenter', 'dragover'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.highlight, false)
            });
            ['dragleave', 'drop'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.unhighlight, false)
            });
            // Handle dropped files
            document.getElementById("drop-zone").addEventListener('drop', this.handleDrop, false)
        },
        async store(file, options = null) {
            // axios.defaults.headers.common["Authorization"] = `Bearer ${window.token}`
            const response = await axios.post('/vapor_signed_url', {
                'bucket': options.bucket || '',
                'content_type': options.contentType || file.type,
                'expires': options.expires || ''
            });

            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }
            var instance = axios.create();
            instance.defaults.headers.common = {};
            const s3Response = await instance.put(response.data.url, file, {
                headers: response.data.headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            });

            response.data.extension = file.name.split('.').pop()

            return response.data;
        },
        storeFile(files) {
            if (null == files) {
                files = this.$refs.file.files;
            }
            files = this.validate_files_for_upload(files);
            $('.btn-primary').prop('disabled', true);

            this.initializeProgress(files.length)
            for (var i = 0; i < files.length; i++) {
                this.store(files[i], {
                    progress: progress => {
                        this.updateProgress(Math.round(progress * 100));
                    }
                }).then(response => {
                    // these are image uuids from vapor's upload
                    this.triggerResizeJob(response.uuid);
                });
            }

        },
        triggerResizeJob(uuid) {
            this.showThumbContainer(uuid);
            axios.post('/api/resize_gift_image', { uuid: uuid }).then(response => {
                this.showUploadedThumb(response.data);
            })
        },
        showThumbContainer(uuid) {
            var el = '<div class="uploaded_thumb ' + uuid + '"><span class="deleteFreshPhoto">x</span><div uuid="' + uuid + '"</div>';
            $('#gallery').append(el);
            window[uuid] = this.$loading.show({ width: 20, height: 20, container: $('div.' + uuid)[0] });
        },
        showUploadedThumb(response) {
            // uuid here is the origianl uuid from vapor
            var url = this.getCloudFrontUrl() + response.photo.path_thumb;
            var el = '<img uuid="' + response.photo.uuid + '" src="' + url + '">';
            $('div[uuid="' + response.uuid + '"]', $('#gallery')).replaceWith(el);
            $('.btn-primary').prop('disabled', false);
            this.initImages();
            window[response.uuid].hide();
        },
        validate_files_for_upload(files) {
            files = Array.from(files);
            for (var i = 0; i < files.length; i++) {
                if (files[i].size > 15000000) {
                    files.splice(i, 1);
                }
            }
            // no more than 10 files inside our allowed file size
            // server also validates this
            return files.splice(0, 10);
        },
        initImages() {
            var self = this;
            // make sure the primary is not lost
            this.primaryImage = this.gift.primary_photo.uuid;
            var primary = '<span class="primaryImage">Featured Image</span>';
            $('img', '#gallery').off("click").on("click", function(e) {

                var el = e.target;
                var uuid = $(el).attr('uuid');

                self.primaryImage = uuid;
                $('img', '#gallery').removeClass('primary');
                $('span.primaryImage', '#gallery').remove();
                $(el).addClass('primary');
                $(el).after(primary);
            })
            if ($('img.primary', '#gallery').length == 0) {
                $('img', '#gallery').first().addClass('primary');
                $('img', '#gallery').first().after(primary);
                self.primaryImage = $('img', '#gallery').first().attr('uuid');
            }
            $('#gallery').off("click").on("click", ".deleteFreshPhoto", function(e) {
                self.deleted_photos.push($(this).siblings('img').attr('uuid'));
                $(this).parent('.uploaded_thumb').remove();
                if (!$('.uploaded_thumb').length) {
                    $('.clickFeaturedText').hide();
                }
            })
        },
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        highlight(e) {
            document.getElementById("drop-area").classList.add('highlight')
        },
        unhighlight(e) {
            document.getElementById("drop-area").classList.remove('active')
        },
        handleDrop(e) {
            var dt = e.dataTransfer
            var files = dt.files
            this.storeFile(files);
        },
        initializeProgress(numFiles) {
            this.numFiles = numFiles;
            this.finalTotal = 0;
        },
        updateProgress(percent) {
            if (this.numFiles == 0) {
                $('#progress-bar').attr('aria-valuenow', 100).css('width', 100 + '%');
            } else {
                let total = (percent / this.numFiles);
                this.finalTotal = total > this.finalTotal ? total : this.finalTotal
                $('#progress-bar').attr('aria-valuenow', this.finalTotal).css('width', this.finalTotal + '%');
                if (percent == 100) {
                    this.numFiles = this.numFiles - 1;
                }
            }
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>
