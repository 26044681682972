<template>
    <div class="ct forms container causes centered" v-if="causes[key] && causes[key].causes">
        <titletag title="FundItOn Peer to Peer Fundraising: Best Causes To Donate To" description="Find good causes to donate to and explore how to raise money for charities supporting children, education, animal rescue, veterans, the environment, and more!"></titletag>
        <h2 class="title">Support an Open Cause</h2>
        <p>Support community causes by donating funds or selling/purchasing marketplace items. Your funds will make a difference in supporting local causes.</p>
        <!-- <filters type="cause" cat="1" state="1" city="1"></filters> -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            <item v-for="item in causes[key].causes" :key=" item.id" :item="item">
            </item>
        </div>
        <div v-if="moreExist" class="showMore form-group"><a class="btn-primary simple" @click="init">Show more</a></div>
        <div v-if="empty" class="empty">
            <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/cause.svg">
            <p class="nothing">No Causes matched your search. Try broadening your search</p>
        </div>
    </div>
</template>
<script>
import Filters from '../common/Filters'
import Item from './Item'
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');

export default {
    props: [],
    components: {
        Item,
        Filters
    },
    data() {
        return {
            a: false,
            key: null,
            moreExist: false,
            empty: false
        }
    },
    watch: {
        $route(to, from) {
            this.init();
        }
    },
    computed: {
        ...mapState({ causes: state => state.cause.causes }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(['setCauses']),
        init() {
            let loader = this.$loading.show();
            this.setCauses(window.location.search).then(response => {
                loader.hide();
                if (!this.causes[response.data.q].causes.length) {
                    this.empty = true
                } else {
                    this.empty = false;
                }
                this.setFiltering(true, response.data.q);
            });
        },
        setFiltering(bool, key) {
            this.key = key;
            this.$forceUpdate();
            if (this.causes[key].lastPage > this.causes[key].currentPage) {
                this.moreExist = 1;
            } else {
                this.moreExist = 0;
            }
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollListen);
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>
