export default {
    namespaced: true,
    state: {
        homeData: null,
        howItWorks: null,
        howToSell: null,
        howToStartACause: null,
        landingG: null
    },
    mutations: {
        setHomeData(state, payload) {
            state.homeData = payload;
        },
        setHowItWorksData(state, payload) {
            state.howItWorks = payload;
        },
        setHowToSellData(state, payload) {
            state.howToSell = payload;
        },
        setHowToStartACauseData(state, payload) {
            state.howToStartACause = payload;
        },
        setLandingGData(state, payload) {
            state.landingG = payload;
        }
    },
    actions: {
        setHomeData({ commit, state }) {
    		return new Promise((res, rej) => {
	            axios.get('/api/home_data')
                .then((response) => {
                    commit('setHomeData', response.data);
                    res(state.homeData);
                })
                .catch(() => {
                    console.log('TODO error handling');
                });
            });
        },
        setHowItWorksData({ commit, state }) {
    		return new Promise((res, rej) => {
	            axios.get('/api/how_it_works')
                .then((response) => {
                    commit('setHowItWorksData', response.data);
                    res(state.howItWorks);
                })
                .catch(() => {
                    console.log('TODO error handling');
                });
            });
        },
        setHowToSellData({ commit, state }) {
    		return new Promise((res, rej) => {
	            axios.get('/api/how_to_sell')
                .then((response) => {
                    commit('setHowToSellData', response.data);
                    res(state.howToSell);
                })
                .catch(() => {
                    console.log('TODO error handling');
                });
            });
        },
        setHowToStartACauseData({ commit, state }) {
    		return new Promise((res, rej) => {
	            axios.get('/api/how_to_start_cause')
                .then((response) => {
                    commit('setHowToStartACauseData', response.data);
                    res(state.howToSell);
                })
                .catch(() => {
                    console.log('TODO error handling');
                });
            });
        },
        setLandingGData({ commit, state }) {
    		return new Promise((res, rej) => {
	            axios.get('/api/landing_general')
                .then((response) => {
                    commit('setLandingGData', response.data);
                    res(state.landingG);
                })
                .catch(() => {
                    console.log('TODO error handling');
                });
            });
        }
    }
}
