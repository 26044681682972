<template>
    <div class="modal fade" id="makeDonation" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content ct forms">
                <div class="modal-header">
                    <h5 class="modal-title">Donate to {{cause.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="donateOptions">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div class="buttons">
                                    <button class="btn" amount="10">$10</button>
                                    <button class="btn" amount="15">$15</button>
                                    <button class="btn" amount="20">$20</button>
                                    <button class="btn" amount="50">$50</button>
                                    <button class="btn" amount="100">$100</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <span id="donationError">Donations must <span>$</span>5 or more</span>
                            <span id="donationErrorH">Better to send them a check. <span>$</span>25,000 max.</span>
                            <div class="col-sm-12 dollar">
                                <input type="number" max="25000" name="other" id="other" autocomplete="off" value="" placeholder="Minimum $5 due to card processing fees">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <button id="goDonate" class="btn btn-primary" @click="sendToCheckout()">Make Donation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['cause'],
    data() {
        return {}
    },
    mounted() {
        this.init();
    },
    methods: {
        sendToCheckout() {
            $('#other').blur();
            var amount = $('#other').val();
            if (amount > 25000) {
            	$("#donationErrorH").show();
            	return;
            }
            if (amount >= 5) {
                $("#makeDonation").trigger('click.dismiss.bs.modal');
                this.$router.push({ path: '/donate/' + this.cause.uuid + '/' + amount });

            } else {
                $("#donationError").show();
            }
        },
        init() {
        	if(!this.cause.published) {
        		$("#makeDonation").trigger('click.dismiss.bs.modal');
        	}
            var container = $('.donateOptions');
            var self = this;
            $('button[amount]', container).on("click", function() {
                $('input[name="other"]', container).val($(this).attr('amount'));
                $('button[amount]', container).removeClass('highlight');
                $(this).addClass('highlight');
            });
            $('input[name="other"]', container).on("focus", function() {
                $('input[type="radio"]', container).prop('checked', false).parent('label').removeClass('active');
            });
            $("#makeDonation").on("hide.bs.modal", function() {
                $("#donationError").hide();
                $("#donationErrorH").hide();
                $('input[name="other"]', container).val('');
            })
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#donationError,#donationErrorH {
    color: red;
    display: none;
    width: 100%;
    text-align: center;
    font-family: $open-sans;
    font-size: 14px;

    span {
        font-family: 'Times New Roman';
    }
}

#makeDonation {
    .modal-title {
        font-family: $cat;
        color: $bluetext;
    }

    .dollar {
        &::before {
            position: absolute;
            content: "$";
            color: $buttonblue;
            left: 20px;
            top: 18px;
        }
    }

    #other {
        width: 100%;
        height: 60px;
        background-color: $blue;
        border-radius: 14px;
        color: $buttonblue;
        text-align: right;
        padding-right: 15px;
        content: '.00';
        border: none;

        &::placeholder {
            color: $lightgrey;
        }

        &.highlight {
            // color:$red;
            font-weight: 600;
            border: 1px solid $buttonblue;

        }

    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            border: 1px solid $formgrey;
            border-radius: 30px;
            background-color: #fff;
            margin-left: 1%;
            margin-right: 1%;
            color: $grey;
            &.highlight {
                border: 1px solid $buttonblue;
                color:$buttonblue;
            }
        }
    }
}

</style>
