<template>
    <div class="ct container logins">
        <titletag title="Register for FunditOn"></titletag>
        <div class="register login row justify-content-center">
            <div class="card">
                <div class="card-head">{{$t('login.welcome-n')}}</div>
                <div class="card-body">
                    <!-- <div class="form-group row">
                        <i class="fab fa-facebook-f"></i>
                        <a class="f" :href="facebookUrl">{{$t('login.signup-f')}}</a>
                    </div> -->
                    <!-- <div class="form-group row">
                        <a class="g" :href="googleUrl">{{$t('login.signup-g')}}</a>
                    </div> -->
                    <div class="form-group row or">
                        <!-- <span class="d">...............</span><span class="or">{{$t('login.or')}}</span><span class="d">...............</span> -->
                    </div>
                    <h4>WE ARE NOT TAKING NEW CUSTOMERS AS WE ARE CLOSING DOWN</h4>
                    <form @submit.prevent="register">
                        <div class="form-group row">
                            <i class="fas fa-user"></i>
                            <input id="name" type="text" v-model="form.name" class="form-control f" :placeholder="$t('login.name')">
                        </div>
                        <div class="form-group row">
                            <i class="fas fa-envelope"></i>
                            <input id="email" type="email" v-model="form.email" class="form-control" :placeholder="$t('login.email')" autocomplete="username">
                        </div>
                        <div class="form-group row">
                            <i class="fas fa-lock"></i>
                            <input id="password" type="password" v-model="form.password" class="form-control l" :placeholder="$t('login.password')" autocomplete="current-password">
                            <!-- <router-link :to="{path: '/reset-password'}" style="display: inline;" class="forgot-link">{{ $t("nav.forgot") }}</router-link> -->
                        </div>
                        <div class="form-group row ta">
                           <p class="termsAgree">By registering, I agree to FunditOn's <a href="/terms" target="_blank">terms of service.</a></p>
                        </div>
                        <div class="form-group row">
                            <!-- <input class="submit" type="submit" :value="$t('login.register')"> -->
                        </div>
                        <div class="form-group row" v-if="authError">
                            <p class="error">
                                {{ authError }}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row mem">
                <p>Already have an account? <router-link to="/login"> Login</router-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { register } from '../../helpers/auth';
export default {
    name: "register",
    data() {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                redirect: ''
            },
            error: null
        };
    },
    mounted() {
    	if (this.$route.query.redirect.indexOf('cause/new') == 1) {
            // this.flashMessage({ m: 'To start a cause please register or login', d: 1200 });
        }
    },
    methods: {
        validate() {
            if (!this.form.name) {
                this.scrollToError('#name');
                this.flashMessage({ m: 'Please add your name', e: 1 });
                return false;
            }
            if (!this.form.email) {
                this.scrollToError('#email');
                this.flashMessage({ m: 'Please add your email', e: 1 });
                return false;
            }
            if (!this.validEmail(this.form.email)) {
                this.scrollToError('#email');
                this.flashMessage({ m: 'Please check your email for errors', e: 1 });
                return false;
            }
            if (!this.form.password || this.form.password.length < 8) {
                this.scrollToError('#password');
                this.flashMessage({ m: 'Password should have at least 8 chararacters', e: 1 });
                return false;
            }
            return true;
        },
        register() {
            if (this.validate()) {
                this.form.redirect = this.$route.query.redirect;
                let loader = this.$loading.show();
                register(this.$data.form)
                    .then((res) => {

                        loader.hide();
                        if(res.success) {
                        	this.$router.push('/email_verifying');
                        }else{
                        	var error = res.error.email[0];
                        	this.$store.commit("user/loginFailed", { error });
                        }
                        
                    })
                    .catch((error) => {
                        loader.hide();
                        this.$store.commit("user/loginFailed", { error });
                    });
            }

        }
    },
    computed: {

        authError() {
            return this.$store.getters['user/authError'];
        },
        // facebookUrl() {
        //     var path = this.$route.query.redirect || '/';
        //     return '/facebook_redirect?url=' + path;
        // },
        googleUrl() {
            var path = this.$route.query.redirect || '/';
            return '/google_redirect?url=' + path;
        },
    }
}

</script>
