<template>
    <div class="row messageContainer requesters" id="buyerCheckout">
        <h4>Ready to Buy?</h4>
        <p>{{message}}</p>
        <a class="btn" @click="goToCheckout">Proceed to Payment</a>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('channel');
export default {
    props: ['channel', 'loggedInId'],
    data() {
        return {
        	message: null
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        ownerName() {
            for (var i = 0; i < this.channel.users.length; i++) {
                if (this.channel.users[i].id == this.channel.gift.user_id) {
                    return this.channel.users[i].name;
                }
            }
        }
    },
    methods: {
    	
        init() {
        	
            //append this to the dom where we want it
            // no not append if not needed 
            // either not enough messages or already sent the request
            if (this.canShow()) {
            	this.setMessage();
                $('#buyerCheckout').show();
            }

        },
        setMessage() {
        		this.message = "We strongly recommend asking questions, confirming availability, knowing how you will pick up or receive the item you intend to purchase before continuing. When you are ready to buy you can proceed to payment.";
        },
        goToCheckout() {
        	window.location = '/g/' + this.channel.gift.url_str +'/1';
        },
        canShow() {
            if (((this.channel.gift.buyer_id === this.loggedInId) || (null == this.channel.gift.buyer_id)) && (this.channel.gift.status == 1) && (this.channel.gift.user_id !== this.loggedInId)) {
                return true;
            }
            return false;
        }

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
// styles are in Buyrequest.vue

</style>
