<template>
    <div class="myLiked col-sm-9" v-if="likedCauses">
        <titletag title="Causes I like"></titletag>
        
            <h2 class="title">Liked Causes</h2>

            <template v-if="likedCauses.length">
            	<div class="row row-cols-1 row-cols-sm-2">
                <causeitem v-for="item in likedCauses" :key="item.id" :item="item"></causeitem>
            	</div>
            </template>
            <template v-else>
                <div class="empty">
                	<img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/cause.svg">
                    <p class="nothing">You haven't liked any causes yet. When you like a cause it will appear here.</p>
                </div>
            </template>
        
    </div>
</template>
<script>
import Causeitem from '../causes/Item'
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {
    name: 'likedcauses',
    components: {
        Causeitem
    },
    computed: {
        ...mapState({ likedCauses: state => state.account.likedCauses }),
        ...mapState({ changed: state => state.account.changed }),
    },
    mounted() {
    	if(this.changed.indexOf('liked_cause') != -1) {
    		this.refreshLikes();
    	}
    },
    methods: {
    	...mapActions(['refreshLikes'])
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
// See Account container shared in custom.css

</style>
