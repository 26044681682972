<template>
    <div class="col-sm-9 causeOrders">
        <h2 v-if="name" class="title">{{name}} Transactions</h2>
        <table v-if="orders">
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Available<div data-toggle="tooltip" data-placement="bottom" :title="$t('account.amount_available')">i</div>
                    </th>
                    <th>Shipping</th>
                    <th>Taxes</th>
                    <th>Fees<div data-toggle="tooltip" data-placement="bottom" :title="$t('account.fees')">i</div>
                    </th><!-- Stripe fees here -->
                    <th>Total Charge</th>
                    <th>Date</th>
                    <th><span class="y"></span><div data-toggle="tooltip" data-placement="bottom" :title="$t('account.cleared')">i</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in orders">
                    <td>{{item.gift ? item.gift.name : 'Donation'}}</td>
                    <td>${{item.gift ? item.gift.price : item.order_total}}</td>
                    <td>${{total_available(item)}}</td>
                    <td>${{item.shippingrate ? item.shippingrate.amount : '0:00'}}</td>
                    <td>${{item.taxrate ? item.taxrate.amount_to_collect : '0:00'}}</td>
                    <!-- <td>${{cc_fees(item)}}</td>
                    <td>${{ct_fees(item)}}</td> -->
                    <td>${{total_fees(item)}}</td>
                    <td>${{item.order_total}}</td>
                    <td>{{order_date(item.created_at)}}</td>
                    <td><span class="y" v-if="item.funds_available_to_cause"></span></td>
                </tr>
                <tr class="totals">
                    <td>Totals</td>
                    <td>${{totals.pr.toFixed(2)}}</td>
                    <td>${{totals.ta.toFixed(2)}}</td>
                    <td>${{totals.sh.toFixed(2)}}</td>
                    <td>${{totals.tx.toFixed(2)}}</td>
                    <td>${{totals.fees.toFixed(2)}}</td>
                    <!-- <td>${{totals.ct.toFixed(2)}}</td> -->
                    <td>${{totals.ot.toFixed(2)}}</td>
                    <td></td>
                    <td></td>
                    
                </tr>
            </tbody>
        </table>
        <div  v-if="orders" class="export forms ct">
        	<h3>Export csv of transactions</h3>
        	<div class="form-group">
        	<select v-model="year">
        		<option v-for="v in years" :value="v">{{v}}</option>
        		<!-- <option value="2021">2021</option> -->
        	</select>
        	<select v-model="period">
        		<option value="">Choose Period</option>
        		<option value="1q">Jan-Mar</option>
        		<option value="2q">Apr-Jun</option>
        		<option value="3q">Jul-Sep</option>
        		<option value="4q">Oct-Dec</option>
        		<option v-for="(name,key) in months" :value="parseFloat(key) + 1">{{name}}</option>
        	</select>
        	<a class="secondaryButton" @click="generate">Download Csv</a>
        	</div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {
    name: "",
    props: ['urlStr'],
    data() {
        return {
            'orders': null,
            'name': null,
            'year' : new Date().getFullYear(),
            'period': '',
            'quarter': '',
            'month' : ''
        }
    },
    computed: {
        ...mapState({ causeOrders: state => state.account.causeOrders }),
        months() {
        	return moment.monthsShort();
        },
        years() {
		    var cy = new Date().getFullYear(), years = [];
		    var sy = 2020;  
		    while ( sy <= cy ) {
		        years.push(sy++);
		    }   
		    return years;
        },
        totals() {
            // var a = { pr: 0, ta: 0, sh: 0, tx: 0, cc: 0, ct: 0, ot: 0 };
            var a = { pr: 0, ta: 0, sh: 0, tx: 0, fees: 0, ot: 0 };
            if(this.orders.length > 0) {
	            for (var i = 0; i < this.orders.length; i++) {
	            	
	            	a.pr = this.orders[i].gift ? a.pr + parseFloat(this.orders[i].gift.price) : (a.pr + parseFloat(this.orders[i].order_total));
	            	a.ta = a.ta +  parseFloat(this.total_available(this.orders[i]));
	            	a.sh = this.orders[i].shippingrate ? a.sh +  parseFloat(this.orders[i].shippingrate.amount) : a.sh;
	            	a.tx = this.orders[i].taxrate ? a.tx +  parseFloat(this.orders[i].taxrate.amount_to_collect) : a.tx;
	            	// a.cc = a.cc +  parseFloat(this.cc_fees(this.orders[i]));
	            	// a.ct = a.ct +  parseFloat(this.ct_fees(this.orders[i]));
	            	a.fees = a.fees +  parseFloat(this.total_fees(this.orders[i]));
	            	a.ot = a.ot +  parseFloat(this.orders[i].order_total);
	            }
        	}
        	return a;
        }
    },
    mounted() {
        let loader = this.$loading.show();
        this.setCauseOrders(this.urlStr).then(response => {
            this.orders = this.causeOrders[this.urlStr];
            this.name = response.data.name;
            loader.hide();
            setTimeout(() => {
                if (window.matchMedia("(max-width: 768px)").matches) {
                    $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
                } else {
                    $('[data-toggle="tooltip"]').tooltip({ "delay": 400 });
                }
            }, 200);

        });

    },
    methods: {
        ...mapActions(['setCauseOrders']),
        // Stripe fees here
        cc_fees(item) {
        	if(item.transaction.object == 'Bitcoin') {
        		return (item.order_total / 100).toFixed(2); // 1% for Coinbase fees
        	}
            var f = 2.9;
            if (item.transaction.card_country !== 'US') {
                f = 3.9;
            }
            var amount = (item.order_total * f) / 100;
            amount = amount + 0.3;
            return Math.round((amount + Number.EPSILON) * 100) / 100
            // return (amount + 0.3).toFixed(2);

        },
        
        ct_fees(item) {
        	if(moment(item.created_at).isBefore('2023-02-10')) {
        		return 0.00;
        	}
            var amount = (item.order_total * 1) / 100;
            amount = amount + 0.2;
            return Math.round((amount + Number.EPSILON) * 100) / 100
            // return (amount + 0.2).toFixed(2);
        },
        total_fees(item) {
        	return  (parseFloat(this.ct_fees(item)) +  parseFloat(this.cc_fees(item))).toFixed(2);
        },
        order_date(date){
        	return moment(date).subtract(new Date().getTimezoneOffset(), 'm').format('L');
        },
        total_available(item) {
            var amount = item.gift ? item.gift.price : item.order_total;
            return (amount - this.cc_fees(item) - this.ct_fees(item)).toFixed(2);
        },
        generate() {
        	var s = this.period + '';
        	if(s.indexOf('q') != -1) {	
        		this.quarter = s.substring(0,1);
        		this.month = '';
        	}else{
        		this.month = s;
        		this.quarter = '';
        	}
        	
        	axios.get('/api/generate_csv/?cause=' + this.orders[0].cause_id + '&quarter=' + this.quarter + '&year=' + this.year + '&month=' + this.month ).then(response => {
        		if(response.data.status) {
        			this.flashMessage({m: "Downloading. Look in your downloads for \n" + response.data.file, d:3000});
        			window.location= response.data.url;
        		}else{
        			this.flashMessage({e:1,m: response.data.message});
        		}
        	});
        	}
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.causeOrders {
	table, h2.title{
		padding-left:15px;
	}
	tr.totals {
		td {
			font-weight: 600;
		}
	}
	span.y {
		&:before {
                content: "\2713";
                display: inline-block;
                color: #86af49;
                padding: 0 3px 0 0px;
            }
	}
	.export {
		padding-left:25px;
		padding-top: 10px;
		>.form-group {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
			align-items: center;
			@include media-breakpoint-up(sm) {
				flex-direction: row;
				column-gap: 5px;
				align-items: normal;
			}
		}
		select,.secondaryButton{
			width:170px;
			height: 35px;
			padding: 6px 12px;
		}
		
	}
}
</style>
