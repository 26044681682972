<template>
    <div class="col-sm-9">
        <titletag title="My Causes"></titletag>
        <h2 class="title">My Causes</h2>
        <template v-if="causes.length">
            <div class="row">
                <div v-for="cause in causes" class="container myCauseItem">
                    <div class="causeImageContainer col-12">
                        <img :src="photoUrl(cause)" :alt="cause.name">
                        <router-link :to="`/c/${cause.url_str}`">
                            <div class="shaded">
                                <div class="name">{{cause.name}}</div>
                                <div @click.prevent="changeActiveState(cause)" :class="cause.published == 1 ? 'active': ''" class="published">{{cause.published == 1 ? "Published": "Unpublished"}}</div>
                                <div @click.prevent="recipientNotSet(cause)" :class="cause.connectedaccount_id ? 'active': ''" class="published recipient">{{cause.connectedaccount_id ? "Recipient Set": "No Recipient"}}</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 details">
                        <div class="key">Transactions</div>
                        <div class="value">{{cause.account && cause.account.transaction_count ? cause.account.transaction_count : 0}}</div>
                        <div class="key">Direct Donations</div>
                        <div class="value">${{cause.account && cause.account.donation_sales ? cause.account.donation_sales : '0.00'}}</div>
                        <div class="key">Item Sales</div>
                        <div class="value">${{cause.account && cause.account.item_sales ? cause.account.item_sales : '0.00'}}</div>
                        <div class="key">Total Raised</div>
                        <div class="value">${{cause.account && cause.account.balance ? cause.account.balance : '0.00'}}</div>
                        <div class="more">
                            <router-link :to="`/gift/add/${cause.uuid}/${cause.name}`">Add an Item</router-link>
                        </div>
                        <div class="more">
                            <router-link :to="`/account/causeorders/${cause.url_str}`">Transaction details</router-link>
                        </div>
                        <div class="more ed">
                            <router-link :to="`/cause/edit/${cause.uuid}`">Edit</router-link>
                            <a v-if="canDelete(cause)" class="del" @click="deleteConfirm(cause)">Delete</a>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="empty">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/cause.svg">
                <p class="nothing">You haven't started a cause. Once you start a cause it will apear here.</p>
            </div>
        </template>
        <router-link :to="`/account`" class="btn back">Go Back</router-link>
    </div>
</template>
<script>
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('account');

export default {
    props: [],
    data() {
        return {
            currentChanged: []
        }
    },
    mounted() {
        this.currentChanged = this.changed;
    },
    watch: {
        currentChanged(b, a) {
            if (b.indexOf('causes') != -1) {
                this.refreshCauses();
            }
        }
    },
    computed: {
        ...mapState({ changed: state => state.account.changed }),
        ...mapState({ causes: state => state.account.causes })
    },
    methods: {
        ...mapActions(['publishCause']),
        ...mapActions(['refreshCauses']),
        ...mapActions(['deleteCause']),
        decimalFormat(item) {
            return parseFloat(item).toFixed(2);
        },
        canDelete(cause) {
            if (!cause.account) {
                return true;
            }
            return false;
        },
        deleteConfirm(item) {
            var data = {
                h: "Delete " + item.name,
                q: "Are you sure you want to delete?",
                y: "Yes delete",
                n: "Cancel",
                uuid: item.uuid
            };
            this.confirmDialog(data, this.deleteItem);
        },
        deleteItem(bool, data) {
            if (bool) {
                let loader = this.$loading.show();
                this.deleteCause(data.uuid).then(response => {
                    loader.hide();
                    if (response.data.status) {
                        this.flashMessage({ m: "Your cause has been deleted" });
                    } else {
                        this.flashMessage({ m: response.data.message, e: 1, d: 3000 });
                    }

                });
            }
        },
        photoUrl(cause) {
            if (cause.primary_photo && cause.primary_photo.path_thumb) {
                return this.getCloudFrontUrl() + cause.primary_photo.path_thumb;
            } else {
                return 'https://d1812kujpqokfk.cloudfront.net/assets/cause/causedefault_thumb1.png';
            }
        },
        publishConfirm(bool, data) {
            if (bool) {
            	let loader = this.$loading.show();
                this.publishCause(data.payload).then((r) => {
                	loader.hide();
                });
            }
        },
        recipientNotSet(cause) {
            if (cause.connectedaccount_id) {
                this.flashMessage({ m: 'Your cause recipient is set up. They will receive funds at the end of each month.', d: 2000 });
            } else {
                this.flashMessage({ m: 'As soon as this cause receives any funds you will be notified to assign the recipient. If you have completed this step the recipient may still be in the process.', d: 5000 });
            }

        },
        changeActiveState(cause) {
            //TODO: What happens to items in a cause when it is unpublished?
            var w = '';
            if (cause.published == 1) {
                var v = "Unpublish ";
                var payload = { "uuid": cause.uuid, "unpublish": 1 };
                w = 'If your cause is still unpublished in 48 hours all users with items for sale will be notified to reassign them to another cause. In the meantime you can still receive funds from any sales of these items. ';

            } else {
                var v = "Publish ";
                var payload = { "uuid": cause.uuid };

            }
            var data = {
                h: v + "Cause",
                q: w + "Are you sure you want to " + v.toLowerCase() + cause.name + '?',
                y: "Yes",
                n: "Cancel",
                payload: payload
            };
            this.confirmDialog(data, this.publishConfirm);

        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.myCauseItem {
    // width: 100%;
    max-width: 339px;
    margin: 10px;
    padding: 0;
    background-color: #FFFFFF;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    margin-bottom: 12px;
    border-radius: 0px 0px 10px 10px;

    .causeImageContainer {
        width: 100%;
        max-width: 339px;
        padding: 0;
        position: relative;

        img {
            width: 100%;
            border-radius: 10px 10px 0px 0px;
        }

        .shaded {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 10px 10px 0px 0px;
            background-color: rgba(46, 46, 48, 0.55);

            .name {
                position: absolute;
                top: 40%;
                left: 20px;
                color: #fff;
                font-family: $cat;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 24px;
                max-width: 60%;
            }

            .published {
                position: absolute;
                bottom: 10%;
                left: 20px;
                font-family: $open-sans;
                color: #fff;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-transform: uppercase;
                padding: 3px 20px;
                border-radius: 14px;
                background-color: $red;
                cursor: pointer;
                z-index: 500;

                &.recipient {
                    right: 20px;
                    left: unset;
                    background-color: $red;

                    &.recipientSet {
                        background-color: $brightgreen;
                    }
                }

                &.active {
                    background-color: $brightgreen;
                }
            }

        }

    }

    .details {
        padding-top: 10px;

        .key,
        .value {
            width: 49%;
            display: inline-block;
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 30px;
            padding-bottom: 12px;
        }

        .key {
            text-align: left;
        }

        .value {
            text-align: right;
        }

        .more {
            border: 1px solid rgba(177, 176, 190, 0.4);
            border-radius: 25px;
            width: 100%;
            text-align: center;

            padding: 10px 0;
            font-family: $cat;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 12px;

            &.ed {
                a {
                    padding: 0 20px;

                    &.del {
                        color: $red;
                    }
                }
            }

            a {
                color: $grey;
                cursor: pointer;
            }
        }

    }
}

</style>
