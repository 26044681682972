<template>
	<div class="container">
		<p class="title">Logging you in...</p>
	</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapMutations, mapActions } = createNamespacedHelpers('user');
export default {
	
	mounted() {
		this.loginSuccess({'access_token' : '', 'expires_in': 60, 'user' : {name: this.$route.query.name,'access_token' : this.token, 'expires_in': 60}});
		// do this so the current token wasn't in the url. No big deal really.
		this.refresh().then(response => {
			this.$router.push(this.$route.query.url);
		});
	},
	methods: {
		...mapMutations(['loginSuccess']),
		...mapActions(['refresh']),
	}
}
</script>