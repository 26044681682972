<template>
    <div id="reportFlag">
        <a v-if="!received" rel="nofollow" @click="showR"><img src="https://d1812kujpqokfk.cloudfront.net/assets/report.png"><span>Report an Issue</span></a>
        <a v-if="received"><img src="https://d1812kujpqokfk.cloudfront.net/assets/report.png"><span>Report Received</span></a>
        <div class="reportBox">
            <textarea v-model="reason" placeholder="Please add a brief reason for reporting"></textarea>
            <a class="btn secondary" @click="hideR">Cancel</a>
            <a rel="nofollow" class="btn simple" @click="sendReport">Send Report</a>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    props: ['type', 'item'],
    data() {
        return {
        	reason: '',
        	received: 0
        }
    },
    methods: {
        showR() {
        	if (this.item.published) {
	            $(".reportBox").slideDown();
	        }else{
	        	this.flashMessage({ m: this.$t('messages.unpubaction') });
	        }
        },
        hideR() {
            $(".reportBox").slideUp();
        },
        sendReport() {
            let loader = this.$loading.show({width: 30, height: 30,container: $('#reportFlag')[0]});
            var url = '/api/misusage?hash=' + this.item.uuid + '&type=' + this.type + '&reason='+this.reason;
            axios.get(url)
                .then(response => {
                    this.flashMessage({ m: 'Your report has been received. We will take a look.', d: 2200 });
                    loader.hide();
                    $(".reportBox").slideUp();
                    this.received = 1;
                });

        },
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#reportFlag {
    width: 100%;
    text-align: center;
    padding-top: 20px;

    a {
        padding: 2px;
        color: #B8B8BF !important;
        text-transform: uppercase;
        cursor: pointer;
        font-family: $cat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;

        span {
            margin-left: 7px;
        }
    }
}
.ct.forms #reportFlag {
    .reportBox {
        display: none;
        textarea {
        min-height: 75px;
        width: 100%;
        border-radius: 10px;
    }
    }
}

</style>
