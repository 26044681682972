<template>
    <div class="ct container forms" id="causePage" v-if="cause">
        <titletag :title="cause.name" :description="cause.description" :image="cloudFrontPath"></titletag>
        <div class="row">
            <div class="col-sm-7 mainSection">
                <div class="likeCause" v-bind:class="liked ? 'liked' : ''" @click="likeThis($event,'cause',cause.id)">
                    <i v-bind:class="liked ? 'fas' : 'far'" class=" fa-heart"></i>
                    <!-- <span>{{likedText}}</span> -->
                </div>
                <img :src="cloudFrontPath">
                <h1>{{cause.name}}</h1>
                <div class="sub float-left">
                    <span class="loc float-left"><i class="fas fa-map-marker-alt"></i>{{cause.city}}, {{cause.state}}</span>
                    <span class="cat float-right">Category: {{cause.category.name}}</span>
                    <hr>
                </div>
                <div class="desc float-left">
                    {{cause.description}}
                </div>
            </div>
            <div class="col-sm-5 supporting">
                <div class="supp">
                    <sold :item="cause"></sold>
                    <div class="supportLink">
                        <button v-bind:class="cause.published ? '': 'unpub'" data-toggle="modal" :data-target="cause.published ? '#supportChoices' : ''">Donate to support</button>
                    </div>
                    <div class="supportLink b">
                        <button @click="goToItems">Shop to support</button>
                    </div>
                    <share :item="cause" :key="cause.uuid" :url="'/c/' +urlStr"></share>
                </div>
                <div class="supp">
                    <supporters :urlStr="urlStr"></supporters>
                </div>
                <div id="causeEin" v-if="cause.ein">
                    <div class="einContain">
                        <a rel="nofollow" @click="showEinData" target="_blank"><img src="https://d1812kujpqokfk.cloudfront.net/assets/cause/ein.png"><span>Cause EIN: {{formatEin(cause.ein)}}</span></a>
                        <!-- <a rel="nofollow" title="Nonprofit Organization Information Search" target="_blank" :href="`https://www.erieri.com/Form990Finder/Details/Index?EIN=${cause.ein}`"><img src="https://d1812kujpqokfk.cloudfront.net/assets/cause/ein.png"><span>Cause EIN: {{formatEin(cause.ein)}}</span></a> -->
                    </div>
                </div>
                <report type="cause" :item="cause"></report>
            </div>
            <div class="col-sm scTo" v-if="gifts && gifts.length">
                <hr>
                <h3>Shop to support {{cause.name}}</h3>
                <giftindex :gifts="gifts"></giftindex>
                <div v-if="moreExist" class="showMore form-group"><a class="btn-primary simple" @click="moreItems">Show more</a></div>
            </div>
            <div class="col-sm scTo" v-if="!gifts || !gifts.length">
                <hr>
                <h3>Shop to support {{cause.name}}</h3>
                <p>Currently there is nothing for sale to support this cause. You can help when you
                    <template v-if="cause.published">
                        <router-link class="nothingYet" :to="`/gift/add/${cause.uuid}/${cause.name}`">donate something.</router-link>
                    </template>
                    <template v-else>
                        <a class="nothingYet" @click="likeThis">donate something.</a>
                    </template>
                </p>
            </div>
        </div>
        <actions :cause="cause"></actions>
        <donate :cause="cause"></donate>
        <support :cause="cause"></support>
        <pubbox type="cause" :item="cause"></pubbox>
    </div>
</template>
<script>
import Giftindex from '../gifts/Giftindex'
import Donate from '../donations/Donate'
import Actions from './Actions'
import Supporters from './Supporters'
import Share from './Share'
import Sold from './Sold'
import Support from './Support'
import Pubbox from '../common/PublishBox'
import Report from '../common/Report'
import { getLocalUser } from "../../helpers/auth";
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');

export default {
    props: ['urlStr'],
    name: 'cause',
    components: {
        Giftindex,
        Actions,
        Donate,
        Supporters,
        Share,
        Support,
        Sold,
        Pubbox,
        Report
    },
    data() {
        return {
            moreExist: false,
            liked: false,
            gifts: null,
            einData: null
        }
    },
    watch: {
        '$route'(to, from) {
            if (from.params.urlStr !== to.params.urlStr) {
                return this.initData();
            }
        }
    },
    computed: {
        ...mapGetters(['getcause']),
        likedText() {
            return this.liked ? 'Liking this cause' : 'Like this cause';
        },
        cloudFrontPath() {
            if (this.cause.primary_photo && this.cause.primary_photo.path_full) {
                return this.getCloudFrontUrl() + this.cause.primary_photo.path_full;
            } else {
                return 'https://d1812kujpqokfk.cloudfront.net/assets/cause/causedefault.png';
            }
        },
        cause() {
            return this.getcause(this.urlStr);
        },
        ...mapState({ causeGifts: state => state.gift.causeGifts }),

    },
    methods: {
        ...mapActions(['setCause']),
        initData() {
            if (null == this.cause) {
                let loader = this.$loading.show();
                this.setCause(this.urlStr).then(response => {
                    if (undefined == this.cause) {
                        this.$router.push('/404');
                        loader.hide();
                    } else {
                        var user = getLocalUser();
                        if (null != user) {
                            for (var i = 0; i < user.likes.length; i++)
                                if (this.cause.id == user.likes[i].cause_id) {
                                    this.liked = true;
                                }
                        }
                        loader.hide();
                        var self = this;
                        $("#causePage").on("click", ".unpub", function() {
                            self.flashMessage({ m: self.$t('messages.unpubaction') });
                        });
                    }
                });

            }
            this.moreItems();

            setTimeout(this.hideLong, 1000);

        },
        moreItems() {
            this.$store.dispatch('gift/setCauseGifts', this.urlStr).then(response => {
                this.gifts = this.causeGifts[this.urlStr].gifts;
                if (this.causeGifts[this.urlStr].lastPage > this.causeGifts[this.urlStr].currentPage) {
                    this.moreExist = 1;
                } else {
                    this.moreExist = 0;
                }
            });
        },
        hideLong() {
            var self = this;
            $(document).ready(function() {
                var maxLength = 500;
                $(".desc").each(function() {
                    var myStr = $.trim($(this).text());
                    if ($.trim(myStr).length > maxLength) {
                        var newStr = myStr.substring(0, maxLength);
                        var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
                        $(this).empty().html(newStr);
                        $(this).append('<div class="form-group read-more"><a class="btn-primary simple" href="javascript:void(0);">Read more</a></div>');
                        $(this).append('<span class="more-text">' + removedStr + '</span>');
                    }
                });
                $(".read-more").click(function() {
                    $(this).siblings(".more-text").contents().unwrap();
                    $(this).remove();
                });
            });

        },
        formatEin(ein) {
            if (ein) {
                return ein.substr(0, 2) + '-' + ein.substr(2, 7);
            }

        },
        likeThis(e, t, id) {
            if (this.cause.published) {
                this.liked = this.likeIt(e, t, id, this.liked);
            } else {
                this.flashMessage({ m: this.$t('messages.unpubaction') });
            }
        },
        goToItems() {
            var offset = $('.scTo').offset();
            $('html, body').animate({
                scrollTop: offset.top - 30,
                scrollLeft: offset.left
            });
        },
        showEinData() {
            if (!this.einData) {
                var url = '/api/ein_data?ein=' + this.cause.ein;
                axios.get(url).then(response => {
                    this.einData = response.data.einData;
                    if(this.einData) {
                    	$('#shareModal .modal-body').empty().append(this.einData);
		            	$('#shareModal').modal('show');
		            }else{
		            	$('#shareModal .modal-body').empty().append('This EIN does not match a registered US non-profit. Donations will not be tax deductible. If this cause was recenty added there may be a typo in the EIN.');
		            	$('#shareModal').modal('show');
		            } 
                });
            }
            if(this.einData) {
            	$('#shareModal .modal-body').empty().append(this.einData);
            	$('#shareModal').modal('show');
            }
            
        }
    },
    mounted() {
        this.initData();
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causePage {
    h3 {
        font-family: $cat;
        color: $bluetext;
    }

    @include media-breakpoint-up(sm) {
        padding-top: 15px;
    }

    .mainSection {
        padding: 10px 1%;
        position: relative;

        img {
            max-width: 100%;
            border-radius: 10px;
        }

        .likeCause {
            // display: none;
            position: absolute;
            right: 30px;
            top: 30px;
            padding: 7px 7px 6px 7px;
            cursor: pointer;

            &.liked {
                background-color: rgb(255, 255, 255, 0.7);
            }

            // @include media-breakpoint-up(sm) {

                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                background-color: #FFF;
                box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);

                // span {
                //     color: $bluetext;
                //     font-family: $cat;
                //     font-size: 12px;
                //     line-height: 18px;
                //     padding-left: 7px;
                //     text-transform: uppercase;
                //     font-weight: 600;
                // }

                i {
                    font-size: 22px;
                }

                i.fas {
                    color: $red;
                }

                i.far {
                    color: $bluetext;
                }
            
        }

        h1 {
            color: $bluetext;
            padding: 20px 8px 0 10px;

            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
        }

        .sub {
            color: $bluetext;
            font-size: 12px;
            width: 100%;

            font-weight: 600;
            padding-left: 10px;

            .loc {

                i {
                    color: $red;
                    padding-right: 1rem;

                }
            }

            .cat {

                padding-right: 1rem;
            }

            hr {
                color: $grey;
                margin-top: 2rem;
            }
        }

        .desc {
            width: 100%;
            padding: 10px;
            white-space: pre-line;
            color: $textcolor;
            font-family: $open-sans;
            font-size: 16px;
            line-height: 26px;
            position: relative;

            .more-text {
                display: none;
            }

            >.read-more {
                color: $buttonblue;
                display: flex;
                align-items: flex-end;
                position: relative;
                bottom: 100px;
                width: 100%;
                height: 100px;
                background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);
                background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%);

                >a.simple {
                    text-decoration: none;
                    max-width: 130px;
                    color: $grey;
                    font-family: $cat;
                    font-size: 15px;
                    position: absolute;
                    bottom: -75px;
                    text-align: center;
                }
            }
        }
    }

    .supporting {
        padding: 10px 1%;

        .supp {
            border-radius: 10px;
            padding-top: 5x;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);

            .supportLink {
                display: none;

                @include media-breakpoint-up(sm) {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding-top: 0;

                    &.b {
                        // justify-content: left;

                    }
                }

                width: 100%;
                text-align: center;
                padding: 15px 5%;

                button {
                    background-color: $red;
                    outline: none;
                    color: $white;
                    border: none;
                    width: 100%;
                    border-radius: 25px;
                    height: 50px;
                    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
                    font-family: $cat;

                    &:hover {
                        background-color: #fff;
                        border: 1px solid $red;
                        color: $red;
                    }
                }

                &.b {
                    button {
                        border: 1px solid rgba(177, 176, 190, 0.4);
                        border-radius: 25px;
                        background-color: #fff;
                        color: $grey;

                        &:hover {
                            border-color: #51506F
                        }
                    }
                }

                .likeItL {
                    width: 15%;
                    margin-left: 2%;
                }

                i {
                    font-size: 22px;
                }

                i.fas {
                    color: $red;
                }

                i.far {
                    color: $lightgrey;
                }
            }

        }


    }

    .nothingYet {
        color: $red;
        cursor: pointer;
    }


}

#einModalData {
    h2 {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    p,
    div {
        padding-top: 0;
        font-size: 14px;
        font-family: $open-sans;
        color: $textcolor;
    }
    div.data {
    	padding: 5px 0;
    }
    div.navigator {
    	font-size: 12px;
    }
}

#causeEin {
    width: 100%;
    display: flex;
    text-align: center;
    padding-top: 20px;

    .einContain {
        border-radius: 10px;
        border: 1px solid #E8EBF0;
        width: 100%;
        padding: 10px 0;

        a {
            color: #B8B8BF !important;
            text-transform: uppercase;
            cursor: pointer;
            font-family: $open-sans;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
        }

        span {
            margin-left: 7px;
        }
    }
}

</style>
