<template>
    <div class="ct forms container" id="landing" v-if="d">
    	<titletag title="School garage sales made easy online"></titletag>
        <section class="one">
            <div class="l">
                <div class="contain form-group">
                	<h1>School garage sales made simple</h1>
                	<p>We combines fundraising with an online used goods marketplace to make supporting your school easy.</p>
                	<p>Imagine what 300 families who all live near each other can accomplish buying and selling those things their kids have grown out of. Your school gets all the proceeds!</p>

                    <!-- <html-fragment :html="d.textslots['landing-g-title'].content"></html-fragment> -->
                    <!-- <html-fragment :html="d.textslots['landing-g-one-p'].content"></html-fragment> -->
                    <div>
                        <router-link to="/register" class="btn-primary">Register and Start now</router-link>
                    </div>
                </div>
            </div>
            <div class="r">
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/landing/computer-woman.png">
                </div>
            </div>
        </section>
        <section class="two">
            <div>
            	<h2>Teach repurposing whilst raising money!</h2>
            	<p>We make it easy for your community to buy/sell/donate used items online redirecting the proceeds to your cause.</p>
            	<p>Set up is less than 5 minutes. Share the link to your page with the community and let your families do the rest!!</p>
            	<p>Proceeds are automatically deposited for you.</p>
            	<!-- <h1>Free for life!</h1>
            	<p>All causes created before April 30th will have no platform fees for the life of the cause.</p>
            	<p>As this is a new concept we want to reward those who help us get started by never charging them a platform fee.</p> -->
                <!-- <html-fragment :html="d.textslots['landing-g-two-title'].content"></html-fragment>
                <html-fragment :html="d.textslots['landing-g-two-p'].content"></html-fragment> -->
            </div>
        </section>
        <section class="three">
            <div class="r">
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/landing/mum-kid.png">
                </div>
            </div>
            <div class="l">
                <div class="contain">
                	<h1>Teach your kids repurposing</h1>
                	<p>Through FunditOn anyone can sell anything they no longer need to provide support for the the community</p>
                	<p>You are indirectly stopping excessive transportaion of goods by ecouraging your kids to buy and sell locally</p>
                    <!-- <html-fragment :html="d.textslots['landing-g-three-title'].content"></html-fragment>
                    <html-fragment :html="d.textslots['landing-g-three-p'].content"></html-fragment> -->
                </div>
            </div>
        </section>
        <section class="four">
            <div class="l">
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/landing/family.png">
                </div>
            </div>
            <div class="r">
                <div class="contain">
                    <html-fragment :html="d.textslots['landing-g-four-title'].content"></html-fragment>
                    <html-fragment :html="d.textslots['landing-g-four-p'].content"></html-fragment>
                </div>
            </div>
        </section>
        <section class="five">
            <div class="r">
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/landing/unwrap.png">
                </div>
            </div>
            <div class="l">
                <div class="contain">
                    <html-fragment :html="d.textslots['landing-g-five-title'].content"></html-fragment>
                    <html-fragment :html="d.textslots['landing-g-five-p'].content"></html-fragment>
                </div>
            </div>
        </section>
        <section class="six">
            <div>
                <html-fragment :html="d.textslots['landing-g-six-title'].content"></html-fragment>
                <html-fragment :html="d.textslots['landing-g-six-p'].content"></html-fragment>
                <div class="form-group">
                    <router-link to="/cause/new" class="btn-primary">Start your cause</router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    name: "",
    props: [],
    data() {
        return {}
    },
    computed: {
        ...mapState({ d: state => state.slots.landingG })
    },
    mounted() {
        if (!this.d) {
            this.setLandingGData().then(response => {});
        }
    },
    methods: {
        ...mapActions(['setLandingGData']),
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#landing {
    max-width: 100%;
    // margin-top: 40px;

    section {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .l {
            min-width: 35%;

            .contain>div {
                text-align: center;
                padding: 20px;

            }
        }

        .r {
            min-width: 35%;
        }

        .form-group {

            padding: 20px;

            a {
                border-radius: 10px;
            }

        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding: 20px;

            >div {
                // width: 50%;
            }

            h1 {
                font-size: 50px;
                line-height: 50px;
            }
        }
    }

    section.one {
        >div {
            width: auto;
        }

        p {
            padding-top: 5px;

            @include media-breakpoint-up(md) {
                padding-top: 15px;
            }
        }
    }

    section.two { 
        border-radius: 4px;
        min-height: 190px;

        >div {
            width: 100%;
            text-align: center;
            padding: 0 8px;
            background-color: $blue;
            // padding:10% 20%;
            >h2,
            p {
                // color: #fff;
            }

            >h2 {
                font-size: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }
            }

            >p {
                font-weight: 600;
                text-align: center;

            }
        }
    }

    section.three,
    section.five {
        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }

    section.six {
        >div {
            width: 100%;
            padding: 25px 5px;
            background-color: $blue;
            border-radius: 15px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }
        p:nth-child(3) {
        	font-weight: 600;
        }
    }

    .contain {
        // padding: 5% 10%;
        padding-bottom: 10px;
    }

    .imgContain {
        width: 100%;
        padding-bottom: 10px;

        img {
            width: 100%;
            height: auto;
        }

    }
}

</style>
