<template>
    <div class="container  payment-page" id="donateCheckout" v-if="donation && donation.cause">
        <titletag v-if="donation && donation.cause" :title="'Donating to ' + donation.cause.name"></titletag>
        <p>SITE IS CLOSING. DO NOT DONATE PLEASE.</p>
        <div class="row" v-if="donation">
            <div class="col-sm-12 details">
                <div class="row item detail">
                    <div class="col-5">
                        <div class="itemImage">Donation</div>
                    </div>
                    <div class="col-7">
                        <div class="cause">Supporting {{donation.cause.name}}</div>
                    </div>
                </div>
                <div class="row item">
                    <div class="col-6 keys">
                        <div>Amount</div>
                    </div>
                    <div class="col-6 vals">
                        <div>${{parseFloat(donation.order_total).toFixed(2)}}</div>
                    </div>
                </div>
                <div class="row item total">
                    <div class="col-6 keys">
                        <div>Total</div>
                    </div>
                    <div class="col-6 vals">
                        <div>${{parseFloat(donation.order_total).toFixed(2)}}</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 cardInfo">
                <div class="row no_crypto">
                    <!-- <card :order="donation" :key="donation.uuid" :savedCards="donation.payment_methods"></card> -->
                    
                </div>
                <!-- <div class="row">
                	<crypto :order="donation" :total="amount" :key="donation.uuid"></crypto>
                </div> -->
                
            </div>
            
        </div>
    </div>
</template>
<script>
import Card from './Card';
import Crypto from './Crypto';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('checkout');
export default {
    props: ['uuid', 'amount'],
    data() {
        return {
            order: null
        }
    },
    components: {
        Card, Crypto
    },
    computed: {
        ...mapGetters(['donation'])
    },
    mounted() {
    	if(this.amount >= 5) {
        this.setDonationOrder({ "uuid": this.uuid, "total": this.amount }).then(response => {
            this.price = this.amount;
            // this.order = response.order
        });
    	}else{
    		this.flashMessage({m:"The amount must be at least 5 dollars. Sending you back.", d:3000, e:1});
    		setTimeout(() => {
    			this.$router.go(-1);
    		},2000);
    	}

    },
    methods: {
        ...mapActions(['setDonationOrder'])
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#donateCheckout {
    .title {
        width: 100%;
        color: $bluetext;
        font-family: $cat;
    }

    #payment-form {
        .form-row {
            padding: 10px 0;
        }
    }

    button.btn {
        border-radius: 30px;
        width: 96%;
        height: 50px;
        margin: 2%;
        background-color: $red;
        color: #fff;
    }
}

</style>
