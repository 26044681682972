<template>
    <div class="container page howitworks" id="wpPostEmbed" v-if="howToSell">
        <titletag title="How to donate and sell items on FunditOn" description="Benefit causes, charities and schools by selling your used items to raise money."></titletag>
        <div class="headsection">
        	<html-fragment :html="howToSell.textslots['how-to-sell-h1'].content"></html-fragment>
        	<html-fragment :html="howToSell.textslots['how-to-sell-h2'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-section-1'].content"></html-fragment>
            <router-link class="more secondaryButton" to="how_to_start_a_cause">Learn how to create a cause on FunditOn</router-link>
            <html-fragment :html="howToSell.textslots['how-to-sell-section-2'].content"></html-fragment>
            <router-link class="more secondaryButton" to="/register">Create an account</router-link>
            <html-fragment :html="howToSell.textslots['how-to-sell-step-1'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-step-2'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-list-from-cause'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-list-from-my-items'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-step-3'].content"></html-fragment>
            <router-link class="more secondaryButton" to="/p/shipping-policy">See FunditOn's shipping policy</router-link>
            <html-fragment :html="howToSell.textslots['how-to-sell-step-4'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-step-5'].content"></html-fragment>
            <html-fragment :html="howToSell.textslots['how-to-sell-respond-to-offers'].content"></html-fragment>
        </div>
        
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    name: "howtosell",
    props: [],
    data() {
        return {}
    },
    computed: {
        ...mapState({ howToSell: state => state.slots.howToSell })
    },
    mounted() {
    	if (!this.howToSell) {
            this.setHowToSellData();
        }
    },
    methods: {
    	...mapActions(['setHowToSellData']),
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>
