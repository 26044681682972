<template>
	<div class="col-sm-12 misusageReported">
		<titletag title="Misuse Reported"></titletag>
		<h2 class="title">Thank you for reporting</h2>
		<p>Taking the time to report bad content helps all of us. It will be reviewed and removed if it does not conform to our terms of service.</p>
		<p>Thanks<br>FunditOn</p>
	</div>
</template>

<script>
export default {
	props: [],
	data () {
		return {
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.misusageReported {
	padding:50px 25%;
	color: $bluetext;
	h2.title {
		font-size: 22px;
		font-family: $cat;
	}
	p {
		font-size: 18px;
		font-family: $open-sans;
		color: $textcolor;
	}
}
</style>
