<template>
    <div class="col-6 col-sm-4 col-lg-3 giftItem" v-if="item">
        <div class="card">
            <div class="card-body" >
                <div v-if="item.shippable && item.status" class="ship">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/shipping.svg" alt="Seller is willing to ship">
                    <div class="shippable">SELLER IS WILLING TO SHIP</div>
                </div>
                <div v-if="!item.status" class="soldItem">SOLD</div>
                <router-link :to="item.status ? `/g/${item.url_str}` : `#`" v-bind:class="!item.status? 'disabled' : ''" >
                    <img v-if="item && item.primary_photo" :src="getCloudFrontUrl() + item.primary_photo.path_thumb" :alt="item.name">

                    <div class="inset float-left">
                        <div>{{item.user.address.city}}, {{item.user.address.state}}</div>
                    </div>
                    <h4 class="card-title float-left">{{item.name}}</h4>
                </router-link>
                <div class="desc float-left">{{item.description}}</div>
                <div class="card-price float-left">${{item.price}}</div>
                <div @click="likeThis($event,'gift',item.id)" class="likeIt float-right"><i v-bind:class="liked ? 'fas' : 'far'" class=" fa-heart"></i></div>
            </div>
        </div>
    </div>
</template>
<script>
import { getLocalUser } from "../../helpers/auth";
export default {
    props: ['id', 'item'],
    data() {
        return {
            liked: false
        }
    },
    mounted() {
        var user = getLocalUser()
        if (null != user) {
            for (var i = 0; i < user.likes.length; i++)
                if (this.item.id == user.likes[i].gift_id) {
                    this.liked = true;
                }
        }

        if (window.matchMedia("(max-width: 768px)").matches) {
            $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
        } else {
            $('[data-toggle="tooltip"]').tooltip({ "delay": 400 });
        }
    },
    methods: {
        likeThis(e,g,id) {
        	this.liked = this.likeIt(e,g,id,this.liked);
        }
	}
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.giftItem {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;

    a,
    a:hover {
        text-decoration: none;
    }
    a.disabled {
    	cursor: auto;
    }
    .card {
        margin-bottom: 10px;
        border: none;
        height: auto;
        border: none;
        border-radius: 10px 10px 12px 12px;
        text-align: center;
        box-shadow: 0 2px 22px 0 $boxshade;

        .ship {
            img {
                padding: 7px;
            }
        }

        .card-body {
            position: relative;
            padding: 0;
            
            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 10px 10px 0px 0px;
            }
            .soldItem {
            	position: absolute;
            	background-color: $red;
            	top:10px;
            	left:10px;
            	padding: 4px 12px;
            	color:#fff;
            	font-size:10px;
            	line-height: 18px;
            	font-family: $cat;
            	border-radius: 20px;
            	z-index: 100;
            }
            .inset {
                position: relative;
                display: flex;
                max-width: 91%;
                top: -16px;
                left: 10px;
                border-radius: 13px;
                background-color: #fff;
                height: 28px;
                padding: 2px 2px;

                div {
                    display: inline;
                    font-family: $cat;
                    text-transform: uppercase;
                    padding: 4px 12px;
                    font-size: 11px;
                    background-color: $greybg;
                    color: $bluetext;
                    border-radius: 13px;
                    height: 22px;
                    white-space: nowrap;
                    font-weight:600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .card-title {
                font-family: $cat;
                color: $bluetext;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 28px;
                padding: 0px 10px;
                width: 100%;
                text-align: left;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            div.desc {
                color: $textcolor;
                width: 100%;
                font-family: $open-sans;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: left;
                height: 64px;
                padding: 8px 10px;
                overflow: hidden;
                word-break: break-word;
            }

            .card-price {
                font-size: 16px;
                font-family: $open-sans;
                font-weight: 600;
                color: $bluetext;
                padding: 10px 0 10px 10px;
            }

            .supporting {
                font-size: 12px;
            }
        }
    }

    div.likeIt {
        width: 32px;
        height: 32px;
        z-index: 1000;
        padding: 10px 10px 10px 0;
        cursor: pointer;
        i.fas {
            color: $red;
        }

        i.far {
            color: $lightgrey;
        }
    }
}

</style>
