<template>
	<div class="ct container">
		<titletag title="404 Page Not Found"></titletag>
		<h2 class="title">404 Page not found</h2>
	</div>
</template>

<script>
export default {
	name:'notfound',
}
</script>