<template>
    <div class="ct container forms" id="locationStep">
        <titletag title="Confirm Contact Details"></titletag>
        <div v-if="gift" class="container">
        	<steps :uuid="uuid" :active="activeState" :key="gift.updated_at"></steps>
            <h2 v-if="v == 's'" class="title">Step 2: Confirm Your Address</h2>
            <h2 v-if="v == 't' && !gift.published" class="title">Step 3: Publish {{ gift.name}}</h2>
            <h2 v-if="(v == 'fo' || v == 't') && gift.published" class="title">Great! It's ready to share now</h2>
            <p v-if="v == 's'">{{$t('address.privacy')}}</p>
            <locationaddress :saveText="`Next >`" :validate="1" v-if="v == 's'" @setVerified="setAddressVerified"></locationaddress>
            <div v-if="v == 't' && !gift.published" class="publish form-group row">
                <p>You can now preview and publish your item. A publish button is available on the Preview page</p>
                <router-link :to="`/g/${gift.url_str}`" class="secondaryButton preview">Preview item before publishing</router-link>
                <a class="btn btn-primary pub" @click="publishItem">Publish</a>
            </div>
            <div v-if="(v == 'fo' || v == 't') && gift.published" class="publish form-group row">
                <p>You’ve now published {{ gift.name}}. We suggest you share your item so it sells soon.</p>
                <social-sharing :url="domain + '/g/'+ gift.url_str" :description="gift.description" :title="gift.name" inline-template>
                    <div class="sharingLinks">
                        <div>
                            <network network="facebook">
                                <i class="fa fa-facebook fb-g"></i>Share your item on Facebook
                            </network>
                        </div>
                        <div>
                            <network network="twitter">
                                <i class="fa fa-twitter tw-g"></i>Share your item on Twitter
                            </network>
                        </div>
                        <div>
                            <network network="email">
                                <i class="fa fa-envelope em-g"></i>Share your item via email
                            </network>
                        </div>
                        <div>
                            <network network="whatsapp">
                                <i class="fa fa-whatsapp wa-g"></i>Share your item on WhatsApp
                            </network>
                        </div>
                    </div>
                </social-sharing>
                <div class="directLink">
                    <p>Click below to copy url and share in your own way.</p>
                    <div>
                        <input type="text" @click="copyLink()" id="copyUrl" ref="copytext" :value="domain + '/g/'+ gift.url_str">
                    </div>
                    <router-link class=" secondaryButton item" :to="/g/+ gift.url_str">View item</router-link>
                    <router-link class=" secondaryButton item" :to="`/gift/add/${gift.cause.uuid}/${gift.cause.name}`">Add another item</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Locationaddress from '../account/Address';
import Steps from './Steps';
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');
export default {

    props: ['uuid','v'], // v means view
    name: 'giftlocation',
    data() {
        return {
            submitted: false,
            errors: null,
            addressVerified: false,
            domain: window.location.origin

        }
    },
    computed: {
    	 ...mapState({ gift: state => state.gift.gift }),
    	 activeState() {
        	return this.v;
        } 
    },
    components: { Locationaddress,Steps },
    mounted() {
        if (!this.gift || this.gift.uuid != this.uuid) {
            this.setGiftByUuid(this.uuid).then((res) => {
            });
        } 
    },
    methods: {
    	...mapActions(['setGiftByUuid']),
        setAddressVerified(value) {
            if(!value) {
            		this.flashMessage({m:"Your address did not validate. Please try again",e:1,d:2250});

            }else{
            	// this.gift.user.address.verified = 1;
            	this.$store.commit('gift/setGift',this.gift);
            	this.$router.push({ name: 'giftlocation', params: { uuid: this.uuid,v: "t"} });
            }
        },
        copyLink() {
            this.$refs.copytext.select();
            document.execCommand("copy");
        },
        publishItem() {
        	let loader = this.$loading.show();
            var payload = {
                "uuid": this.uuid
            }
            axios.post('/api/publish_gift', payload)
                .then(response => {
                    if (response.data.success) {
                    	this.$store.commit('gift/setGift',response.data.gift);
                        this.$router.push({ name: 'giftlocation', params: { uuid: this.uuid,v: "fo"} });
                    } else {
                        this.flashMessage({ m: response.data.error, e: 1 })
                    }
                    loader.hide();
                }, error => {
                    this.flashMessage({ m: "There was a problem with your request", e: 1 })
                    loader.hide();
                });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#locationStep {
	max-width:750px;
    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;

    }

    h4 {
        color: $textcolor;
        // font-family: $open-sans;
        font-weight: normal;
        font-size: 15px;
        padding-bottom: 1rem;
        padding-left: 15px;
    }

    #copyUrl {
        border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        outline: none;
        color: $buttonblue;
    }

    .sharingLinks,
    .directLink {
        width: 100%;
        text-align: center;
        input {
			padding-left: 10px;
		}
        a.secondaryButton {
            width: 94%;
            display: inline-block;
            // padding: 10px;
            // text-align: center;
            // color: $buttonblue;
            // background-color: $blue;
            // border-radius: 25px;
            margin: 10px;
            max-width: 400px;
        }
        div {
            width: 100%;
            margin-bottom: 10px;
            border: 1px solid rgba(177, 176, 190, 0.2);
            border-radius: 25px;
            padding: 16px 0;
            text-align: left;

            span {
                color: $grey;
                font-family: $cat;
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 18px;
                cursor:pointer;
            }

            i {
                padding: 0 10px;
                width: 30px;
                text-align: center;
                margin: 0 6px;
            }
        }
    }

    p {
        text-align: left;
        color: $textcolor;
        font-family: $open-sans;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
        padding-left: 15px;
        width: 100%;
        // @include media-breakpoint-up(sm) {
        // 	width: auto;
        // }
    }
    .publish {
    	flex-direction: column;
    	align-items: center;
    	.secondaryButton, .btn-primary {
    		max-width: 300px;
    		margin-bottom:30px;
    		height:50px;
    		padding:12px;
    	}
    	input {
    		min-width:300px;
    	}
    }
}

</style>
