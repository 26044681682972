<template>
    <div class="ct privacy container">
        <titletag title="FunditOn Privacy Policy"></titletag>
        <div id="privacyContainer" class="row">
            <!-- Termly Tracking Code -->
            <div name="termly-embed" data-id="231f936f-924d-412a-89e9-e2eef1d47304" data-type="iframe"></div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        $('#termly-jssdk').remove();
            (function(d, s, id) {
                var js, tjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://app.termly.io/embed-policy.min.js";
                tjs.parentNode.insertBefore(js, tjs);
            }(document, 'script', 'termly-jssdk'));

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#privacyContainer {
    padding: 20px;
    width: 100%;

    >div {
        width: 100%;
    }
}

</style>
