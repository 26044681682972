<template>
    <div class="col-sm-9 myGiftsPage">
        <titletag title="My Items"></titletag>
        <h2 class="title">My Items <a @click="addItem" class="secondaryButton">Add Item</a></h2>
        <template v-if="gifts.length">
            <!-- Desktop here -->
            <div class="desktop row tablehead">
                <div class="col">Item</div>
                <div class="col-2">Category</div>
                <div class="col-1">Price</div>
                <div class="col-2">Status</div>
                <div class="col-1">Edit</div>
                <div class="col-1">Delete</div>
            </div>
            <div v-if="gifts" v-for="item in gifts" :uuid="item.uuid" class="row myItems desktop">
                <div class="col img">
                    <img v-if="item.primary_photo && item.primary_photo.path_thumb" :src="getCloudFrontUrl() + item.primary_photo.path_thumb">
                    <div class="description">
                        <div class="name">
                            <router-link :to="item.status == 1 ? `/g/${item.url_str}` : ``">{{item.name}}</router-link>
                        </div>
                        <div class="desc">{{item.description}}</div>
                    </div>
                </div>
                <div class="col-2">{{item.category.name}}</div>
                <div class="col-1">${{item.price}}</div>
                <div class="col-2" :class="item.status ? 'point': ''" @click="goPublish(item)">{{itemState(item)}}</div>
                <div class="col-1">
                    <router-link v-if="item.status == 1" :to="`/gift/edit/${item.uuid}`"><img class="edit icon" src="https://d1812kujpqokfk.cloudfront.net/assets/account/24/edit.svg"></router-link>
                </div>
                <div class="col-1"><img v-if="item.status == 1" @click="deleteConfirm(item)" class="trash icon" src="https://d1812kujpqokfk.cloudfront.net/assets/account/15/trash1.svg"></div>
            </div>
            <!-- MOBILE HERE -->
            <div v-if="gifts" v-for="item in gifts" :uuid="item.uuid" class="row myItems mobile">
                <div class="col-3 img"><img v-if="item.primary_photo && item.primary_photo.path_thumb" :src="getCloudFrontUrl() + item.primary_photo.path_thumb"></div>
                <div class="col-9 ">
                    <h3>{{item.name}}</h3>
                    <div class="pc">
                        <span class="price">${{item.price}}</span>
                        <span class="category"><span class="dot">&bull;</span>Category: {{item.category.name}}</span>
                    </div>
                </div>
                <div class="col-5 status" @click="goPublish(item)">{{itemState(item)}}</div>
                <div class="col-2 ">
                    <router-link v-if="item.status == 1" :to="`/g/${item.url_str}`"><img class="icon" src="https://d1812kujpqokfk.cloudfront.net/assets/account/24/eye_open.svg"></router-link>
                </div>
                <div class="col-2 ">
                    <router-link v-if="item.status == 1" :to="`/gift/edit/${item.uuid}`"><img class="edit icon" src="https://d1812kujpqokfk.cloudfront.net/assets/account/24/edit.svg"></router-link>
                </div>
                <div class="col-2 "><img v-if="item.status == 1" @click="deleteConfirm(item)" class="trash icon" src="https://d1812kujpqokfk.cloudfront.net/assets/account/15/trash1.svg"></div>
            </div>
        </template>
        <template v-else>
            <div class="empty">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/items.svg">
                <p class="nothing">You don’t have any items to sell yet. Add items to sell to your own or any cause and they’ll appear here.</p>
            </div>
        </template>
        <router-link :to="`/account`" class="btn back">Go Back</router-link>
    </div>
</template>
<script>
import Vue from 'vue'
import Choosecause from '../gifts/Choosecause';
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions, mapMutations } = createNamespacedHelpers('account');
export default {
    props: [],
    data() {
        return {}
    },
    components: {
        Choosecause
    },
    mounted() {
        if (this.changed.indexOf('gifts') != -1) {
            this.setGifts();
        }
    },
    beforeRouteLeave(to, from, next) {
        $('#shareModal').modal('hide');
        next();
    },
    computed: {
        ...mapState({ gifts: state => state.account.gifts }),
        ...mapState({ changed: state => state.account.changed }),
    },
    methods: {
        ...mapActions(['deleteGift', 'setGifts']),
        ...mapMutations(['setChanged']),
        itemState(item) {
            var s = item.status === 1 ? 'Published' : 'Sold';
            if (!item.published) {
                s = 'Unpublished';
            }
            return s;
        },
        addItem() {
            var ComponentClass = Vue.extend(Choosecause)
            var instance = new ComponentClass();
            instance.$mount();
            $('#shareModal .modal-body').empty().append(instance.$el);
            $('#shareModal').modal('show');
        },
        goPublish(item) {
        	if(!item.primary_photo && !item.published) {
        		this.flashMessage({m:'To publish an item it needs at least one photo. Redirecting you to add photos',e:1, d:2500});
        		this.$router.push('/gift/edit/' + item.uuid);
        		return;
        	}
            if (!item.published) {
                this.setChanged({ "a": "gifts" });
                this.$router.push('/gift/confirm_address/' + item.uuid + '/t');
            }
            if (item.status && item.published) {
                this.unPublishConfirm(item);
            }
        },

        unPublishConfirm(item) {
            var data = {
                h: "Unpublish " + item.name,
                q: "Are you sure you want to unpublish?",
                y: "Yes Unpublish",
                n: "Cancel",
                uuid: item.uuid
            };
            this.confirmDialog(data, this.unPublishItem);
        },
        unPublishItem(bool, data) {
            if (bool) {
            	let l = this.$loading.show();
                var payload = {
                    "uuid": data.uuid
                }
                axios.post('/api/unpublish_gift', payload)
                    .then(response => {
                        if (response.data.success) {
                            this.flashMessage({ m: response.data.message });
                            this.setGifts();
                            this.$store.commit('gift/setGift', response.data.gift);
                            l.hide();
                        } else {
                        	l.hide();
                            this.flashMessage({ m: response.data.error, e: 1 })

                        }
                    }, error => {
                        this.flashMessage({ m: "There was a problem with your request", e: 1 })
                    });
            }
        },

        deleteConfirm(item) {
            var data = {
                h: "Delete " + item.name,
                q: "Are you sure you want to delete?",
                y: "Yes delete",
                n: "Cancel",
                uuid: item.uuid
            };
            this.confirmDialog(data, this.deleteItem);
        },
        deleteItem(bool, data) {
            if (bool) {
                let loader = this.$loading.show();
                this.deleteGift(data.uuid).then(response => {
                    loader.hide();
                    if (response.data.status) {
                        this.flashMessage({ m: "Your item has been deleted" });
                    } else {
                        this.flashMessage({ m: response.data.message, e: 1, d: 3000 });
                    }
                });
            }
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.myGiftsPage .secondaryButton {
    margin-left: 20px;
}

.desktop {
    @include media-breakpoint-down(xs) {
        display: none;
    }

    &.tablehead {
        border-radius: 12px 12px 0 0;
        background-color: rgba(194, 200, 216, 0.1);
        padding-bottom: 0;
        margin-bottom: 0;
        height: 44px;
        padding: 8px 20px 0 20px;

        div[class^="col"] {
            text-transform: uppercase;
            color: #A9A7B4;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 30px;
            padding-top: 8px;
        }

        div.col {
            padding-left: 5px;
        }
    }

    .point {
        cursor: pointer;
    }

    &.myItems {
        margin: 0 -15px 2px -15px;
        box-shadow: 1 1px 1px 1px rgba(17, 12, 83, 0.08);
        border-radius: 0;
        padding: 20px;

        div[class^="col"] {
            font-family: $open-sans;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: left;
        }

        div[class^="col"].img {
            justify-content: left;

            img {
                max-width: 25%;
            }

            .description {
                vertical-align: middle;
                display: inline-block;
                font-family: $open-sans;
                max-width: 73%;
                padding-left: 5px;

                .name a {
                    color: $bluetext;
                    font-family: $open-sans;
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 26px;
                }

                .desc {
                    color: #52506F;
                    ;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: normal;
                    font-family: $open-sans;
                    max-height: 75px;
                    overflow: hidden;
                }
            }

        }

    }

    img.icon.trash {
        background-color: $red;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
    }
}


.myItems {
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    border-radius: 10px;
    margin: 15px -5px;
    padding: 20px;

    .img {
        padding: 0px;

        img {
            max-width: 80px;
            height: 58px;
            border-radius: 8px;
        }

        margin-bottom: 15px;
    }

    .status {
        border: 1px solid $formgrey;
        border-radius: 25px;
        padding: 7px;
        height: 40px;
        width: 143px;
        padding-left: 15px;
        font-size: 14px;
        letter-spacing: 0;
        font-family: $open-sans;
        color: $bluetext;
    }
}

.myItems.mobile {
    @include media-breakpoint-up(sm) {
        display: none;
    }

    &.col-9 {
        padding-right: 0;
    }

    h3 {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
    }

    .pc {
        width: 100%;
        overflow: visible;
        white-space: nowrap;

        span.price {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            text-align: left;
            display: inline-block;
            padding-right: 5px;
        }

        span.dot {
            text-align: left;
            padding-right: 5px;
        }

        span.category {
            color: #A9A7B4;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            text-transform: uppercase;
            display: inline-block;
            overflow: visible;
            white-space: nowrap;

            // &::before {
            //     content: ".";
            //     color: #A9A7B4;
            //     padding-right: 10px;
            // }
        }
    }



    img.icon {
        border: 1px solid $formgrey;
        border-radius: 50%;
        padding: 8px;
        height: 40px;

        &.edit,
        &.trash {
            height: 40px;
            padding: 11px;
        }

        &.trash {
            background-color: $red;
            color: fff;
            padding: 12px;
        }

    }


}


.fade-enter-active,
.fade-leave-active {
    transition: opacity .8s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

</style>
