<template>
    <div class="searchBox">
        <input id="searchInput" v-model="inputVal" type="text" placeholder="Search causes, items, categories, anything..">
        <input id="searchBoxSubmit" type="submit" value="Search">
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('search');
export default {
    name: 'search',
    props: [],
    data() {
        return {
            results: null,
            inputVal: ''
        }
    },
    mounted() {
        this.initsearch();
    },
    computed: {
        ...mapGetters(['autocomplete']),
    },
    methods: {
        ...mapActions(['setautocomplete']),

        initsearch() {
            $.widget("custom.catcomplete", $.ui.autocomplete, {
                _create: function() {
                    this._super();
                    this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
                },
                _renderMenu: function(ul, items) {
                    var that = this,
                        currentCategory = "",
                        catCounter = 0;
                    $.each(items, function(index, item) {
                        var li;
                        if (item.category != currentCategory) {
                            catCounter++;
                            if (catCounter % 2 == 1) {
                                ul.append("<li class='left ui-autocomplete-category'>" + item.category + "</li>");
                            } else {
                                ul.append("<li class=' right ui-autocomplete-category'>" + item.category + "</li>");
                            }
                            currentCategory = item.category;
                        }
                        li = that._renderItemData(ul, item);
                        if (item.category) {
                            li.attr("aria-label", item.category + " : " + item.label);
                        }
                        if (catCounter % 2 == 1) {
                            li.addClass('left');
                        } else {
                            li.addClass('right');
                        }
                    });
                }
            });
            var self = this;
            $('#searchInput').catcomplete({
                delay: 0,
                minLength: 3,
                autoFocus: true,
                select: function(event, ui) {
                    if (ui.item.category == 'Items for Sale') {
                        self.$router.push({ name: 'gift', params: { urlStr: ui.item.url_str } });
                    }
                    if (ui.item.category.indexOf('Items in') === 0) {
                        self.$router.push({ name: 'gift', params: { urlStr: ui.item.url_str } });
                    }
                    if (ui.item.category == 'Causes') {
                        self.$router.push({ name: 'cause', params: { urlStr: ui.item.url_str } });
                    }
                    if (ui.item.category == 'Posts') {
                        self.$router.push({ name: 'blog', params: { slug: ui.item.url_str } });
                    }
                    if (ui.item.category == 'Pages') {
                        self.$router.push({ name: 'page', params: { slug: ui.item.url_str } });
                    }
                    if (ui.item.category == 'Item Categories') { //TODO ADD TO ROUTES
                        self.$router.push({ name: 'giftcategory', params: { urlStr: ui.item.url_str } });
                    }
                    if (ui.item.category == 'Cause Categories') { //TODO ADD TO ROUTES
                        self.$router.push({ name: 'causecategory', params: { urlStr: ui.item.url_str } });
                    }
                    self.inputVal = '';
                    // See Navigation.vue
                    $('.modal-backdrop').remove()
                    $('.searchFromNav').hide();
                },
                source: function(request, response) {
                    var term = $('#searchInput').val();
                    self.results = false;
                    self.results = self.autocomplete(term);
                    if (!self.results) {
                        self.setautocomplete(term);
                    }
                    var interval = setInterval(() => {
                        self.results = self.autocomplete(term);
                        if (self.results) {
                            response(self.results);
                            clearInterval(interval);
                        }
                    }, 50);
                }
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.searchBox {
    position: relative;
    width: 100%;
    top: 10px;
    left: 50%;
    margin-left: -50%;
    z-index:1070;
    @include media-breakpoint-up(sm) {
    	width:500px;
    	margin-left: -250px;
    }

    #searchInput {
        box-shadow: 0 2px 12px 0 rgba(155, 158, 161, 0.12);
        border: none;
        border-radius: 30px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding-left: 45px;
        color: $bluetext;
        outline: none;
        background-image: url(https://d1812kujpqokfk.cloudfront.net/assets/search.svg);
        background-repeat: no-repeat;
        background-position-x: 20px;
        background-position-y: 16px;
        background-size: 7%;
        &::placeholder {
            font-size: 16px;
        }
        @include media-breakpoint-up(sm) {
        	background-size: 5%;
        	background-position-x: 25px;
        	background-position-y: 14px;
        	padding-left: 50px;
        }
        
    }
}

#searchBoxSubmit {
    display: none;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    border: 1px solid #4b9b78;
    border-radius: 3px;
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3000;
    float: left;
    display: none;
    min-width: 160px;
    padding: 15px 25px;
    // margin: 0 0 10px 25px;
    list-style: none;
    background-color: #ffffff;
    border:none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    font-family: $cat;

}

// .ui-menu-item>a.ui-corner-all {
//     display: block;
//     // padding: 3px 15px;
//     clear: both;
//     font-weight: normal;
//     line-height: 18px;
//     color: $bluetext;
//     white-space: nowrap;
//     text-decoration: none;
    

// }
.ui-menu-item {
	padding: 0 10px;
	cursor: pointer;
	border-radius: 20px;
	>div {
		padding:5px 10px;
		border-radius:20px;
	}
}
.ui-autocomplete-category {
	font-weight: 600;
	padding-left: 10px;
}
.ui-state-hover,
.ui-state-active {
    color: $bluetext;
    text-decoration: none;
    background-color: $blue;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    background-image: none;
}
.ui-helper-hidden-accessible{
    display:none !important;
}
</style>
