import { router } from "../../app.js"
export default {
    namespaced: true,
    state: {
        causesFull: [],
        causes: [],
        categories: [],
        supporters: [],
        categoryCauses: [],
        currentPage: 0,
        lastPage: 1,
        changed: 0
    },
    mutations: {
        setCause: (state, payload) => {
            var cause = {
                uuid: payload.uuid,
                urlStr: payload.url_str,
                cause: payload
            };
            state.causesFull.unshift(cause);
        },
        setSupporters: (state, payload) => {
            state.supporters[payload.urlStr] = payload.orders;
        },
        deleteCause: (state, payload) => {
            var index = state.causesFull.findIndex(causesFull => causesFull.uuid == payload);
            //Delete. Not being used. No action yet and maybe this should not happen.
            if (index !== -1) {
                state.causesFull.splice(index, 1);
            }
        },
        setFilteredCauses(state, payload) {

            if (state.causes[payload.q] && state.causes[payload.q].causes && payload.page != 1) {
                state.causes[payload.q].causes = state.causes[payload.q].causes.concat(payload.causes.data);
            } else {
                state.causes[payload.q] = {};
                state.causes[payload.q].causes = payload.causes.data;

            }
            state.causes[payload.q].currentPage = payload.causes.current_page;
            state.causes[payload.q].lastPage = payload.causes.last_page;
        },
        setCauses: (state, payload) => {
            state.causes = state.causes.concat(payload.data);
            state.currentPage = payload.current_page;
            state.lastPage = payload.last_page;

        },
        setCategoryCauses(state, payload) {
            if (state.categoryCauses[payload.urlStr].causes) {
                state.categoryCauses[payload.urlStr].causes = state.categoryCauses[payload.urlStr].causes.concat(payload.causes.data);
            } else {
                state.categoryCauses[payload.urlStr].causes = payload.causes.data;
            }
            state.categoryCauses[payload.urlStr].currentPage = payload.causes.current_page;
            state.categoryCauses[payload.urlStr].lastPage = payload.causes.last_page;
            state.categoryCauses[payload.urlStr].categoryName = payload.categoryName;
        },
        resetCauses: (state) => {
            state.lastPage = 1;
            state.causes = [];
            state.currentPage = 0;
        },
        setCategories: (state, payload) => {
            state.categories = payload;
        },
        setChanged: (state, payload) => {
            state.changed = payload;
        }
    },
    actions: {
        setCategories: (context, payload) => {
            return new Promise((res, rej) => {
                axios.get('/api/cause_categories')
                    .then(response => {
                        context.commit('setCategories', response.data);
                        res(response);
                    }, error => {
                        router.push('/login');
                        // rej(error);
                    })
            });
        },
        setCause: (context, payload) => { // payload is the url_str
            return new Promise((res, rej) => {
                axios.get('/api/cause/' + payload)
                    .then(response => {
                        context.commit('deleteCause', response.data.uuid);
                        context.commit('setCause', response.data);

                        res(response);
                    }, error => {
                        rej(error);
                    })
            });
        },
        setCategoryCauses: async (context, payload) => {
            if (!context.state.categoryCauses[payload]) {
                context.state.categoryCauses[payload] = {};
                context.state.categoryCauses[payload].causes = [];
                context.state.categoryCauses[payload].currentPage = 0;
                context.state.categoryCauses[payload].lastPage = 1;
            }
            if (context.state.categoryCauses[payload].currentPage != context.state.categoryCauses[payload].lastPage) {
                return new Promise((res, rej) => {
                    var page = context.state.categoryCauses[payload].currentPage + 1;
                    axios.get('/api/causes_in_category/' + payload + '?page=' + page)
                        .then(response => {
                            context.commit('setCategoryCauses', response.data);
                            res(response);
                        }, error => {
                            console.log('TODO error handling');
                            rej(error);
                        });

                });
            }
        },
        setSupporters: (context, payload) => { // payload is the url_str
            return new Promise((res, rej) => {
                axios.get('/api/cause_supporters/' + payload)
                    .then(response => {
                        context.commit('setSupporters', response.data);
                        res(response.data);
                    }, error => {
                        rej(error);
                    })
            });
        },
        setCauseByUuid: async (context, payload) => {
            return new Promise((res, rej) => {
                axios.get('/api/cause_by_uuid/' + payload) // this is a uuid
                    .then(response => {
                        context.commit('deleteCause', response.data.uuid);
                        context.commit('setCause', response.data);
                        res(response);
                    }, error => {
                        rej(error);
                    })
            });
        },
        addCause: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/create_cause', payload)
                    .then(response => {
                        context.commit('setCause', response.data);
                        context.commit('resetCauses');
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        // TODO get this working.
        updateCause: async (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/update_cause/' + payload.id, payload)
                    .then(response => {
                    	if(response.data.status == 'success') {
	                        context.commit('deleteCause', response.data.cause.uuid);
	                        context.commit('setCause', response.data.cause);
	                        context.commit('resetCauses');
	                    }
                        res(response);
                    }, error => {
                        rej(error);
                    })
            });

        },
        setCauses: async (context, payload) => {

            var page = 1;
            if (payload) {
                var str = payload.replace('=', '|');
                str = str.replace('&', '--');
                str = str.replace('?', '');
            } else {
                var str = 'd'; // empty just default unfiltered
            }

            return new Promise((res, rej) => {

                if ((context.state.causes[str] == undefined || context.state.causes[str].currentPage < context.state.causes[str].lastPage)) {
                    if (context.state.causes[str] == undefined) {
                        context.state.causes[str] = {};
                        context.state.causes[str].gifts = [];
                        context.state.causes[str].currentPage = 0;
                        context.state.causes[str].lastPage = 1;

                    }
                    if (context.state.causes[str].currentPage) {

                        page = context.state.causes[str].currentPage + 1;
                    }

                    axios.get('/api/causes?page=' + page + '&' + payload.substring(1))
                        .then(response => {
                            context.commit('setFilteredCauses', response.data);
                            res(response);
                        }, error => {
                            console.log('TODO error handling');
                            rej(error);
                        });
                } else {
                    //handle empty repeater
                    res({ data: { q: str, causes: { data: ['s'] } } });
                }

            });
            // if(context.state.currentPage != context.state.lastPage) {
            // 	return new Promise((res, rej) => {
            //     	var page = context.state.currentPage + 1;
            //         axios.get('/api/causes?page='+ page) 
            //             .then(response => {
            //                 context.commit('setCauses', response.data);
            //                 res(response);
            //             }, error => {
            //                 rej(error);
            //             })
            //     })
            // }        
        },
        saveCauseImage: async (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/add_cause_image', {
                    "uuid": payload.uuid,
                    "imageUuid": payload.imageUuid,
                    "crop": payload.cropInfo,
                    // "h": payload.cropInfo.height,
                    // "x": payload.cropInfo.x,
                    // "y": payload.cropInfo.y,
                    "city": payload.cause.city,
                    "state": payload.cause.state
                }).then((response) => {
                    if (response.data.status == 'success') {
                        context.commit('deleteCause', response.data.cause.uuid);
                        context.commit('setCause', response.data.cause);
                        context.commit('account/setChanged', { "a": "causes" }, { root: true });
                        context.commit('resetCauses');
                    }
                    res(response);
                }, error => {
                    rej(error);
                })
            });
        }
    },
    getters: {
        getcause(state) {
            return (urlStr) => {
                var cause = state.causesFull.find(causesFull => causesFull.urlStr == urlStr);
                if (cause) {
                    return cause.cause;
                }
            }
        },
        getSupporters(state) {
            return (urlStr) => {
                var supporters = state.supporters[urlStr];
                if (supporters) {
                    return supporters;
                }
            }
        },
        getcauseByUuid(state) {
            return (uuid) => {
                var cause = state.causesFull.find(causesFull => causesFull.uuid == uuid);
                if (cause) {
                    return cause.cause;
                }
            }
        },
        causes: (state) => {
            return state.causes;
        },
        categories: (state) => {
            return state.categories;
        }
    }
}
