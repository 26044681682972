import Home from '../../components/pages/Home'
import Login from '../../components/auth/Login.vue';
import Register from '../../components/auth/Register.vue';
import Privacy from '../../components/pages/Privacy'
import Terms from '../../components/pages/Terms'
import Gift from '../../components/gifts/Gift'
import Gifts from '../../components/gifts/Gifts'
import Giftcategory from '../../components/gifts/Giftcategory'
import Causecategory from '../../components/causes/Causecategory'
import Causeindex from '../../components/causes/Causeindex'
import Cause from '../../components/causes/Cause'
import Verifying from '../../components/auth/Verifying'
import Verified from '../../components/auth/Verified'
import Contactus from '../../components/help/Contactus'
import Misusereported from '../../components/account/Misusereported'
import Socialredirect from '../../components/auth/Socialredirect'
import Socialnoemail from '../../components/auth/Socialnoemail'
import Facebookdeletion from '../../components/auth/Facebookdeletion'
import ForgotPassword from '../../components/auth/ForgotPassword'
import ResetPasswordForm from '../../components/auth/ResetPasswordForm'
import Blogindex from '../../components/pages/Blogindex'
import Blog from '../../components/pages/Blog'
import Page from '../../components/pages/Page'
import Howitworks from '../../components/pages/Howitworks'
import Howtosell from '../../components/pages/Howtosell'
import Howtostartacause from '../../components/pages/Howtostartacause'
import Landingschool from '../../components/pages/Landingschool'
import Ptotoday from '../../components/pages/landing/Ptotoday'
import Allcharities from '../../components/pages/landing/Allcharities'
import Notfound from '../../components/pages/Notfound'

export default [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/register',
            component: Register
        },
        { 
            path: '/reset-password', 
            name: 'reset-password', 
            component: ForgotPassword, 
            meta: { 
              auth:false 
            } 
          },
          { 
            path: '/reset-password/:token', 
            name: 'reset-password-form', 
            component: ResetPasswordForm, 
            meta: { 
              auth:false 
            } 
          },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/causes',
            name: 'causes',
            component: Causeindex,
            props: true,
        },
        
        {
            path: '/c/:urlStr',
            name: 'cause',
            component: Cause,
            props: true,
        },
        {
            path: '/gifts',
            name: 'gifts',
            component: Gifts,
        },
        {
            path: '/category/g/:urlStr',
            name: 'giftcategory',
            props: true,
            component: Giftcategory,  
        },
        {
            path: '/category/c/:urlStr',
            name: 'causecategory',
            props: true,
            component: Causecategory,  
        },
        {
            path: '/g/:urlStr/:intent?',
            name: 'gift',
            props: true,
            component: Gift,
        },
        {
            path: '/email_verifying',
            name: 'emailVerifying',
            component: Verifying,
        },
        {
            path: '/email_verified',
            name: 'emailVerified',
            component: Verified,
        },
        {
            path: '/contactus',
            name: 'contactus',
            component: Contactus,
        },
        {
            path: '/misusereported',
            name: 'misusereported',
            component: Misusereported,
        },
        {
            path: '/socialredirect',
            name: 'socialredirect',
            component: Socialredirect,
        },
        {
            path: '/social_no_email',
            name: 'socialnoemail',
            props: true,
            component: Socialnoemail,
        },
        {
            path: '/facebook_data_deletion',
            name: 'facebookdeletion',
            props: true,
            component: Facebookdeletion,
        },
        {
            path: '/how_it_works',
            name: 'howitworks',
            props: false,
            component: Howitworks,
        },
        {
            path: '/how_to_sell',
            name: 'howtosell',
            props: false,
            component: Howtosell,
        },
        {
            path: '/how_to_start_a_cause',
            name: 'howtostartacause',
            props: false,
            component: Howtostartacause,
        },
        {
            path: '/support_your_school',
            name: 'landingschool',
            props: false,
            component: Landingschool,
        },
         {
            path: '/free_school_fundraising',
            name: 'ptotoday',
            props: false,
            component: Ptotoday,
        },
        {
            path: '/donations_in_kind_made_easy',
            name: 'allcharities',
            props: false,
            component: Allcharities,
        },
        {
            path: '/blog',
            name: 'blogindex',
            props: false,
            component: Blogindex,
        },
        {
            path: '/blog/:slug',
            name: 'blog',
            props: true,
            component: Blog,
        },
        {
            path: '/p/:slug',
            name: 'page',
            props: true,
            component: Page,
        },
        {
            path: '*',
            name: 'notfoundpath',
            component: Notfound,
        } 


    ]
