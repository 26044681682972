<template>
	<div class="container textonly">
		<h2 class="title">Facebook data deletion</h2>
		<div>
			<p>At Funditon the only information shared from Facebook is your profile photo, name and email.</p>
			<p>If you have made a transaction, have any active causes or items for sale we will need to keep your name and email for our own accounting purposes. An active cause means someone has donated something which was sold or has received a cash donation. If you are the recipient of such a cause that amounts to the same thing. We want you to be fully paid out which means we need to know who you are.</p>
			<p>If you wish to completely remove yourself from our system you can <router-link to="contactus" class="textLink">contact us</router-link> with your name and email <router-link to="contactus" class="textLink">here</router-link> and we will get it done assuming the above conditions do not prohihit us.</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "",
	props: ['id'],
	data () {
		return {
			username: '',
		}
	},
	mounted() {
		
	},
	methods: {
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>