<template>
    <div class="row messageContainer requesters" id="holdRequest">
        <h4>Interested but need to see first?</h4>
        <p>You can ask the seller to put this item on hold for you until you have seen it or clarified any details.</p>
        <p class="warn" v-if="heldForOther">This is on hold for someone else who may have decided not to buy.</p>
        <a class="btn" v-if="heldForOther" @click="sendRequest">Ask to change Hold</a>
        <a class="btn" v-if="!heldForOther" @click="sendRequest">Request to Hold</a>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('channel');
export default {
    props: ['channel', 'loggedInId'],
    data() {
        return {

        }
    },
    mounted() {
        this.init();
    },
    computed: {
        ownerName() {
            for (var i = 0; i < this.channel.users.length; i++) {
                if (this.channel.users[i].id == this.channel.gift.user_id) {
                    return this.channel.users[i].name;
                }
            }
        },
        heldForOther(){
        	if(this.channel.gift.buyer_id && this.channel.gift.buyer_id != this.loggedInId) {
        		return true;
        	}
        }
    },
    methods: {
        ...mapActions(['sendHoldRequest']),
        init() {

            //append this to the dom where we want it
            // no not append if not needed 
            // either not enough messages or already sent the request
            if (this.canShow()) {
                $('#holdRequest').show();
            }

        },
        sendRequest() {
            if (this.channel.gift.user_id != this.loggedInId) {
                let loader = this.$loading.show();
                //changing Buy text to Hold but keeping existing code for next dev confusion. Too lazy to mess with the back end right now. Previously the Seller had to accept the buyers request before they could buy. Reusing this for holds.
                this.sendHoldRequest({ "message": "Hold Request", "type": "buy_request", "channel_id": this.channel.id })
                    .then(response => {
                        // TODO need to be checking the response here
                        loader.hide();
                        this.$emit('reloadMessages');
                        $('#holdRequest').hide();

                    })

            }
        },
        canShow() {
            var canShow = true;
            if ((this.channel.gift.user_id === this.loggedInId) || (this.channel.gift.buyer_id === this.loggedInId)) {
                return false;
            }
            for (var i = 0; i < this.channel.messages.length; i++) {
                if (this.channel.messages[i].type === 'buy_request' && this.channel.gift.buyer_id === this.loggedInId) {
                    return false;
                }
                if (this.channel.messages[i].type === 'buy_request' && moment(this.channel.messages[i].created_at).isAfter(moment().subtract(1, 'hours'))) { // prevent users from nagging too quickly
	                    return false;
	                }
            }
            return canShow;
        }

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
 #accountConversation .conversation .messageContainer.requesters {

    display: none;
    float: right;
    min-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    max-width: 480px;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    background-color: $blue;

    h4 {
        color: $bluetext;
        font-family: $open-sans;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
    }

    p {
        color: #52506F;
        font-family: $open-sans;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
        &.warn {
        	font-size: 12px;
        	color:$red;
        	line-height:12px;
        }
    }

    a.btn {
        border-radius: 21px;
        background-color: $buttonblue;
        color: #fff;
        font-family: $cat;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        padding: 6px 27px;
        max-width:200px;
        
        float: left;
    }
}

</style>
