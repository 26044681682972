<template>
    <div class="container page howitworks howToStart" id="wpPostEmbed" v-if="how">
        <titletag title="How to start a cause on FunditOn" description="Raise money for the charity or cause that feels important to you"></titletag>
        <div class="headsection">
        	<html-fragment :html="how.textslots['how-to-start-a-cause-h1'].content"></html-fragment>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-h2'].content"></html-fragment>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-main'].content"></html-fragment>
        	<router-link to="/cause/new" class="secondaryButton rev">Start a cause</router-link><br>
        	<img src="https://d1812kujpqokfk.cloudfront.net/assets/how/user.svg">
        	<h3>Create an Account</h3>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-step-1'].content"></html-fragment>
        	<img src="https://d1812kujpqokfk.cloudfront.net/assets/how/start_cause.svg">
        	<h3>Post your Cause</h3>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-step-2'].content"></html-fragment>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-step-3'].content"></html-fragment>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-step-4'].content"></html-fragment>
        	<img src="https://d1812kujpqokfk.cloudfront.net/assets/how/share.svg">
        	<h3>Publish and Share</h3>
        	<html-fragment :html="how.textslots['how-to-start-a-cause-step-5'].content"></html-fragment>
        	<img src="https://d1812kujpqokfk.cloudfront.net/assets/how/funds.svg">
        	<h3>How to Receive your Funds</h3>
        	<html-fragment :html="how.textslots['how-it-works-beneficiary'].content"></html-fragment>
        </div>
        
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    name: "howtostartacause",
    props: [],
    data() {
        return {}
    },
    computed: {
        ...mapState({ how: state => state.slots.howToStartACause })
    },
    mounted() {
    	if (!this.howToStartACause) {
            this.setHowToStartACauseData();
        }
    },
    methods: {
    	...mapActions(['setHowToStartACauseData']),
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.howToStart {
	.secondaryButton {
		width: 180px;
		display: block;
		border: 1px solid $buttonblue;
		
	}
	
	img {
    		margin-bottom:12px;
    	}
	
}

</style>
