<template>
    <div class="container payment-page">
        <titletag v-if="gift" :title="'Purchase ' + gift.name"></titletag>
        <div v-if="!available" class="row borderError">
            <!-- <p>{{notAvailableMessage}}</p> -->
            <p>SITE IS CLOSING. DO NOT BUY</p>
        </div>
        <div class="row" v-if="available && gift && ready">
            <div class="col-sm-12 details">
                <div class="row item detail">
                    <div class="col-5">
                        <div class="itemImage" :style="{backgroundImage:`url(${getCloudFrontUrl() + gift.primary_photo.path_thumb})`}"></div>
                    </div>
                    <div class="col-7">
                        <div>{{gift.name}}</div>
                        <div class="cause">Supporting {{gift.cause.name}}</div>
                    </div>
                </div>
                <div class="row item ct forms">
                    <div class="col-6 keys">
                        <div>Price</div>
                        <div v-if="shipping">Shipping</div>
                        <div v-if="gift.tax_to_charge > 0">Estimated taxes<div class="tax" data-toggle="tooltip" data-placement="top" :title="$t('checkout.estimated')">i</div></div>
                    </div>
                    <div class="col-6 vals">
                        <div>${{parseFloat(gift.price).toFixed(2)}}</div>
                        <div v-if="shipping">${{parseFloat(gift.shipping_price).toFixed(2)}}</div>
                        <div v-if="gift.tax_to_charge > 0">${{parseFloat(gift.tax_to_charge).toFixed(2)}}</div>
                    </div>
                </div>
                <div class="row item total">
                    <div class="col-6 keys">
                        <div>Total</div>
                    </div>
                    <div class="col-6 vals">
                        <div>${{parseFloat(gift.total).toFixed(2)}}</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 cardInfo">
                <div class="row">
                    <!-- <card :key="gift.order_id" :gift="gift" :order_id="gift.order_id" :order_uuid="gift.order_uuid" :cause="gift.cause" :savedCards="gift.payment_methods"></card> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Card from './Card';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('checkout');
export default {
    data() {
        return {
            available: true,
            notAvailableMessage: null,
            ready: false
        }
    },
    props: ['uuid', 'shipping'],
    components: {
        Card
    },
    computed: {
        ...mapGetters(['gift'])
    },
    created() {
    	var loader = this.$loading.show();
        this.setGift({ uuid: this.uuid, shipping: this.shipping }).then(response => {
        	this.ready = true;
        	loader.hide();
            if (response.status == 0) {
                this.available = false;
                this.notAvailableMessage = this.gift.notAvailableMessage;
                
            }
            if (response.status == 2) {
            	setTimeout(() =>  {
                	this.$router.push({name:'conversations'});
                },5000);
                this.available = false;
                this.notAvailableMessage = this.gift.notAvailableMessage;
            }
        });
    },
    methods: {
        ...mapActions(['setGift'])
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.payment-page {
    max-width: 550px;
	@include media-breakpoint-up(sm) {
		padding-top:30px;
	}
	.borderError {
		margin-top:40px;
		border: $red solid 2px;
		padding:10px;
		border-radius: 8px;

	}
    .row>.details {
        padding: 0 15px;
        margin: 8px 15px;

        @include media-breakpoint-up(sm) {
            margin: 0;
          
        }

        border: 1px solid $formgrey;
        border-radius: 10px;

        .item {
            &.detail {
                height: 100px;
            }

            padding: 10px 0;
            position: relative;

            &:after {
                position: absolute;
                content: '';
                width: 92%;
                left: 4%;
                border-bottom: 1px solid $formgrey;
                bottom: 0;
            }

            &.total:after {
                border-bottom: none;
            }

            .col-5 {
                // margin-left:10px;
                height: 100%;

                .itemImage {
                    height: 80px;
                    background-position: center;
                    background-size: cover;
                    border-radius: 8px;
                }
            }

            .col-7 {
                height: 100%;
                padding: 5px 0;

                // display: flex;
                // align-items: center;
                // justify-content: center;
                // margin-right:10px;
                >div {
                    font-size: 16px;
                    font-family: $cat;
                    color: $grey;
                    line-height: 24px;
                    width: 100%;
                }

                >div.cause {
                    font-size: 14px;
                    font-family: $open-sans;
                    color: $lightgrey;
                    line-height: 20px;
                }

            }

            .col-6 {
                &.keys {
                    // display: flex;
                    align-items: center;
                    
                    >div {
                        color: #B8B8BF;
                        font-size: 12px;
                        line-height: 30px;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-family: $cat;
                        padding-top: 4px;
                        width: 100%;
                        position: relative;
                        .tax {
                        	left:105px;
                        }
                    }
                }

                &.vals {
                    align-items: center;

                    >div {
                        color: $bluetext;
                        font-size: 15px;
                        line-height: 30px;
                        font-family: $open-sans;
                        padding-top: 4px;
                        width: 100%;
                        text-align: right;
                    }
                }
            }
        }

        .total .col-6.vals,
        .total .col-6.keys>div {
            font-weight: 600;
            color: $bluetext;
        }
    }

    .row>.cardInfo {
        padding: 0 15px;
        // margin: 8px 15px;
    }
}

</style>
