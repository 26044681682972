import { getLocalUser } from "../../helpers/auth";
const user = getLocalUser();

export default {
    namespaced: true,
    state: {
        currentUser: user,
        isLoggedIn: !!user,
        loading: false,
        auth_error: null,
        tokenExpires: null
    },
    getters: {
        isLoading(state) {
            return state.loading;
        },
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        currentUser(state) {
            return state.currentUser;
        },
        authError(state) {
            return state.auth_error;
        },
        getCurrentUser(state) {
            return state.currentUser;
        },
        getTokenExpires(state) {
            return state.tokenExpires;
        }

    },
    mutations: {
        login(state) {
            state.loading = true;
            state.auth_error = null;
        },
        loginSuccess(state, payload) {
            state.auth_error = null;
            state.isLoggedIn = true;
            state.loading = false;
            state.currentUser = Object.assign({}, payload.user);
            localStorage.setItem("user", JSON.stringify(state.currentUser));
            // setAuthorization(payload.access_token);
        },
        updateAvatar : (state,payload) => {
        	state.currentUser.avatar_url = payload
        },
        updateToken(state, payload) {
        	if(payload.user){
        		state.currentUser = Object.assign({}, payload.user);
	            localStorage.setItem("user", JSON.stringify(state.currentUser));
	            
        	}else{
        		localStorage.removeItem("user");
	            state.isLoggedIn = false;
	            state.currentUser = null;
        	}
            
        },
        loginFailed(state, payload) {
            state.loading = false;
            state.auth_error = payload.error;
            localStorage.removeItem("user");
        },
        logout(state) {
            localStorage.removeItem("user");
            // setAuthorization(null);
            state.isLoggedIn = false;
            state.currentUser = null;
        },
        setUser(state, payload) {
            state.user.id = payload.id;
            state.user.name = payload.name;
        }
    },
    actions: {
        login(context) {
            context.commit("login");
        },
        updateUser(context) {
            return new Promise((res) => {
                axios.get('/current_user').then((response) => {
                    context.commit('setUser', response.data);
                    res();
                });
            });
        },
        logout(context) {
        	axios.get('/api/auth/logout').then(response => {
        		 context.commit("logout");
            	window.location = '/login';
        	});
           
        },
        refresh(context) {
            return new Promise((res, rej) => {
                axios.get('/api/auth/refresh').then(response => {
                    context.commit('updateToken', response.data);
                    context.dispatch('channel/setChannels',null,{root:true});
                    res(response);
                }, error => {
                    context.commit('logout');
                    rej(0);
                });
            });
        }
    }
}
