import { router } from "../../app.js"
export default {
    namespaced: true,
    state: {
        gift: null,
        sameUserGifts: null,
        gifts: [],
        categories: [],
        currentPage: 0,
        lastPage: 1,
        categoryGifts: [],
        causeGifts: []
    },
    mutations: {
        setGift(state, payload) {
            state.gift = payload;
        },
        setSameUserGifts(state, payload) {
            state.sameUserGifts = payload;
        },
        deleteGift(state, payload) {
            if (state.gifts.length) {
                for (var i = 0; i < state.gifts.length; i++) {
                    if (state.gifts[i].url_str == payload.url_str) {
                        state.gifts.splice(i, 1);
                        break;
                    }
                }
            }
            state.gift = null;
        },
        // setGifts(state, payload) {
        //     state.gifts = state.gifts.concat(payload.data);
        //     state.currentPage = payload.current_page;
        //     state.lastPage = payload.last_page;
        // },
        resetGifts: (state) => {
            state.lastPage = 1;
            state.gifts = [];
            state.currentPage = 0;
        },
        resetCauseGifts: (state, payload) => {
            if (null == payload) {
                state.causeGifts = [];
            } else {
                if (state.causeGifts.hasOwnProperty(payload)) {
                    delete state.causeGifts[payload];
                }
            }
        },
        resetCategoryGifts: (state, payload) => {
            if (null == payload) {
                state.categoryGifts = [];
            } else {
                if (state.categoryGifts.hasOwnProperty(payload)) {
                    delete state.categoryGifts[payload];
                }
            }
        },
        setFilteredGifts(state, payload) {

            if (state.gifts[payload.q] && state.gifts[payload.q].gifts && payload.page != 1) {
                state.gifts[payload.q].gifts = state.gifts[payload.q].gifts.concat(payload.gifts.data);
            } else {
                state.gifts[payload.q] = {};
                state.gifts[payload.q].gifts = payload.gifts.data;

            }
            state.gifts[payload.q].currentPage = payload.gifts.current_page;
            state.gifts[payload.q].lastPage = payload.gifts.last_page;
        },
        setCategoryGifts(state, payload) {
            if (state.categoryGifts[payload.urlStr].gifts) {
                state.categoryGifts[payload.urlStr].gifts = state.categoryGifts[payload.urlStr].gifts.concat(payload.gifts.data);
            } else {
                state.categoryGifts[payload.urlStr].gifts = payload.gifts.data;
            }
            state.categoryGifts[payload.urlStr].currentPage = payload.gifts.current_page;
            state.categoryGifts[payload.urlStr].lastPage = payload.gifts.last_page;
            state.categoryGifts[payload.urlStr].categoryName = payload.categoryName;
        },
        setCauseGifts(state, payload) {
            state.causeGifts[payload.urlStr].gifts = state.causeGifts[payload.urlStr].gifts.concat(payload.gifts.data);
            state.causeGifts[payload.urlStr].currentPage = payload.gifts.current_page;
            state.causeGifts[payload.urlStr].lastPage = payload.gifts.last_page;
        },
        deleteGiftPhoto(state, payload) {
            for (var i = 0; i < state.gifts.length; i++) {
                if (state.gifts[i].uuid == payload.gift_uuid) {
                    for (var j = 0; j < state.gifts[i].photos.length; j++) {
                        if (state.gifts[i].photos[j].uuid == payload.old) {
                            state.gifts[i].photos.splice(j, 1);
                            if (state.gifts[i].photos == 0) {
                                state.gifts[i].primary_photo = null;
                                state.gifts[i].primary_photo_uuid = null;
                            }
                            break;
                        }
                    }
                    break;
                }
            }
        },
        setPrimaryPhoto(state, payload) {
            if (payload.primary) {
                for (var i = 0; i < state.gifts.length; i++) {
                    if (state.gifts[i].uuid == payload.gift_uuid) {
                        state.gifts[i].primary_photo_uuid = payload.primary;
                        for (var j = 0; j < state.gifts[i].photos.length; j++) {
                            if (state.gifts[i].photos[j].uuid == payload.primary) {
                                state.gifts[i].primary_photo = state.gifts[i].photos[j];
                                state.gifts[i].primary_photo_uuid = state.gifts[i].photos[j].uuid;
                                break;
                            }
                        }
                        break;
                    }
                }
            }
        },
        setCategories: (state, payload) => {
            state.categories = payload;
        }

    },
    actions: {
        addNewGift({ commit, state }, payload) {
            return new Promise((res, rej) => {
                axios.post('/api/add_gift', payload)
                    .then((response) => {
                        commit('deleteGift', response.data.gift);
                        commit('setGift', response.data.gift);
                        commit('account/setGifts', response.data.user_gifts, { root: true });
                        commit('resetCauseGifts', response.data.gift.cause.url_str);
                        commit('resetCategoryGifts', response.data.gift.category.url_str);
                        commit('resetGifts');
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        updateGift({ commit, state }, payload) {
            return new Promise((res, rej) => {
                axios.post('/api/update_gift/' + payload.uuid, payload)
                    .then((response) => {
                        commit('deleteGift', response.data.gift);
                        commit('setGift', response.data.gift);
                        commit('account/setGifts', response.data.user_gifts, { root: true });
                        commit('resetCauseGifts', response.data.gift.cause.url_str);
                        commit('resetCategoryGifts', response.data.gift.category.url_str);
                        commit('resetGifts');
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        assignBuyer(context, payload) {
            return new Promise((res, rej) => {
                axios.post('/api/assign_holder', payload)
                    .then((response) => {

                        context.commit('setGift', response.data.gift);
                        context.commit('channel/setChannels', response.data.channels, { root: true });
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        setGift({ commit, state }, payload) {
            return new Promise((res, rej) => {
                axios.get('/api/gift/' + payload)
                    .then(response => {
                        // commit('deleteGift', response.data);
                        commit('setGift', response.data.gift);
                        commit('setSameUserGifts', response.data.sameUserGifts);
                        res(response);
                    }, error => {
                        console.log('TODO error handling');
                        rej(error);
                    })
            });
        },
        setGiftByUuid({ commit, state }, payload) {
            return new Promise((res, rej) => {
                axios.get('/api/gift_by_uuid/' + payload)
                    .then(response => {
                        // commit('deleteGift', response.data);
                        commit('setGift', response.data);
                        res(response);
                    }, error => {
                        console.log('TODO error handling');
                        rej(error);
                    })
            });
        },
        setGifts: async (context, payload) => {
            var page = 1;
            if (payload) {
                var str = payload.replace(/=/g, '|');
                str = str.replace(/&/g, '--');
                str = str.replace('%20', '');
                str = str.replace('?', '');
            } else {
                var str = 'd'; // empty just default unfiltered
            }

            return new Promise((res, rej) => {
                if ((context.state.gifts[str] == undefined || context.state.gifts[str].currentPage < context.state.gifts[str].lastPage)) {
                    if (context.state.gifts[str] == undefined) {
                        context.state.gifts[str] = {};
                        context.state.gifts[str].gifts = [];
                        context.state.gifts[str].currentPage = 0;
                        context.state.gifts[str].lastPage = 1;

                    }
                    if (context.state.gifts[str].currentPage) {
                    	
                        page = context.state.gifts[str].currentPage + 1;
                       
                    }

                    axios.get('/api/gifts?page=' + page + '&' + payload.substring(1))
                        .then(response => {
                            context.commit('setFilteredGifts', response.data);
                            res(response);
                        }, error => {
                            console.log('TODO error handling');
                            rej(error);
                        });
                } else {
                    //handle empty repeater
                    res({ data: { q: str, gifts: { data: ['s'] } } });
                }

            });
        },
        setCategoryGifts: async (context, payload) => {
            if (!context.state.categoryGifts[payload]) {
                context.state.categoryGifts[payload] = {};
                context.state.categoryGifts[payload].gifts = [];
                context.state.categoryGifts[payload].currentPage = 0;
                context.state.categoryGifts[payload].lastPage = 1;
            }
            if (context.state.categoryGifts[payload].currentPage != context.state.categoryGifts[payload].lastPage) {
                return new Promise((res, rej) => {
                    var page = context.state.categoryGifts[payload].currentPage + 1;
                    axios.get('/api/gifts_in_category/' + payload + '?page=' + page)
                        .then(response => {
                            context.commit('setCategoryGifts', response.data);
                            res(response);
                        }, error => {
                            console.log('TODO error handling');
                            rej(error);
                        });

                });
            }
        },
        setCauseGifts: (context, payload) => {
            if (!context.state.causeGifts[payload]) {
                context.state.causeGifts[payload] = {};
                context.state.causeGifts[payload].gifts = [];
                context.state.causeGifts[payload].currentPage = 0;
                context.state.causeGifts[payload].lastPage = 1;
            }
            if (context.state.causeGifts[payload].currentPage != context.state.causeGifts[payload].lastPage) {
                return new Promise((res, rej) => {
                    var page = context.state.causeGifts[payload].currentPage + 1;
                    axios.get('/api/gifts_in_cause/' + payload + '?page=' + page)
                        .then(response => {
                            context.commit('setCauseGifts', response.data);
                            res(response);
                        }, error => {
                            console.log('TODO error handling');
                            rej(error);
                        });

                });
            }
        },
        deleteGiftPhoto({ commit, state }, payload) {
            return new Promise((res, rej) => {
                axios.delete('/api/photo', { params: { uuid: payload } })
                    .then(response => {
                        commit('deleteGiftPhoto', response.data);
                        commit('setPrimaryPhoto', response.data);
                        //some more work here to verify state is correctly updated.
                        //Not sure primary is correct
                        res(response);
                    }, error => {
                        rej(error);
                    });
            });
        },
        setCategories: (context, payload) => {
            return new Promise((res, rej) => {
                axios.get('/api/gift_categories')
                    .then(response => {
                        context.commit('setCategories', response.data);
                        res(response);
                    }, error => {
                        router.push('/login');
                        rej(error);
                    })
            });
        },
    },
    getters: {
        gift: (state) => {
            return state.gift;
        },
        getGift(state, getters) {
            return (urlStr) => {
                var gift = state.gifts.find(gift => gift.url_str == urlStr);
                if (gift) {
                    return gift;
                }
            }
        },
        getGiftByUuid(state) {
            return (uuid) => {
                var gift = state.gifts.find(gifts => gifts.uuid == uuid);
                if (gift) {
                    return gift;
                }
            }
        },
        categoryGifts: (state) => {
            return (urlStr) => {
                if (state.categoryGifts[urlStr]) {
                    return state.categoryGifts[urlStr];
                } else {
                    return false;
                }

            }
        },
        causeGifts: (state) => {
            return (urlStr) => {
                if (state.causeGifts[urlStr]) {
                    return state.causeGifts[urlStr];
                } else {
                    return false;
                }

            }
        },
        hasChannel: (state) => {
            return state.gift.channels && (state.gift.channels.length > 0);
        },
        owned: (state, getters, rootState) => {
            if (null == rootState.user.currentUser) {
                return false;
            }
            return state.gift.user_id === rootState.user.currentUser.id;
        },
        lastFourgifts: (state) => {
            return state.gifts.slice(Math.max(state.gifts.length - 4, 0));
        },
        categories: (state) => {
            return state.categories;
        }
    }
}
