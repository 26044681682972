export default {
    namespaced: true,
    state: {
        channels: null,
        unread: ''
    },
    mutations: {
        setMessage: (state, message) => {
            state.channels.forEach((channel, index) => {
                if (channel.id === message.channel_id) {
                    state.channels[index].messages.push(message);
                }
            });
        },
        setChannels: (state, payload) => {
            state.channels = payload;
        },
        setUnread: (state, payload) => {
            state.unread = payload;
        },
    },
    actions: {
    	// all actions here get the updated channels as their response
    	//  a simple way to get latest messages without resorting to Pusher at this time.
        setChannels: (context) => {
            return new Promise((res, rej) => {
                axios.get('/api/user_channels')
                    .then(response => {
                        context.commit('setChannels', response.data.channels);
                        context.commit('setUnread', response.data.unread);
                        res(response)
                    }, error => {
                        rej(error)
                    })
            })
        },
        markMessagesAsRead: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/mark_messages_as_read', payload)
                    .then(response => {
                    	if(undefined == response.data.error) {
                        	context.commit('setChannels', response.data.channels);
                        	context.commit('setUnread', response.data.unread);
                        }
                        res(response)
                    }, error => {
                        res(error)
                    })
            });
        },
        sendMessage: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/send_gift_message', payload)
                    .then(response => {
                        context.commit('setChannels', response.data.channels);
                        context.commit('setUnread', response.data.unread);
                        res(response)
                    }, error => {
                        res(error)
                    })
            })
        },
        sendHoldRequest: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/send_hold_request', payload)
                    .then(response => {
                        context.commit('setChannels', response.data.channels);
                        context.commit('setUnread', response.data.unread);
                        res(response)
                    }, error => {
                        res(error)
                    })
            })
        },
        createChannel: (context,payload) => {
        	return new Promise((res, rej) => {
                axios.post('/api/create_channel', payload)
                    .then(response => {
                        context.commit('setChannels', response.data.channels);
                        context.commit('setUnread', response.data.unread);
                        res(response);
                    }, error => {
                        res(error)
                    })
            })
        }
    },
    getters: {
        channels(state) {
        	if(state.channels) {
        		return state.channels.sort(function(a, b) {
	                return new Date(b.messages[b.messages.length - 1].updated_at) - new Date(a.messages[a.messages.length - 1].updated_at);
	            });
        	}
            
        },
        giftChannelId: (state) => (id) => {
            if (state.channels.length > 0) {
                var data = state.channels.find(channel => channel.gift_id === id);
                if (data) {
                    return data.id;
                }
            }
        },
        channelbyGiftUuid: (state) => (uuid) => {
            if (state.channels.length > 0) {
                var data = state.channels.find(channel => channel.gift.uuid ===uuid);
                if (data) {
                    return data;
                }
            }
        },
        channelByUuid: (state) => (uuid) => {
            if (state.channels.length > 0) {
                var data = state.channels.find(channel => channel.uuid === uuid);
                if (data) {
                    return data;
                }
            }
        },
        getChannelMessages: (state) => (id) => {
            if (state.channels.length > 0) {
                var data = state.channels.find(channel => channel.id == id);
                if (data) {
                    return data;
                }
            }
        }
    }
}
