<template>
    <div class="container" v-if="page">
        <div id="wpPostEmbed" class="page" v-html="page"></div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapActions } = createNamespacedHelpers('wp');
export default {
    name: "page",
    props: ['slug'],
    data() {
        return {
            page: null
        }
    },
    computed: {
        ...mapState({ pages: state => state.wp.pages })
    },
    watch: {
        slug(value) {
            this.init();
        }
    },
    mounted() {
        if (window.location.search == '?u=1') {
            let loader = this.$loading.show();
            this.refresh('pages').then(
                response => {
                    loader.hide();
                    this.init();
                }, error => {
                    loader.hide();
                    this.mustLogin();
                });
        } else {
            this.init();
        }
    },
    methods: {
        ...mapActions(['setPage', 'refresh']),
        init() {
            if (!this.pages[this.slug]) {
                this.retrieve();
            } else {
                this.display();
            }
        },
        display() {
            this.page = '<h1>' + this.pages[this.slug].title + '</h1>' + this.pages[this.slug].content;
            this.wpHead(this.pages[this.slug].yoast);
            setTimeout(() => {
            	if($("#faq")) {
            		$("#wpPostEmbed").addClass('faq');
            		$("#faq").on("click", "button", function() {
                    $(this).toggleClass('active');
                    var next = $(this).next();
                    if (next.is(":visible")) {
                        next.hide();
                    } else {
                        next.show();
                    }
                })
            	}
                
            }, 10);

        },
        retrieve() {
            let loader = this.$loading.show();
            this.setPage({ "s": this.slug, "p": 'page' }).then(response => {
                if (response.slug) {
                    loader.hide();
                    this.display();
                } else {
                    loader.hide();
                    this.$router.push('/404');
                }
            }, error => {
                loader.hide();
                this.$router.push('/404');
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

// See Blog.vue
//special for FAQ
#wpPostEmbed.faq {
	max-width: 750px;
}
#faq {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .howitworks {
        width: 100%;

        >div {
            border-radius: 10px 10px 12px 12px;
            box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
            background-color: #fff;
            padding: 0 18px;
            width: 100%;
            
            margin-bottom: 20px;

            p {
                color: $textcolor;
                display: none;
                padding-bottom: 28px;
            }
        }
    }

    button {
        cursor: pointer;
        font-size: 18px;
        background-color: #fff;
        padding: 10px 0;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        font-family: $cat;
        color: $bluetext;

        span {
            float: right;
            padding-right: 5px;
        }

        span::before {
            content: "+";
            font-size: 22px;
            line-height: 22px;
        }

        &.active {
            span::before {
                content: "\2014";

            }
        }
    }



}

</style>
