<template>
    <div class="ct container forgot logins">
        <titletag :title="$t('nav.forgot-p')"></titletag>
        <div class="login row justify-content-center">
            <div class="card">
                <div class="card-head">{{$t('nav.forgot-p')}}</div>
                <div class="card-body">
                    <p>{{$t('login.forgot-noproblem')}}</p>
                    <form autocomplete="off" @submit.prevent="requestResetPassword" method="post">
                        <div class="form-group row">
                            <i class="fas fa-envelope"></i>
                            <input type="email" v-model="email" class="form-control" :placeholder="$t('login.email')" autocomplete="username">
                        </div>
                        <div class="form-group row">
                            <input class="submit" type="submit" :value="$t('login.send')">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            email: null,
            has_error: false,
        }
    },
    methods: {
        requestResetPassword() {
            if (this.validEmail(this.email)) {
                let loader = this.$loading.show();
                axios.post("/api/auth/reset-password", { email: this.email }).then(result => {
                    this.flashMessage({ m: result.data.message });
                    loader.hide();
                }, error => {
                    this.flashMessage({ m: error.message, e: 1, d: 3000 });
                    loader.hide();
                });
            } else {
                this.flashMessage({ m: this.$t('messages.bademail'), e: 1 });
            }
        }
    }
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.forgot .login {
    .card {
        .card-body {
            form {
                .form-group {
                    input {
                        border-radius: 25px;
                    }
                }
            }
        }
    }
}

</style>
