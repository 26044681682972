<template>
    <div class="ct container forms shipping">
        <h2 class="title">Shipping quote</h2>
        <h3 v-if="!addressVerified">Please verify or update your address</h3>
        <shipping-address :saveText="`Verify shipping address`" :validate="1" v-if="!addressVerified" @setVerified="setAddressVerified"></shipping-address>
        <div v-if="quote" class="container quotes">
            <div class="item form-group row">
                <div class="container">
                <div><img src="https://shippo-static.s3.amazonaws.com/providers/200/USPS.png"><span>USPS {{quote.service_level}}</span><span class="price">${{quote.amount.toFixed(2)}}</span></div>
                </div>
            </div>
            <div class="item form-group row">
                <div>
                    <p>{{quote.duration_terms}}</p>
                </div>
            </div>
            <div class="item form-group row">
                <label>
                    <input type="checkbox" v-model="termsAccepted" v-bind:value="1" class="form-control">
                    <span>Agree to FunditOn <a href="/p/shipping-policy" target="_blank">shipping terms</a></span>
                </label>
            </div>
            <div class="form-group row btns">
                <a @click="cancelShipping" class="btn btn-primary simple">No thanks - Cancel</a>
                <a @click="sendToCheckout" class="btn btn-primary">Save shipping and Checkout</a>
            </div>
        </div>
    </div>
</template>
<script>
import Navigation from '../nav/Navigation'
import ShippingAddress from '../account/Address';
export default {
    props: ['uuid'],
    components: {
        Navigation,
        ShippingAddress
    },
    data() {
        return {
            addressVerified: false,
            showAddress: true,
            quote: null,
            parcel: null,
            picked: null,
            termsAccepted: false,
        }
    },
    watch: {
        addressVerified: function() {
            if (this.addressVerified == 1) {
                //get the quotes form the server
                this.getShippingQuotes();
            }
        }
    },
    mounted() {},
    methods: {
        setAddressVerified(value) {
            this.addressVerified = value;
        },
        getShippingQuotes() {
            let loader = this.$loading.show();
            axios.get('/api/get_shipping_quotes/' + this.uuid).then(response => { // always returns a single quote    
                loader.hide();
                this.quote = response.data.quote.rate;
                this.parcel = response.data.parcel;
            })
        },
        sendToCheckout() {
            this.picked = this.quote.object_id;
            if (!this.termsAccepted) {
                this.flashMessage({ m: "Please agree to shipping terms", e: 1, d: 1500 });
            }
            if (this.picked && this.termsAccepted && this.uuid) {
                this.$router.push({ name: 'checkout', params: { uuid: this.uuid, shipping: this.picked } });
            } else {
                console.log('TODO error handling');
            }
        },
        cancelShipping() {
            //send them backto the conversation
            this.$router.push({ name: 'conversations', params: { 'giftUuid': this.uuid } });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.shipping {
    h3 {
        font-size: 20px;
        font-family: $cat;
        color: $bluetext;
        padding-bottom:10px;
    }

    .quotes {
        max-width: 600px;
        justify-content: center;

        label {
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            align-items: center;

            input[type="checkbox"].form-control {
                height: 17px;
                width: 17px;
                display: inline;
                margin: 0;
                margin-right: 10px;
                padding-top: 3px;
            }

            a {
                color: $buttonblue;
            }
        }


        .item {
            padding: 15px;
            color: $bluetext;
            font-family: $cat;
            font-size: 16px;

            .container {
                img {
                    max-width: 60px;
                    height: auto;
                }

                span {
                    padding: 5px;

                    &.price {
                        font-weight: 600;
                        font-family: $open-sans;
                    }
                }

                p {
                    font-family: $open-sans;
                }
            }
        }
        .btns {
        	display:flex;
        	padding: 0 5px;
        	a {
        		margin:5px;
        		max-width: 93%;

        		@include media-breakpoint-up(sm) {
        			max-width: 45%;
        		}
        	}
        }
        // a.btn-primary {
        //     margin-bottom: 10px;

        //     @include media-breakpoint-up(sm) {
        //         min-width: 120px;
        //         max-width: 44%;
        //     }
        // }
    }
}

</style>
