<template>
    <div id="steps">
        <ul>
            <li class="f" @click="goToUrl('f')">
                <div><span v-html="fState"></span></div>
                <div>Start a Cause</div>
            </li>
            <li class="s" @click="goToUrl('s')">
                <div><span v-html="sState"></span></div>
                <div>Add a Photo</div>
            </li>
            <!-- <li class="t" @click="goToUrl('t')">
                <div><span v-html="tState"></span></div></span>
            </li> -->
            <li class="fo" @click="goToUrl('fo')">
                <div><span v-html="foState"></span></div>
                <div>Publish &amp; Share</div>
            </li>
        </ul>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('cause');
export default {
    name: 'steps',
    props: ['uuid', 'active'],
    data() {
        return {}
    },
    watch: {
        active(value) {
            this.setState();
        }
    },
    computed: {
        ...mapGetters(['getcauseByUuid']),
        cause() {
            return this.getcauseByUuid(this.uuid);
        },
        fState() {
            if (this.uuid) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 1;
            }
        },
        sState() {
            if (this.cause && this.cause.primary_photo_id) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 2;
            }
        },
        foState() {
            if (this.cause && this.cause.published) {
                return '<i class="fa fa-check" aria-hidden="true"></i>';
            } else {
                return 3;
            }
        }

    },
    mounted() {
        this.setState();
    },
    methods: {
        goToUrl(el) {
            switch (el) {
                case "f":
                    if (this.uuid) {
                        this.$router.push({ name: 'editcause', params: { uuid: this.uuid } });
                    }
                    break;
                case "s":
                    if (this.uuid) {
                        this.$router.push({ name: 'editcausephoto', params: { uuid: this.uuid } });
                    }
                    break;
                case "fo":
                    if (this.uuid && this.cause && this.cause.primary_photo_id || this.cause.published) {
                        this.$router.push({ name: 'causepublishshare', params: { uuid: this.uuid, v: "fo"} });
                    }
                    break;
            }
        },
        setState() {
            var s = $('#steps');
            $('li', s).removeClass('active');
            switch (this.active) {
                case "f":
                    $('.f ', s).addClass('active');
                    break;
                case "s":
                    $('.f , .s ', s).addClass('active');
                    break;
                case "fo":
                case "fi":
                    $('.f ,.s ,.t ,.fo ', s).addClass('active');
                    break;
            }
        }
    }
}
// <i class="fa fa-check" aria-hidden="true">

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#steps, #g-steps {
    width: 100%;

    ul {
        list-style: none;
        width: 100%;
        padding: 15px;
        margin-bottom: 0;
    }

    li {
        display: inline-block;
        width: 32%;
        text-align: center;
        cursor: pointer;
        &.active {
                div {
                	color: $buttonblue;
                }
                span {
                	color: $buttonblue;
                	border-color: $buttonblue;
                }
            }
        div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $open-sans;
            color: #D2D1E5;
            font-size:13px;
            font-weight: 600;
            &:last-child {
            	padding-top:5px;
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            border: 1px solid #D2D1E5;
            color: #D2D1E5;
            font-family: $cat;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;

            i {
                font-size: 0.7em;
            }

            

        }
    }
}

</style>
