<template>
    <div class="container" v-if="categoryName">
        <titletag :title="`${categoryName} items for sale`"></titletag>
        <h2 class="title">{{categoryName}}</h2>
        <div class="categoryGifts row" v-if="gifts">
            <template v-if="gifts.length">
                <giftitem v-for="item in gifts" :key="item.id" :id="item.id" :item="item"></giftitem>
            </template>
            <template v-else>
                <p>Looks like there are no items available in this category at this time</p>
            </template>
        </div>
    </div>
</template>
<script>
import Giftitem from './Item'
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');
export default {
    props: ['urlStr'],
    data() {
        return {
            gifts: null,
            categoryName: null,
            a: false
        }
    },
    watch: {
        urlStr(value) {
            this.init();
        }
    },
    components: {
        Giftitem
    },
    name: "giftcategory",
    computed: {
        ...mapState({ categoryGifts: state => state.gift.categoryGifts })
    },
    methods: {
        ...mapActions(['setCategoryGifts']),
        init() {
            if (!this.categoryGifts[this.urlStr]) {
                let loader = this.$loading.show();
                this.setCategoryGifts(this.urlStr).then(response => {
                    this.gifts = this.categoryGifts[this.urlStr].gifts;
                    this.categoryName = this.categoryGifts[this.urlStr].categoryName;
                    loader.hide();
                    if (this.gifts.length) {
                        this.initInfinite();
                    }
                });
            } else {
                this.gifts = this.categoryGifts[this.urlStr].gifts;
                this.categoryName = this.categoryGifts[this.urlStr].categoryName;
            }
        },
        initInfinite() {
            window.addEventListener("scroll", this.scrollListen);
        },
        scrollListen(e) {
            var s = window.scrollY;
            var d = $(document).height() - $(window).height();
            var p = (s / d) * 100;
            if (p > 90) {
                if (!this.a) {
                    this.a = true;
                    this.setCategoryGifts(this.urlStr).then(response => {
                        this.gifts = this.categoryGifts[this.urlStr].gifts;
                        this.categoryName = this.categoryGifts[this.urlStr].categoryName;
                        this.a = false;
                    });
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollListen);
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.categoryGifts p {
    padding: 15px;
}

</style>
