<template>
    <div class="col-sm-9 ct forms" id="profile">
        <titletag title="Profile"></titletag>
        <!-- <div class="row">
            <h2 class="title">Profile</h2>
        </div> -->
        <profilephoto></profilephoto>
        <personal :profile="profile" v-if="profile"></personal>
        <h2 class="title">Address</h2>
        <profileaddress :saveText="`Save address`" validate="1" @setVerified="setAddressVerified"></profileaddress>
        
        <changepassword v-if="profile && !profile.social_id"></changepassword>
        <router-link :to="`/account`" class="btn back">Go Back</router-link>
    </div>
</template>
<script>
import Profilephoto from './Profilephoto';
import Profileaddress from './Address';
import Changepassword from './Changepassword';
import Personal from './Personal';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('account');
export default {
    props: [],
    name: 'profile',
    data() {
        return {}
    },
    components: {
        Profileaddress,
        Changepassword,
        Profilephoto,
        Personal
    },
    computed: {
        ...mapGetters(['profile', 'address']),
    },
    mounted() {
    	this.changeButtons();
    },
    methods: {
        setAddressVerified() {
            this.flashMessage({ m: "Address Updated" });
        },
        changeButtons() {
        	setTimeout(() => {
        		$('#profile .btn-primary').each(function() {
        		$(this).removeClass('btn-primary').removeClass('btn').addClass('secondaryButton');
        	})
        	},100);
        	
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#profile {
	a.secondaryButton {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
	}
}
</style>