<template>
    <div class="ct container forms contactus">
    	<titletag title="Contact Funditon"></titletag>
    	<div class="container">
        <form>
        	<h2>Contact us</h2>
        	<p>Your feedback is much appreciated. Please give as much detail as you like so we can assist your as well as possible.</p>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <input v-model="name" type="text" class="form-control" id="name" name="name" placeholder="Your name" required>
                            <label class="magic" for="name">Name</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <input v-model="email" type="email" class="form-control" id="contactEmail" name="email" placeholder="Your email" required>
                            <label class="magic" for="contactEmail">Email</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <select @change="selectChange" v-model="topic" class="form-control" id="contactTopic" name="topic" required>
                                <option selected="selected" value="">Choose a topic</option>
                                <option value="question">Question</option>
                                <option value="suggest">Suggestion</option>
                                <option value="bug">Website Bug</option>
                                <option value="other">Everything else</option>
                            </select>
                            <label class="magic" for="contactTopic">Topic</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <textarea rows="4" v-model="message" name="message" id="contactMessage" class="form-control" placeholder="Please type your message. The more details the better we can help you." required></textarea>
                            <label class="magic" for="contactMessage">Message</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <a @click.prevent="checkForm" class="btn btn-primary">Send message</a>
                        </div>
                    </div>
              
        </form>
        </div>
    </div>
</template>
<script>
export default {
    name: "contactus",
    props: [],
    data() {
        return {
            errors: [],
            name: null,
            email: null,
            topic: '',
            message: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.$store.getters['account/profile'] && this.$store.getters['account/profile'].name) {
                this.name = this.$store.getters['account/profile'].name;
                this.email = this.$store.getters['account/profile'].email;
            } else {
                if (this.$store.getters['user/currentUser'] && this.$store.getters['user/currentUser'].name) {
                    this.name = this.$store.getters['user/currentUser'].name;
                    this.email = this.$store.getters['user/currentUser'].email;
                }
            }
            if(this.$route.query.email) {
            	this.email = this.$route.query.email;
            }
            if(this.$route.query.name) {
            	this.name = this.$route.query.name;
            }
            setTimeout(this.initLabels, 200);
        },
        resetForm() {
            this.message = null;
            this.topic = '';
        },
        selectChange(e) {
            if ('bug' == $('#contactTopic').val()) {
                $('#contactMessage').attr('placeholder', 'Please give as much detail as possible. What you saw and what you expected to see. The url where you saw this. Browser, device etc is all very helpful');
            } else {
                $('#contactMessage').attr('placeholder', 'Please type your message. The more details the better we can help you.');
            }
        },
        saveForm() {
            return new Promise((resolve) => {
                axios.post('api/save_contact_us', {
                        name: this.name,
                        email: this.email,
                        message: this.message,
                        topic: this.topic
                    })
                    .then((response) => {
                        if (response.data.status == 1) {
                            this.flashMessage({ m: "Your feedback is appreciated. It will help us improve the site for everyone. We will get back to you soon.", d: 3000 });
                            this.resetForm()
                        }

                        resolve();
                    });
            });

        },
        checkForm() {
            if (this.name && this.email && this.message && this.topic && this.validEmail(this.email)) {
                this.saveForm();
            }
            if (!this.name) {
                this.scrollToError('#name');
                this.flashMessage({ m: 'Your name is required', e: 1 });
                return false;
            }

            if (!this.email) {
                this.scrollToError('#contactEmail');
                this.flashMessage({ m: 'Your email is required', e: 1 });
                return false;
            }

            if (!this.validEmail(this.email)) {
                this.scrollToError('#contactEmail');
                this.flashMessage({ m: 'Please check email formatted correctly ', e: 1 });
                return false;
            }
            if (!this.topic) {
                this.scrollToError('#contactTopic');
                this.flashMessage({ m: 'Please choose a topic', e: 1 });
                return false;
            }
            if (!this.message) {
                this.scrollToError('#contactMessage');
                this.flashMessage({ m: 'Please add a message', e: 1 });
                return false;
            }

        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.contactus.container {
    width: 100%;
    text-align: center;
    max-width: 715px;
    p {
    	padding:5px 0;
    }
}

</style>
