var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ct forms container",attrs:{"id":"ptotoday"}},[_c('titletag',{attrs:{"title":"Raising money for schools through upcycling and reusing everyday items"}}),_vm._v(" "),_c('section',{staticClass:"one"},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"contain form-group"},[_c('h1',[_vm._v("Raise Money for Your School. It’s Simple, Easy & Free")]),_vm._v(" "),_c('p',[_vm._v("FunditOn is a free-to-use fundraising marketplace where your community raises funds for your cause by selling their goods and services. Proceeds go directly to your cause.")]),_vm._v(" "),_c('div',[_c('router-link',{staticClass:"btn-primary",attrs:{"to":"/cause/new"}},[_vm._v("Register and Start Now")])],1)])]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('different'),_vm._v(" "),_c('div',{staticClass:"form-group centered"},[_c('router-link',{staticClass:"secondaryButton rev",attrs:{"to":"/cause/new"}},[_vm._v("Start your Fundraiser")])],1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('section',{staticClass:"six"},[_c('div',[_c('h2',[_vm._v("Raise Funds Effortlessly")]),_vm._v(" "),_c('p',[_vm._v("Simply start your cause. Share it in your own way and let the familiar process of selling unused stuff support your school.")]),_vm._v(" "),_c('p',[_vm._v("You can also process cash donations.")]),_vm._v(" "),_c('p',[_vm._v("Ready to get started? Sign up today and and join our mission to make fundraising accessible to everyone.")]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('router-link',{staticClass:"btn-primary",attrs:{"to":"/cause/new"}},[_vm._v("Start your cause")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r"},[_c('div',{staticClass:"imgContain"},[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/tree.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"two"},[_c('div',[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/arrow.png"}}),_vm._v(" "),_c('h2',[_vm._v("Connect your Donors to the Community")]),_vm._v(" "),_c('p',[_vm._v("Your families can clean out their garages helping each other and your school!!")]),_vm._v(" "),_c('p',[_vm._v("Set up is super easy. Then your cause runs itself!")]),_vm._v(" "),_c('p',[_vm._v("Just share your cause page and watch your community connect and make money for you.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"three bubbles"},[_c('div',{staticClass:"r"},[_c('div',{staticClass:"blurred"}),_vm._v(" "),_c('div',{staticClass:"imgContain"},[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/flowers.png"}})])]),_vm._v(" "),_c('div',{staticClass:"l"},[_c('div',{staticClass:"contain"},[_c('h2',[_vm._v("Ever struggled to engage your community?")]),_vm._v(" "),_c('p',[_vm._v("Bring your community together organically through re-selling.")]),_vm._v(" "),_c('p',[_vm._v("By selling to each other your families meet other families from your school.")]),_vm._v(" "),_c('p',[_vm._v("Benefit the environment by upcycling and repurposing.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"four bubbles"},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"blurred"}),_vm._v(" "),_c('div',{staticClass:"imgContain"},[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/with_book.png"}})])]),_vm._v(" "),_c('div',{staticClass:"r"},[_c('div',{staticClass:"contain"},[_c('h2',[_vm._v("Add some Fun to School Fundraising")]),_vm._v(" "),_c('p',[_vm._v("All that sports equipment that is too small can be resold with the proceeds going to your school(a few times!) ")]),_vm._v(" "),_c('p',[_vm._v("Growing families have a lot they need and that they no longer need. They will love the deals and being able to provide for someone in your school.")]),_vm._v(" "),_c('p',[_vm._v("All you have to do is set up a cause and share it in your network!")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"five bubbles"},[_c('div',{staticClass:"r"},[_c('div',{staticClass:"blurred"}),_vm._v(" "),_c('div',{staticClass:"imgContain"},[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/red_hair.png"}})])]),_vm._v(" "),_c('div',{staticClass:"l"},[_c('div',{staticClass:"contain"},[_c('h2',[_vm._v("Effortlessly get Paid")]),_vm._v(" "),_c('p',[_vm._v("We deposit all funds for your cause directly into your bank.")]),_vm._v(" "),_c('p',[_vm._v("We do not take a platform fee "),_c('strong',[_vm._v("ever")]),_vm._v(" for causes started before May 15th 2022")]),_vm._v(" "),_c('p',[_vm._v("Set up receiving your funds in less than 3 minutes.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"four bubbles"},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"blurred"}),_vm._v(" "),_c('div',{staticClass:"imgContain"},[_c('img',{attrs:{"src":"https://d1812kujpqokfk.cloudfront.net/assets/pto/greenshirt.png"}})])]),_vm._v(" "),_c('div',{staticClass:"r"},[_c('div',{staticClass:"contain"},[_c('h2',[_vm._v("Sign Up & Start a Fundraiser for Free")]),_vm._v(" "),_c('p',[_vm._v("FunditOn is a free-to-use platform where you can easily sign up and set up a fundraiser on behalf of your school.")]),_vm._v(" "),_c('p',[_vm._v("Everyone wins as your school community shares those items they no longer need.")]),_vm._v(" "),_c('p',[_vm._v("Use it as much as you like, as long as you like, for free.")])])])])
}]

export { render, staticRenderFns }