<template>
    <div class="col-sm-9" id="accountConversations" v-if="channels">
        <titletag title="Messages"></titletag>
        <h2 v-if="channels && !activeUuid" class="title">Messages <i class="fa fa-refresh" aria-hidden="true" @click="refreshMessages"></i></h2>
        <template v-if="channels.length">
            <div class="container" v-for="channel in channels" v-if="channel.messages.length && !activeUuid">
                <router-link :to="`/account/conversations/${channel.gift.uuid}/${channel.uuid}`">
                    <div class="conversations" :class="channelLastMessageClass(channel.messages[channel.messages.length - 1])">
                        <div class="avatar justify-content-center" :style="{backgroundImage:`url(${getCloudFrontUrl() + channel.gift.primary_photo.path_thumb})`}"><img class="userIdenticon messages" :src="channelUser(channel).avatar_url ? channelUser(channel).avatar_url : getCloudFrontUrl() + 'identicons/' + channelUser(channel).identicon"></div>
                        <div class="center">
                            <div class="userName">{{channelUser(channel).name}}</div>
                            <div class="lastMessage">{{channel.messages[channel.messages.length - 1].message}}</div>
                        </div>
                        <div class="info">
                            <div class="date">{{humanDate(channel.messages[channel.messages.length - 1].created_at)}}</div>
                            <div class="star"><i class="fas fa-check-double"></i></div>
                        </div>
                    </div>
                </router-link>
            </div>
            <conversation v-if="activeUuid" :channel="activeChannel" :key="activeKey" @refreshMessages="refreshMessages" @reloadMessages="reloadMessages" @setActive="setActive"></conversation>
        </template>
        <template v-else>
            <div class="empty">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/mssgs.svg">
                <p class="nothing">You do not have any messages yet. Once you start a conversation with a seller your messages will appear here.</p>
            </div>
        </template>
        <!-- <router-link :to="`/account`" class="btn back">Go Back</router-link> -->
    </div>
</template>
<script>
import Conversation from './Conversation'
import { createNamespacedHelpers } from 'vuex';
import { mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('channel');
export default {
    props: ['giftUuid', 'channelUuid'], //gift urlStr
    name: 'conversations',
    data() {
        return {
            activeUuid: null,
            activeChannel: null,
            activeKey: null,
            d: false

        }
    },
    watch: {
        channelUuid(value) {
            this.init();
        }
    },
    computed: {
        ...mapState({ channels: state => state.channel.channels }),
        ...mapGetters(['channelByUuid', 'channelbyGiftUuid']),
        ...mapState({ gift: state => state.gift.gift }),
        loggedInId() {
            return null != this.$store.getters['user/currentUser'] ? this.$store.getters['user/currentUser'].id : false;
        },
    },
    components: {
        Conversation
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!Array.isArray(this.channels)) {
                this.$store.dispatch('channel/setChannels').then(response => {
                    this.channelsLoaded();
                })
            } else {
                this.channelsLoaded();
            }
        },
        channelsLoaded() {
            // find our channel otherwise create it
            // first check if we have a channel uuid in the url. means channel exists
            if (this.channelUuid) {
                this.activeChannel = this.channelByUuid(this.channelUuid);
                this.activeUuid = this.channelUuid;
                this.activeKey = this.activeUuid
            } else if (this.giftUuid) {
                var channel = this.channelbyGiftUuid(this.giftUuid);
                if (channel && channel.uuid) {
                    // push to router
                    this.$router.replace({ name: 'conversations', params: { 'giftUuid': this.giftUuid, 'channelUuid': channel.uuid } });
                } else {
                    // create the channel for this gift and user
                    this.$store.dispatch('channel/createChannel', { 'uuid': this.giftUuid }).then(response => {
                        if (response.data.status) { // will be 0 if user is gift owner
                            var channel = this.channelbyGiftUuid(this.giftUuid);
                            if (channel && channel.uuid) {
                                this.$router.replace({ name: 'conversations', params: { 'giftUuid': this.giftUuid, 'channelUuid': channel.uuid } });
                            }
                        }
                    });
                }
            } else {
                this.activeUuid = null;
                this.activeChannel = null;
            }
        },
        setActive(giftUuid, channelUuid) {
            this.activeUuid = channelUuid;
            this.activeKey = this.activeUuid;
            this.activeChannel = this.channelByUuid(channelUuid);
            if (giftUuid && channelUuid) {
                this.$router.push({ name: 'conversations', params: { 'giftUuid': giftUuid, 'channelUuid': channelUuid } });
            } else {
                this.$router.push({ name: 'conversations' });
            }
        },
        refreshMessages(callback = false) {
            let loader = this.$loading.show();
            if (!this.d) {
                this.d = true;
                this.$store.dispatch('channel/setChannels').then(response => {
                    this.reloadMessages();
                    if (callback && typeof callback === 'function') {
                        callback();
                    }
                    loader.hide();
                });
                // prevent spamming the server
                setTimeout(() => {
                    this.d = false;
                }, 7000);
            }

            setTimeout(() => {
                loader.hide();
            }, 500);
        },
        // doesn't reoad as such
        reloadMessages() {
            this.activeChannel = this.channelByUuid(this.channelUuid); //data changed so reactive
        },
        channelGift(channel) {
            return channel.gift.name;
        },
        channelUser(channel) {
            for (var i = 0; i < 2; i++) { // should only ever be 2 users
                if (channel.users[i].id != this.loggedInId) {
                    return channel.users[i];
                }
            }
        },
        channelLastMessage(channel) {
            if (channel.messages.length) {
                return channel.messages[channel.messages.length - 1].message;
            }

        },
        channelLastMessageDate(channel) {
            if (channel.messages.length) {
                return channel.messages[channel.messages.length - 1].updated_at;
            }

        },
        channelLastMessageClass(message) {
            var className = 'other'
            if (message.user_id == this.loggedInId) {
                className = 'self'
            }
            if (message.read !== 1) {
                className = className + ' unread'
            }
            return className;
        },
    },

}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#accountConversations {
    i.fa-refresh {
        margin-left: 15px;
        color: $buttonblue;
        font-size: 16px;
        cursor: pointer;
    }

    >.container {
        padding-bottom: 6px;

        @include media-breakpoint-up(sm) {
            padding-bottom: 10px;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 12px;
        }
    }

    .title {
        font-size: 24px;
        font-family: $cat;
        line-height: 30px;
        color: $bluetext;
        padding: 15px;
    }

    .row {
        width: 100%;
    }

    .conversations {
        &.self {
            background-color: darken(white, 1%);

            i {
                font-size: 10px;
                color: $buttonblue;
            }

            &.unread {
                background-color: darken(white, 1%);

                i {
                    font-size: 10px;
                    color: $formgrey;
                }
            }
        }

        &.other {
            // background-color: #fff;
            background-color: darken(white, 1%);

            i {

                display: none;
            }

            &.unread {
                background-color: $messageblue;

                i {
                    display: none;
                }
            }
        }

        background-color: #fff;
        // opacity:0.6;
        width: 100%;
        border-radius: 12px;
        height: 80px;
        margin-bottom: 5px;
        cursor: pointer;

        .avatar {
            height: 100%;
            width: 20%;
            max-width: 100px;
            position: relative;
            // display: flex;
            // flex-direction: column;
            float: left;
            // margin-left: 2%;
            background-size: 180%;
            background-position: 0 50%;
            background-repeat: no-repeat;
            padding-top: 10%;
            border-radius: 12px;

            @include media-breakpoint-up(md) {
                background-size: 100%;
            }

            img {
                margin: 0;
                width: 36px;
                height: 36px;
                position: absolute;
                bottom: 0px;
                right: -10%;
                border: 2px solid $messageblue;
                background-color: $messageblue;
            }

        }

        .center {
            width: 60%;
            float: left;
            padding: 10px;
            height: 100%;

            .userName {
                color: $bluetext;
                font-size: 15px;
                line-height: 26px;
                font-family: $open-sans;
                font-weight: 600;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .lastMessage {
                color: $textcolor;
                font-size: 13px;
                line-height: 26px;
                font-family: $open-sans;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .info {
            width: 18%;
            float: left;
            height: 100%;

            .date {
                width: 100%;
                height: 50%;
                color: $dategrey;
                font-size: 11px;
                line-height: 18px;
                padding-top: 15px;
                padding-right: 10px;
                text-align: right;
            }

            .star {
                width: 100%;
                height: 50%;
                text-align: right;
                padding-right: 10px;
            }
        }
    }
}

</style>
