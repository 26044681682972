<template>
    <div id="causesSharedWith" ref="cSW" class="ct forms col-sm-9" v-if="profile">
        <titletag title="Data Sharing Preferences"></titletag>
        <div v-if="uuid || cause" class="shareInputs">
            <h2 class="title">Share your name and email with {{cause.name}}</h2>
            <p>When you share your name and email a nonprofit can issue you a tax receipt for your transaction. Tax receipts are only available to either the seller or someone who makes a cash donation. Buyers do not get tax receipts. By sharing you also give the cause an opportunity to thank you. Not all causes are registered nonprofits.</p>
            <p>Verify we have your correct name and then share. Updating here updates your <router-link to="/account/profile">profile</router-link>. Does not change your public name.</p>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="first_name" name="first_name" class="form-control" type="text" v-model="profile.first_name" placeholder="First name">
                    <label class="magic" for="first_name">First Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="last_name" name="last_name" class="form-control" type="text" v-model="profile.last_name" placeholder="Last Name">
                    <label class="magic" for="last_name">Last Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="email" name="email" class="form-control" disabled type="text" v-model="profile.email" placeholder="Email">
                    <label class="magic" for="email">Email</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <a class="secondaryButton mb-2" @click="share_with_cause(cause.id,1)">Share with {{cause.name}}</a>
                </div>
            </div>
        </div>
        <div class="sharedWith" v-if="profile.causes_shared_with">
            <h2 class="title">Causes you shared your data with</h2>
            <p>When you share your name and email with a cause we do not send them your data. When they download their transaction data your name and email will be accessible to them for any transaction you are connected with(as a donor or buyer). When you revoke this permission we stop adding your name and email to their transaction data. Marking an item or purchase as anonymous or not does not affect how we handle this.</p>
            <table class="sharedCauses" v-if="profile.causes_bought_or_sold_for">
                <thead>
                    <tr>
                        <th class="first">Cause name</th>
                        <th class="statCol">Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in profile.causes_bought_or_sold_for">
                        <td class="first">{{c.name}}</td>
                        <td class="statCol"><span class="status" :class="shareStatus[c.id]? 'y' : 'n' ">{{shareStatus[c.id] ? 'Sharing':'Not Sharing'}}</span></td>
                        <td class="last" :itemId="c.id">
                            <label class="switch">
                                <input :itemId="c.id" type="checkbox" @click="share_with_cause(c.id,!shareStatus[c.id])" :checked="shareStatus[c.id]? 'checked': ''">
                                <div class="slider round"></div>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
export default {
    name: "causessharedwith",
    props: ['uuid'],
    data() {
        return {
            cause: null,
            sharing: 0,
            sharedStatus: []
        }
    },
    computed: {
        ...mapGetters(['getcauseByUuid']),
        ...mapState({ profile: state => state.account.profile }),
        shareStatus() {
        	var a = [];
        	for (var i = 0; i < this.profile.causes_shared_with.length; i++) {
            	console.log('checking: ' + i);
                if (this.profile.causes_shared_with[i].id && this.profile.causes_shared_with[i].pivot.sharing) {
                	console.log('checked');
                   a[this.profile.causes_shared_with[i].id] = 1;

                }else{
                	a[this.profile.causes_shared_with[i].id] = 0;
                }
            }
            return a;
            // return false;
        }
    },
    mounted() {
        this.init();
        // this.initLabels();
    },
    methods: {
        ...mapActions(['setCauseByUuid']),
        init() {
            var params = (new URL(document.location)).searchParams;
            if (this.uuid) {
                this.cause = this.getcauseByUuid(this.uuid);
                if (!this.cause) {
                    this.setCauseByUuid(this.uuid).then(r => {
                        this.cause = this.getcauseByUuid(this.uuid);
                    });
                }
                setTimeout(() => {
                    this.initLabels();
                }, 1000);
            }

        },
        share_with_cause(id, allowed) {
        	$('input').removeClass('e');
            if (this.validate(id,allowed)) {
                if (!this.sharing) {
                    this.sharing = 1;
                    let loader = this.$loading.show();
                    axios.post('/api/set_cause_sharing', { 'id': id, 'allowed': allowed, 'first_name': this.profile.first_name, 'last_name': this.profile.last_name }).then(response => {
                        if (response.data.status) {
                            if (this.isMobile()) {
                                this.flashMessage({ m: response.data.message, d: 500 });
                            }
                            this.$store.commit("account/setProfileCauseSharedStatus", response.data);
                        } else {
                            this.flashMessage({ m: response.data.message, e: 1 });
                        }
                        this.sharing = 0;
                        loader.hide();
                    }, error => {
                        this.flashMessage({ m: error.data.message, e: 1 });
                    });
                }

            }

        },
        validate(id,allowed) {
            if (allowed && !this.profile.first_name) {
                this.setCause(id);
                this.$nextTick(() => {
                	this.initLabels();
                    this.flashMessage({ m: "Please add your first name", e: 1 });
                    this.scrollToError('#first_name');
                    $('input[itemId="'+ id +'"]').prop("checked",this.share_status(id));
                });

                return 0;
            }
            if (allowed && !this.profile.last_name) {
                this.setCause(id);
                this.$nextTick(() => {
                	this.initLabels();
                    this.flashMessage({ m: "Please add your last name", e: 1 });
                    this.scrollToError('#last_name');
                    $('input[itemId="'+ id +'"]').prop("checked",this.share_status(id));
                });

                return 0;
            }


            return 1;
        },
        setCause(id) {
            for (var i = 0; i < Object.keys(this.profile.causes_bought_or_sold_for).length; i++) {
                if (id === this.profile.causes_bought_or_sold_for[i].id) {
                    this.cause = this.profile.causes_bought_or_sold_for[i];
                    break;
                }
            }
        },
        share_status(id) {

            for (var i = 0; i < this.profile.causes_shared_with.length; i++) {
            	console.log('checking: ' + i);
                if (id === this.profile.causes_shared_with[i].id && this.profile.causes_shared_with[i].pivot.sharing) {
                	console.log('checked');
                    return true;

                }
            }
            console.log('checked');
            return false;
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causesSharedWith {
    .shareInputs {
        padding: 10px 0;

        >p:nth-child(3) {
            margin-bottom: 30px;
        }
    }

    .sharedWith {
        padding: 10px 0;
    }

    h2.title {
        padding: 0px;
    }

    a.secondaryButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
}


table.sharedCauses {
    display: inline-table;
    width: 100%;

    // white-space: pre-wrap;
    .first {
        max-width: 70%;
        white-space: pre-wrap;
    }

    .last {
        cursor: pointer;
        white-space: pre-line;
    }

    .status {
        &.y {
            &:before {
                content: "\2713";
                display: inline-block;
                color: #86af49;
                padding: 0 3px 0 0px;
            }
        }

        &.n {
            &:before {
                content: "x";
                font-weight: 600;
                display: inline-block;
                color: $red;
                padding: 0 3px 0 0px;
            }
        }
    }

    thead,
    tbody {
        width: 100%;
    }

    th,
    td {
        font-size: 16px;
    }

    th {
        text-transform: capitalize;
    }

    .statCol {
        display: none;

        @include media-breakpoint-up(md) {
            display: table-cell;
        }
    }
}

</style>
