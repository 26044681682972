<template>
    <div class="container supporters">
        <template v-if="supporters.length">
            <div class="icon">
            	<img src="https://d1812kujpqokfk.cloudfront.net/assets/cause/users_increase.svg">
            </div>
            <div class="detail count">
                <span>{{count}} {{count == 1 ? 'person is' : 'people are'}} supporting</span>
            </div>
            <div v-for="supporter in supporters">
                <div class="icon">
                    <img v-if="supporter.anonymous" class="anon" src="https://d1812kujpqokfk.cloudfront.net/assets/cause/anon_user.svg">
                    <img v-else class="user" :src="supporter.user.avatar_url ? supporter.user.avatar_url : getCloudFrontUrl() + 'identicons/' + supporter.user.identicon">
                </div>
                <div class="detail">
                    <div class="name">{{supporter.user ? supporter.user.name : 'Anonymous'}}</div>
                    <div v-if="supporter.order_total" class="what" v-html="supporter.gift ? ' Bought ' + supporter.gift.name : ' Donated <span>$' + supporter.order_total + '</span>'"></div>
                    <div v-if="supporter.name" class="what">Donated {{supporter.name}}</div>
                    <!-- <div v-if="!supporter.order_total" class="what" v-html="supporter.gift ? ' Donated ' + supporter.gift.name : ' Donated <span>$' + supporter.order_total + '</span>'"></div> -->
                </div>
            </div>
            <div v-if="count > 3" class="fadeout"></div>
        </template>
        <template v-if="count == 0">
            <p>Currently no supporters for this cause</p>
        </template>
    </div>
</template>
<script>
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');

export default {
    props: ['urlStr'],
    data() {
        return {
            'supporters': [],
            'count': null
        }
    },
    computed: {
        ...mapGetters(['getSupporters']),
    },
    mounted() {
    	let loader = this.$loading.show({ width: 30, height: 30, container: $('div.supporters')[0] });
        this.setSupporters(this.urlStr).then(response => {
            this.supporters = this.getSupporters(this.urlStr);
            this.count = response.count; // should be in Vuex sometime :)
            loader.hide();
        });
    },
    methods: {
        ...mapActions(['setSupporters']),
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.supporters {
    font-family: $cat;
    font-size: 16px;
    color: $grey;
    position: relative;
    min-height:100px;
    margin-top:15px;
    .vld-background {
		background-color: #fff;
	}
	p{
		text-align: center;
		width: 100%;
	}
    img {
        width: 50px;
        height: 50px;
    }

    >div {
        display: flex;
        padding: 12px 0;
    }

    .count {
        span {
            font-weight: 600;
        }
    }

    .icon {
        display: inline-block;
        height: 100%;

        .anon {}

        .user {
            border-radius: 50%;
        }
    }

    .detail {
        height: 100%;
        display: inline-block;
        padding-left: 20px;

        div {
            display: block;

            &.what {
                font-family: $cat;
                font-size: 12px;
                text-transform: uppercase;
                color: $lightgrey;

                span {
                    font-family: $open-sans; // $ sign looks wierd in cat
                }
            }
        }
    }
}

</style>
