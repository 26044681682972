<template>
    <div class="container" v-if="title">
        <titletag :title="title"></titletag>
        <div id="wpPostEmbed" class="page" v-html="post"></div>
        <!-- <div id="wpPostEmbed"><pre>{{post}}</pre></div> -->
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapActions } = createNamespacedHelpers('wp');
export default {
    name: "blog",
    props: ['slug'],
    data() {
        return {
            title: null,
            post: null
        }
    },
    computed: {
        ...mapState({ posts: state => state.wp.posts })
    },
    watch: {
        slug(value) {
            this.init();
        }
    },
    mounted() {
        if (window.location.search == '?u=1') {
            let loader = this.$loading.show();
            this.refresh('posts').then(
                response => {
                    loader.hide();
                    this.init();
                }, error => {
                    loader.hide();
                    this.mustLogin();
                });
        } else {
            this.init();
        }

    },
    methods: {
        ...mapActions(['setPage','refresh']),
        init() {
            if (!this.posts[this.slug]) {
                this.retrieve();
            } else {
                this.display();
            }
        },
        display() {
            this.title = this.posts[this.slug].title;
            this.post = '<h1>' + this.title + '</h1>' + this.posts[this.slug].content;
            this.wpHead(this.posts[this.slug].yoast);
        },
        retrieve() {
            let loader = this.$loading.show();
            this.setPage({"s":this.slug,"p":'post'}).then(response => {
                if (response.slug) {
                    loader.hide();
                    this.display();
                } else {
                    loader.hide();
                    this.$router.push('/404');
                }
            }, error => {
                loader.hide();
                this.$router.push('/404');
            });
        }
    }
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#wpPostEmbed {
    margin: 0 auto;
    width: 100%;
    max-width: 650px;

    &.page {

        h1,
        h2 {
            padding-top: 20px;
            font-size: 22px;
            color: $bluetext;
            font-family: $cat;
            font-weight: 500;
        }

        img {
            max-width: 100%;
            height: auto;
            border-radius: 3px;
        }

        h1 {
            font-size: 28px;
        }

        h2 {
            color: $bluetext;
            font-family: $open-sans;
        }
        h3 {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 22px;
        }
        p,
        li {
            font-family: $open-sans;
            color: $textcolor;
            font-size: 16px;
            padding-bottom: 4px;

            a {
                color: $buttonblue;
                &.rev {
                	color: #fff;
                	&:hover{
                		color: $buttonblue;
                	}
                }
            }
        }
    }
    a.more {
    	// background-color: $buttonblue;
     //    color: #FFFFFF;
     //    font-family: $cat;
     //    font-size: 15px;
        display:flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 25px;
        height:40px;
        cursor:pointer;
        margin-bottom: 5px;
    }
    a.anchor {
    	color: $buttonblue;
    	text-decoration: none;
    	padding-bottom:5px;
    	display: block;
    }
}

</style>
