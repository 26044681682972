<template>
	<div class="ct forms">
		<h2 class="title">Change your password</h2>
		<form>
		<div class="form-group row">
            <div class="col-sm-12">
                <input id="old_password" name="old_password" class="form-control" type="password" v-model="old_password" placeholder="old password" autocomplete="current-password">
                <label class="magic" for="old_password">Old password</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="new_password" name="new_password" class="form-control" type="password" v-model="new_password" placeholder="new password" autocomplete="new-password">
                <label class="magic" for="new_password">New password</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="confirm_password" name="new_password_confirmation" class="form-control" type="password" v-model="confirm_password" placeholder="confirm new password" autocomplete="new-password">
                <label class="magic" for="confirm_password">Confirmed new password</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <a class="secondaryButton mb-2" @click="updatePassword()">Save new password</a>
            </div>
        </div>
    </form>
	</div>
</template>
<script>
export default {
	name: "",
	props: [],
	data () {
		return {
			old_password: null,
			new_password: null,
			confirm_password: null
		}
	},
	mounted() {
		this.initLabels();
	},
	methods: {
		updatePassword() {
			if(this.validate()) {
				let loader = this.$loading.show();
				axios.post('/api/auth/change_password',{'old_password': this.old_password,'new_password': this.new_password,'new_password_confirmation': this.confirm_password}).then(response =>{
				if(response.data.status){
					this.flashMessage({m:response.data.message});	
				}else{
					this.flashMessage({m:response.data.message, e:1});
				}
				loader.hide();
			},error => {
				this.flashMessage({m:error.data.message, e:1});
			});
			}
			// no need to use vuex.
			
		},
		validate() {
			if (!this.old_password) {
                this.scrollToError('#old_password');
                this.flashMessage({ m: this.$t('account.change_p_old'), e: 1 });
                return false;
            }
            if (!this.new_password) {
                this.scrollToError('#new_password');
                this.flashMessage({ m: this.$t('account.change_p_new'), e: 1 });
                return false;
            }
            // do this right and force a little improvement
            var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,32}$/g;
            if (!this.new_password.match(pattern)) {
                this.scrollToError('#new_password');
                this.flashMessage({ m: this.$t('account.password_format'), e: 1,d:3000 });
                return false;
            }
            if (this.new_password !== this.confirm_password) {
                this.scrollToError('#confirm_password');
                this.flashMessage({ m: this.$t('account.change_p_match'), e: 1 });
                return false;
            }
			if(this.old_password && (this.new_password === this.confirm_password)){

				return true;
			}
		}
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>