<template>
    <div class="ct forms container" id="ptotoday">
        <titletag title="Raising money for schools through upcycling and reusing everyday items"></titletag>
        <section class="one">
            <div class="l">
                <div class="contain form-group">
                    <h1>Raise Money for Your School. It’s Simple, Easy & Free</h1>
                    <p>FunditOn is a free-to-use fundraising marketplace where your community raises funds for your cause by selling their goods and services. Proceeds go directly to your cause.</p>
                    <div>
                        <router-link to="/cause/new" class="btn-primary">Register and Start Now</router-link>
                    </div>
                </div>
            </div>
            <div class="r">
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/tree.png">
                </div>
            </div>
        </section>
        <section class="two">
            <div>
            	<img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/arrow.png">
                <h2>Connect your Donors to the Community</h2>
                <p>Your families can clean out their garages helping each other and your school!!</p>
                <p>Set up is super easy. Then your cause runs itself!</p>
                <p>Just share your cause page and watch your community connect and make money for you.</p>
            </div>
        </section>
        <different></different>
        <div class="form-group centered"><router-link to="/cause/new" class="secondaryButton rev">Start your Fundraiser</router-link></div>
        <section class="three bubbles">
            <div class="r">
                <div class="blurred"></div>
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/flowers.png">
                </div>
            </div>
            <div class="l">
                <div class="contain">
                    <h2>Ever struggled to engage your community?</h2>
                    <p>Bring your community together organically through re-selling.</p>
                    <p>By selling to each other your families meet other families from your school.</p>
                    <p>Benefit the environment by upcycling and repurposing.</p>
                </div>
            </div>
        </section>
        <section class="four bubbles">
            <div class="l">
            	<div class="blurred"></div>
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/with_book.png">
                </div>
            </div>
            <div class="r">
                <div class="contain">
                	<h2>Add some Fun to School Fundraising</h2>
                	<p>All that sports equipment that is too small can be resold with the proceeds going to your school(a few times!) </p>
                	<p>Growing families have a lot they need and that they no longer need. They will love the deals and being able to provide for someone in your school.</p>
                	<p>All you have to do is set up a cause and share it in your network!</p>
                </div>
            </div>
        </section>
        <section class="five bubbles">
            <div class="r">
                <div class="blurred"></div>
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/red_hair.png">
                </div>
            </div>
            <div class="l">
                <div class="contain">
                	<h2>Effortlessly get Paid</h2>
                    <p>We deposit all funds for your cause directly into your bank.</p>
                    <p>We do not take a platform fee <strong>ever</strong> for causes started before May 15th 2022</p>
                    <p>Set up receiving your funds in less than 3 minutes.</p>
                </div>
            </div>
        </section>
        <section class="four bubbles">
            <div class="l">
            	<div class="blurred"></div>
                <div class="imgContain">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/greenshirt.png">
                </div>
            </div>
            <div class="r">
                <div class="contain">
                	<h2>Sign Up & Start a Fundraiser for Free</h2>
                    <p>FunditOn is a free-to-use platform where you can easily sign up and set up a fundraiser on behalf of your school.</p>
                    <p>Everyone wins as your school community shares those items they no longer need.</p>
                    <p>Use it as much as you like, as long as you like, for free.</p>
                </div>
            </div>
        </section>
        <section class="six">
            <div>
            	<h2>Raise Funds Effortlessly</h2>
            	<p>Simply start your cause. Share it in your own way and let the familiar process of selling unused stuff support your school.</p>
            	<p>You can also process cash donations.</p>
            	<p>Ready to get started? Sign up today and and join our mission to make fundraising accessible to everyone.</p>
                
                <div class="form-group">
                    <router-link to="/cause/new" class="btn-primary">Start your cause</router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import Different from '../../layout/landing/Different';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    name: "ptotoday",
    props: [],
    components: {
        Different
    },
    data() {
        return {}
    },
    // mounted() {
    // 	$('nav').hide();
    // 	$('#app').css({"padding-top": 0});
    // },
    // destroyed() {
    //     $('nav').show();
    //     $('#app').css({"padding-top": '56px'});
    // },
    // methods: {
    //     ...mapActions(['setLandingGData']),
    // }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#ptotoday {
    max-width: 100%;
    // margin-top: 40px;

    section {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .l {
            min-width: 35%;

            .contain {
            	 padding: 40px; 
            >div {
                text-align: center;
               

            }
             
        }
        }

        .r {
            min-width: 35%;
        }

        .form-group {

            padding: 20px;

            a {
                border-radius: 10px;
            }

        }
        
        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding: 20px;

            >div {
                // width: 50%;
            }

            h1 {
                font-size: 50px;
                line-height: 65px;
            }
        }
    }

    section.one {
        .imgContain {
            box-shadow: none;
            min-width: 38%;
        }

        >div {
            width: auto;
        }

        p {
            padding-top: 5px;
            padding-bottom:30px;
            font-size: 18px;
            @include media-breakpoint-up(md) {
                padding-top: 15px;
            }
        }
    }

    section.two {

        background-color: #FBFBFF;
        border-radius: 4px;
        min-height: 190px;
        img {
        	padding-bottom:30px;
        	padding-top: 10px;
        }
        @include media-breakpoint-up(md) {
            min-height: 390px;
        }

        >div {
            width: 100%;
            text-align: center;
            padding: 0 8px;

            // padding:10% 20%;
            >h2,
            p {
                // color: #fff;
            }

            >h2 {
                font-size: 24px;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                }
            }

            >p {
                font-weight: 600;
                text-align: center;
                font-size: 17px;
            }
        }
    }

    section.three,
    section.five {
        .r {
            position: relative;
            z-index: 1;

            .blurred {
                background: #59B1E6;
                opacity: 0.8;
                filter: blur(150px);
                position: absolute;
                width: 50%;
                height: 50%;
                right: 0;
                top: 20%;
                z-index: 2;
            }

            .imgContain {
                position: relative;
                // left: 0;
                //      top: 0;
                z-index: 3;
                border-radius: 24px;
                background: #fff;
            }

        }


        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
        }
    }
    section.four {
    	.l {
            position: relative;
            z-index: 1;

            .blurred {
                background: #59B1E6;
                opacity: 0.8;
                filter: blur(150px);
                position: absolute;
                width: 50%;
                height: 50%;
                left: 0;
                top: 20%;
                z-index: 2;
            }

            .imgContain {
                position: relative;
                // left: 0;
                //      top: 0;
                z-index: 3;
                border-radius: 24px;
                background: #fff;
            }

        }
        .r {
        	div.contain {
            	padding: 40px;
            }
        }

    }
    section.six {
        >div {
            width: 100%;
            padding: 25px 5px;
            background-color: #FBFBFF;
            border-radius: 15px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }

        p:nth-child(3) {
            font-weight: 600;
        }
    }

    .contain {
        // padding: 5% 10%;
        padding-bottom: 10px;
    }

    .imgContain {
        width: 100%;
        padding-bottom: 10px;
        z-index: 500;
        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
        border-radius: 26px;

        img {
            width: 100%;
            height: auto;
            padding: 24px;
        }

    }
    section.bubbles {
    	p::before {
        		width: 12px;
        		height: 12px;
        		display: inline-block;
        		border-radius: 4px;
        		
        		content:' ';
        		padding:3px;
        		color:#F15A2B;
        		margin-right:12px;
        	}
    	p:nth-child(2)::before {
    		background: #F0BB1F;
    	}
    	p:nth-child(3)::before {
    		background: #F15A2B;
    	}

    	p:nth-child(4)::before {
    		background: #006380;
    	}
        
    }
    .centered {
    	text-align: center;
    	width: 100%;
    	padding: 10px;
    	.rev {
    		padding:10px 20px;
    		border-radius: 10px;
    	}
    }
}

</style>
