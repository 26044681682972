<template>
    <div class="ct forms container centered">
        <titletag title="FundItOn Peer to Peer Fundraising: Shop Products" description="Shop and help charities and individuals while you’re at it! Your money goes toward children’s charities, animal rescue, helping those in poverty, and more."></titletag>
        <h2 class="title">Shop to Support Causes</h2>
        <p>When you buy anything here the proceeds will be supporting an important cause</p>
        <filters :key="key" type="gift" cat="1" ship="1" price="1" location="1"></filters>
        <div class="giftList row" v-if="gifts[key] && gifts[key].gifts">
            <giftitem v-for="item in gifts[key].gifts" :key="item.id" :id="item.id" :item="item"></giftitem>
        </div>
        <div v-if="moreExist" class="showMore form-group"><a class="btn-primary simple" @click="init">Show more</a></div>
        <div v-if="empty" class="empty">
            <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/items.svg">
            <p class="nothing">No items matched your search. Try broadening your search</p>
        </div>
    </div>
</template>
<script>
import Giftitem from '../gifts/Item'
import Filters from '../common/Filters'
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');
export default {
    components: {
        Giftitem,
        Filters
        // Pagination
    },
    data() {
        return {
            a: false,
            key: null,
            moreExist: false,
            empty: false
        }
    },
    watch: {
        $route(to, from) {
            this.init();
        }
    },
    computed: {
        ...mapState({ gifts: state => state.gift.gifts }),
    },
    methods: {
        ...mapActions(['setGifts']),
        init() {
            let loader = this.$loading.show();
            this.setGifts(window.location.search).then(response => {
                loader.hide();
                if (!this.gifts[response.data.q].gifts.length) {
                    this.empty = true
                } else {
                    this.empty = false;
                }
                this.setFiltering(true, response.data.q);

            });
        },

        setFiltering(bool, key) {
            this.key = key;
            this.$forceUpdate();
            if (this.gifts[key].lastPage > this.gifts[key].currentPage) {
                this.moreExist = 1;
            } else {
                this.moreExist = 0;
            }
        }
    },
    mounted() {
        this.init();
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollListen);
    }

}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

div.showMore.form-group {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    >a.simple {
        width: 150px !important;
        text-align: center;
        cursor: pointer;
    }
}

</style>
