<template>
	<div  class="sold_container">
		<div class="raised "><span>$</span>{{amount.toLocaleString()}}<span class="ofAmount">/{{item.goal.toLocaleString()}}</span></div><div class="available" v-bind:class="item.gifts_count - item.sold ? '':'n'">{{available}}</div>
	</div>
</template>

<script>
	export default {
		props: ['item'],
		computed: {
			amount() {
				if(this.item.account && this.item.account.balance > 0){
					return Math.round(this.item.account.balance);
				}
				return 0;
			},
			available() {
				var items = " items";
				if(this.item.gifts_count) {
					if(this.item.gifts_count - this.item.sold == 1) {
						items = " item";
					}
					return (this.item.gifts_count - this.item.sold) + items;
				}
				return 0 + items;
			}
		}
	}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.sold_container {
	float: left;
	width: 100%;
	padding:10px 18px;
	box-sizing: border-box;
	margin-top:10px;
	 @include media-breakpoint-up(sm) {
	 	margin-top: 0;
	 }
	
	.raised{
		display: inline-block;
		width:52%;
		text-align: left;
		font-family: $open-sans;
		color: $buttonblue;
		font-size: 16px;
		font-weight: 600;
	}
	.available {
		display: inline-block;
		width: 48%;
		text-align: right;
		font-family: $cat;
		color: $bluetext;
		font-size: 16px;
		font-weight: 600;
	}
	.ofAmount {
		padding:0 1px 0 1px;
		color: lighten($buttonblue,15%);
		// padding:0;
		// color: $bluetext;
	}
	.n {
			visibility: hidden;
		}
}
</style>