<template>
    <div class="ct container forms" id="addGift">
        <titletag title="Add an item"></titletag>
        <div class="container">
            <steps active="f"></steps>
            <h2 class="title">Donate something to {{causeName}}</h2>
            <div class="form-group row">
            	<p>Upload JPG or PNG images up 12 MB each. Max 10 Photos</p>
                <div id="drop-zone">
                    <label id="upload-label" class="file-upload">Drag and drop files here. Clicking here opens your system file finder.
                        <input type="file" id="fileElem" multiple accept="image/jpeg,image/png" ref="file" @change="storeFile(null)">
                    </label>
                    <div class="progress">
                        <div id="progress-bar" class="progress-bar progress-bar-striped bg-success" role="progressbar" style="" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <p class="clickFeaturedText">Click on an image to make it your Featured Image</p>
                <div id="gallery"></div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input v-model="name" type="text" class="form-control" id="giftname" placeholder="Name for your item">
                    <label class="magic" for="giftname">Item title</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <select v-model="selectedCategory" class="category form-control" id="category" required="true">
                        <option value="">Choose a Category</option>
                        <option v-for="category in giftCategories" :value="category.id">{{category.name}}</option>
                    </select>
                    <label class="magic" for="category">Category</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                	<span class="dollar">$</span>
                    <input v-model="price" type="number" class="form-control" min="5" max="100000" id="price" placeholder="Suggest a dollar price, minimum: 5">
                    <label class="magic" for="price">Price</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <textarea v-model="description" class="form-control" id="description" rows="3" placeholder="Add any more details about this item"></textarea>
                    <label class="magic" for="description">Description</label>
                </div>
            </div>
            <div class="form-group row">
            	<div class="col-sm-12">
	                <div class="radiocheck">
	                    <label>Anonymous in the cause activity feed
	                        <input v-model="anonymous_in_cause_feed" type="checkbox">
	                        <span></span>
	                    </label>
	                </div>
	            </div>
            </div>
            <div class="form-group row">
            	<div class="col-sm-12">
	                <div class="radiocheck">
	                    <label>Available for Shipping?
	                        <input v-model="shippable" type="checkbox">
	                        <span></span>
	                    </label>
	                </div>
	            </div>
            </div>
            <template v-if="shippable">
            	<div class="form-group row">
                    <div class="col-sm-12">
                    	<p>{{$t('gifts.ship-p')}}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input class="form-control" id="pWidth" type="number" v-model="parcel_width" :placeholder="$t('gifts.parcel-w')">
                        <label class="magic" for="pWidth">Width</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input class="form-control" id="pHeight" type="number" v-model="parcel_height" :placeholder="$t('gifts.parcel-h')">
                        <label class="magic" for="pHeight">Height</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input class="form-control" id="pLength" type="number" v-model="parcel_length" :placeholder="$t('gifts.parcel-l')">
                        <label class="magic" for="pLength">Length</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input class="form-control" id="pWeight" type="number" v-model="parcel_weight" :placeholder="$t('gifts.parcel-weight')">
                        <label class="magic" for="pWeight">Weight</label>
                    </div>
                </div>
            </template>
            <div class="form-group row">
                <div class="col-sm-12">
                    <a class="btn btn-primary mb-2" @click="send()">Next ></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Steps from './Steps';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('gift');
export default {
    props: ['causeUuid','causeName'],
    data() {
        return {
            uploadProgress: [],
            image_uuids: [],
            dropArea: '',
            name: '',
            description: '',
            price: '',
            primaryImage: '',
            numFiles: 0,
            finalTotal: 0,
            submitted: false,
            selectedCategory: '',
            anonymous_in_cause_feed: false,
            shippable: false,
            address: '',
            showAddress: false,
            deleted_photos: [],
            parcel_width: '',
            parcel_height: '',
            parcel_length: '',
            parcel_weight: ''
        }
    },
    components: { Steps },
    mounted() { 
        this.init();
    },
    computed: {
        ...mapGetters(['categories']),
        giftCategories() {
            return this.categories;
        }
    },
    methods: {
    	init() {

    		this.initDropZone();
	        if (this.categories.length < 1) {
	            this.setCategories();
	        }
	        setTimeout(this.initLabels,200);
	        $('#price').on('focus', function() {
	        	$('.dollar').addClass('focussed');
	        });
	  //       axios.get('/sanctum/csrf-cookie').then(response => {
			//     // Login...
			// });
    	},
        ...mapActions(['setCategories', 'addNewGift']),
        async store(file, options = null) {
            // axios.defaults.headers.common["Authorization"] = `Bearer ${window.token}`
            const response = await axios.post('/vapor_signed_url', {
                'bucket': options.bucket || '',
                'content_type': options.contentType || file.type,
                'expires': options.expires || ''
            });

            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }
            // var instance = axios.create();
            // instance.defaults.headers.common = {};
            const s3Response = await axios.put(response.data.url, file, {
                headers: response.data.headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            });

            response.data.extension = file.name.split('.').pop()

            return response.data;
        },
        send() {
            if (!this.submitted && this.validate()) {
                this.saveGift();
            }
        },
        validate() {
            // add all the things to validate here
            // if shippable check parcel is complete
            var uuids = [];
            $.each($('img', '#gallery'), function(i, v) {
                uuids.push($(v).attr('uuid'));
            });
            this.image_uuids = uuids;
            if (!this.image_uuids.length) {
                this.scrollToError('#fileElem');
                this.flashMessage({ m: 'Your item needs at least one photo', e: 1 });
                return false;
            }
            if (!this.name) {
                this.scrollToError('#giftname');
                this.flashMessage({ m: 'Your item needs a name', e: 1 });
                return false;
            }
            if (!this.selectedCategory) {
                this.scrollToError('#category');
                this.flashMessage({ m: 'Your item needs a category', e: 1 });
                return false;
            }
            if (!this.price || this.price < 5) {
                this.scrollToError('#price');
                this.flashMessage({ m: 'Your item needs a price of at least $5', e: 1 });
                return false;
            }
            if (this.price > 999999.99) {
                this.scrollToError('#price');
                this.flashMessage({ m: 'Your item is way too expensive. Please stay under $100,000', e: 1 });
                return false;
            }
            if (!this.description) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your item needs a description', e: 1 });
                return false;
            }
            if (this.description.length < 10) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your item description needs a few words :)', e: 1 });
                return false;
            }
            if (this.shippable) {
                // validate the parcel here
                if (!this.parcel_width || this.parcel_width < 0.5) {
                    this.scrollToError('#pWidth');
                    this.flashMessage({ m: this.$t('gifts.wide'), e: 1 });
                    return false;
                }
                if (!this.parcel_height || this.parcel_height < 0.5) {
                    this.scrollToError('#pHeight');
                    this.flashMessage({ m: this.$t('gifts.high'), e: 1 });
                    return false;
                }
                if (!this.parcel_length || this.parcel_length < 0.5) {
                    this.scrollToError('#pLength');
                    this.flashMessage({ m: this.$t('gifts.long'), e: 1 });
                    return false;
                }
                if (!this.parcel_weight || this.parcel_weight < 0.1 || this.parcel_weight > 70) {
                    this.scrollToError('#pWeight');
                    this.flashMessage({ m: this.$t('gifts.weight'), e: 1 });
                    return false;
                }
                if(!this.validParcel(this.parcel_width,this.parcel_height,this.parcel_length)) {
                	this.scrollToError('#pWidth');
                	this.flashMessage({ d:2000,m: this.$t('gifts.toobig'), e: 1 });
                	return false;
                }
            }
            return true;
        },
        saveGift() {
            this.submitted = true;
            var payload = {
                'image_uuids': this.image_uuids,
                'deleted_image_uuids': this.deleted_photos, // the diff is needed
                'name': this.name,
                'category_id': this.selectedCategory,
                'primary_photo_uuid': this.primaryImage,
                'description': this.description,
                'anonymous_in_cause_feed': this.anonymous_in_cause_feed,
                'shippable': this.shippable,
                'width': this.parcel_width,
                'height': this.parcel_height,
                'length': this.parcel_length,
                'weight': this.parcel_weight,
                'price': this.price,
                'cause_uuid': this.causeUuid,

            };
            this.addNewGift(payload).then(response => {
                this.$router.push({ name: 'giftlocation', params: { uuid: response.data.gift.uuid, gift: response.data.gift, v: "s" } });
            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again")
            })
        },
        initDropZone() {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.preventDefaults, false)
                document.body.addEventListener(eventName, this.preventDefaults, false)
            })
            // Highlight drop area when item is dragged over it
            ;
            ['dragenter', 'dragover'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.highlight, false)
            });
            ['dragleave', 'drop'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.unhighlight, false)
            });
            // Handle dropped files
            document.getElementById("drop-zone").addEventListener('drop', this.handleDrop, false)
        },
        storeFile(files) {
            if (null == files) {
                files = this.$refs.file.files;
            }
            files = this.validate_files_for_upload(files);
            $('.btn-primary').prop('disabled', true);

            this.initializeProgress(files.length)
            for (var i = 0; i < files.length; i++) {
                this.store(files[i], {
                    progress: progress => {
                        this.updateProgress(Math.round(progress * 100));
                    }
                }).then(response => {
                    // these are image uuids from vapor's upload
                    this.triggerResizeJob(response.uuid);
                });
            }

        },
        triggerResizeJob(uuid) {
            this.showThumbContainer(uuid);
            axios.post('/api/resize_gift_image', { uuid: uuid }).then(response => {
                this.showUploadedThumb(response.data);
            })
        },
        validate_files_for_upload(files) {
            files = Array.from(files);
            for (var i = 0; i < files.length; i++) {
                if (files[i].size > 15000000) {
                    files.splice(i, 1);
                }

            }
            // no more than 10 files inside our allowed
            var currentCount = $('#gallery img').length;
            return files.splice(0, 10 - currentCount);
        },
        initImages() {
            var self = this;
            var primary = '<span class="primaryImage">Featured Image</span>';
            $('img', '#gallery').off("click").on("click", function(e) {
                var el = e.target;
                var uuid = $(el).attr('uuid');
                self.primaryImage = uuid;
                $('img', '#gallery').removeClass('primary');
                $('span.primaryImage', '#gallery').remove();
                $(el).addClass('primary');
                $(el).after(primary);
            });
            $('#gallery').off("click").on("click", ".deleteFreshPhoto", function(e) {

                self.deleted_photos.push($(this).siblings('img').attr('uuid'));
                $(this).parent('.uploaded_thumb').remove();
                if (!$('.uploaded_thumb').length) {
                    $('.clickFeaturedText').hide();
                }
            })
            if ($('img.primary', '#gallery').length == 0) {
                $('img', '#gallery').first().addClass('primary');
                $('img', '#gallery').first().after(primary);
                self.primaryImage = $('img', '#gallery').first().attr('uuid');
            }
            $('.clickFeaturedText').show();
        },
        showThumbContainer(uuid) {
            var el = '<div class="uploaded_thumb ' + uuid + '"><span class="deleteFreshPhoto">x</span><div uuid="' + uuid + '"</div>';
            $('#gallery').append(el);
            window[uuid] = this.$loading.show({ width: 20, height: 20, container: $('div.' + uuid)[0] });
        },
        showUploadedThumb(response) {
            // uuid here is the origianl uuid from vapor
            if (response.valid) {
                var url = this.getCloudFrontUrl() + response.photo.path_thumb;
                var el = '<img uuid="' + response.photo.uuid + '" src="' + url + '">';
                $('div[uuid="' + response.uuid + '"]', $('#gallery')).replaceWith(el);
                $('.btn-primary').prop('disabled', false);
                this.initImages();
                window[response.uuid].hide();
            } else {
                window[response.uuid].hide();
                $('.uploaded_thumb.' + response.uuid).remove();
            }

        },
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        highlight(e) {
            document.getElementById("upload-label").classList.add('highlight');
        },
        unhighlight(e) {
            document.getElementById("upload-label").classList.remove('highlight');
        },
        handleDrop(e) {
            var dt = e.dataTransfer
            var files = dt.files;
            this.storeFile(files);

        },
        initializeProgress(numFiles) {
            this.numFiles = numFiles;
            this.finalTotal = 0;

        },
        updateProgress(percent) {

            if (this.numFiles == 0) {
                $('#progress-bar').attr('aria-valuenow', 100).css('width', 100 + '%');
            } else {
                let total = (percent / this.numFiles);
                this.finalTotal = total > this.finalTotal ? total : this.finalTotal
                $('#progress-bar').attr('aria-valuenow', this.finalTotal).css('width', this.finalTotal + '%');
                if (percent == 100) {
                    this.numFiles = this.numFiles - 1;
                }
            }
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#addGift,
#editGift {
    max-width: 750px;

    .uploaded {
        width: 100%;
    }

    .done {
        color: $green;
    }

    p {
        width: 100%;
    }
    span.dollar {
    	position:absolute;
    	left:25px;
    	top:13px;
    	color:$textcolor;
    	opacity: 0.51;
    	&.focussed {
    		opacity:1;
    	}
    }
    p {
        margin-top: 0;
        padding: 0 15px;
    }

    #gallery {
        margin-top: 15px;
        margin-left: 15px;
    }

    .clickFeaturedText {
        display: none;
        color: $green;
        padding-left: 15px;
    }

    #gallery,
    #existing_photos {
        .uploaded_thumb {
            display: inline-block;
            position: relative;
            width: 135px;
            height: 135px;
            margin: 3px;
            border-radius: 5px;

            img {
                max-width: 135px;
                max-height: 135px;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 2px;
            }

            span.primaryImage {
                position: absolute;
                background: transparent;
                top: -17px;
                font-size: 12px;
                left: 0;
                color: $green;
                opacity: 0.8;
                font-weight: 600;
            }

            span.deleteFreshPhoto,
            span.deletePhoto {
                position: absolute;
                top: 0px;
                right: 0px;
                display: block;
                border: 1px solid #000;
                border-radius: 2px;
                width: 16px;
                height: 16px;
                line-height: 11px;
                text-align: center;
                background-color: $white;
                font-weight: 600;
                color: $red;
                cursor: pointer;
                z-index: 1000;
            }

        }
    }

    .button {
        display: inline-block;
        padding: 10px;
        background: #ccc;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .button:hover {
        background: #ddd;
    }

    #fileElem {
        display: none;
    }

    .progress {
        margin: 1%;
    }
}

</style>
