import Account from '../../components/pages/Account'
import AccountProfile from '../../components/account/Profile'
import AccountGifts from '../../components/account/Gifts'
import AccountPurchases from '../../components/account/Purchases'
import AccountCauses from '../../components/account/Causes'
import Causeorders from '../../components/account/Causeorders'
import Likeditems from '../../components/account/Likeditems'
import Likedcauses from '../../components/account/Likedcauses'
import Notifications from '../../components/account/Notifications'
import Conversations from '../../components/account/messaging/Conversations'
import ConnectToStripe from '../../components/account/Connecttostripe'
import Assignrecipient from '../../components/account/Assignrecipient'
import Acceptdelivery from '../../components/account/Acceptdelivery'
import Causessharedwith from '../../components/account/Causessharedwith'


export default [
{
	path: '/account',
	name: 'account',
	component: Account,
	meta: {
	    requiresAuth: true
	},
	children: [
        {
          path: 'profile',
          name: 'profile',
          component: AccountProfile,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'causes',
          component: AccountCauses,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'causeorders/:urlStr',
          component: Causeorders,
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'gifts',
          component: AccountGifts,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'purchases',
          name: 'purchases',
          component: AccountPurchases,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'conversations/:giftUuid?/:channelUuid?',
          component: Conversations,
          name: 'conversations',
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'liked_items',
          component: Likeditems,
          name: 'likeditems',
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'liked_causes',
          component: Likedcauses,
          name: 'Likedcauses',
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'notification_preferences',
          name:"notifications",
          component: Notifications,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: 'stripe_account/:uuid?',
          component: ConnectToStripe,
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'assign_recipient/:uuid',
          component: Assignrecipient,
          name:'assignrecipient',
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'accept_delivery/:uuid',
          component: Acceptdelivery,
          name:'acceptdelivery',
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'causes_shared_with/:uuid?',
          component: Causessharedwith,
          name:'causessharedwith',
          props: true,
          meta: {
            requiresAuth: true
          }
        }

      ]
}]