<template>
    <div class="notifications col-sm-9">
        <titletag title="Notification Preferences"></titletag>
        <div class="row" v-if="prefs">
            <h2 class="title">{{$t("nav.notifications")}}</h2>
            <label class="slider-label">Messages<span>Notify when someone sends you a message about an item you are buying or selling</span></label>
            <label class="switch">
                <input id="message" type="checkbox" v-model="prefs.message_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Cause Likes<span>Notify when someone likes a cause that you started</span></label>
            <label class="switch">
                <input id="cause_like" type="checkbox" v-model="prefs.cause_like_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Item Likes<span>Notify when someone likes an item that you are selling </span></label>
            <label class="switch">
                <input id="gift_like" type="checkbox" v-model="prefs.gift_like_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Cause Purchase<span>Notify when someone purchases an item that supports the cause you started </span></label>
            <label class="switch">
                <input id="cause_item_purchase" type="checkbox" v-model="prefs.cause_item_purchase_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Cause Donation<span>Notify when someone makes a cash donation to a cause you started</span></label>
            <label class="switch">
                <input id="cause_donation" type="checkbox" v-model="prefs.cause_donation_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">New Item<span>Notify when someone adds an item to a cause you started</span></label>
            <label class="switch">
                <input id="item_added_to_cause" type="checkbox" v-model="prefs.item_added_to_cause_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Liked Item Price Reduced<span>Notify when the price is reduced on an item you like</span></label>
            <label class="switch">
                <input id="liked_item_price_change" type="checkbox" v-model="prefs.liked_item_price_change_notifications">
                <div class="slider round"></div>
            </label>
            <!-- <label class="slider-label">Shared Cause<span>Someone shared your cause via Social Media</span></label>
            <label class="switch">
                <input id="cause_shared" type="checkbox" v-model="prefs.cause_shared_notifications">
                <div class="slider round"></div>
            </label>
            <label class="slider-label">Shared item<span>Someone shared an item you are selling via Social Media</span></label>
            <label class="switch">
                <input id="gift_shared" type="checkbox" v-model="prefs.gift_shared_notifications">
                <div class="slider round"></div>
            </label> -->
        </div>
        <router-link :to="`/account`" class="btn back">Go Back</router-link>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {
    name: "notifications",
    props: [],
    computed: {
        ...mapState({ prefs: state => state.account.profile }),
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            var self = this;
            $('.notifications').on("change", 'input[type="checkbox"]', function() {
                $('input[type="checkbox"]', $('.notifications')).prop('disabled', true);
                var key = $(this).attr('id');
                var payload = { "field": key, "value": self.prefs[key + '_notifications'] };
                axios.post('/api/set_notification_preference', payload).then(response => {
                    setTimeout(() => {
                        $('input[type="checkbox"]', $('.notifications')).prop('disabled', false);
                    }, 500);
                }, error => {
                    self.flashMessage({ m: error.message, e: 1 });
                    $('input[type="checkbox"]', $('.notifications')).prop('disabled', false);
                })
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.notifications,
#causesSharedWith {
    .row h2.title {
        padding-left: 20px;
    }

    .switch {
        position: relative;
        display: table-cell;
        width: 42px;
        height: 22px;
        margin-bottom: 25px;
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #F0F2F6;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 2px;
        background-color: #BFC5CF;
        // border: 0.2px solid #BEC5CF;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: rgba(72, 152, 255, 0.12);
    }

    input:focus+.slider {
        box-shadow: 0 0 1px rgba(72, 152, 255, 0.12);
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
        background-color: #4898FF;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider-label {
        display: table-cell;
        vertical-align: middle;
        padding-right: 5px;
        padding-left: 20px;
        width: 80%;
        color: $bluetext;
        font-family: $open-sans;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 17px;
        }

        span {
            display: block;
            width: 100%;
            font-size: 11px;
            color: $grey;

            @include media-breakpoint-up(sm) {
                font-size: 12px;
            }
        }
    }
}

</style>
