<template>
    <div class="ct container terms">
        <titletag title="FunditOn Terms and Conditions"></titletag>
        <div name="termly-embed" data-id="6b234117-74c2-461c-a0b1-29611277dd1c" data-type="iframe"></div>
        
    </div>
</template>
<script>
export default {
    mounted() {
    	$('#termly-jssdk').remove();
        (function(d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; 
        	if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs); }(document, 'script', 'termly-jssdk'));
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';


</style>
