<template>
    <div class="col-sm-9 ct forms" id="stripeConnect" v-if="profile">
        <titletag title="My Funds"></titletag>
        <h2 class="title">Receive your funds</h2>
        <div v-for="acc in causeAccounts" class="causeTotals"> 
        	<h3>{{acc.name}}</h3>
        	<p>Total Raised: ${{acc.balance}}<span>(${{acc.amount_raised}} after credit card fees)</span></p>
        	<p>Total Cleared: ${{acc.amount_released}}</p>
        </div>
        <div class="panel" v-if="requirements && !admin_confirmed">
            <h3>Congratulations, you have received funds for your cause!</h3>
            <p>FunditOn uses Stripe to get you paid quickly and keep your personal and payment information secure. Thousands of companies around the world trust Stripe to process payments for their users.</p>
            <h3>Next steps</h3>
            <p>In order to get your funds, Stripe will guide you through a secure multi-step process to authenticate your identity and to verify your payment information.</p>
            <p>If you cannot complete some of the information you can return here to continue later.</p>
            <h3>What you need. Please read.</h3>
            <p>Stripe will require some or all of the following depending on whether you are an individual or organization:</p>
            <ul>
                <li>Name.</li>
                <li>Email.</li>
                <li>Cell phone number to get 2 step auth codes.</li>
                <li>Business website. Individuals can click the <strong>add a product description</strong> link and just type "Receiving funds from FunditOn".</li>
                <li>Bank details or debit card details where you want to receive the funds.</li>
                <li>To accept Stripe Terms of Service</li>
            </ul>
        </div>
        <div class="panel" v-if="requirements && admin_confirmed">
        	<h3>Congratulations, you have received funds for your cause!</h3>
        	<p>When you reach a certain threshold Stripe likes to perform some extra security checks to verify your identity.</p>
        	<p>You will need to complete them before you get your next payment. Please click the link below.</p>
        </div>
        <div class="step1 form-group" v-if="!account">
            <div class="empty">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/funds.svg">
                <p class="nothing">Connect to Stripe to start collecting your funds.</p>
            </div>
            <a class="btn btn-primary" @click.prevent="getConnectUrl">Connect with Stripe</a>
        </div>
        <div class="step form-group" v-if="requirements && account">
            <a class="btn btn-primary" target="_blank" href="" @click.prevent="getConnectUrl">Stripe needs more information</a>
        </div>
        <div class="step" v-if="req_complete && !admin_confirmed">
            <p>You are waiting on a FunditOn admin to confirm your Stripe account. Verification is typically complete within 1 to 2 business days. Return to this page(My funds) under your Settings to check your status.</p>
            <p>In the meantime Stripe may request some more information. If so you will be prompted to return to Stripe to complete their process.</p>
            <p>Once admin verification is complete your bank or debit card will receive the cleared funds automatically at the end of each month.</p>
        </div>
        <div class="step" v-if="req_complete && admin_confirmed">
            <p>You are set up to receive payments. When you have cleared funds transfers will happen automatically at the end of each month.</p>
            <p>If you need to update your bank or card details click here: <a type="button" class="btn btn-primary simple" @click="loginLink">Update Stripe</a></p>
        </div>
        <div class="step" v-if="!req_complete && admin_confirmed">
        	<h3 class="attention">Action Required</h3>
            <p>You have hit a Stripe threshold where they need some more information for you to continue receiving payouts. Usually because you have received over $3000. You will need your ein if your are a non-profit or the last 4 of your social and your date of birth if you are an individual.</p>
            <p>To update your details click here: <a class="btn btn-primary" @click.prevent="getConnectUrl">Update Stripe</a></p>
        </div>
        
        <payouts v-if="admin_confirmed" :transfers="account.transfers" :payouts="account.payouts"></payouts>
            <!-- Plural as a user could potentially be receiving from more than one cause to the same connected account -->
        <payments v-if="admin_confirmed" :causeAccounts="causeAccounts"></payments>
        
    </div>
</template>
<script>
import Payouts from './Payouts';
import Payments from './Nextpayments';
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {
    props: ['uuid'],
    data() {
        return {}
    },
    components: {
        Payouts,
        Payments
    },
    mounted() {
        this.checkUserStatus();
    },
    computed: {
        ...mapState({ profile: state => state.account.profile }),
        ...mapState({ causeAccounts: state => state.account.causeAccounts }),

        account() {
            if (this.profile.connectedaccount) {
                return this.profile.connectedaccount;
            }
        },
        requirements() {
            if (this.account) {
                var req = $.parseJSON(this.account.requirements);
                if (!req || req && req.currently_due.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        },
        req_complete() {
            if (this.account) {
                return this.account.req_complete;
            }
            return false;
        },
        admin_confirmed() {
            if (this.account) {
                return this.account.admin_confirmed;
            }
            return false;
        }
    },
    methods: {
        ...mapActions(['checkConnectedAccountStatus']),
        checkUserStatus() {
            if (this.uuid) {
                var payload = {
                    "uuid": this.uuid
                } 
                this.checkConnectedAccountStatus(payload).then(response => {
                   //TODO look at what is shown when this response returns
                });
            }
        },
        //TODO
        updateAllAccounts() {
            if (this.account && this.accounts > 0) {
                // need to check if the uuid passed in is for a new cause

            }
        },
        getConnectUrl() {
            let loader = this.$loading.show();
            if (this.uuid) {
                var url = '/api/get_connect_token_url?uuid=' + this.uuid;
            } else {
                var url = '/api/get_connect_token_url';
            }

            axios.get(url)
                .then(response => {
                    if (response.data.status === 1) {
                        loader.hide()
                        window.location = response.data.url;
                    } else {
                        loader.hide();
                        this.flashMessage({ m: response.data.error, e: 1, d: 2250 });
                    }

                }).catch(error => {
                    loader.hide();
                    this.flashMessage({ m: 'There was error an issue connecting you to Stripe. Please try again later.', e: 1, d: 2250 });
                });
        },
        loginLink() {
        	window.addEventListener("unhandledrejection", (event) => {
			  console.log(event.reason);
			});
        	axios.get('/api/generate_connect_login_link').then(response => {
        		if(response.data.status) {
        			window.open(response.data.url, '_blank').focus();
        		}
        	}).catch(error => {
                    this.flashMessage({ m: 'There was error an issue connecting you to Stripe. Please try again later. If you are using Mac Safari you may be blocking Pop-up windows. Fix at Safari Settings->Websites->Pop-up windows', e: 1, d: 3750 });
                });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#stripeConnect {
	h2.title {
		padding-left: 15px;
	}
	.causeTotals {
		h3{
			margin-bottom: 0;
		}
		padding-left:15px;
		>p {
			font-size: 18px;
			font-weight:600;
			padding-bottom: 3px;
			margin-bottom: 0;
			>span {
				font-size: 16px;
				font-weight:normal;
			}
		}
	}
    // max-width: 700px;

    ul {
        padding-inline-start: 20px;
        font-family: $open-sans;
        color: $textcolor;

        li {
            text-align: left;
        }
    }

    .step,
    .step1,
    .panel {
        padding: 15px;
        color: $textcolor;
        text-align: center;

        p {
            text-align: left;
        }

        // a.btn {
        //     color: $bluetext;
        // }
    }

    .step1 {
        background-color: #fff;

        .empty {
            padding-top: 1% !important;
        }
    }
    .step .attention {
    	color: $red;
    }
}

</style>
