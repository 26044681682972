<template>
    <div class="container causes" v-if="categoryName">
        <titletag :title="`Causes in ${categoryName}`"></titletag>
        <h2 class="title">{{categoryName}}</h2>
        <div class="categoryCauses row row-cols-1 row-cols-sm-2 row-cols-md-3" v-if="causes">
            <template v-if="causes.length">
                <causeitem v-for="item in causes" :key="item.id" :id="item.id" :item="item"></causeitem>
            </template>
            <template v-else>
                <p>Looks like there are no items available in this category at this time</p>
            </template>
        </div>
    </div>
</template>
<script>
import Causeitem from './Item'
import { mapState, createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
export default {
    props: ['urlStr'],
    data() {
        return {
            causes: null,
            categoryName: null,
            a: false
        }
    },
    watch: {
        urlStr(value) {
            this.init();
        }
    },
    components: {
        Causeitem
    },
    name: "causecategory",
    computed: {
        ...mapState({ categoryCauses: state => state.cause.categoryCauses })
    },
    methods: {
        ...mapActions(['setCategoryCauses']),
        init() {
            if (!this.categoryCauses[this.urlStr]) {
                let loader = this.$loading.show();
                this.setCategoryCauses(this.urlStr).then(response => {
                    this.causes = this.categoryCauses[this.urlStr].causes;
                    this.categoryName = this.categoryCauses[this.urlStr].categoryName;
                    loader.hide();
                    if (this.causes.length) {
                        this.initInfinite();
                    }
                });
            } else {
                this.causes = this.categoryCauses[this.urlStr].causes;
                this.categoryName = this.categoryCauses[this.urlStr].categoryName;
            }
        },
        initInfinite() {
            window.addEventListener("scroll", this.scrollListen);
        },
        scrollListen(e) {
            var s = window.scrollY;
            var d = $(document).height() - $(window).height();
            var p = (s / d) * 100;
            if (p > 90) {
                if (!this.a) {
                    this.a = true;
                    this.setCategoryCauses(this.urlStr).then(response => {
                        // this.causes = this.getCauses;
                        this.causes = this.categoryCauses[this.urlStr].causes;
                        this.categoryName = this.categoryCauses[this.urlStr].categoryName;
                        this.a = false;
                    });
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollListen);
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
// .categoryCauses p {
// 	padding:15px;
// }

</style>
