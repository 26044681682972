<template>
	<div class="row messageContainer float-right" id="cannedMessages" v-if="show">
		<div class="canned" @click="setMessageText">
			<span>Is this still available?</span>
		</div>
		<div class="canned" @click="setMessageText">
			<span>Where can I pick this up?</span>
		</div>
		<div class="canned" @click="setMessageText">
			<span>Do you deliver or ship?</span>
		</div>
		<div class="canned" @click="setMessageText">
			<span>What condition is this in?</span>
		</div>
	</div>
</template>
<script>
export default {
	name: "",
	props: ['channel', 'loggedInId'],
	computed: {
		show() {
			return (this.channel.gift.user_id != this.loggedInId) && !this.channel.messages.length;
		}
	},
	methods: {
		setMessageText(e) {
			$('.giftMessage textarea').val($(e.target).text());
			$('.giftMessage textarea').addClass("canning").delay(800).queue(function(next){
				$('.giftMessage textarea').removeClass("canning");
				next()
			});
			
		}
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#accountConversation #cannedMessages {
	background-color:#fff;
	flex-direction: row;
	justify-content: center;
	.canned {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		max-width: 200px;
		border: 1px solid $formgrey;
		border-radius: 10px;
		background-color: #fff;
		color:$textcolor;
		margin:10px 5px;
		padding:10px;
		cursor:pointer;
		font-family: $cat;
		&:hover {
			border-color: $grey;;
		}
	}
}
</style>