<template>
    <div id="personal">
        <h2 class="title">Personal Info</h2>
        <form>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="name" name="name" class="form-control" type="text" v-model="profile.name" placeholder="Public Name">
                    <label class="magic" for="name">Public Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="first_name" name="first_name" class="form-control" type="text" v-model="profile.first_name" placeholder="First name">
                    <label class="magic" for="first_name">First Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="last_name" name="last_name" class="form-control" type="text" v-model="profile.last_name" placeholder="Last Name">
                    <label class="magic" for="last_name">Last Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input id="email" name="email" class="form-control" disabled type="text" v-model="profile.email" placeholder="Email">
                    <label class="magic" for="email">Email</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <a class="secondaryButton mb-2" @click="updatePersonal()">Update</a>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: "",
    props: ['profile'],
    mounted() {
        this.initLabels();
    },
    methods: {
        updatePersonal() {
            if (this.validate()) {
                let loader = this.$loading.show();
                axios.post('/api/auth/change_user_name', { 'name': this.profile.name, 'first_name': this.profile.first_name, 'last_name': this.profile.last_name }).then(response => {
                    if (response.data.status) {
                        this.flashMessage({ m: response.data.message });
                        this.$store.dispatch('user/refresh');
                    } else {
                        this.flashMessage({ m: response.data.message, e: 1 });
                    }
                    loader.hide();
                }, error => {
                    this.flashMessage({ m: error.data.message, e: 1 });
                });
            }
        },
        validate() {
            if (!this.profile.name) {
                this.scrollToError('#name');
                this.flashMessage({ m: 'Name cannot be empty', e: 1 });
                return false;
            }
            return true;
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>
