<template>
    <div id="connectedPayouts" v-if="transfers.length">
        <h2 class="title">Transfer History</h2>
        <table>
            <thead>
                <tr>
                    <th class="first">Amount</th>
                    <th>Destination Account</th>
                    <th>Sent Date</th>
                    <th class="last">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="transfer in transfers">
                    <td class="first">${{transfer.amount}}</td>
                    <td>{{accountName(transfer.externalaccount)}}</td>
                    <td>{{sent_date(transfer.created)}}</td>
                    <td class="last">Sent</td>
                </tr>
            </tbody>
        </table>
        <div class="payoutHistory">
            <h2 class="title">Payout History</h2>
            <p>Your payouts represent deposits to your bank account or card. Normally a couple of business days behind the transfers above.
            </p>
            <p v-if="!payouts.length">No payouts have been been made yet. Your first payout can take an extra 7 days to process due to Stripe's payout policy.</p>
            <table v-if="payouts.length">
                <thead>
                    <tr>
                        <th class="first">Amount</th>
                        <th>Destination Account</th>
                        <th>Arrival Date</th>
                        <th class="last">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payout in payouts">
                        <td class="first">${{payout.amount}}</td>
                        <td>{{accountName(payout.externalaccount)}}</td>
                        <td>{{sent_date(payout.arrival_date)}}</td>
                        <td class="last">{{status(payout.status)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    props: ['transfers', 'payouts'],
    methods: {
        accountName(externalaccount) {
            if (null != externalaccount) {
                if (externalaccount.bank_name) {
                    return externalaccount.bank_name + '(' + externalaccount.last4 + ')';
                }
                return externalaccount.brand + ' ...' + externalaccount.last4
            }
            return '';
        },
        sent_date(timestamp) {
            return window.moment.unix(timestamp).format("MM/DD/YYYY");
        },
        status(s) {
        	s = s.replace('_', ' ');
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    }

}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#connectedPayouts,
#nextPayment {
    margin-bottom: 20px;
    width: 100%;

    .title,
    p {
        padding-left: 15px;
    }

    table {
        display: block;
        margin: 10px;
        width: 100%;

        border: 1 px solid $formgrey;
        table-layout: fixed
    }

    thead,
    tbody {
        width: 100%;

        tr {

            th,
            td {
                width: auto;

            }

            th.last,
            th.first,
            td.last,
            td.first {
                width: auto;
            }
        }
    }
}

</style>
