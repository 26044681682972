<template>
    <div class="giftActions">
        <div id="pp">
            <h4>${{gift.price}}</h4>
            <div class="likeItL float-right" @click="likeThis($event,'gift',gift.id)"><i v-bind:class="liked ? 'fas' : 'far'" class=" fa-heart"></i></div>
        </div>
        <div class="shareItem" @click="shareNow"><i class="fa fa-share-square-o" aria-hidden="true"></i></div>
        <div class="likeIt float-left" @click="likeThis($event,'gift',gift.id)"><i v-bind:class="liked ? 'fas' : 'far'" class=" fa-heart"></i></div>
        <a class="btn float-right" @click="contactDonor">Buy Now</a>
        <a class="btn s float-right" @click="contactDonor">Message</a>
       
    </div>
</template>
<script>
import { getLocalUser } from "../../helpers/auth";
export default {
    props: ['gift'],
    data() {
        return {
            liked: false
        }
    },
    mounted() {
         var user = getLocalUser();
        if (null != user) {
            for (var i = 0; i < user.likes.length; i++)
                if (this.gift.id == user.likes[i].gift_id) {
                    this.liked = true;
                }
        }
    },
    methods: {
        shareNow() {
            var content = $('.itemShare', '#giftPage')[0];
            
            if (content && content.length) {
                $('#shareModal .modal-body').empty().append(content);
            } else {
                content = $('.itemShare')[0];
                $('#shareModal .modal-body').empty().append(content);
            }
            $('#shareModal').modal('show');
            $('#shareModal .itemShare').show();
        },
        goToCheckout() {
            this.$emit('goToCheckout');
        },
        contactDonor() {
            var user = localStorage.getItem("user");
            if (null != user) {
                user = JSON.parse(user);
                if (this.gift.user_id == user.id) {
                    this.flashMessage({ m: 'It looks like you donated this.' });
                    return;
                }
            }
            this.$router.push({ name: 'conversations', params: { 'giftUuid': this.gift.uuid } });
        },
        likeThis(e,t,id) {
        	if(this.gift.published) {
        		this.liked = this.likeIt(e,t,id,this.liked);	
        	}else{
        		this.flashMessage({ m: this.$t('messages.unpubaction') });
        	}
        	
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';


.giftActions {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 1090;
    background: $white;
    box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin-left: -15px; //account for it's container at this size

    @include media-breakpoint-up(sm) {
        position: relative;
        margin-left: 0;
        box-shadow: none;
        display: block;
        z-index: 10;
    }

    .shareItem,
    .likeIt {
        width: 18%;
        color: $lightgrey;
        text-align: center;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            display: none !important;
        }
    }

    #pp {
        width: 100%;
		padding: 12px;
		display: none;
		@include media-breakpoint-up(sm) {
			display: block;
		}
        .likeItL,
        h4 {
            display: none;
            text-align: left;
			
            @include media-breakpoint-up(sm) {
                display: inline-block;
                padding: 0px 20px;
                width: 50%;
            }
        }
        .likeItL {
        	text-align: right;
        	i {
        		font-size: 22px;
        	}

        }
    }


    i.fas {
        color: $red;
    }

    i.far {
        color: $lightgrey;
    }

    i.fa-comments-o {
        color: $lightgrey;
    }

    .btn {
        border-radius: 30px;
        // border: 1px solid $formgrey;
        color: #fff;
        font-family: $cat;
        font-weight: 600;
        font-size: 17px;
        width: 60%;
        height: 50px;
        margin-left: 2%;
        margin-right: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $red;
        &:hover {
        	background-color: #fff;
        	border: 1px solid $red;
        	color: $red;
        }
        &.s {
        	border: 1px solid rgba(177, 176, 190, 0.4);
		    border-radius: 25px;
		    background-color: #fff;
		    color: #51506F;
		    &:hover {
		    	    border-color: #51506F;
		    }
        }
        @include media-breakpoint-up(sm) {
            width: 100%;
            margin: 0;
            margin-bottom:  20px;
        }
    }
}

</style>
