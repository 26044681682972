<template>
    <div class="ct container forms" id="causePhoto" v-if="cause">
        <titletag :title="'Edit Image for  ' + cause.name"></titletag>
        <div v-if="cause" class="container">
            <steps :uuid="cause.uuid" active="s"></steps>
            <h2 class="title">Choose a photo for your cause page</h2>
            <div class="form-group row">
            </div>
            <div class="currentCauseImage causeHideable">
                <img v-if="cloudFrontUrl" @click="openFilePicker" :src="cloudFrontUrl">
            </div>
            <p>Upload JPG or PNG image up to 12 MB. Your image needs to be at least <strong>700px</strong> wide by <strong>370px</strong> high. Just about anything from your phone camera will work.<br>You will be able to crop it.</p>
            <div id="drop-zone">
                <label id="upload-label" class="causeHideable file-upload">Upload a photo by dragging and dropping it onto this dashed region. Or just click here to choose a photo. <span v-if="cloudFrontUrl">It will replace the photo above.</span>
                    <input class="causeHideable" type="file" id="mainCauseImage" accept="image/jpeg,image/png" ref="file" @change="storeFile(null)">
                </label>
                <div class="progress causeHideable">
                    <div id="progress-bar" class="progress-bar progress-bar-striped bg-success" role="progressbar" style="" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="form-group row changePhoto">
                <div class="col-sm-12">
                    <a @click="changePhoto" class="btn btn-primary simple">Try another photo</a>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input type="text" id="city" v-model="cause.city" placeholder="Your cause's city" class="form-control form-control-lg" required="true">
                    <label class="magic" for="city">City</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <!-- <input type="text" id="state" maxlength="2" minlength="2" v-model="cause.state" :placeholder="$t('address.state')" class="form-control form-control-lg" required="true"> -->
                    <select id="state" class=" form-control" v-model="cause.state" :placeholder="$t('address.state')">
                        <option disabled value="null">Choose a state</option>
                        <option v-for="state in states()" :value="state.v">{{state.n}}</option>
                    </select>
                    <label class="magic" for="state">State</label>
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-sm-12">
                    <a @click="skip" class="btn btn-primary simple">Skip this step</a>
                </div>
            </div> -->
            <div class="form-group row save">
                <div class="col-sm-12">
                    <a @click="saveChanges" class="btn btn-primary">Save</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Steps from './Steps';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
export default {
    props: ['uuid'],
    name: 'editcausephoto',
    components: {
        Steps
    },
    data() {
        return {
            cropInfo: null,
            imageUuid: null,
            imageChanged: false,
        }
    },
    watch: {
        cause(value) {
            if (value) {
                setTimeout(() => {
                    if (window.location.pathname.indexOf('edit_photo') != -1) {
                        this.initDropZone();
                    }
                }, 100);
            }
        }
    },
    computed: {
        ...mapGetters(['getcauseByUuid']),
        cause() {
            return this.getcauseByUuid(this.uuid);
        },
        cloudFrontUrl() {
            if (this.cause.primary_photo && this.cause.primary_photo.path_full) {
                return this.getCloudFrontUrl() + this.cause.primary_photo.path_full;
            } else {
                return false;
            }
        }
    },
    mounted() {
        if (!this.cause || !this.cause.primary_photo) {
            this.setCauseByUuid(this.uuid).then((res) => {
                this.initDropZone();
                if (this.cause.primary_photo) {
                    $('.changePhoto').hide();
                }
                // $('.save').hide();
            });
        } else {
            this.initDropZone();
            if (this.cause.primary_photo) {
                $('.changePhoto').hide();
            }
            // $('.save').hide();
        }
        setTimeout(this.initLabels, 200);

    },
    methods: {
        ...mapActions(['setCauseByUuid', 'saveCauseImage']),
        // skip() {
        //     this.$router.push({ name: 'causelocation', params: { uuid: this.uuid, v: "t" } });
        // },
        openFilePicker() {
            $("#upload-label input").trigger("click");
        },
        changePhoto() {
            $('.causeHideable').show();
            $('#drop-zone').removeClass('noBorder');
            $('.rcrop-wrapper').remove();
            $('#progress-bar').attr('aria-valuenow', 0).css('width', 0);
            $('.changePhoto').hide();
            $('.currentCauseImage').remove();
        },
        badImage() {
            this.flashMessage({ m: "Your uploaded file needs to be at least 700px wide by 370px high. Please try again.", e: 1, d: 2250 });
            this.changePhoto();

        },
        validateChanges() {
            if (!this.cause.city) {
                this.scrollToError('#city');
                this.flashMessage({ m: 'Your cause needs a city', e: 1 });
                return false;
            }
            if (!this.cause.state || this.cause.state.length !== 2) {
                this.scrollToError('#city');
                this.flashMessage({ m: 'Please choose a state', e: 1 });
                return false;
            }
            return true;
        },
        saveChanges() {
            if (this.validateChanges()) {
                let loader = this.$loading.show();
                this.saveCauseImage(this)
                    .then((response) => {
                        $('#picture').rcrop('destroy');
                        if (response.data.status == 'success') {
                            this.$router.push({ name: 'causepublishshare', params: { uuid: this.uuid, v: "fo" } });
                        } else {
                            this.flashMessage({ m: response.data.error, e: 1, d: 3500 });
                            setTimeout(function() {
                                window.location.reload();
                            }, 3000);
                        }
                        loader.hide();
                    });
            }


        },
        initRCrop() {
            var self = this;
            this.imageChanged = 1;
            // $('.save').show();
            $('#picture').rcrop({
                minSize: [window.FunditOn.causeImageWidth, window.FunditOn.causeImageHeight],
                preserveAspectRatio: true,
                grid: true,
            });
            $('#picture').on('rcrop-ready', function() {
                self.cropInfo = $('#picture').rcrop('getValues');
                if (self.cropInfo.x < 0 || self.cropInfo.y < 0) {
                    self.badImage();
                }
            });


            $('#picture').on('rcrop-changed', function() {
                self.cropInfo = $('#picture').rcrop('getValues');

            });
        },
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        initDropZone() {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                document.getElementById("drop-zone").addEventListener(eventName, this.preventDefaults, false)
                document.body.addEventListener(eventName, this.preventDefaults, false)
            });
            ['dragenter', 'dragover'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.highlight, false)
            });
            ['dragleave', 'drop'].forEach(eventName => {
                document.getElementById("upload-label").addEventListener(eventName, this.unhighlight, false)
            });

            // Handle dropped files
            document.getElementById("drop-zone").addEventListener('drop', this.handleDrop, false)
        },
        highlight(e) {
            document.getElementById("upload-label").classList.add('highlight')
        },
        unhighlight(e) {
            document.getElementById("upload-label").classList.remove('highlight')
        },
        handleDrop(e) {
            var dt = e.dataTransfer
            var file = dt.files[0];
            this.storeFile(file);
        },
        async store(file) {
            // axios.defaults.headers.common["Authorization"] = `Bearer ${window.token}`
            const response = await axios.post('/vapor_signed_url', {
                'bucket': '',
                'content_type': file.type,
                'expires': ''
            });

            // var instance = axios.create();
            // instance.defaults.headers.common = {};
            // instance.defaults.withCredentials = false;
            const s3Response = await axios.put(response.data.url, file, {
                headers: response.data.headers,
                onUploadProgress: (progressEvent) => {
                    var width = ((progressEvent.loaded / progressEvent.total) * 100);
                    if (width > 98) {
                        $('.causeHideable').hide();
                        $('#drop-zone').addClass('noBorder');
                        $('.changePhoto').show();
                    }
                    $('#progress-bar').attr('aria-valuenow', width).css('width', width + '%');

                }
            });
            return response.data;
        },
        storeFile(file) {
            if (null == file) {
                file = this.$refs.file.files[0];
            }
            // let loader = this.$loading.show({  container: $('#drop-zone') });
            this.store(file).then(response => {
                $('.causeHideable').hide();
                $('#drop-zone').addClass('noBorder');
                this.imageUuid = response.uuid;
                var url = response.url.split('?');
                var img = $('<img id="picture" src="' + url[0] + '">');
                $('#drop-zone').append(img);
                var self = this;
                $('#picture').on('load', function() {
                    self.initRCrop();
                    // loader.hide();
                });
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#causePhoto {
    max-width: 750px;

    .currentCauseImage {
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
            border-radius: 13px;
        }
    }

    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        padding-left: 15px;
    }

    p {
        color: $textcolor;
        // font-family: $open-sans;
        font-weight: normal;
        font-size: 15px;
        padding-left: 15px;
        padding-top: 10px;
    }


}

.progress {
    margin-top: 5px;
}

#progress-bar {
    background-color: darken($blue, 10%) !important;
}

#drop-zone {

    width: 100%;

    margin-bottom: 20px;
    padding: 10px;

    &.noBorder {
        border: none;
    }

    .file-upload {
        &.highlight {
            border: 2px dashed $bluetext;
        }

        width: 100%;
        display: inline-block;
        height: 100%;
        min-height:130px;
        background-color:$blue;
        border: 1px dashed $bluetext;
        border-radius: 20px;
        padding:10px;
        position: relative;
        cursor: pointer;
        text-align: center;

        &:before {
            font-family: 'FontAwesome';
            content: "\f382";
            left: 50%;
            top: 60%;
            color: darken($blue, 6%);
            position: absolute;
            font-size: 27px;
            margin-left: -16.370px;
            margin-top: 0px;


        }

        input[type="file"] {
            visibility: hidden;
            height: 100%;
            margin: -10px;
        }
    }
}

#drop-zone.highlight {
    border-color: $bluetext;
}

p {
    margin-top: 0;
}

#picture {
    width: 100%;
    max-width: 100%;
    max-height: 100%;

    height: auto;
}

.changePhoto {
    display: none;
}

</style>
