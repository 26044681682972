<template>
	<div  class="progress-contain">
		<!-- <span class="amount">${{Math.round(amount)}}</span><span class="goal">{{ $t("causes.collected") }}${{goal}}</span> -->
		<div class="progress" >
	  		<div class="progress-bar" v-bind:style="{ width: percent + '%' }"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['item'],
		data() {
			return {
				amount:null,
				goal: null,
				percent:0
			}
		},
		mounted() {
			this.setData();
		},
		methods: {
			setData() {
				this.goal = this.item.goal;
				if(this.item.account && this.item.account.balance > 0){
					this.amount = this.item.account.balance;
					
					if(this.amount < this.goal)
					{
						this.percent = (this.amount / this.goal) * 100;
					}else{
						this.percent = 100;
					}
				}
			}
		}
	}
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.progress-contain {
	padding: 15px;
	width:100%;
	.progress {
			height:8px;
			background-color: $blue;
			width: 100%;
			.progress-bar {
				height:8px;
				// color:$buttonblue;
				background-color: $buttonblue;
			}
	}
}
</style>