<template>
    <div v-if="show">
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="street" class="form-control" type="text" v-model="address.address_1" :placeholder="$t('address.street')">
                <label class="magic" for="street">Street</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="street_2" class="form-control" type="text" v-model="address.address_2" :placeholder="$t('address.flat')">
                <label class="magic" for="street_2">Apartment number</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="city" class="form-control" type="text" v-model="address.city" :placeholder="$t('address.city')">
                <label class="magic" for="city">City</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <!-- <input id="state" class="form-control" type="text" maxlength="2" minlength="2" v-model="address.state" :placeholder="$t('address.state')"> -->
                <select id="state" class="form-control" v-model="address.state" :placeholder="$t('address.state')">
                    <option disabled value="undefined">{{$t('address.state')}}</option>
                    <option v-for="state in states()" :value="state.v">{{state.n}}</option>
                </select>
                <label class="magic" for="state">State</label>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <input id="zip" class="form-control" type="text" maxlength="5" minlength="5" v-model="address.zip" :placeholder="$t('address.zip')">
                <label class="magic" for="zip">Zip</label>
            </div>
        </div>
        <!--  <div class="form-group row">
            <div class="col-sm-12">
                <input id="phone" class="form-control" type="tel" maxlength="16" v-model="address.phone" :placeholder="$t('address.phone')">
                <label class="magic" for="phone">Phone</label>
            </div>
        </div> -->
        <div class="form-group row">
            <div class="col-sm-12">
                <a class="btn btn-primary mb-2" @click="verifyAddress()">{{saveText}}</a>
            </div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('account');
export default {
    props: ['validate', 'saveText'],
    data() {
        return {
            show: true,
            changed: 0,
            errors: []
        }
    },
    computed: {
        ...mapGetters(['address']),
    },
    mounted() {
        this.init();
    },
    watch: {
        address: {
            handler: function(v) {
                this.changed++;
            },
            deep: true
        }
    },
    methods: {
        ...mapActions(['setAccountDetails', 'updateAddress']),
        init() {
            if (null == this.address.address_1) {

                let loader = this.$loading.show();
                this.setAccountDetails().then(response => {
                    // phoneFormat();
                    setTimeout(() => {
                        this.changed = 0;
                        loader.hide();
                        setTimeout(this.initLabels, 200);
                    }, 200);
                });
            } else {
                setTimeout(this.initLabels, 200);
                // phoneFormat();
                setTimeout(() => {
                    this.changed = 0;
                }, 200);
            }

        },
        checkForm: function(e) {
            $('input,textarea,select').removeClass('e');
            if (!this.address.address_1) {
                this.scrollToError('#street');
                this.flashMessage({ m: this.$t('address.valid.street'), e: 1 });
                return false;
            }
            if (!this.address.city) {
                this.scrollToError('#city');
                this.flashMessage({ m: this.$t('address.valid.city'), e: 1 });
                return false;

            }
            if (!this.address.state) {
                this.scrollToError('#state');
                this.flashMessage({ m: this.$t('address.valid.state'), e: 1 });
                return false;
            }
            if (this.address.state && this.address.state.length != 2) {
                this.scrollToError('#state');
                this.flashMessage({ m: this.$t('address.valid.state'), e: 1 });
                return false;
            }
            if (!this.address.zip) {
                this.scrollToError('#zip');
                this.flashMessage({ m: this.$t('address.valid.zip'), e: 1 });
                return false;
            }
            if (this.address.zip && this.address.zip.length != 5) {
                this.scrollToError('#zip');
                this.flashMessage({ m: this.$t('address.valid.zip-l'), e: 1 });
                return false;
            }

            return true;
        },
        verifyAddress() {
            if (this.checkForm()) {
                if (this.changed < 1 && this.address.verified == 1) {
                    //user changed nothing and submitted
                    //System already knows their address is shippable.
                    this.$emit('setVerified', this.address.verified);
                } else {
                    if (this.validate) {
                        this.address.validate = 1;
                    }
                    // this.address.country = 'US'; // For now we will do this
                    let loader = this.$loading.show();
                    this.updateAddress(this.address).then(response => {
                        this.changed = 0;
                        this.$emit('setVerified', this.address.verified);
                        loader.hide();
                        //parent component can message them what to do 
                    })
                    // save address info and verify address at server for post.
                }
            }
        }
    }
}

</script>
