<template>
    <div class="container eula forms ct" v-if="page">
        <titletag :title="`End user agreement`" description="ASAS"></titletag>
        <h1>{{pages["eula"].title}}</h1>
        <div id="wpPostEmbed" class="page inner" v-html="page"></div>
        <div class="agree">
            <a @click="agreed" class="btn disabled" >I have read and agree to these terms</a>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers, mapState } from 'vuex'
const { mapActions } = createNamespacedHelpers('wp');
export default {
    name: "eula",
    data() {
        return {
            page: null,
            enabled: false
        }
    },
    computed: {
        ...mapState({ pages: state => state.wp.pages })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(['setPage']),
        init() {
            if (!this.pages['eula']) {
                this.retrieve();
            } else {
                this.display();
            }
        },
        agreed() {
        	if(this.enabled) {
        		let loader = this.$loading.show();
        		axios.post('/api/agree_to_eula', {}).then(response => {
                if (response.data.status) {
                    this.$store.dispatch('user/refresh').then(response => { 
                    	loader.hide();
                 		this.$router.push(this.$route.query.redirect || '/')
                    });
                    
                } else {
                	loader.hide();
                    this.flashMessage({ m: "There was a problem with your request", e: 1 });
                }

            }, error => {
                this.flashMessage({ m: "There was a problem with your request.", e: 1 });
            })
        	}
            
        },

        disclaimerFunction(e) {

            var elem = $(e.currentTarget);
            if (elem[0].scrollHeight - elem.scrollTop() < (elem.outerHeight() + 10)) {
                $('.agree a').removeClass('disabled');
                this.enabled = true;
            }
        },
        display() {
            this.page = this.pages["eula"].content;
            // this.wpHead(this.pages["eula"].yoast);
            setTimeout(() => {
                document.getElementById("wpPostEmbed").addEventListener("scroll", this.disclaimerFunction);
            }, 100);

            // this.disclaimerFunction();
        },
        retrieve() {
            let loader = this.$loading.show();
            this.setPage({ "s": "eula", "p": 'page' }).then(response => {
                if (response.slug) {
                    loader.hide();
                    this.display();
                } else {
                    loader.hide();
                    this.$router.push('/404');
                }
            }, error => {
                loader.hide();
                this.$router.push('/404');
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.eula {
    max-width: 650px;

    #wpPostEmbed {
        max-height: 350px;
        overflow-y:scroll !important;
        ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
overflow-x: hidden;
        @media (min-height: 600px) {
        	max-height: 480px;
        }
        @media (min-height: 700px) {
        	max-height: 520px;
        }
        @include media-breakpoint-up(sm) {
            max-height: 550px;
        }
        @media (min-height: 1000px) {
        	max-height: 720px;
        }
        @media (min-height: 1200px) {
        	max-height: 950px;
        }
        padding:5px;
        background-color: lighten($lightgrey, 25%);
        margin-top: 10px;
    }

    .bottom {
        text-align: center;
        width: 100%;
    }

    .agree {
        display: block;
        margin: 10px auto;
        width: 100%;
        max-width: 650px;

        // this should be refactored as there a few buttons like this
        .btn {
            background-color: #4898FF;
            color: #FFFFFF;
            font-family: "Catamaran", sans-serif;
            font-size: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 25px;
            &.disabled {
            	background-color: $lightgrey;
            	color: #FFFFFF;
            }
        }
    }
}

</style>
