require('./bootstrap');
require('./rcrop.min.js');
// require('babel-polyfill');
require('es6-promise').polyfill();
require('webpack-jquery-ui/autocomplete');


window.moment = require('moment');
// import Echo from 'laravel-echo'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import myroutes from './routes'
import { store } from './store/store'
import App from './App.vue'
import Navigation from './components/nav/Navigation.vue'

import { initialize } from './helpers/auth'
import Loading from 'vue-loading-overlay'
import Titletag from './components/layout/Titletag';
import MyMixins from './helpers/MyMixins'
import AOS from 'aos';
import 'aos/dist/aos.css';
var SocialSharing = require('vue-social-sharing');

Vue.component('titletag', Titletag);
Vue.component('html-fragment', {
  functional: true,
  props: ['html'],
  render(h, ctx) {
    const nodes = new Vue({
      // beforeCreate() { this.$createElement = h }, // not necessary, but cleaner imho
      template: `<div>${ctx.props.html}</div>`
    }).$mount()._vnode.children
    return nodes
  }
})
Vue.use(MyMixins);

Vue.use(Loading);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(SocialSharing);
export const router = new VueRouter({
    mode: 'history',
    routes: [
        ...myroutes
    ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});
initialize(store, router);

// Create VueI18n instance with options
export const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: loadLocaleMessages() // set locale messages
})

function loadLocaleMessages() {
    const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

// Note that FunditOn is being used in app.blade
window.Funditon = new Vue({
    el: '#app',
    store,
    components: {
        App,
        Navigation,
        Loading
    },
    router,
    i18n
});
window.Vapor = require('laravel-vapor');

//closes menu when clicking outside of bootstrap menu when it is open
$(document).ready(function() {
	$('#overlay').removeClass('initial');
    $(document).click(function(event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("show");
        if (_opened === true && !clickover.hasClass("navbar-toggler")) {
            $(".navbar-toggler").click();
        }
    });
    AOS.init({
        // duration: 700,
        // delay:300
    });
});
