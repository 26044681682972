<template>
	<div>
		<hheader v-if="homeData" :homeData="homeData"></hheader>
		<div class="row mainContain">
			<router-view></router-view>
		</div>
		<foot></foot>
	</div>
</template>
<script>
import Foot from './components/layout/Footer'
import Hheader from './components/home/Header';
import { mapState } from 'vuex';
export default {
	components: {
		Foot,
		Hheader
	},
	computed: {
        ...mapState({ homeData: state => state.slots.homeData })
    },
}
</script>
