<template>
    <div class="ct container forms" id="newCause">
        <titletag title="Start a Fundraiser" description="Raising money for a mission trip, youth sports team, an individual, or a charity? Start here to raise money fast for charity with the unique and easy FundItOn."></titletag>
        <div class="container">
            <steps active="f"></steps>
            <h2 class="title">Step 1: DO NOT Start a Cause</h2>
            <!-- <h4>Tell us a little about your cause. You will have a chance to edit later</h4> -->
            <h4>DO NOT START A CAUSE. WE ARE SHUTTING DOWN THE SITE.</h4>
            <form>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" id="name" v-model="name" placeholder="Name your cause" class="form-control form-control-lg" required="true">
                        <label class="magic" for="name">Cause title</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="number" id="goal" min="100" v-model="goal" step="100" placeholder="Goal: amount you wish to raise" class="form-control form-control-lg" required="true">
                        <label class="magic" for="goal">Goal</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <select v-model="selectedCategory" class="category form-control" id="category" required="true">
                            <option value="" selected>Choose your Cause Category</option>
                            <option v-for="category in causeCategories" :value="category.id">{{category.name}}</option>
                        </select>
                        <label class="magic" for="category">Cause Category</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <textarea rows="5" class="form-control form-control-lg" type="text" id="description" v-model="description" placeholder="Share your story. Tell people something about your cause" minlength="10" required="true"></textarea>
                        <label class="magic" for="description">Description</label>
                    </div>
                </div>
                <div class="form-group row">

                    <div class="col-sm-12">
                    	<p class="radio pl-3">Who will be receiving the funds raised?</p>
                        <div class="form-check form-check-inline pl-3">
                            <input class="form-check-input" type="radio" id="receiver1" v-model="receiver" value="1" required="true">
                            <label class="form-check-label radio" :class="receiver === '1' ? 'selected': ''" for="receiver1">Myself</label>
                        </div>
                        <div class="form-check form-check-inline pl-3">
                            <input class="form-check-input" type="radio" id="receiver2" v-model="receiver" value="0" required="true">
                            <label class="form-check-label radio" :class="receiver === '0' ? 'selected': ''" for="receiver2">Another person or organization</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text"  id="ein" v-model="ein" @blur="addDash" placeholder="EIN (required for non-profits)" class="form-control form-control-lg" required="false">
                        <label class="magic" for="ein">Ein</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <a @click="sendForm" class="btn btn-primary">Next ></a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Steps from './Steps';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
export default {
    props: [],
    components: {
        Steps
    },
    data() {
        return {
            name: '',
            ein: '',
            goal: '',
            description: '',
            selectedCategory: '',
            receiver: '',
            submitted: false
        }
    },
    computed: {
        ...mapGetters(['categories']),
        causeCategories() {
            return this.categories;
        }
    },
    mounted() {
        if (this.categories.length < 1) {
            this.setCategories();
        }
        this.initLabels();
    },
    methods: {
        ...mapActions(['setCategories', 'addCause']),
        sendForm() {
            if (!this.submitted && this.validate()) {
                this.sendCause();
            }
        },
        addDash() {
        	var r = /(\D+)/g,
		        b = '',
		        e = '';   
		    this.ein = this.ein.replace(r, '');
		    b = this.ein.substr(0, 2);
		    e = this.ein.substr(2, 7);
		    if(b > 1) {
		    	this.ein = b + '-' + e;
			}
        },
        validate() {
            if (!this.name) {
                this.scrollToError('#name');
                this.flashMessage({ m: 'Your cause needs a title', e: 1 });
                return false;
            }
            if (!this.goal) {
                this.scrollToError('#goal');
                this.flashMessage({ m: 'Your cause needs a goal', e: 1 });
                return false;
            }
            if (!this.selectedCategory) {
                this.scrollToError('#category');
                this.flashMessage({ m: 'Your cause needs a category', e: 1 });
                return false;
            }
            if (!this.description) {
                this.scrollToError('#description')
                this.flashMessage({ m: 'Your cause needs a description', e: 1 });
                return false;
            }
            if (this.description.length < 10) {
                this.scrollToError('#description')
                this.flashMessage({ m: 'Your cause story needs a few words :)', e: 1 });
                return false;
            }
            if(this.ein) {
            	this.addDash();
            	var patternEIN = /^\d{2}\-?\d{7}$/;
            	if(!patternEIN.test(this.ein)) {
            		this.scrollToError('#ein')
                	this.flashMessage({ m: 'EIN format 00-0000000 or 000000000', e: 1 });
                	return false;
            	}
            }
            if (this.receiver.length < 1) {
                this.scrollToError('#receiver1')
                this.flashMessage({ m: 'Who receives the funds raised?', e: 1 });
                return false;
            }
            return true;
        },
        sendCause() {
            this.submitted = true;
            let loader = this.$loading.show();
            var payload = {
                "name": this.name,
                "goal": this.goal,
                "ein": this.ein.replace("-",""),
                "category_id": this.selectedCategory,
                "description": this.description,
                "self_to_receive_funds": this.receiver,
            };
            this.addCause(payload)
                .then((response) => {
                    // this.$router.push({ name: 'newcausephoto', params: { uuid: response.data.uuid, cause: response.data } });
                    this.$store.commit('account/setChanged', { "a": "causes" });
                    this.$router.push({ name: 'editcausephoto', params: { uuid: response.data.uuid } });
                    loader.hide();

                })
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#newCause {
    max-width: 750px;

    .title {
        padding-top: 1rem;
        font-weight: 700;
        color: $bluetext;
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        padding-left: 15px;
    }

    h4 {
        color: $textcolor;
        font-family: $open-sans;
        font-weight: normal;
        font-size: 16px;
        padding-bottom: 1rem;
        padding-left: 15px;
    }

    //   a.next {
    //       border-radius: 30px;
    //       width:100%;
    //       height: 50px;
    //       border: none;
    //       // margin-left: 2%;
    //       // margin-right: 2%;
    // font-family: $cat;
    // font-weight: 600;
    // font-size: 15px;
    // line-height: 40px;
    // letter-spacing: 0;
    //       background-color: $red;
    //       color: $white;

    //   }
}

</style>
