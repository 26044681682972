export default {
    namespaced: true,
    state: {
        profile: null,
        // on these pages read only
        causeOrders: {},
        causes: [],
        gifts: [],
        purchases: [],
        address: {},
        likedItems: null,
        likedCauses: null,
        causeAccounts: [],
        changed: [] // Implement this so each section of the account can decide if it needs to refresh
    },
    getters: {
    	causeAccounts(state) {
            return state.causeAccounts;
        },
        profile(state) {
            return state.profile;
        },
        causes(state) {
            return state.causes;
        },
        gifts(state) {
            return state.gifts;
        },
        purchases(state) {
            return state.purchases;
        },
        address(state) {
            return state.address;
        },
        likedItems(state) {
            return state.likedItems;
        },
        likedCauses(state) {
            return state.likedCauses;
        },
        changed(state) {
            return state.changed;
        },
        causeOrders(state) {
            return (urlStr) => {
                state.causeOrders.urlStr;
            }
        }
    },
    mutations: {
    	setCauseAccounts(state, payload) {
            state.causeAccounts = payload;
        },
        setProfile(state, payload) {
            state.profile = payload;
        },
        setProfilePhoto(state, payload) {
            state.profile.avatar_url = payload
        },
        setProfileCauseSharedStatus(state,payload) {
        	var c = state.profile.causes_shared_with.find(shared => shared.id == payload.cause_id);
        	if (c) {
        		c.pivot.sharing = payload.sharing;
        	}else{
        		state.profile.causes_shared_with.push({'id': payload.cause_id, 'pivot': payload.pivot});
        		
        	}
        	state.profile.first_name = payload.first_name;
        	state.profile.last_name = payload.last_name;
        },
        updateNotficationPref(state, payload) {
            state.profile[payload.key + '_notifications'] = payload.value;
        },
        setCauses(state, payload) {
            state.causes = payload;
        },
        setRecipient(state,payload) {
        	var cause = state.causes.find(causes => causes.uuid == payload.uuid);
        	state.causes.find(causes => causes.uuid == payload.uuid).recipient = payload.recipient;
        },
        setGifts(state, payload) {
            state.gifts = payload;
        },
        setPurchases(state, payload) {
            state.purchases = payload;
        },
        setAddress(state, payload) {
            if (null != payload) {
                state.address = payload;
            }
        },
        setLikedItems(state, payload) {
            state.likedItems = payload;
        },
        setLikedCauses(state, payload) {
            state.likedCauses = payload;
        },
        setCauseOrders(state, payload) {
            state.causeOrders[payload.url_str] = payload.orders;
        },
        // so far only likes make use of this
        setChanged(state, payload) {
            if (null == payload) {
                state.changed = []; // reset as loading them all
            } else {
                //add to changed list forcing cache busting for next view of the page
                if (payload.a) {
                    if (state.changed.indexOf(payload.a) == -1) {
                        state.changed.push(payload.a);
                    }
                } else {
                    // reset as just received the new data
                    if (payload.r) {
                        var index = state.changed.indexOf(payload.r);
                        if (index != -1) {
                            state.changed.splice(index, 1);
                        }
                    }
                }
            }
        }
    },
    actions: {
        setAccountDetails(context) {
            return new Promise((resolve) => {
                axios.get('/api/account_details')
                    .then((response) => {
                        context.commit('setCauses', response.data.causes);
                        context.commit('setGifts', response.data.gifts);
                        context.commit('setProfile', response.data.user);
                        context.commit('setCauseAccounts', response.data.cause_accounts);
                        context.commit('setPurchases', response.data.orders);
                        context.commit('setAddress', response.data.address);
                        context.commit('setLikedItems', response.data.liked_items);
                        context.commit('setLikedCauses', response.data.liked_causes);
                        context.commit('setChanged', null);
                        
                        // context.commit('channel/setChannels', response.data.channels,{ root: true });
                        resolve();
                    });
            });
        },
        // not used yet. will be when we fix the caching
        setGifts(context) {
            return new Promise((resolve) => {
                axios.get('/api/user_gifts')
                    .then((response) => {
                        context.commit('setGifts', response.data);
                        resolve();
                    });
            });
        },
        setPurchases(context) {
            return new Promise((resolve) => {
                axios.get('/api/user_purchases')
                    .then((response) => {
                        context.commit('setPurchases', response.data);
                        resolve();
                    });
            });
        },
        updateAddress: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/update_user_address', payload)
                    .then(response => {
                        context.commit('setAddress', response.data);
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        updateAvatarUrl: (context, payload) => {
            return new Promise((res, rej) => {
                context.commit('setProfilePhoto', payload);
                res(1);
            })
        },
        setCauseOrders: (context, payload) => {
            return new Promise((res, rej) => {
                axios.get('/api/cause_orders/' + payload)
                    .then(response => {
                        context.commit('setCauseOrders', response.data);
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        deleteGift: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/delete_gift',{"uuid": payload})
                    .then(response => {
                    	if(response.data.status) {
                    		context.commit('setGifts', response.data.gifts);
                    		context.commit('gift/resetGifts', null,{ root: true });
                    		context.commit('gift/resetCauseGifts', null,{ root: true });
                    		context.commit('gift/resetCategoryGifts', null,{ root: true });
                    	}
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        deleteCause: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/delete_cause',{"uuid": payload})
                    .then(response => {
                    	if(response.data.status) {
                     	   context.commit('setCauses', response.data.causes);
                     	   context.commit('cause/resetCauses', null,{ root: true });
                    	}
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        refreshLikes: (context) => {
        	return new Promise((res, rej) => {
                axios.get('/api/user_likes')
                    .then(response => {
                        context.commit('setLikedItems', response.data.liked_items);
                        context.commit('setLikedCauses', response.data.liked_causes);
                        context.commit('setChanged', {r: 'liked_causes'});
                        context.commit('setChanged', {r: 'liked_items'});
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        refreshCauses: (context) => {
        	return new Promise((res, rej) => {
                axios.get('/api/user_causes')
                    .then(response => {
                        context.commit('setCauses', response.data);
                        context.commit('setChanged', {r: 'causes'});
                        res(response);
                    }, error => {
                        rej(error);
                    })
            })
        },
        refreshPurchases: (context) => {
        	console.log('implement');
        },
        publishCause: (context, payload) => {
            return new Promise((res, rej) => {
                axios.post('/api/publish_cause', payload)
                    .then(response => {
                        if (response.data.success) {
                            //returning all causes as maybe an update happened
                            context.commit('setCauses', response.data.causes);
                            context.commit('cause/deleteCause', payload.uuid,{ root: true });
                            context.commit('cause/resetCauses', null,{ root: true });
                            res(response.data)
                            // this.flashMessage({m: "Your cause has been published"});
                        } else {
                            rej(response.data);
                        }
                    }, error => {
                    	rej(error);
                    });
            });
        },
        assignRecipient: (context,payload) => {
        	return new Promise((res, rej) => {
                axios.post('/api/assign_recipient', payload)
                    .then(response => {
                        if (response.data.success) {
                            //returning all causes as maybe an update happened
                            context.commit('setRecipient', response.data);
                           
                            res(response.data)
                            // this.flashMessage({m: "Your cause has been published"});
                        } else {
                            rej(response.data);
                        }
                    }, error => {
                    	rej(error);
                    });
            });
        },

        checkConnectedAccountStatus: (context,payload) => {
        	return new Promise((res, rej) => {
                axios.get('/api/connected_account_status?uuid='+ payload.uuid)
                    .then(response => {
                        if (response.data.status && response.data.change) {
                            context.commit('setCauseAccounts', response.data.cause_accounts);
                           
                            res(response.data);
                            
                        } else {
                            res(response.data);
                        }
                    }, error => {
                    	rej(error);
                    });
            });
        }

    }
}
