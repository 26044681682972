<template>
    <div class="container" id="different">
        <h2 class="title">Fundraising done differently</h2>
        <div class="row">
            <div class="diffItem col-12 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/blu_point.png">
                <h3>Ease of Use</h3>
                <p>Starting or supporting a cause is quick and easy.</p>
                <!-- <html-fragment :html="text['different-a'].content"></html-fragment> -->
            </div>
            <div class="diffItem col-12 col-sm-4 popped">
                <div class="m">
                    <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/p_dol.png">
                    <h3>Inclusive Accessible Giving</h3>
                    <p>We provide your community with a platform to convert goods into financial support.</p>
                </div>
                <div class="rect"></div>
            </div>
            <div class="diffItem col-12 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/green_bird.png">
                <h3>Almost free</h3>
                <p>We only take 1% plus $0.20 per transaction</p>
            </div>
            <div class="diffItem col-12 col-sm-4">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/green_shake.png">
                <h3>Support a Sustainable Future</h3>
                <p>Upcycle, repurpose, and reuse your goods for good.</p>
            </div>
            <div class="diffItem col-12 col-sm-4">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/pink_people.png">
                <h3>Bring Communities Together</h3>
                <p>Build connections through a shared goal with your supporters.</p>
            </div>
            <div class="diffItem col-12 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/pto/blue_hands.png">
                <h3>Teach Social Awareness</h3>
                <p>Demonstrate how we can impact our communities.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#different {
    h2 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    .diffItem {
        flex-direction: column;
        text-align: center;
        padding: 1px 20px;
    	padding-top:40px;
        @include media-breakpoint-up(sm) {
        	padding: 35px 20px;
        	padding-top:70px;
    	}
        

        h3 {
            color: $bluetext;
            font-family: $cat;
            font-weight: 600;
            font-size: 19px;
            padding-top: 20px;
        }

        &.popped {
            position: relative;
            box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
                border-radius: 36px;
                padding: 0;
                padding-top: 35px;
            >div.m {
            	position: relative;
                background: #FFFFFF;
                height: 100%;
                z-index: 3;
                 border-radius: 36px;
                 padding: 0px 10px;
                 padding-top: 25px

            }



        }

        .rect {
        	z-index: 2;
            position: absolute;
            background: #DF6951;
            border-radius: 30px 0px 10px;
            width: 100px;
            height: 100px;
            bottom: -20px;
            left: -25px;
        }
    }

    p {
        font-family: $open-sans;
        font-size: 17px;
        font-weight: 500;
        color: $textcolor;
        padding: 3% 10%;
    }
}

</style>
