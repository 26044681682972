<template>
    <div class="ct container forms" id="editCause" v-if="cause">
        <titletag v-if="cause" :title="'Edit ' + cause.name"></titletag>
        <div class="container">
            <steps active="f" :uuid="uuid"></steps>
            <h2 class="title">Edit your Cause</h2>
            <form class="cause">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" id="name" v-model="cause.name" placeholder="Title for your Cause" class="form-control form-control-lg" required="true">
                        <label class="magic" for="name">Cause title</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <select v-model="cause.category_id" class="category form-control" id="category" required="true">
                            <option value="">Choose a Category</option>
                            <option v-for="category in causeCategories" :value="category.id">{{category.name}}</option>
                        </select>
                        <label class="magic" for="category">Category</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <textarea rows="5" class="form-control form-control-lg" type="text" id="description" v-model="cause.description" placeholder="Description of what you are raising money for" required="true"></textarea>
                        <label class="magic" for="description">Description</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text" id="ein" v-model="cause.ein" @blur="addDash" placeholder="Registered charity? Add the EIN. You can add later." class="form-control form-control-lg">
                        <label class="magic" for="ein">Ein</label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <a @click="sendForm" class="btn btn-primary">Save and continue</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Steps from './Steps';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('cause');
export default {
    props: ['uuid'],
    data() {
        return {
            cause: null,
            submitted: false
        }
    },
    components: {
        Steps
    },
    computed: {
        ...mapGetters(['getcauseByUuid', 'categories']),
        causeCategories() {
            return this.categories;
        }
    },
    mounted() {
        this.initData();
    },
    methods: {
        ...mapActions(['setCauseByUuid', 'updateCause', 'setCategories']),
        sendForm() {
            if (!this.submitted && this.validate()) {
                // nned to remove dash from ein
                if (this.cause.ein) {
                    this.cause.ein = this.cause.ein.replace("-", "");
                }
                this.updateCause(this.cause).then(response => {
                    this.$store.commit('account/setChanged', { "a": "causes" });
                    if (response.data.status == 'success') {
                        this.$store.commit('account/setChanged', { "a": "causes" });
                        this.$router.push({ name: 'editcausephoto', params: { uuid: this.uuid } });
                        
                    } else {
                        this.flashMessage({ m: response.data.error, e: 1, d: 3500 });
                    }
                });
                this.submitted = true;

            }
        },
        addDash() {
            //triggered on blur and also just before validating
            if (this.cause.ein) {
                var r = /(\D+)/g,
                    b = '',
                    e = '';
                this.cause.ein = this.cause.ein.replace(r, '');
                b = this.cause.ein.substr(0, 2);
                e = this.cause.ein.substr(2, 7);
                if (b > 1) {
                    this.cause.ein = b + '-' + e;
                }
            }

        },
        validate() {
            if (!this.cause.name) {
                this.scrollToError('#name');
                this.flashMessage({ m: 'Your cause needs a title', e: 1 });
                return false;
            }
            if (!this.cause.category_id) {
                this.scrollToError('#category');
                this.flashMessage({ m: 'Your cause needs a category', e: 1 });
                return false;
            }
            if (!this.cause.description) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your cause needs a description', e: 1 });
                return false;
            }
            if (this.cause.description.length < 10) {
                this.scrollToError('#description');
                this.flashMessage({ m: 'Your cause story needs a few words :)', e: 1 });
                return false;
            }
            if (this.cause.ein) {
                this.addDash();
                var patternEIN = /^\d{2}\-?\d{7}$/;
                if (!patternEIN.test(this.cause.ein)) {
                    this.scrollToError('#ein')
                    this.flashMessage({ m: 'EIN format 00-0000000 or 000000000', e: 1 });
                    return false;
                }
            }
            return true;
        },
        initData() {
            $('.cause').hide();
            let loader = this.$loading.show();

            this.cause = this.getcauseByUuid(this.uuid);
            if (!this.cause) {
                this.setCauseByUuid(this.uuid);
            }
            if (this.categories.length < 1) {
                this.setCategories();
            }
            var interval = setInterval(() => {
                if (this.cause) {
                    loader.hide();
                    $('.cause').show();
                    this.addDash();
                    setTimeout(this.initLabels, 200);
                    clearInterval(interval);
                } else {
                    this.cause = this.getcauseByUuid(this.uuid);
                }
            }, 50);
        },
    }
}

</script>
<style lang="scss">
#editCause {
    max-width: 750px;
}

</style>
