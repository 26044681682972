<template>
	<div class="col-sm-9 assignrecipient ct forms">
		<titletag title="Assign Recipient"></titletag>
		<h2 v-if="causeOwner" class="title">Assign the Recipient for {{cause.name}}</h2>
		<div v-if="causeOwner " class="assign container">
			<template v-if="!recipient_assigned && !recipient_assigning">
			<p>Once you submit these details we will email the recipient with instructions on how to access their funds. You might want to tell them so they look out for it. Once their identity verification is complete they will receive the funds available at the end of each month.</p>
			 <div class="form-group row">
                <div class="col-sm-12">
                    <input v-model="name" type="text" class="form-control" id="recName" placeholder="Name of the recipient">
                    <label class="magic" for="recName">Recipient Name</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input v-model="email" type="email" class="form-control" id="recEmail" placeholder="Email of the recipient">
                    <label class="magic" for="recEmail">Recipient Email</label>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <input v-model="org" type="text" class="form-control" id="recOrg" placeholder="Organization name(if applicable)">
                    <label class="magic" for="recOrg">Organization</label>
                </div>
            </div>
             <div class="form-group row" v-if="!sent">
                <div class="col-sm-12">
                    <a class="btn btn-primary mb-2" @click="send()">Assign Recipient</a>
                </div>
            </div>
            <div class="form-group row" v-if="sent">
                <div class="col-sm-12">
                    <p class="sentToRecipient">Your recipient has been notified</p>
                </div>
            </div>
        	</template>
			<div class="rec complete" v-if="recipient_assigned || recipient_assigning">
				<p>You have successfully assigned your recipient. {{cause.recipient.name}} will be contacted and guided through setting themselves up to receive their funds. If you want you could let them know to look for the email from FunditOn that they just received.</p>
				<div class="t">Name:</div><div>{{cause.recipient.name}}</div><br>
				<div class="t">Email:</div><div>{{cause.recipient.email}}</div><br>
				<div class="t">Organization:</div><div>{{cause.recipient.organization}}</div><br>
			</div>
		</div>

		<div class="rec" v-if="account_loaded && !causeOwner">
			It looks like you do not have permission for this action. You need to be logged in as the person who started the cause mentioned in the email which linked you here.
		</div>
		
	</div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const {mapActions } = createNamespacedHelpers('account');
export default {
	name: "assignrecipient",
	props: ['uuid'],
	data () {
		return {
			name: null,
			email: null,
			org: null,
			sent: 0
		}
	},
	computed: {
		account_loaded() {
			return this.$store.state.account.profile;
		},
		cause () {
			var c = this.$store.state.account.causes.find(causes => causes.uuid == this.uuid);
			if(c) {
				return c;	
			}
    		
    	},
    	causeOwner() {
    		if (this.cause && this.cause.user_id == this.$store.getters['user/currentUser'].id) {
    			return 1;
    		}else{
    			return 0;
    		}
    	},
    	recipient_assigned() {
    		if (this.cause && this.cause.connectedaccount_id) {
    			return 1;
    		}
    		return 0;
    	},
    	recipient_assigning()
    	{
    		if((this.cause && this.cause.recipient) && !this.cause.connectedaccount_id) {
    			return 1;
    		}
    		return 0;
    	}
	},
	mounted() {
		this.initLabels();
	},
	methods: {
		...mapActions(['assignRecipient']),
		validate() {
			if(!this.name) {
				this.flashMessage({m:"Please add recipient name",e:1});
				this.scrollToError('#recName')
				return 0;
			}
			if(!this.email) {
				this.flashMessage({m:"Please add recipient email",e:1});
				this.scrollToError('#recEmail')
				return 0;
			}

			if(!this.validEmail(this.email)) {
				this.flashMessage({m:"Please check email format.",e:1});
				this.scrollToError('#recEmail')
				return 0;
			}
			return 1;
		},
		send() {
			if(this.validate())
			{
				var loader = this.$loading.show();
				var payload = {
					'uuid': this.uuid,
					'name': this.name,
					'email': this.email,
					'organization': this.org
				}
				this.assignRecipient(payload).then(response => {
					if(response.success) {
						this.sent = 1;
						loader.hide();
					}else{
						loader.hide();
						this.flashMessage({m: response.message, e:1,d:5000})
					}
					
				})
			}
		}
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.assignrecipient {
	.rec{
		width: 100%;
		padding:30px;
		text-align: center;
		color:$bluetext;
		font-family: $cat;
		font-size: 18px;
		&.complete {
			text-align: left;
			padding-left: 30px;
		}
		div {
			font-size:18px;
			font-family: $cat;
			display: inline-block;
			&.t {
				width:150px;

			}
		}
	}
	.sentToRecipient {
		padding:20px;
		background-color: $ctabg;
		color:#fff;
		font-family: $cat;
		font-size: 18px;
	}
}

</style>