<template>
	<div class="container blogIndex">
		<div class="e" v-if="posts" v-for="post in posts">
			<h2 class="title">{{post.title}}</h2>
			<div  v-html="post.excerpt"></div>
			<router-link class="secondaryButton" :to="`/blog/${post.slug}`">Continue reading</router-link>
			
		</div>
	</div>
</template>
<script>
	import { createNamespacedHelpers, mapState } from 'vuex'
	const { mapActions } = createNamespacedHelpers('wp');
export default {
	name: "Blogindex",
    data() {
        return {
            title: null,
            post: null
        }
    },
    computed: {
        ...mapState({ posts: state => state.wp.postIndex })
    },
	mounted() {
		if(!this.posts.length) {
			this.setPostIndex();
		}
	},
	methods: {
		...mapActions(['setPostIndex'])
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.blogIndex {
	max-width: 650px;
	.e{
		padding: 20px 0;
	}
	.rev {
		margin: 10px 0;
	}
}
</style>