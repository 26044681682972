<template>
    <footer class="text-center container" id="footer">
        <ul class="social">
            <li><a href="https://www.instagram.com/fund.it.on/" target="_blank"><i class="fa fa-instagram"></i></a></li>
            <li><a href="https://twitter.com/fundit_on" target="_blank"><i class="fa fa-twitter"></i></a></li>
            <li><a href="https://www.facebook.com/funditon/" target="_blank"><i class="fa fa-facebook"></i></a></li>
            <li><a href="https://www.linkedin.com/company/funditon/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
        </ul>
        <ul class="">
            <!-- <li><router-link to="/about" class="nav-link"">{{ $t("footer.about") }}</router-link></li> -->
            <li>
                <router-link to="/p/faq" class="nav-link">FAQ</router-link></li>
			<li><router-link to="/p/shipping-policy" class="nav-link">Shipping</router-link></li>
			<li><router-link to="/privacy" class="nav-link">{{ $t("footer.privacy") }}</router-link></li>
		    <li><router-link to="/terms" class="nav-link">{{ $t("footer.terms") }}</router-link></li>
		    <li><router-link to="/contactus" class="nav-link">{{ $t("footer.contact") }}</router-link></li>
		</ul>
		<div class=" copy">© {{year}} FunditOn 1919 14th St #700, Boulder, CO 80302.</div>
    </footer>
</template>
<script>
export default {
    watch: {
        $route: function(to, from) {
            $('#footer').hide();
            $('#footer').delay(2000).fadeIn(500);
        }
    },
    mounted() {
        $('#footer').hide();
        $('#footer').delay(1000).fadeIn(500);
    },
    computed: {
    	year() {
    		return new Date().getFullYear();
    	}
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    left: 0;
    font-family: $cat;
    flex-direction: column;
    // margin-top: 20px;
    background-color: transparent;
    box-sizing: border-box;
    // clear:both;
    // padding: 10px;
    opacity: 1;
    min-height: 60px;
    height: auto;

    ul {
        list-style: none;
        width: 100%;
        padding-left: 0;
        margin: 0;

        li {
            display: inline-block;

            a {
                color: $bluetext;
                font-size: 11px;
                outline: none;
            }

        }

        &.social {
            li {
                padding: 0 10px;
            }

            a {
                font-size: 25px;
            }
        }
    }

    .copy {
        font-size: 9px;
        padding-bottom: 10px;
    }
}

</style>
