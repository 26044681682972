export default {
    namespaced: true,
    state: {
        autocomplete: [],
        full: null,
        expires: null
    },
    mutations: {
        settingAutocomplete(state, payload) {
            var expire = (new Date().valueOf()) + 300000;
            var results = payload.results;
            if(results.length ==  0) {
            	results = [{'label' :'No results found','url_str':'','category':'Searching for '+ payload.key}];
            }
             
            var item = {
                key: payload.key,
                results: results,
                expires: expire
            };
            state.autocomplete.unshift(item);
        }
    },
    actions: {
        setautocomplete({ commit, state }, payload) {
            axios.get('/api/search/?term=' + payload)
                .then((response) => {
                    commit('settingAutocomplete', response.data);
                })
                .catch(() => {
                    
                });
        }
    },
    getters: {
        autocomplete(state, getters) {
            return (term) => {
                var data = state.autocomplete.find(item => item.key == term);
                if (data) {
                    return data.results;
                }
            }
        }
    }
}
