import { render, staticRenderFns } from "./Notfound.vue?vue&type=template&id=d8c0461a"
import script from "./Notfound.vue?vue&type=script&lang=js"
export * from "./Notfound.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports