<template>
    <div class="ct container verifying logins">
        <titletag title="Link sent for Verification"></titletag>
        <div class="login row justify-content-center">
            <div class="card">
                <div class="card-head">Link sent for verification</div>
                <div class="card-body">
                    <p>Please check your emails and click the link to verify your email address</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
