<template>
    <div class="ct container reset logins">
        <titletag title="Reset Password"></titletag>
        <div class="login row justify-content-center">
            <div class="card card-default">
                <div class="card-head">{{$t('login.reset password')}}</div>
                <div class="card-body">
                    <div v-if="has_error" class="alert alert-processing" role="alert">
                        {{$t(`messages.${message}`)}}
                    </div>
                    <form autocomplete="off" @submit.prevent="resetPassword" method="post">
                        <div class="form-group row">
                            <i class="fas fa-envelope"></i>
                            <input id="email" type="email" v-model="email" class="form-control f" :placeholder="$t('login.email')" autocomplete="username">
                        </div>
                        <div class="form-group row">
                            <i class="fas fa-lock"></i>
                            <input id="pass1" type="password" v-model="password" class="form-control" :placeholder="$t('login.password')" autocomplete="current-password">
                        </div>
                        <div class="form-group row">
                            <i class="fas fa-lock"></i>
                            <input id="pass2" type="password" v-model="password_confirmation" class="form-control l" :placeholder="$t('login.confirm password')" autocomplete="current-password">
                        </div>
                        <div class="form-group row">
                            <input class="submit" type="submit" :value="$t('login.update')">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            token: null,
            email: null,
            password: null,
            password_confirmation: null,
            has_error: false,
            message: ''
        }
    },
    methods: {
        validate() {
        	if(!this.email) {
				this.flashMessage({m:"Please add your email",e:1});
				this.scrollToError('#email')
				return 0;
			}

			if(!this.validEmail(this.email)) {
				this.flashMessage({m:this.$t('messages.bademail'),e:1});
				this.scrollToError('#email')
				return 0;
			}
            if (this.password.length < 8) {
                this.scrollToError('#pass1');
                this.flashMessage({ m: this.$t('messages.chars'), e: 1 });
                return false; 
            }
            if (this.password != this.password_confirmation) {
                this.scrollToError('#pass1');
                this.flashMessage({ m: this.$t('messages.mismatch'), e: 1 });
                return false; 
            }  
            return true;
           
        },
        resetPassword() {
            if (this.validate()) {
                let loader = this.$loading.show();
                axios.post("/api/auth/reset/password", {
                        token: this.$route.params.token,
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    })
                    .then(result => {
                        this.has_error = true;
                        this.message = result.data.message;
                        if (this.message == 'successful reset') {
                            setTimeout(() => {
                                $('.alert').fadeOut()
                                this.$router.push('/login');
                            }, 1000);
                        }
                         loader.hide();
                    }, error => {
                        this.has_error = true;
                        if (error.data.errors.password.length) {
                            if (error.data.errors.password[0] == 'The password must be at least 8 characters.') {
                                this.message = 'chars';
                            }
                            if (error.data.errors.password[0] == 'The password confirmation does not match.') {
                                this.message = 'mismatch';
                            }
                        } else {
                            this.message = 'failed';
                        }
                         loader.hide();
                    });
            }
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

.login.reset {}

</style>
