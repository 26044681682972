<template>
	<div class="container">
		<h2 class="title"></h2>
	</div>
</template>
<script>
export default {
	name: "acceptdelivery",
	props: ['uuid'],
	data () {
		return {
		}
	},
	computed: {

	},
	mounted() {
		this.accept();
	},
	methods: {
		accept() {
			axios.post('/api/accept_delivery',{"uuid" : this.uuid})
				.then(response => {
					if(response.data.status) {
						this.flashMessage({m:"Thank you for accepting your item. The funds will be released to the cause later today",d:2000});
						this.$router.push({ name: 'purchases'});
					}else{
						this.flashMessage({m:"There was a problem with your request. The funds will still be released just a few days later",e:1,d:2000});
					}
					
				});
				
		}
		
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

</style>