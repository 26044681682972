<template>
    <div class="modal fade" id="supportChoices"  data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                	<div class="addItem">
                    	<h4>Donate something to this marketplace.</h4>
                    	<p>Goods or anything other than a cash donation.</p>
                    	<router-link data-dismiss="modal" :to="`/gift/add/${cause.uuid}/${cause.name}`">Donate Item<i class="fa fa-arrow-right" aria-hidden="true"></i></router-link>
                    </div>
                    <div class="direct">
                    	<h4>Cash Donation</h4>
                    	<p>Nothing to donate to the marketplace but prefer to make a cash donation.</p>
                    	<a data-dismiss="modal" data-toggle="modal" data-target="#makeDonation">Donate<i class="fa fa-arrow-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['cause'],
    data() {
        return {}
    },
    mounted() {},
    methods: {}
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#supportChoices {
    .modal-content {
    	border-radius: 12px;
    }
    .modal-body {

    	.direct, .addItem {
    		width: 100%;
    		padding:5%;
    		h4 {
    			font-size: 20px;
    			color: $bluetext;
    			font-family: $cat;
    			font-weight: 600;
    		}
    		p{
    			font-size: 16px;
    			color:$textcolor;
    			font-family: $open-sans;
    		}
    		a {
    			color:$red;
    			text-transform: uppercase;
    			cursor: pointer;
    			text-decoration: none;
    			line-height: 25px;
    		}
    		i {
    			padding-left:10px;
    			font-size:0.9em;
    		}
    	}
    }
}

</style>
