export default {
	namespaced: true,
	state: {
		gift :null,
		donation:null
	},
	getters : {
		gift : (state) => {
			return state.gift;
		},
		donation : (state) => {
			return state.donation;
		}
	},
	mutations: {
		setGift(state,payload) {
			state.gift = payload;
		},
		setDonation(state,payload) {
			state.donation = payload;
		},
		chargeMade(state,payload) {
			state.gift.status = payload.status
		}
	},
	actions: {
		setGift({commit,state}, payload) {
			 return new Promise((res, rej) => {
			 	var params = undefined != payload.shipping ? payload.uuid + '/' + payload.shipping : payload.uuid;
				axios.get('/api/order_with_estimated_tax/' + params)
	             .then(response => {
	                commit('setGift',response.data);
	                res(response.data);
	            }, error => {
                    rej(error);
                })
	        });
		},
		setDonationOrder({commit,state}, payload) {
			 return new Promise((res, rej) => {
			 	axios.post('/api/donation_order', payload)
			 	.then(response => {
	                commit('setDonation',response.data);
	                res(response.data);
	            }, error => {
                    rej(error);
                })
		 	});
		}	
	}
}

