<template>
    <div class="container page howitworks" id="wpPostEmbed" v-if="howItWorks">
        <howhead :titles="howItWorks.textslots"></howhead>
        <titletag title="How FunditOn works" description="Find out how to raise money for a causes, charities and individuals.Buy and sell used items to generate income for your cause."></titletag>
        <div class="headsection">
            <!-- <html-fragment :html="howItWorks.textslots['how-it-works-head-h1'].content"></html-fragment>
            <html-fragment :html="howItWorks.textslots['how-it-works-head-h2'].content"></html-fragment> -->
            <html-fragment :html="howItWorks.textslots['how-it-works-head-content'].content"></html-fragment>
        </div>
        <ul class="nav nav-tabs" role="tablist">
            <!-- Here we use the :after content so we can handle mobile. Cause Organizers is too long -->
            <li class="nav-item"><a class="nav-link active cause" data-toggle="tab" href="#cause" role="tab" aria-controls="cause" aria-selected="false"></a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#buy" role="tab" aria-controls="buy" aria-selected="true">Buyers</a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#sell" role="tab" aria-controls="sell" aria-selected="false">Sellers</a></li>
        </ul>
        <div class="tab-content">
            <div id="cause" class="tab-pane fade show active">
                <html-fragment :html="howItWorks.textslots['how-it-works-cause-h2'].content"></html-fragment>
                <!-- <html-fragment :html="howItWorks.textslots['how-it-works-cause'].content"></html-fragment> -->
                <p>Raise passive funds through community in kind donations by adding your cause to the FunditOn marketplace</p>
                <div class="icons">
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/user.svg">
                            <h3>Create an Account</h3>
                            <p>Create your account and become part of the FunditOn community</p>
                            <router-link to="/register" class="secondaryButton rev">Create account</router-link>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/start_cause.svg">
                            <h3>Post your Cause</h3>
                            <p>Add your cause to the FunditOn marketplace by giving it a name and a brief description.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/share.svg">
                            <h3>Share your Cause</h3>
                            <p>Generate awareness by sharing your cause with your community members and on your social accounts.</p>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/funds.svg">
                            <h3>Receive your Funds</h3>
                            <p>Your funds are automatcally deposited in your bank or credited to your debit card at the end of each month.</p>
                        </div>
                    </div>
                </div>
                <router-link to="/how_to_start_a_cause" class="more secondaryButton">Learn more</router-link>
            </div>
            <div id="buy" class="tab-pane fade">
                <html-fragment :html="howItWorks.textslots['how-it-works-buy-h2'].content"></html-fragment>
                <p>Support a cause you care about by purchasing items or donating funds on the FunditOn marketplace.</p>
                <!-- <html-fragment :html="howItWorks.textslots['how-it-works-buy'].content"></html-fragment> -->
                <div class="icons">
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/user.svg">
                            <h3>Create an Account</h3>
                            <p>Create your account and become part of the FunditOn community</p>
                            <router-link to="/register" class="secondaryButton rev">Create account</router-link>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/start_cause.svg">
                            <h3>Start Shopping</h3>
                            <p>Browse new or used items on the FunditOn marketplace by cause, category or seller. You can like or share items and causes with your social community.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/phone.svg">
                            <h3>Contact the Seller</h3>
                            <p>When you find an item that catches your eye, message the seller to arrange local pickup or shipping. Learn more about FunditOn’s shipping policy</p>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/buy.svg">
                            <h3>Make your Purchase</h3>
                            <p>Once you’ve agreed upon a price, complete your transaction and enjoy knowing your money is supporting a good cause.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sell" class="tab-pane fade">
                <html-fragment :html="howItWorks.textslots['how-it-works-seller-h2'].content"></html-fragment>
                <!-- <html-fragment :html="howItWorks.textslots['how-it-works-seller'].content"></html-fragment> -->
                <p>Moving, cleaning out your closet or downsizing? Now, you can give your gently used items a second life, while supporting of a charity, cause or individual on the FunditOn marketplace.</p>
                <div class="icons">
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/user.svg">
                            <h3>Create an Account</h3>
                            <p>Create your account and become part of the FunditOn community</p>
                            <router-link to="/register" class="secondaryButton rev">Create account</router-link>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/list_item.svg">
                            <h3>List your Item</h3>
                            <p>Select a cause to support and then list an item with a brief description and photo(s)</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/share.svg">
                            <h3>Publish and Share</h3>
                            <p>Amplify the reach of your listing and the cause you are supporting by sharing on your social accounts.</p>
                        </div>
                        <div class="c col-sm">
                            <img src="https://d1812kujpqokfk.cloudfront.net/assets/how/reply.svg">
                            <h3>Respond to your Offers</h3>
                            <p>Interested buyers can message you about your item to arrange pickup. If not local FunditOn calculates postage for the buyer and sends you a shipping label.</p>
                        </div>
                    </div>
                </div>
                <router-link to="/how_to_sell" class="more secondaryButton">Learn more</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Howhead from '../common/pages/Howhead'
import { createNamespacedHelpers, mapState } from 'vuex';
const { mapActions } = createNamespacedHelpers('slots');
export default {
    name: "",
    props: [],
    components: {
        Howhead
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({ howItWorks: state => state.slots.howItWorks })
    },
    mounted() {
        if (!this.howItWorks) {
            this.setHowItWorksData().then(res => {
                this.init()
            });
        } else {
            this.init();
        }

    },
    methods: {
        ...mapActions(['setHowItWorksData']),
        init() {
            this.setActive();
            var self = this;
            $('.nav-tabs a').on("click", function() {
                setTimeout(() => {
                    self.setActive();
                }, 22)


            });

        },
        setActive() {
            $('.nav-tabs li').removeClass('active');
            $('a.active').parent('li').addClass('active');
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#wpPostEmbed.howitworks {
    p.step {
        color: $buttonblue;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 0;
    }

    h3 {
        font-family: $cat;
        color: $bluetext;
    }
    .icons {
    	
    	.c {
    		margin: 15px 0;
    	}
    	img {
    		margin-bottom:12px;
    	}
    	@include media-breakpoint-up(md) {
    		.c {
    			margin: 20px 0;
    		}
    	}
    	@include media-breakpoint-up(lg) {
    		margin: 0 -150px;
    		.c {
    			margin: 25px 0;
    		}
    	}
    }
}

.nav-tabs {
    li {
        width: 33.33%;
        text-align: center;
        vertical-align: middle;

        &.active {
            position: relative;
        }

        &.active:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $buttonblue;
            height: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

        }

        a {
            color: rgba(17, 12, 83, 0.45) !important;
        }

        a.cause:after {
            content: 'Organizers';

            @include media-breakpoint-up(sm) {
                content: 'Cause Organizers';
            }
        }

        a:hover {
            border: none;
            color: $textcolor !important;
        }

        a.active {
            color: $textcolor !important;
            font-weight: 600;
            border: none;
            background-color: #fff !important;


        }
    }


}

</style>
