<template>
    <div class="container" id="different">
        <h2 class="title">Fundraising done differently</h2>
        <div class="row">
            <div class="diffItem col-6 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/start_cause.svg">
                <html-fragment :html="text['different-a'].content"></html-fragment>
            </div>
            <div class="diffItem col-6 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/help.svg">
                <html-fragment :html="text['different-b'].content"></html-fragment>
            </div>
            <div class="diffItem col-6 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/reach.svg">
                <html-fragment :html="text['different-c'].content"></html-fragment>
            </div>
            <div class="diffItem col-6 col-sm-4">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/eco.svg">
                <html-fragment :html="text['different-d'].content"></html-fragment>
            </div>
            <div class="diffItem col-6 col-sm-4">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/community.svg">
                <html-fragment :html="text['different-e'].content"></html-fragment>
            </div>
            <div class="diffItem col-6 col-sm-4 ">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/home/icons/security.svg">
                <html-fragment :html="text['different-f'].content"></html-fragment>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["text"]
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#different {
    h2 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    .diffItem {
        flex-direction: column;
        text-align: center;

        h3 {
            color: $bluetext;
            font-family: $cat;
            font-weight: 600;
            font-size: 18px;
        }
    }

    p {
        font-family: $open-sans;
        font-size: 16px;
        font-weight: 500;
        color: $textcolor;
    }
}

</style>
