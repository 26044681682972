<template>
    <div class="col payment_card ct forms">
        <form :action="`#`" method="post" id="payment-form">
            <div class="row">
                <h3 v-show="!success">Payment Information</h3>
            </div>
            <div v-show="savedCards.length && !success && !error" class="saved_cards row">
                <div v-for="savedCard in savedCards" class="radiocheck">
                    <label>Use saved card: <img :src="'https://d1812kujpqokfk.cloudfront.net/assets/ccards/' + savedCard.brand + '.png'">...{{savedCard.last4}}
                        <input v-model="chosenCard" type="radio" class="" :value="savedCard.payment_method">
                        <span></span>
                    </label>
                </div>
                <div class="or">Or use a new card below</div>
            </div>
            <div v-show="!success && !error" class="row">
                <div id="card-element"></div>
                <div id="card-errors" role="alert"></div>
            </div>
            <div v-show="!success && !error  && !chosenCard" class="row">
                <div class="radiocheck">
                    <label>Save card for later use
                        <input v-model="save_card" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.cc-tooltip')">i</div>
                </div>
            </div>
            <div v-show="!success && !error" class="row">
                <div class="radiocheck">
                    <label>Buy anonymously
                        <input v-model="anonymous" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.anon')">i</div>
                </div>
            </div>
            <div v-show="!success && !error" class="row">
            	<div id="terms-error" role="alert" v-if="!agree && agree != null"></div>
                <div class="radiocheck">
                    <label>Agree to FunditOn <a class="terms" href="/terms" target="_blank">terms of service</a>
                        <input v-model="agree" type="checkbox">
                        <span></span>
                    </label>
                    <div data-toggle="tooltip" data-placement="top" :title="$t('checkout.agreeitem')">i</div>
                </div>
            </div>
            <div class="row pay" v-show="!success && !error">
                <button class="btn btn-primary btn-block buy">Pay with card</button>
            </div>
            <div class="row apple" v-show="!success && !error">
                <div id="payment-request-button"></div>
            </div>
            <div class="row">
                <div class="messaging" v-show="success">
                    <h3>Your payment succeeded!</h3>
                    <p>Email confirmation sent<br>Order ID:{{order_uuid}}</p>
                    <p class="thanks">Thank you for supporting<br>{{cause.name}}</p>
                    <div class="onward form-group">
                    	
                    	<router-link :to="`/c/${cause.url_str}`" class="btn-primary">Show cause</router-link>
                    	<router-link :to="`/causes`" class="btn-primary simple">Browse causes</router-link>
                    </div>
                    <share :item="cause" :key="cause.uuid" :url="'/c/' + cause.url_str"></share>
                </div>
                <div class="messaging error" v-show="error">
                    <p>Your payment did not go through</p>
                    <p>{{error_message}}</p>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import Share from '../causes/Share'
const { mapActions } = createNamespacedHelpers('account');
export default {
    name: 'Card',
    props: {
        'order_id': null,
        'order_uuid': null,
        'cause': null,
        'gift': null,
        'savedCards': null,
    },
     components: {
    	Share
    },
    mounted() {
        this.initWhenReady();
    },
    data() {
        return {
            success: false,
            error: false,
            error_message: null,
            save_card: false,
            anonymous: false,
            payment_method: null,
            payment_intent_id: null,
            cardFilled: false,
            chosenCard: null,
            paymentButtonActive: false,
            agree: false,
        }
    },
    methods: {
    	...mapActions(['setAccountDetails']),
        initWhenReady() {
            var interval = setInterval(() => {
                if (this.gift) {
                    clearInterval(interval);
                    this.initiateStripe();
                    this.initPaymentButtons();
                    if (window.matchMedia("(max-width: 768px)").matches) {
                        $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
                    } else {
                        $('[data-toggle="tooltip"]').tooltip({ "delay": 200 });
                    }
                }
            }, 50);
        },
        setStripeStyles() {
            var style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
            return style;
        },
        make_payment() {
            let loader = this.$loading.show();
            return new Promise((res, rej) => {
                axios.post('/api/make_payment', { 'order_id': this.order_id, 'payment_method_id': this.payment_method, 'save_card': this.save_card, 'payment_intent_id': this.payment_intent_id, 'anonymous': this.anonymous, 'agree': this.agree })
                    .then(response => {
                        res(response);
                        loader.hide();
                    }, error => {
                        rej(error);
                        loader.hide();
                    });
            });
        },
        initPaymentButtons() {
            var self = this;
            var displayItems = [{ 'label': this.gift.name, 'amount': Math.round(parseFloat(this.gift.price) * 100) }];
            var amount = parseFloat(this.gift.price)  * 100;
         
            if (this.gift.shipping_price) {
                amount = amount + (parseFloat(this.gift.shipping_price) * 100);
                
                displayItems.push({
                    'label': 'Shipping',
                    'amount': Math.round(parseFloat(this.gift.shipping_price * 100))
                })
            }
            if (this.gift.tax_to_charge) {
            	amount = amount + (parseFloat(this.gift.tax_to_charge) * 100);
                displayItems.push({
                    'label': 'Estimated Taxes',
                    'amount': Math.round(parseFloat(this.gift.tax_to_charge) * 100)
                })
            }
           
            var stripe = Stripe(this.stripePublicKey());

            var paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'FunditOn',
                    amount: Math.round(amount)
                },
                displayItems: displayItems
            });
            var elements = stripe.elements();
            var prButton = elements.create('paymentRequestButton', {
                paymentRequest: paymentRequest,
            });
            // Check the availability of the Payment Request API first.
            paymentRequest.canMakePayment().then(function(result) {
                if (result) {
                    prButton.mount('#payment-request-button');
                } else {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
            });
            prButton.on("click", function(event) {
            	if(!self.agree) {
            		document.getElementById('terms-error').textContent = "Please agree to terms";
            		event.preventDefault();
            		return;
            	}
            });
            paymentRequest.on(
                'paymentmethod',
                function(ev) {
                    $('button.buy').prop('disabled', true);
                    // First, confirm the PaymentIntent without handling potential
                    // next actions to see if there are any payment errors.
                    self.save_card = false;
                    self.payment_method = ev.paymentMethod.id;
                    self.ev = ev;
                    self.make_payment().then(self.handleMakePaymentResponse);
                });
        },
        handleMakePaymentResponse(response) {
            if (response.data.error) {
                this.error = true;
                this.error_message = response.data.error;
                if (this.ev) {
                    this.ev.complete('fail');
                    $('#payment-request-button').parent('.row').hide();
                }
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
                var stripe = Stripe(this.stripePublicKey());
                stripe.handleCardAction(
                    response.data.payment_intent_client_secret
                ).then(this.handleRequiredAction);
            } else {
                this.success = true;
                if (this.ev) {
                    this.ev.complete('success');
                }
                $('#payment-request-button').parent('.row').hide();
                this.$store.commit('account/setChanged', { "a": "account" });
            }
        },
        handleStripeCreatePaymentResponse(result) {
            if (result.error) {
                this.success = false;
                $('button.buy').prop('disabled', false);
                document.getElementById('card-errors').textContent = result.error.message;
            } else {
                this.payment_method = result.paymentMethod.id;
                this.make_payment().then(this.handleMakePaymentResponse);
            }
        },
        handleRequiredAction(response) {
        	if(response.error) {
        		 this.success = false;
        		 $('button.buy').prop('disabled', false);
                document.getElementById('card-errors').textContent = response.error.message;
        	}
        	if(response.paymentIntent) {
	            this.payment_intent_id = response.paymentIntent.id;
	            this.payment_method = null;
	            this.make_payment().then(this.handleMakePaymentResponse);
        	}
        },
        initiateStripe() {
            var self = this;
            var stripe = Stripe(this.stripePublicKey());
            var elements = stripe.elements();
            var card = elements.create('card', { style: this.setStripeStyles() });
            card.mount('#card-element');
            card.addEventListener('change', function(event) {
                self.chosenCard = null;
                $('.saved_cards input').prop("checked", false);
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
                if (event.complete) {
                    //card element is good to go
                    self.cardFilled = true;
                } else {
                    self.cardFilled = false;
                }
            });
            $('.saved_cards input').on("change", function() {
            	card.clear()	
    			setTimeout(() => {
    				$('.saved_cards input').each(function(i,v) {
        				$(v).prop("checked", false);
        			});
        			$(this).prop("checked", true);
        			self.chosenCard = $(this).val();
        			self.save_card = 0;
        		},30);
            });
            // Handle form submission.

            var form = document.getElementById('payment-form');
            form.addEventListener('submit', function(event) {
            	event.preventDefault();
            	if(!self.cardFilled && !self.chosenCard) {
            		document.getElementById('card-errors').textContent = "Please fill out your card details";
            		return;
            	}
            	if(!self.agree) {
            		document.getElementById('terms-error').textContent = "Please agree to terms";
            		return;
            	}
            	// document.getElementById('terms-error').textContent = "";
                
                $('button.buy').prop('disabled', true);
                if (self.chosenCard) {
                    self.save_card = false;
                    self.payment_method = self.chosenCard;
                    self.make_payment().then(self.handleMakePaymentResponse);
                } else {
                    stripe.createPaymentMethod({
                        type: 'card',
                        card: card,
                        // billing_details: {
                        //     // Include any additional collected billing details. TODO We need the email
                        //     email: 'Jenny Rosen',
                        // },
                    }).then(self.handleStripeCreatePaymentResponse);
                }
            });
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

button[disabled] {
    background-color: $grey;
}

.StripeElement {
    box-sizing: border-box;
    height: 50px;
    padding: 15px 12px;
    border: 1px solid $formgrey;
    border-radius: 18px;
    background-color: $white;
    width: 100%;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#card-errors, #terms-error {
    width: 100%;
    font-weight:bold;
    font-size: 15px;
    color: $red;
    padding:3px 10px;
}

.payment_card {
    .row {
        &.pay {
            button {
                background-color: $red;
                color: #fff;
                height: 50px;
                width: 100%;
                border-radius: 20px;
                font-family: $cat;
                font-weight: 600;
                font-size: 15px;
            }
        }

        padding: 5px;

        h3 {
            color: $bluetext;
            font-size: 18px;
            line-height: 30px;
            padding-left: 10px;
        }
    }

}

#payment-request-button {
    border: none;
    padding: 5px 0;

    iframe {
        border-radius: 20px;
    }
}

.payment_card .messaging {
    width: 100%;
    text-align: center;
    background-color: #fff;
    margin-top: 0 10px;
    padding: 15px;
    border-radius:10px;
    border: 1px solid $formgrey;
    p {
    	font-family: $open-sans;
    	color: $bluetext;
    	font-size: 18px;
    	a {
    		color: $buttonblue;
    	}
    	&.thanks {
    		font-size: 20px;
    		font-family: $cat;
    	}
    }
    .onward {
    	display: flex;
    	padding: 0 5px;
    	>a {
    		margin: 0 5px;
    	}
    }
    .itemShare {
    	padding: 20px 5px;
    	display: block;
     h3{
    	text-transform: capitalize;
    }
}
    &.error {
        background-color: $red;
        p {
        	color: #fff;
        }
        
    }
}
.ct.forms .saved_cards {
	.radiocheck {
		margin-bottom:10px;
	}
	.or {
		font-family: $open-sans;
		color: $bluetext;
		padding-left:20px;
	}
}
.ct.forms .row.apple {
	padding:5px 15px;
}
</style>
