<template>
	<div class="col payment_card ct forms cryptoPay">
		<div v-if="!currency" class="row pay">
                <button @click="crypto_address('btc')" class="btn btn-primary btn-block buy bitcoin">Pay with <img src="https://d1812kujpqokfk.cloudfront.net/assets/bitcoinlogo.png"></button>
        </div>
       <!--  <div v-if="!currency" class="row pay">
                <button @click="crypto_address('eth')" class="btn btn-primary btn-block buy">Donate with Ethereum</button>
        </div> -->
        <div class="howToTransfer" v-if="currency">
        	<h2 class="centered">Transfer <img src="https://d1812kujpqokfk.cloudfront.net/assets/bitcoinlogo.png"></h2>
        	<p>Please transfer {{amount}} to the address below.</p>
			<p>We guarantee the US${{usd}} on receiving {{amount}} BTC.</p>
			<p>Please be aware that transferring Bitcoin Cash will result in lost crypto. Only Bitcoin accepted.</p>
        	<p>Once your transfer is confirmed by the network you will receive a confirmation email.</p>
        	<div>BTC Amount:</div>
        	<div><input ref="amount" @click="copyAmount" :value="amount"></div>
        	<div>Bitcoin Address:</div>
        	<div><input ref="address" @click="copyAddress" :value="address"></div>
        	<p>By using Bitcoin you save the cause ${{saved}} in credit card fees!</p>
        	
        </div>
	</div>
</template>
<script>
export default {
	name: "",
	props: ['order','total','anon','terms'],
	data () {
		return {
			amount: null,
			address: null,
			currency: null,
			usd: null,
			saved: null,
			agree: false,
			anonymous: false
		}
	},
	computed: {

	},
	mounted() {
	},
	methods: {
		crypto_address(crypto) {
			this.agree = $('input[name="terms').prop("checked") ? 1 : 0;
			this.anonymous = $('input[name="anon').prop("checked") ? 1 : 0;
			if(!this.agree) {
				this.flashMessage({m:'Please agree to our terms before getting a Bitcoin address',e:1,d:2500});
				return
			}
			
			if(this.total < 10) {
				this.flashMessage({m:"The minimum for a Bitcoin payment is $10 due to some platforms minimum transfers. Sending you back.",d:4000, e:1});
				setTimeout(() => {
	    			this.$router.go(-1);
	    		},3000);
	    		return;
			}
			let loader = this.$loading.show();
			axios.get('/api/crypto_address?uuid=' +this.order.uuid + '&agree='+ this.agree + '&anon='+ this.anonymous + '&crypto='+ crypto).then(response => {
				if(response.data.status) {
					this.amount = response.data.amount;	
					this.address = response.data.address;	
					this.currency = response.data.currency;
					this.usd = response.data.usd;
					this.saved = (parseFloat((this.usd * 1.9 /100)) + 0.30).toFixed(2);
					$('.no_crypto').hide();
				}else{
					// only getting here if they bypass js validating
					this.flashMessage({m:response.data.message,d:4000, e:1});
				}
				loader.hide();
			});
		},
		copyAmount() {
            this.$refs.amount.select();
            document.execCommand("copy");
            
        },
        copyAddress() {
            this.$refs.address.select();
            document.execCommand("copy");
            
        }
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#donateCheckout {
	button.btn-primary.bitcoin {
		background-color: #fff;
		color: $bluetext;
		img {
			margin-left: 10px;
		}
	}
	.howToTransfer {
		h2 {
			color:$bluetext;
			text-align: center;
			line-height:38px; //lines up with png
		}
		>div {
			text-align: center;
			width:100%;
			padding: 3px 0;
			font-family: $open-sans;
			color: $textcolor;
			font-weight: 600;
			input {
				width: 100%;
			}

		}
	}
}

</style>