<template>
    <div class="col-sm-9 myOrders">
        <titletag title="Order History"></titletag>
        <h2 class="title">Purchase History</h2>
        <template v-if="purchases.length">
            <!-- Desktop -->
            <div class="desktop row tablehead">
                <div class="col">Item</div>
                <div class="col-2">Category</div>
                <div class="col-1">Price</div>
                <div class="col-2">Status</div>
                <div class="col-2">Date</div>
            </div>
            <div v-for="item in purchases" class="myItems desktop row">
                <template v-if="item.gift_id != null">
                    <div class="col img">
                        <img v-if="item.gift && item.gift.primary_photo && item.gift.primary_photo.path_thumb" :src="getCloudFrontUrl() + item.gift.primary_photo.path_thumb">
                        <div class="description">
                            <div class="name">{{item.gift.name}}</div>
                            <div class="desc">{{item.gift.description}}</div>
                        </div>
                    </div>
                    <div class="col-2">{{item.gift.category.name}}</div>
                    <div class="col-1">${{item.order_total}}</div>
                    <div class="col-2 status" v-html="getPurchaseStatus(item)"></div>
                    <div class="col-2 receipt">{{humanDate(item.created_at)}}</div>
                </template>
                <template v-else>
                    <!--  A row for donations. This file is getting a bit complicated.
                			Let's refactor into smaler components -->
                    <div class="col img">
                      
                        <div class="description">
                            <div class="name">Donation to {{item.cause.name}}</div>
                            <div class="desc"></div>
                        </div>
                    </div>
                    <div class="col-2">Donations</div>
                    <div class="col-1">${{item.order_total}}</div>
                    <div class="col-2 status" v-html="getPurchaseStatus(item)"></div>
                    <div class="col-2 receipt">{{humanDate(item.created_at)}}</div>
                </template>
            </div>
            <!-- Mobile -->
            <div v-for="item in purchases" class="row myItems mobile">
                <!-- Need to handle donations in here -->
                <template v-if="item.gift_id != null">
                    <div class="col-12">
                        <div class="orderstatus" v-html="getPurchaseStatus(item)"></div>
                        <div class="stateDate">{{formatFullDate(item.created_at)}}</div>
                    </div>
                    <div class="col-3 img"><img v-if="item.gift.primary_photo && item.gift.primary_photo.path_thumb" :src="getCloudFrontUrl() + item.gift.primary_photo.path_thumb"></div>
                    <div class="col-9 ">
                        <h3>{{item.gift.name}}</h3>
                        <div class="pc">
                            <span class="price">${{decimalFormat(item.order_total)}}</span>
                            <span class="category"><span class="dot">&bull;</span>Category: {{item.gift.category.name}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-12 ">
                        <div class="receipt">{{humanDate(item.created_at)}}</div>
                    </div> -->
                </template>
                <template v-else>
                    <div class="col-12">
                        <div class="orderstatus" v-html="getPurchaseStatus(item)"></div>
                        <div class="stateDate">{{formatFullDate(item.created_at)}}</div>
                    </div>
                    <div class="col-12 ">
                        <h3>Donation to {{item.cause.name}}</h3>
                        <div class="pc">
                            <span class="price">${{decimalFormat(item.order_total)}}</span>
                            <span class="category"><span class="dot">&bull;</span>Donation: {{item.cause.name}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-12 ">
                        <div class="receipt">{{humanDate(item.created_at)}}</div>
                    </div> -->
                    <!--  A row for donations. This file is getting a bit complicated.
                			Let's refactor into smaler components -->
                </template>
            </div>
        </template>
        <template v-else>
            <div class="empty">
                <img src="https://d1812kujpqokfk.cloudfront.net/assets/empty/purchases.svg">
                <p class="nothing">You have’t bought anything yet. Once you buy something to support a cause your purchases will appear here.</p>
            </div>
        </template>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('account');
export default {
    props: [],
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['purchases'])
    },
    methods: {
        formatFullDate(date) {
            return moment(date).subtract(new Date().getTimezoneOffset(), 'm').format('h:mma MMM Do YYYY');
        },
        decimalFormat(price) {
            return (Math.round(parseFloat(price) * 100) / 100).toFixed(2);
        },
        getPurchaseStatus(item) {
         	// First check for refunded then shipping then assume complete
            if(Math.round(item.transaction.amount_refunded)  > 0) {
            	return 'Refunded';
            }
            if (item.shippinglabel && item.shippinglabel.tracking_status) {
                return '<span>&bull;</span><a href=https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + item.shippinglabel.tracking_number + '>' + item.shippinglabel.tracking_status.toLowerCase() + '</a>';
            }
            
            return 'Completed'; // sql only returns completed orders
        }
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

// see myItems in Gifts.vue
// some shared styles
.myOrders {
    .desktop.myItems {
        @include media-breakpoint-down(xs) {
            display: none;
        }

        .col-2.status {
        	border: none;
        	padding: 0 15px;
        	height: auto;
            a {
                color: $bluetext;
                font-family: $open-sans;
                text-transform: capitalize;
            }

            span {
                display: none;
            }
        }
        .col-2.receipt {
        	a {
        		color: $bluetext;
        		cursor: pointer;
        	}
        }
    }

    .mobile {
        .col-12 {
            border-bottom: 1px solid $formgrey;
            margin-bottom: 15px;
            padding-left: 0;
            padding-right: 0;
        }

        .col-6 {
            padding-left: 10px;
            padding-right: 10px;
        }

        .orderstatus,
        .stateDate {
            display: inline-block;
            text-transform: uppercase;
            opacity: 0.9;
            color: $bluetext;
            font-family: $open-sans;
            font-size: 11px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 18px;

        }

        .orderstatus {
            width: 45%;

            a {
                color: $bluetext;
            }

            span {
                color: $brightgreen;
                padding-right: 10px;
            }
        }

        .stateDate {
            width: 51%;
            color: #A9A7B4;
            text-align: right;
            font-weight: 600;
        }

        .receipt {
            width: 100%;
            text-align: center;
            height: 30px;
            border: 1px solid rgba(177, 176, 190, 0.2);
            border-radius: 20.5px;
            margin-left: -15px;

            a {

                color: $bluetext;
                font-family: $cat;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .orderDetails {
            width: 100%;
            text-align: center;
            height: 30px;
            background-color: $buttonblue;
            border-radius: 20.5px;
            margin-right: -15px;

            a {

                color: #fff;
                font-family: $cat;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}

</style>
