<template>
	<div class="container causeChoice">
		<h2 class="title">First find a cause to support</h2>
		<div class="form-group row">
        	<p>Start typing a cause name in the field below. Check cause image matches. </p>
            <div class="col-sm-12">
                <input id="choice" class="form-control" type="text" placeholder="Type to find a cause">
                <a :href="`/gift/add/${causeUuid}/${causeName}`" v-if="causeName" class="secondaryButton">Support this cause</a>
            </div>
        </div>
	</div>
</template>
<script>
export default {
	name: "",
	props: [],
	data () {
		return {
			causePreview: '',
			causeUuid: '',
			causeName: ''
		}
	},
	computed: {

	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			var self = this;
			setTimeout(() => {
				
				$("#choice").autocomplete({
                minLength: 3,
                source: '/api/cause_search',
                select: function(event, ui) {
                    self.causeUuid = ui.item.uuid;
                    self.causeName = ui.item.label;
                    if(ui.item.path_thumb) {
                    	self.causePreview = self.getCloudFrontUrl() + ui.item.path_thumb;
                    }else{
                    	self.causePreview = 'https://d1812kujpqokfk.cloudfront.net/assets/cause/causedefault.png';
                    }
                    $("#choice").css('background-image', 'url(' + self.causePreview + ')')
                }
            });
				$("#choice").focus();
			},500);

		}
		
	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
.causeChoice {
	p {
		padding-left:15px;
	}
	.ui-menu-item > div {
		padding:5px;
	}
}
#choice {
	font-size:14px;
	margin-bottom:20px;
	background-size: 45px;
	background-repeat: no-repeat;
	background-position: right 5px center;
	@media only screen and (min-width: 375px) {
		background-position: right 15px center;
		background-size: 65px;
	}


}
</style>