<template>
    <div id="publishBox">
        <p>Your {{type}} is not published.</p>
        <p v-if="!item.primary_photo_id && !item.primary_photo_uuid">Your {{type}} needs a photo</p>
        <a class="secondaryButton" :href="url">{{urlText}}</a>
    </div>
</template>
<script>
import { getLocalUser } from "../../helpers/auth";
export default {
    props: ['type', 'item'],
    computed: {
        url() {
            var str = '';
            if (this.type == 'cause') {
                if (!this.item.primary_photo_id && !this.item.primary_photo_uuid) {
                    str = '/cause/edit_photo/' + this.item.uuid;
                } else {
                    str = '/cause/publish/' + this.item.uuid + '/fo';
                }
            } else {
                if (!this.item.primary_photo_id && !this.item.primary_photo_uuid) {
                    str = '/gift/edit/' + this.item.uuid;
                } else {
                    str = '/gift/confirm_address/' + this.item.uuid + '/t';
                }
            }
            return str;
        },
        urlText() {
            if (!this.item.primary_photo_id && !this.item.primary_photo_uuid) {
                return 'Add Photo';
            } else {
                return 'Publish Now';
            }
        }

    },
    mounted() {
        this.showPublishLink();
    },
    methods: {
        showPublishLink() {
            setTimeout(() => {
                var user = getLocalUser();
                if (user) {
                    if (user.id == this.item.user_id && !this.item.published) {
                        $('#publishBox').slideDown();
                    }
                }
            }, 1800);
        },
    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#publishBox {
    display: none;
    position: fixed;
    padding: 7px 0;
    z-index: 3000;
    top: 1px;
    padding-top: 5px;
    z-index: 3000;
    color: $textcolor;
    background-color: #fff;
    width: 300px;
    margin-left: -150px;
    left: 50%;
    border: 1px solid rgba(177, 176, 190, 0.4);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 22px 0 rgb(17 12 83 / 8%);

    >p {
        color: $red;
        margin-bottom: 5px;
    }

    >a.secondaryButton {
        margin-bottom: 3px;
        display: inline-block;

    }
}

</style>
