export function login(credentials) {
    return new Promise((res, rej) => {
        axios.post('/api/auth/login', credentials)
            .then((response) => {
                if (response.data.user) {
                    res(response.data);
                } else {
                    rej("Wrong email or password");
                }
            })
            .catch((error) => {
                rej("Wrong email or password");
            })
    })
}

export function register(credentials) {
    return new Promise((res, rej) => {
        axios.post('/api/auth/register', credentials)
            .then((response) => {
                res(response.data);
            })
            .catch((error) => {
                rej("Problem with your registration");
            })
    })
}

export function getLocalUser() {
    const userStr = localStorage.getItem("user");
    if (!userStr) {
        return null;
    }
    return JSON.parse(userStr);
}

export function initialize(store, router) {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const currentUser = store.getters['user/currentUser'];
        const loginpath = to.fullPath;
        if (requiresAuth && !currentUser) {
        	console.log(loginpath)
            if (loginpath == '/cause/new') {
                next({ path: '/register', query: { redirect: loginpath } });
            } else {
                next({ path: '/login', query: { redirect: loginpath } });
            }
        } else if (requiresAuth && currentUser && (currentUser.expires < Date.now())) {
            store.dispatch('user/refresh').then(response => {
                next();
            });
        } else if (to.path == '/login' && currentUser) {
            next('/');
        } else {
            next();
        }
    });

    axios.interceptors.response.use(null, (error) => {
        if (error.response.status == 401 || error.response.status == 403) {
            console.log('401 or 403 in auth.js');
            store.commit('user/logout');
        }
        return Promise.reject(error.response);
    });

    if (window.GaTrackId) {
        router.afterEach((to) => {
            setTimeout(() => {
                gtag('config', window.GaTrackId, {
                    page_path: to.fullPath,
                    app_name: 'FunditOn',
                    send_page_view: true,
                });
            }, 500);
        });
    }
}
