<template>
	<div id="nextPayment">
		<h2 class="title">Scheduled Transfers</h2>
		<p>These transfers will be sent on the day shown. Depending on your financial instutution you should receive your funds in 2 business days.</p>
		<p>If you wish to transfer funds early you may but each transfer has an additional $2.25 static fee. To maximize what you receive just allow one transfer to happen automatically at the end of each month. If you take funds early and more funds clear before the end of the month, the end of month transfer will still happen automatically.</p>
		<table v-if="canShow">
            <thead>
                <tr>
                    <th class="first">Cause</th>
                    <th>Cleared Funds<div data-toggle="tooltip" data-placement="bottom" :title="$t('account.clearedfunds')">i</div></th>
                    <th>$2.25 fee</th>
                    <th>0.25% fee</th>
                    <th>Transferring<div data-toggle="tooltip" data-placement="bottom" :title="$t('account.totransfer')">i</div></th>
                    <th>Date</th>
                    <th>Status</th>
                    <th class="last">Transfer</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="account in causeAccounts" :uuid="account.uuid" v-if="account.amount > 0">
                    <td class="first"><router-link :to="`/account/causeorders/${account.url_str}`">{{account.name}}</router-link></td>
                    <td class="c">{{account.amount}}</td>
                    <td>2.25</td>
                    <td class="c">{{zeroOut(account.percentFee)}}</td>
                    <td class="c">{{zeroOut(account.next_payment)}}</td>
                    <td>{{lastDay}}</td>
                    <td>{{account.connectedaccount_confirmed == 1 ? "Approved": "Awaiting Admin"}}</td>
                    <td class="last">
                    	<a v-if="account.connectedaccount_confirmed && account.next_payment > 3 && !account.transferring" class="t" @click="confirmPay(account.uuid,account.name, zeroOut(account.next_payment))">Transfer now</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <p v-if="!canShow"><strong>Currently there are no cleared funds in your account. When funds clear a transfer will be scheduled automatically.</strong></p>
	</div>

</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('account');
export default {

	props: ['causeAccounts'],
	data () {
		return {
		}
	},
	computed: {
			lastDay() {
				var today = new Date();
				var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
				return window.moment(lastDayOfMonth).format("MM/DD/YYYY");;
			},
			canShow() {
				// at least one cause account has some cleared funds
				for(var i = 0; i < this.causeAccounts.length; i++) {
					if(this.causeAccounts[i].amount > 0) {
						return 1;
					}
				}
				return 0;
			}
	},
	mounted() {
		setTimeout(() => {
                if (window.matchMedia("(max-width: 768px)").matches) {
                    $('[data-toggle="tooltip"]').tooltip(); // no delay on touch
                } else {
                    $('[data-toggle="tooltip"]').tooltip({ "delay": 300 });
                }
            }, 200);

	},
	methods: {
		...mapActions(['setAccountDetails']),
		zeroOut(amount) {
			if(amount > 0) {
				return parseFloat(amount).toFixed(2);
			}else{
				return '0.00';
			}
		},
		confirmPay(uuid,name,amount) { // uuid here is a cause uuid
			var data = {
                    h: "Funds for " + name,
                    q: "Are you sure you want to transfer " + amount + "?",
                    y: "Yes Transfer",
                    n: "No Thanks",
                    uuid: uuid
                };
                this.confirmDialog(data, this.payNow);
		},
		payNow(bool,data) {
			if(bool) {
    			let loader = this.$loading.show();
				var p = {
					"uuid": data.uuid
				};
				axios.post('/api/pay_early',p).then(response => {
					if(response.data.status) {
						$(".c",$('tr[uuid="' + data.uuid + '"]')).text('0.00');
						this.flashMessage({m: response.data.message,d:3000});
						setTimeout(()=> {
							this.setAccountDetails().then(response => {
			                    loader.hide();
			                });
						},7000);

					}else{
						this.flashMessage({m: response.data.message,d:3500,e:1});
						setTimeout(()=> {
							window.location.href = window.location.pathname;
						},4500);
						
					}
					loader.hide();
				}, error =>{
					console.log('TODO error handling');
					loader.hide();
				});
			}
		}

	}
}
</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';
#nextPayment {
	width:100%;
	h2 {
		padding-left: 15px;
	}
	>div {
		padding-left: 15px;
		font-family: $open-sans;
		color: $textcolor;
		.status {
			padding-bottom:20px;
		}
	}
	p {
		padding: 0 15px;
	}
	a.t{
		cursor: pointer;
		text-decoration: none;
	}
}
</style>