<template>
    <div class="container" id="accountConversation">
        <div v-if="channel" class="conversationHeader container">
            <div class="user">
                <h2>
                    <div class="back" @click="goback"><i class="fas fa-arrow-left"></i></div>
                    <div class="name">
                        <div class="person"><img :src="avatar">{{otherUserName}}</div>
                    </div>
                    <i class="fa fa-refresh" aria-hidden="true" @click="refresh"></i>
                    <button v-if="channel.gift.shippable && (loggedInId !== channel.gift.user_id)"  @click="quote">
                    	<img src="https://d1812kujpqokfk.cloudfront.net/assets/shipping.svg" alt="Click for shipping quote"><span>Estimate</span>
                    </button>
                </h2>
            </div>
            <div class="itemInfo">
                <div class="image" :style="{backgroundImage:`url(${getCloudFrontUrl() + channel.gift.primary_photo.path_thumb})`}"></div>
                <div class="title">
                    <div class="name">{{channel.gift.name}}</div>
                    <router-link :to="`/g/${channel.gift.url_str}`">
                        <div class="show"><i class="far fa-eye"></i>show</div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="channel" class="conversation container">
            <!-- In here we check if it is a system message -->
            
            <cannedmessages :channel="channel" :loggedInId="loggedInId"></cannedmessages>
            <div class="row messageContainer" v-for="message in channel.messages" v-bind:class="messageClass(message)">
                <div class="messageBlock" v-bind:class="messageClass(message)">
                    <div v-bind:class="{'message ':true,'unread': (message.read == 0) && message.user_id != loggedInId}">{{message.message}}</div>
                    <div class="datePerson">
                        <div class="date">{{humanDate(message.created_at)}}</div>
                    </div>
                </div>
            </div>
            <holdrequest :key="c" :channel="channel" :loggedInId="loggedInId" @reloadMessages="reloadMessages"></holdrequest>
            <assignbuyer :key="a" :channel="channel" :loggedInId="loggedInId" @reloadMessages="reloadMessages"></assignbuyer>
            <buyercheckout :key="b" :channel="channel" :loggedInId="loggedInId" @reloadMessages="reloadMessages"></buyercheckout>
            <div class="giftMessage row">
                <i @click="sendMessage" class="send far fa-arrow-alt-circle-up"></i>
                <textarea class="form-control" placeholder="Type your message here"></textarea>
            </div>
        </div>
        <!-- <span v-if="loggedInId && ownerCanSee && channel && channel.messages.length > 0" rel="nofollow" class="reportButton" @click="report()">report user</span> -->
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import Holdrequest from './Holdrequest';
import Assignbuyer from './Assignbuyer';
import Buyercheckout from './Buyercheckout';
import Cannedmessages from './Cannedmessages';
import { mapState } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('channel');
export default {
    props: ['channel'],
    data() {
        return {
            userName: null,
            avatar: null,
            done: false,
            sending: false,
            // keys need
            a: 1,
            b: 99,
            c: 199,
        }
    },
    components: {
        Holdrequest,
        Assignbuyer,
        Buyercheckout,
        Cannedmessages
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapState({ channels: state => state.channel.channels }),
        loggedInId() {
            return null != this.$store.getters['user/currentUser'] ? this.$store.getters['user/currentUser'].id : false;
        },
        otherUserName() {
            if (this.userName) {
                return this.userName;
            }
            if (this.channel && this.channel.messages.length) {
                for (var i = 0; i < this.channel.users.length; i++) {
                    if (this.channel.users[i].id != this.loggedInId) {
                        this.userName = this.channel.users[i].name;
                        this.avatar = this.channel.users[i].avatar_url ? this.channel.users[i].avatar_url : this.getCloudFrontUrl() + 'identicons/' + this.channel.users[i].identicon;
                        return this.userName;
                    }
                }
            }
        }
    },
    methods: {
        init() {
            this.initTextarea();
            this.markAsRead();
        },
        initTextarea() {
            self = this;
            document.addEventListener('input', function(event) {
                if (event.target.tagName.toLowerCase() !== 'textarea') return;
                self.autoExpand(event.target);
            }, false);
        },
        ...mapActions(['markMessagesAsRead']),
        goback() {
            this.$emit('setActive', null);
        },
        rerender() {
        	this.a++;
    		this.b++;
    		this.c++;
        },
        refresh() {
            this.$emit('refreshMessages', this.rerender);
        },
        quote(){
        	let loader = this.$loading.show();
            axios.get('/api/get_estimate?uuid= ' +this.channel.gift.uuid).then(response => { // always returns a single quote    
                loader.hide();
                if(response.data.status) {
                	var data = {
                		h: "Shipping Estimate",
		                q: response.data.html,
		                y: "Close",
		                n: "",
                	}
                	// noop is an empty callback utility
                	this.confirmDialog(data,this.unhide);
                	$(".modal-footer button.no").hide();
                }else{
                	this.flashMessage({m: response.data.message, e:1, d:4300});
                }
            })
        },
        unhide() {
        	$(".modal-footer button.no").show();
        },
        sendMessage() {

            var text = $('textarea', '.giftMessage').val();
            if (text.length && !this.sending) {
                this.sending = true; // double sending prevented here
                let loader = this.$loading.show();
                var data = { 'message': text, 'channel_id': this.channel.id }
                this.$store.dispatch('channel/sendMessage', data)
                    .then(response => {

                        $('textarea', '.giftMessage').val("").css("height", "45px"); // set height again to handle that it grew
                        // this.initTextarea();

                        loader.hide();
                        this.sending = false;
                        this.$emit('reloadMessages');
                        // this.refresh();
                        this.markAsRead();
                    });
            }
        },
        reloadMessages() {
            this.$emit('reloadMessages',this.rerender);
        },

        markAsRead() {
            setTimeout(() => {
                if ($('.unread').length) {
                    this.markMessagesAsRead({ channel_id: this.channel.id })
                        .then(response => {
                            if (response.status == 200) {}
                        });
                }
            }, 100);
        },
        messageClass(message) {
            if (message.type == 'message') {
                return message.user_id == this.loggedInId ? 'right' : 'left';
            }
            if (message.type == 'buy_request' || message.type == 'request_accepted') {
                return message.user_id == this.loggedInId ? 'buyRequest sender right' : 'buyRequest receiver left';
            }

        }

    }
}

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '~@/_variables.scss';

#accountConversation {
    // box-shadow: 0 2px 22px 0 rgba(17, 12, 83, 0.08);
    box-shadow: 0px -5px 5px 0px rgba(17, 12, 83, .08);
    margin: 10px 0;
    padding: 8px;

    @include media-breakpoint-up(sm) {
        margin-top: 20px;
    }

    .conversationHeader {
        float: left;
        width: 100%;
        padding: 0;

        .user {
            height: 50px;
        }

        h2 {
            color: $bluetext;
            font-family: $open-sans;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
            width: 100%;
            display: flex;
            align-items: center;
            height: 30px;
            button {
            	    border: 1px solid rgba(177, 176, 190, 0.4);
				    border-radius: 25px;
				    background-color: #fff;
				    color: #51506F;
				    margin-left:10px;
				    cursor: pointer;
				    span{
				    	display: none;
				    	@media only screen and (min-width: 380px) {
				    		display: inline;
				    		padding-left:5px;
				    	}
				    }
				    &:hover {
				    	border-color: rgba(177, 176, 190, 0.8)
				    }

            }
            div.back {
                margin-left: 10px;
                margin-right: 10px;
                background-color: rgba(232, 235, 240, 0.35);
                display: inline-block;
                border-radius: 25px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                float: left;
                cursor: pointer;

                i {
                    color: $buttonblue;
                }
            }

            div.name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                img {
                    max-width: 35px;
                    height: auto;
                    border-radius: 50%;
                    margin-right: 6px;
                }
            }

        }

        .itemInfo {
            width: 100%;
            padding: 0 5%;
            margin-bottom: 10px;
            margin-right: 0;
            border: 1px solid $formgrey;
            border-radius: 10px;
            display: flex;
            align-items: center;

            .image {
                display: inline-block;
                width: 77px;
                height: 50px;
                background-size: cover;
                border-radius: 8px;
            }

            .title {
                display: inline-block;
                width: 60%;
                color: $bluetext;
                font-family: $open-sans;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;

                .name {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .show {
                    color: $buttonblue;
                    font-size: 11px;
                    text-transform: uppercase;
                    ;

                    i {
                        padding-right: 5px;
                    }
                }
            }

        }
    }

    .conversation {
        width: 100%;
        float: left;
        padding: 0;

        .messageContainer {
            min-height: 30px;
            margin: 0;
            margin-bottom: 10px;

            // width: 85%;
            &.left {
                align-items: left;
                justify-content: flex-start;
            }

            &.right {
                align-items: right;
                justify-content: flex-end;
            }


            &.buyRequest {
                // width: 100%;
                // height: 40px;
                align-items: center;
                justify-content: center;
                background-color: $formgrey;
                border-radius: 10px;

                .messageBlock {

                    >.message {
                        padding: 0;
                        color: $textcolor;
                        text-align: center;
                        text-transform: uppercase;
                        background-color: $formgrey;
                    }

                    .datePerson {
                        text-align: center;
                        background-color: $formgrey;

                        .date {
                            font-weight: 600;
                        }
                    }
                }


                &.sender {
                    .message {
                        &::after {
                            content: ' Sent';
                        }
                    }

                }

                &.receiver {
                    .message {
                        &::after {
                            content: ' Received';
                        }
                    }

                }
            }

            .messageBlock {
                display: flex;
                flex-direction: column;
                max-width: 85%;

                &.left {
                    border: 1px solid $formgrey;
                    box-sizing: border-box;
                    border-radius: 10px 10px 10px 0;
                }

                &.right {
                    background-color: $messageblue;
                    border-radius: 10px 10px 0 10px;
                }
            }

            .messageBlock>.message {
                padding: 12px;
                // background-color: $light;
                color: $textcolor;
                font-family: $open-sans;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 21px;
                white-space: pre-line;
                width: 100%;

                &.unread {
                    // background-color: lighten($light, 10%);
                }
            }

            .datePerson {
                text-align: right;
                padding: 0 12px 10px 12px;

                .date {
                    color: $dategrey;
                    font-family: $open-sans;
                    font-size: 11px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
        }
    }

    .giftMessage {
        width: 100%;
        padding: 5px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #fff;
        margin-left: 0;

        @include media-breakpoint-up(sm) {
            position: relative;
            left: null;
            bottom: null;
        }

        textarea {
            width: 100%;
            height: 45px;
            min-height: 30px;
            max-height: 50vh;
            border-radius: 30px;
            border: 1px solid $formgrey;
            padding-right: 20px;
            color: $bluetext;
            font-size: 16px;
            font-family: $open-sans;

            &.canning {
                border: 1px solid $buttonblue;
                font-weight: 600;
                // color: $buttonblue;
            }
        }

        i.send {
            position: absolute;
            bottom: 0;
            right: 0;
            margin-right: 15px;
            margin-bottom: 12px;
            color: $buttonblue;
            font-size: 30px;
            cursor: pointer;
        }
    }
}
// This is just for the modal
.cshippingQuote {
	>div {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		img { 
			max-width:100px;
		}
		>span {
			padding-left:15px;
			font-family: $open-sans;
			font-weight: 600;
			&:last-child {
				font-weight: normal;
			}
		}

	}
	
	div.parcel {
		padding-top: 15px;
		flex-direction: column;
		>div:first-child {
			font-weight: 600;
		}
		>div {
			width: 100%;
			text-align: center;
		}
	}
	div {
		width:100%;
		font-family: $open-sans;
		font-size: 16px;
	}
}
</style>
